const checkDates = (dates, reference) => {
  let today = new Date()
  let age = new Date(dates);
  let difference = today.getFullYear() - age.getFullYear() ;
  if (difference >= reference && difference < 100)
  {
    return true
  } 
    return false
}

export default checkDates;