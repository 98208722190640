import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import CpanelLayout from '../../../../layouts/AdminLayout';
import routes from '../../../../routes/names';
import { Dropdown } from 'react-bootstrap';
import { getUsers, getAllRoles, assignRoleToUser, deleteDARole } from '../../../../redux/actions';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import Spinner from '../../../../components/spinner/Spinner';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { emptifyFalseValues } from '../../../../helpers';
import DeleteRoles from '../../components/delete/DeleteModal';

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const sanitizeData = (users, roles) =>
    users.map((user, index) => ({
        ...emptifyFalseValues(user),
        names: `${user.firstName} ${user.lastName}  ${user.middleName}`,
        roles: Array.isArray(user.userRoles) ? user.userRoles.map(role => role.roles.name).join(',') : '',
        allRoles: roles,
        index: index + 1,
    }));

export const SpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccccccba;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 20;
`;

const ViewRoles = () => {
    const dispatch = useDispatch();
    const { users } = useSelector(state => state.users);
    const { roles: LoggedInRoles = [] } = useSelector(state => state.auth);
    const { roles, assignRoleLoading } = useSelector(state => state.roles);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getAllRoles());
    }, [dispatch]);

    const isAllowed = LoggedInRoles && LoggedInRoles.some((role)=>role==="super_admin" ||  role==="progr-manager")
    const onCloseDeleteModal=()=>{
        setOpenDeleteModal(false);
        setUserInfo(null)
    }
    const renderRoles = (_, row) => {
        return (
            <div>
                {Boolean(row.userRoles?.length) ? (
                    row.userRoles.map(({ roles, userId, roleId }, index) => {
                        return (
                            <span className="d-flex align-items-center">
                                {roles.label}&nbsp;
                                { isAllowed && <i className="fas fa-trash-alt text-danger" style={{fontSize:14}} onClick={() => {
                                    setUserInfo({userId, roleId, roleName:roles.label, userNames:row.names})
                                    setOpenDeleteModal(true);
                                }}></i>}
                                {index !== roles.length - 1 && <br />}
                            </span>
                        );
                    })
                ) : (
                    <span className="text-center">-</span>
                )}
            </div>
        );
    };

    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const renderOptons = (cell, row) => {
        const uniqueRole = {};
        row.userRoles &&
            row.userRoles.forEach(user => {
                uniqueRole[user.roles.name] = user.roles.name;
            });
        const roles = row.allRoles;
        const disabled = Object.keys(uniqueRole).length === roles.length;

        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle className="btn btn-light" disabled={disabled}>
                        <i className="fa fa-pen text-primary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {roles &&
                            roles.map(
                                (role, i) =>
                                    !uniqueRole[role.name] && (
                                        <Dropdown.Item href="#" onClick={() => dispatch(assignRoleToUser(row.id, role.id))}>
                                            {role.label}
                                        </Dropdown.Item>
                                    ),
                            )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: 'All', value: users?.length || [] },
        ],
    };
    const columns = [
        { dataField: 'index', text: '#', csvExport: false, sort: true },
        { dataField: 'names', text: 'Names', sort: true },
        { dataField: 'userIdentity', text: 'User ID', filterValue: (cell, row) => (row.userIdentity ? row.userIdentity : 'N/A') },
        { dataField: 'email', text: 'Email', filterValue: (cell, row) => row.email },
        { dataField: 'roles', text: 'Roles', formatter: renderRoles },
        { dataField: 'assignRole', text: 'Assign Role', formatter: renderOptons, csvExport: false },
    ];

    return (
        <CpanelLayout>
            <main>
                <div className="d-flex justify-content-between align-items-center mt-5">
                    <div className="pb-4">
                        <span className="text-title">User Roles</span>
                    </div>
                </div>
                <div>
                    {roles && (
                        <ToolkitProvider
                            keyField="userID"
                            data={sanitizeData(users, roles)}
                            columns={columns}
                            search
                            exportCSV={{
                                fileName: `DAP-USERS-ROLES-${new Date().toLocaleTimeString()}.csv`,
                                separator: ',',
                                ignoreHeader: false,
                                noAutoBOM: false,
                            }}
                        >
                            {props => (
                                <div>
                                    <div>
                                        <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                            <i class="fas fa-file-excel text-small"></i> &nbsp;Export to Excel
                                        </ExportCSVButton>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-end">
                                        <Link to={routes.dashboard.users.create} className="btn  btn-light text-primary mx-4">
                                            <i className="fa fa-plus" /> &nbsp; Add New User
                                        </Link>{' '}
                                        <Link to={routes.dashboard.editRoles} className="btn  btn-light text-primary mx-4">
                                            <i className="fa fa-plus" /> &nbsp; Edit roles
                                        </Link>{' '}
                                        <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0 text-small" placeholder="Search users roles ..." />
                                    </div>

                                    <div className="pt-3" {...(assignRoleLoading && { style: { position: 'relative' } })}>
                                        <BootstrapTable parentClassName="data-table" classes="data-table" bordered={false} {...props.baseProps} pagination={paginationFactory(options)} options={options} bootstrap4 />
                                        {assignRoleLoading && (
                                            <SpinnerWrapper>
                                                <Spinner isUpload={true} />
                                            </SpinnerWrapper>
                                        )}
                                    </div>
                                </div>
                            )}
                        </ToolkitProvider>
                    )}
                </div>
            </main>
            <DeleteRoles 
                open={openDeleteModal}
                loading={assignRoleLoading} 
                handleDeleteRole={()=>{
                    dispatch(deleteDARole(userInfo.userId, userInfo.roleId, onCloseDeleteModal))
                }} 
                onClose={onCloseDeleteModal} 
                headerText="Delete Role"
            >
                Are you sure you want to remove this role {' '}
                <b>{userInfo?.roleName}</b> to {userInfo?.userNames}?
            </DeleteRoles>
        </CpanelLayout>
    );
};

export default ViewRoles;
