import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import snakeCase from "lodash/snakeCase";
import Axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAllRoles } from "../../../../../redux/actions";

export default function EditRole({ open, onClose, role }) {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(" Role name is required"),
    label: Yup.string(),
    description: Yup.string().required(" Role description is required"),
  });
  const initialValues = {
    name: "",
    label: "",
    description: "",
    ...role,
  };

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async(values) => {
        const payload = { ...values, name: values.name.includes(" ")? snakeCase(values.name): values.name };
        try {
          const url = `${process.env.REACT_APP_BACKEND}/api/roles/${payload.id}`
          const response = await Axios.put(url,{description:payload.description, label:payload.label, name:payload.name})
          if(response.status ===200){
            toast.success(response.data.message)
            dispatch(getAllRoles());
            onClose()
          }
        } catch (error) {
          console.error(error)
        }
      },
    });
  return (
    <Modal
      show={open}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal"
      className="custom-modal"
      animation={false}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Role</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form>
          <Form.Group>
            <Form.Control
              type="text"
              name="name"
              placeholder="Role name"
              className="form-control rounded"
              value={values.name}
              disabled={true}
              onChange={(e) => {
                const { value } = e.target;
                const regex = /[a-zA-Z_ ]+/g;

                const matched = String(value).match(regex);
                let sanitizedValue = Array.isArray(matched)
                  ? matched.join("")
                  : "";

                setFieldValue("name", sanitizedValue);
              }}
            />

            {touched.name && errors.name && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            )}
          </Form.Group>

        

          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Role label"
              className="form-control rounded my-2"
              rows="5"
              name="label"
              value={values.label}
              onChange={handleChange}
            />

            {touched.label && errors.label && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {errors.label}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="textarea"
              placeholder="Role description"
              className="form-control rounded my-2"
              rows="5"
              name="description"
              value={values.description}
              onChange={handleChange}
            />

            {touched.description && errors.description && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Button
            type="button"
            className="btn btn-primary rounded px-5 mt-4"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}
