import React from "react";

export default function CircleLegend({ type, label }) {
  const color = type === "das" ? "#cf1b33" : "#078ece";
  return (
    <div>
      <span className="text-small">
        <i className="fa fa-circle" style={{ color }}></i>{" "}
        <span className="text-extra-small" style={{ color: "#808080" }}>
          {label || " "}
        </span>
      </span>
    </div>
  );
}
