import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CpanelLayout from '../../../../layouts/AdminLayout';
import {
    getAllCoTraineee,
    getAllCoAmbasadors,
    // getAllTraineesByAgeChart,
    // getTraineesAgeRangeByDa,
    getAllPartnerTraineeRange,
    getAllPartnersTraineeAttendance,
} from '../../../../redux/actions';
import moment from 'moment';

import { Doughnut, Bar, Line } from 'react-chartjs-2';

import UserSVG from './user.svg';
import UsersSVG from './users.svg';
import HomeSVG from './home.svg';

import axios from 'axios';
// import jwtDecode from "jwt-decode";

function CoOverview() {
    const dispatch = useDispatch();

    const coRangeTrainee = useSelector(state => state.chart.coRangeTrainee);

    const allTrainees = useSelector(state => state.chart.coTrainee);
    // const traineeByAge = useSelector(state => state.chart.coRangeTrainee);
    const { attendences } = useSelector(state => state.partners);

    const activeTrainees = allTrainees.filter(value => value.status.toLowerCase() === 'active');

    const allTraineesActive = activeTrainees.length;

    const ambsadors = useSelector(state => state.chart.coAmbasadors);
    const countDaActive = ambsadors.filter(value => value.isActive === true).length;

    const countTraineesThisWeek = activeTrainees.filter(value => moment(value.createdAt).isSame(moment(), 'week')).length;

    useEffect(() => {
        dispatch(getAllCoTraineee());
        dispatch(getAllCoAmbasadors());
        // dispatch(getAllTraineesByAgeChart());
        dispatch(getAllPartnerTraineeRange());
        dispatch(getAllPartnersTraineeAttendance());
        handleGetAllSap();
    }, [dispatch]);

    const TraineeCountMale = allTrainees.filter(value => value.gender.toLowerCase() === 'male').length;
    const TraineeCountFemale = allTrainees.filter(value => value.gender.toLowerCase() === 'female').length;

    const divideCountTraineeGender = allTrainees.length === 0 ? 1 : allTrainees.length;

    const traineeGender = { male: (TraineeCountMale * 100) / divideCountTraineeGender, female: (TraineeCountFemale * 100) / divideCountTraineeGender };

    const TraineeByGenderChart = {
        labels: [`Female (${Number(traineeGender?.female).toFixed(0)}%)`, `Male (${Number(traineeGender?.male).toFixed(0)}%)`],
        datasets: [
            {
                label: '# of Votes',
                data: [Number(traineeGender?.female).toFixed(0), Number(traineeGender?.male).toFixed(0)],
                backgroundColor: ['#cf1b33', '#078ece'],
                borderColor: ['#cf1b33', '#078ece'],
                borderWidth: 1,
            },
        ],
    };

    const traineeByAgeChart = {
        labels: ['10-20', '20-30', '30-40', '40-50'],
        datasets: [
            {
                label: 'By Age',
                data: coRangeTrainee,
                backgroundColor: '#078ece',
                borderColor: '#078ece',
                borderWidth: 1,
            },
        ],
    };

    const chartData = {
        // labels: ["Apr 5", "Apr 16", "Apr 17", "Apr 18", "Apr 19", "Apr 20", "Apr 21"],
        labels: [
            moment().subtract(6, 'day').format('MMMM DD'),
            moment().subtract(5, 'day').format('MMMM DD'),
            moment().subtract(4, 'day').format('MMMM DD'),
            moment().subtract(3, 'day').format('MMMM DD'),
            moment().subtract(2, 'day').format('MMMM DD'),
            moment().subtract(1, 'day').format('MMMM DD'),
            moment().subtract(0, 'day').format('MMMM DD'),
        ],

        datasets: [
            {
                label: '# Trainees',
                data: [
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(6, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(5, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(4, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(3, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(2, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(1, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(0, 'day'), 'day')).length,
                ],
                fill: true,
                backgroundColor: 'rgba(103, 189, 255, 0.7)',
                borderColor: '#115e82',
                yAxisID: 'y-axis-1',
            },
        ],
    };

    const [countSAPS, setCountSAPS] = useState(0);

    const handleGetAllSap = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partners/my-centers`);

            setCountSAPS(data?.data.length ?? 0);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <CpanelLayout>
            <Container>
                <Row>
                    <p className="text-title col-md-12 mb-3">Overview</p>

                    <Col lg={3}>
                        <Row>
                            <Col md={4}>
                                <Image src={UserSVG} width="70px" height="70px" className="img-fluid" alt="Image" />
                            </Col>

                            <Col md={8} className="px-0">
                                <p className="text-title">
                                    {countDaActive}
                                    <span className="text-small d-block">Active DAs</span>
                                    <span className="text-small d-block"></span>
                                </p>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={3}>
                        <Row>
                            <Col md={4}>
                                <Image src={UsersSVG} width="70px" height="70px" className="img-fluid" alt="Image" />
                            </Col>

                            <Col md={8} className="px-0">
                                <p className="text-title">
                                    {allTraineesActive}
                                    <span className="text-small d-block">Active Trainees</span>
                                    <span className="text-small d-block">
                                        <span>
                                            <i className="fas fa-caret-up text-success"></i> +{countTraineesThisWeek} this week
                                        </span>
                                    </span>
                                </p>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={3}>
                        <Row>
                            <Col md={4}>
                                <Image src={HomeSVG} width="70px" height="70px" className="img-fluid" alt="Image" />
                            </Col>

                            <Col md={8} className="px-0">
                                <p className="text-title">
                                    {countSAPS}
                                    <span className="text-small d-block">SAPs</span>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <p className="text-title col-md-12 mb-3">Citizens Trained</p>

                    <Col md={12}>
                        <div className="traine-box">
                            {/* <Line data={traineedByDayChart} /> */}
                            <Line data={chartData} />
                        </div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <p className="text-title col-md-12 mb-3">Trainees</p>

                    <Col md={3}>
                        <div className="dogn-box">
                            <h5>By Gender</h5>
                            <Doughnut data={TraineeByGenderChart} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="vert-box">
                            <h5>By Age</h5>
                            <Bar data={traineeByAgeChart} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </CpanelLayout>
    );
}

export default CoOverview;
