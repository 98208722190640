import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import RoutesName from './names';
import Homepage from '../pages/client/HomePage';
import RegisterPage from '../pages/client/RegisterPage';
import RegisterVerifyPage from '../pages/client/RegisterVerifyPage';
import RegisterSuccessPage from '../pages/client/RegisterSuccessPage';

import Courses from '../pages/courses/Courses';
import TraineeCertificate from '../pages/trainee-certificate/TraineeCertificate';

import SearchCertificate from '../pages/search-certificate/SearchCertificate';
// import { DAOverview } from '../dashboards/da-dashboard/pages';
import OTPPage from '../pages/client/OTPPage';
import ResetPasswordPage from '../pages/client/ResetPasswordPage';

export default function ClientRoutes({ children }) {
    return (
        <Switch>
            <Route exact path={RoutesName.home} component={Homepage} />
            <Route exact path={RoutesName.register.start} component={RegisterPage} />

            <Route path={RoutesName.register.verify} component={RegisterVerifyPage} />

            <Route path={RoutesName.register.success} component={RegisterSuccessPage} />

            <Route path="/courses" component={Courses} />

            <Route path="/trainee-certificate" component={TraineeCertificate} />
            <Route path="/search-certificate" component={SearchCertificate} />

            <Route path="/check-otp" component={OTPPage} />
            <Route path="/reset-password" component={ResetPasswordPage} />

            {children}
        </Switch>
    );
}
