import React from "react";
import "./index.css";

import { Row } from "react-bootstrap";

const COHORTS = [
  {
    id: 1,
    name: "June 2021",
    groupCounts: 5,
    completedOn: "July 23, 2021",
  },
  {
    id: 2,
    name: "May 2021",
    groupCounts: 6,
    completedOn: "April 23, 2021",
  },

  {
    id: 3,
    name: "April 2021",
    groupCounts: 7,
    completedOn: "March 23, 2021",
  },
];

export default function Cohorts() {
  return (
    <div>
      <Row>
        {COHORTS.map((cohort) => {
          return (
            <div key={cohort.id} className="cohort-card px-4 py-4 mb-3 ml-3">
              <div className="py-2 d-flex align-items-center justify-content-between">
                <span className="text-medium">{cohort.name}</span>
              </div>

              <div className="text-small">
                <span>{cohort.groupCounts} groups</span>
              </div>

              <div className="py-2">
                <span className="text-extra-small">
                  Final training completed on
                </span>
              </div>

              <div>
                <span className="text-small">{cohort.completedOn}</span>
              </div>
            </div>
          );
        })}
      </Row>
    </div>
  );
}
