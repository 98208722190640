import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withNamespaces } from 'react-i18next';
import Header from "./components/Header";
import Footer from "./components/Footer";

import "./index.css";
import { Link } from "react-router-dom";
import Routes from "../../routes/names";
import BackLink from "../../components/commons/back-link/BackLink";

const RegisterSuccessPage = ({ t }) => {
  return (
    <div className="App">
      <Header />

      <div className="body-content bg-dark-primary pt-3">
        <Container>
          <Row>
            <Col md={12}>
              <BackLink />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <div className="box">
                <p className="text-title mb-2">{`${t(`Gusaba guhugurwa`)}`}</p>
                <p className="text-normal">
                  {`${t(`Murakoze kwiyandikisha`)}`}. {`${t(`Intore mu ikoranabuhanga yo mu kagari kanyu izabahamagara ibahe gahunda y'amahugurwa`)}`}.
                </p>
                <Link
                  to={Routes.register.start}
                  className="btn btn-primary  btn-expanded  px-5  mt-2 mt-lg-3"
                >
                  {`${t(`Gusubira inyuma`)}`}
                </Link>{" "}
                &nbsp;&nbsp;
                <Link
                  to={Routes.courses}
                  className="btn btn-primary btn-expanded px-5 mt-1  mt-lg-3 "
                >
                  {`${t(`Kureba amasomo`)}`}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </div>
  );
};
export default withNamespaces() (RegisterSuccessPage);
