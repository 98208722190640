import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { checkOTP } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../utils/LoadingSmall";
import BackLink from "../commons/back-link/BackLink";
import routes from "../../routes/names";

const CheckOTP = ({ t }) => {
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState({
    otp: "",
  });
  const loading = useSelector((state) => state.auth.submitData);
  const otpFailure = useSelector((state) => state.auth.otpFailure);
  const otpSuccess = useSelector((state) => state.auth.otpSuccess);

  const emailReceivedOTP = localStorage.email;

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  };

  const msg ={
    error: `${t(`Sorry your OTP does not match or expired!`)}`,
    success: `${t('You are ready to reset your password')}`
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    e.preventDefault();
    setValidated(true);
    if (user.otp) {
      dispatch(checkOTP(user, msg));
    }
  };

  if (otpSuccess) {
    localStorage.removeItem("email");
    setTimeout(() => {
      window.location.href =`/reset-password`
	  }, 1000);
  }

  // if (token) {
  // 	return <Redirect to='/da-dashboard/overview' />;
  // }

  return (
    <div className="body-content bg-dark-primary pt-5">
      <Container>
        <div className="pb-4">
          {" "}
          <Row>
            <Col md={12}>
              <BackLink path={routes.home} />
            </Col>
          </Row>
        </div>

        <div className="box">
          <Container>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <p className="text-small mt-3">
                {`${t(`To ensure further security. Enter the OTP send to`)}`}{" "}
                <b>{emailReceivedOTP}</b> {`${t(`below to continue`)}`}.
              </p>
              <Row>
                <Col lg={4} sm={12}>
                  <Form.Group className="mt-3">
                    <Form.Control
                      required
                      type="number"
                      name="otp"
                      value={user.otp}
                      placeholder="OTP Code"
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      OTP is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={4} sm={12}>
                  <Form.Group className="mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      className="btn-block"
                      style={{ width: "100%" }}
                    >
                      {`${t(`Submit`)}`}
                    </Button>
                    {loading && <Loading />}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={4} sm={12}>
                  {/* {otpSuccess && <Alert className="alert-info">{otpSuccess}</Alert>} */}
                  {/* {otpFailure && (
                    <Alert className="alert-danger">{`${t(`Sorry your OTP does not match or expired!`)}`}</Alert>
                  )} */}
                  <Link to="/" className="btn-link text-primary text-small mt-3">
                    {`${t(`Gusubira inyuma`)}`}
                  </Link>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default withNamespaces()(CheckOTP);
