import React from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import ResetPassword from '../../components/auth/ResetPassword';

const ResetPasswordPage = () => {
	return (
		<div className='App'>
			<Header />
			<ResetPassword />
			<Footer />
		</div>
	);
};

export default ResetPasswordPage;
