import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Form, Button, Alert } from 'react-bootstrap';
import { sendFeedback } from '../../redux/actions';
import Loading from '../../utils/LoadingSmall';
import { toast } from 'react-toastify';


export const SendFeedback = ({ t }) => {
	const [message, setMessage] = useState({
		email: '',
		yourmessage: '',
	});
	const loading = useSelector(state => state.feedback.sendFeedbackData);
	const loginFailure = useSelector(state => state.feedback.sendFeedbackFailure);
	const loginSuccess = useSelector(state => state.feedback.sendFeedbackSuccess);

	const dispatch = useDispatch();

	const handleChange = e => {
		const { name, value } = e.target;
		setMessage(message => ({ ...message, [name]: value }));
	};

	const handleSubmit = e => {
		e.preventDefault();
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.stopPropagation();
		}
		const body = {
			email: message.email,
			body: message.yourmessage,
		};
		
		
		if(message.yourmessage === '') {
			// const toastStyle = {
			// 	position: "top-right",
			// 	autoClose: 5000,
			// 	hideProgressBar: false,
			// 	closeOnClick: true,
			// 	pauseOnHover: true,
			// 	draggable: true,
			// 	progress: undefined,
			// }
			toast.error(`${t(`Message body is required`)}!`)
		} else {
			const message = {
				success :`${t(`Your feedback has been submitted successfully`)}`,
				error :`${t(`Something went wrong, please try again!`)}`,
			}
			dispatch(sendFeedback(body, message));

			setTimeout(function () {
				setMessage({
					email: '',
					yourmessage: ''
				})
			}, 1000);
		}
	};

	return (
		<Form onSubmit={handleSubmit} className='text-center'>
			<Form.Control
				type='email'
				name='email'
				value={message.email}
				placeholder={`${t(`Email ntabwo ari ngombwa`)}`}
				onChange={handleChange}
			/>
			<Form.Control
				// required
				name='yourmessage'
				value={message.yourmessage}
				placeholder={`${t(`Andika igitekerezo cyawe hano`)}`}
				onChange={handleChange}
				as='textarea'
				rows={5}
				className='mt-2'
			/>

			<Button
				type='submit'
				variant='primary'
				className='mt-3'
				style={{ width: '100%' }}
			>
				{`${t(`Submit`)}`}
			</Button>
			{loading && <Loading />}

			{/* {loginFailure && <Alert className='alert-danger'>{loginFailure}</Alert>} */}
			{/* {loginSuccess && <Alert className='alert-info'>{`${t(`Your feedback has been submitted successfully`)}`}!</Alert>} */}
		</Form>
	);
};
