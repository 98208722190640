import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import './index.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

import Routes from '../../../../../routes/names';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { updateTraineeStatus, makeTraineeCaptain, deleteTraineeAction } from '../../../../../redux/actions';

import { emptifyFalseValues } from '../../../../../helpers';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { reverse } from 'named-urls';
import { isEmpty } from 'lodash';

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const sanitizeData = trainees =>
    trainees.map((trainee, index) => ({
        ...emptifyFalseValues(trainee),
        names: trainee.firstName + ' ' + trainee.lastName + ' ' + trainee.middleName,
        index: index + 1,
        createdAt: trainee.createdAt ? moment(trainee.createdAt).format('DD-MM-YYYY') : 'N/A',
    }));

function TraineesTables({ trainees, search, roles }) {
    const dispatch = useDispatch();
    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const onTraineeStatusUpdate = (id, status) => {
        dispatch(updateTraineeStatus(id, status));
    };
    const onMakingTraineeCapt = id => {
        dispatch(makeTraineeCaptain(id));
    };

    const onDelete = id => {
        dispatch(deleteTraineeAction(id));
    };

    const renderCaptain = (cell, row) => {
        if (row.isCaptain == 'true') return <i className="fas fa-star text-primary "></i>;
        return null;
    };

    const renderOptons = (cell, row) => {
        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle variant="light" className="text-dark">
                        Actions &nbsp; <i className="fas fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {/* <Dropdown.Item>
              {" "}
              <i className="fas fa-pencil-alt text-primary "></i> &nbsp;Edit
            </Dropdown.Item> */}
                        {/* <div className="pl-3">
              <Dropdown drop="left">
                <Dropdown.Toggle
                  variant="light"
                  className="text-dark text-small"
                >
                  <i className="fas fa-angle-left"></i> &nbsp;{" "}
                  <i className="fas fa-sync text-primary"></i> &nbsp;Change Status
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <span
                      onClick={() => onTraineeStatusUpdate(row.id, "Active")}
                      className="text-small"
                    >
                      Active
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span
                      onClick={() => onTraineeStatusUpdate(row.id, "Inactive")}
                      className="text-small"
                    >
                      Inactive
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span
                      onClick={() => onTraineeStatusUpdate(row.id, "Dropout")}
                      className="text-small"
                    >
                      Dropout
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
                        {!roles.includes('SU') && (
                            <Dropdown.Item>
                                <span className="text-small" onClick={() => onDelete(row.id)}>
                                    {' '}
                                    <i className="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                                </span>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item>
                            <Link to={reverse(Routes.dashboard.trainees.view, { id: row.id })}>
                                {' '}
                                <span className="text-small">
                                    {' '}
                                    <i className="fas fa-info-circle text-primary "></i> &nbsp; More details
                                </span>
                            </Link>
                        </Dropdown.Item>

                        {/* <Dropdown.Item>
              {" "}
              <span
                className="text-small"
                onClick={() => onMakingTraineeCapt(row.id)}
              >
                {" "}
                <i className="fas fa-star text-primary "></i> &nbsp; Make Captain
              </span>
            </Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: 'All',
                value: trainees?.length || 0,
            },
        ],
    };
    const columns = [
        { dataField: 'index', text: '#', csvExport: false, sort: true },
        {
            dataField: 'names',
            text: 'Names',
            sort: true,
            csvExport: true,
            filterValue: (cell, row) => row.names,
            formatter: (_, row) => !isEmpty(row.names) && <span className="text-nowrap"> {row.names}</span>,
        },
        {
            dataField: 'isCaptain',
            // text: "The captain",
            formatter: renderCaptain,
            filterValue: (cell, row) => row.isCaptain,
        },
        {
            dataField: 'traineeIdentity',
            text: 'Trainee_ID',
            filterValue: (cell, row) => (row.traineeIdentity ? row.traineeIdentity : 'N/A'),

            csvExport: true,
        },

        {
            dataField: 'email',
            text: 'Email',
            filterValue: (cell, row) => row.email,

            csvExport: true,
        },

        {
            dataField: 'phone',
            text: 'Phone',
            filterValue: (cell, row) => row.phone,

            csvExport: true,
        },
        {
            dataField: 'province',
            text: 'Province',
            filterValue: (cell, row) => row.province,
            formatter: (_, row) => !isEmpty(row.province) && <span className="text-nowrap">{row.province}</span>,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'district',
            text: 'District',
            filterValue: (cell, row) => row.district,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'sector',
            text: 'Sector',
            filterValue: (cell, row) => row.sector,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'cell',
            text: 'Cell',
            filterValue: (cell, row) => row.sector,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            filterValue: (cell, row) => row.status,
            csvExport: true,
        },
        {
            dataField: 'affiliated',
            text: 'Affiliated',
            filterValue: (cell, row) => row.affiliated,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'gender',
            text: 'Gender',
            filterValue: (cell, row) => (row.gender ? row.gender : 'N/A'),
            csvExport: true,
        },
        {
            dataField: 'createdAt',
            text: 'Joined_Date',
            csvExport: true,
        },
        {
            dataField: 'options',
            text: 'Options',
            formatter: renderOptons,
            csvExport: false,
        },
    ];
    return (
        <div>
            <ToolkitProvider
                keyField="traineeIdentity"
                data={sanitizeData(trainees)}
                columns={columns}
                search
                exportCSV={{
                    fileName: `DAP-TRAINEES-${new Date().toLocaleTimeString()}.csv`,
                    separator: ',',
                    ignoreHeader: false,
                    noAutoBOM: false,
                }}
            >
                {props => (
                    <div>
                        <div className="d-flex align-items-end justify-content-between">
                            <div>
                                <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                    <i class="fas fa-file-excel text-small"></i> &nbsp;Export to CSV
                                </ExportCSVButton>
                            </div>
                            {search && <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0" placeholder="Search Trainees ..." />}
                        </div>

                        <div className="pt-3">
                            <BootstrapTable classes="data-table" bordered={false} {...props.baseProps} pagination={paginationFactory(options)} options={options} bootstrap4 />
                        </div>
                    </div>
                )}
            </ToolkitProvider>
        </div>
    );
}

export default TraineesTables;
