export const REQUEST_SEND_MESSAGE = "REQUEST_SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";

export const REQUEST_GET_SENT_MESSAGE = "REQUEST_GET_SENT_MESSAGE";
export const GET_SENT_MESSAGE_SUCCESS = "GET_SENT_MESSAGE_SUCCESS";
export const GET_SENT_MESSAGE_FAILED = "GET_SENT_MESSAGE_FAILED";

export const REQUEST_GET_RECEIVED_MESSAGE = "REQUEST_GET_RECEIVED_MESSAGE";
export const GET_RECEIVED_MESSAGE_SUCCESS = "GET_RECEIVED_MESSAGE_SUCCESS";
export const GET_RECEIVED_MESSAGE_FAILED = "GET_RECEIVED_MESSAGE_FAILED";
