/* eslint-disable import/no-anonymous-default-export */
import {
    REQUEST_TRAINEE_CREATION,
    TRAINEE_CREATION_FAILED,
    TRAINEE_CREATION_SUCCESS,
    REQUEST_GET_TRAINEES,
    GET_TRAINEES_SUCCESS,
    GET_TRAINEES_FAILED,
    REQUEST_SEARCH_CERTIFICATE,
    SEARCH_CERTIFICATE_SUCCESS,
    SEARCH_CERTIFICATE_FAILED,
    REQUEST_VIEW_CERTIFICATE,
    VIEW_CERTIFICATE_SUCCESS,
    VIEW_CERTIFICATE_FAILED,
    REQUEST_UPDATE_STATUS,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_ERROR,
    REQUEST_CAPTAIN_MAKING,
    MAKE_CAPTAIN_SUCCESS,
    MAKE_CAPTAIN_FAILED,
    REQUEST_TRAINEE_DELETE,
    DELETE_TRAINEE_FAILED,
    DELETE_TRAINEE_SUCCESS,
    REQUEST_GET_DA_TRAINEES,
    GET_DA_TRAINEES_SUCCESS,
    GET_DA_TRAINEES_FAILED,
    REQUEST_GET_PENDING_TRAINEES,
    GET_PENDING_TRAINEES_SUCCESS,
    GET_PENDING_TRAINEES_FAILED,
    REQUEST_APPROVE_TRAINEES,
    APPROVE_TRAINEES_SUCCESS,
    APPROVE_TRAINEES_FAILED,
    REQUEST_GET_ALL_TRAINEE_DA_BY_PROVINCE,
    GET_ALL_TRAINEE_DA_BY_PROVINCE_SUCCESS,
    GET_ALL_TRAINEE_DA_BY_PROVINCE_FAILED,
    REQUEST_TRANSFER_TRAINEE,
    TRANSFER_TRAINEE_SUCCESS,
    TRANSFER_TRAINEE_FAILED,
    REQUEST_GET_PENDING_TRANSFER,
    GET_PENDING_TRANSFER_SUCCESS,
    GET_PENDING_TRANSFER_FAILED,
    REQUEST_APPROVE_TRANSFERS,
    APPROVE_TRANSFERS_SUCCESS,
    APPROVE_TRANSFERS_FAILED,
    REQUEST_UPDATE_GROUP,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_ERROR,
    REQUEST_GET_TRAINEE,
    GET_TRAINEE_SUCCESS,
    GET_TRAINEE_FAILED,
    CLEAR_SUCCESS_STATUS,
    VIEW_TRAINEE,
    REQUEST_DOWNLOAD_CREATION,
    DOWNLOAD_CREATION_SUCCESS,
    DOWNLOAD_CREATION_FAILED,
} from '../types';

const initialState = {
    success: false,
    message: '',
    error: '',
    getTraineeError: '',
    loading: null,
    trainees: [],
    trainee: {},
    updateSuccess: false,
    makeCaptain: false,
    data: null,
    pendingTransfers: [],
    view: {},
    downloads: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_TRAINEE_CREATION:
            return {
                ...state,
                loading: true,
                trainees: [],
            };

        case TRAINEE_CREATION_SUCCESS:
            return {
                ...state,
                message: action.payload,
                success: true,
                loading: null,
                error: null,
            };
        case TRAINEE_CREATION_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };
        case REQUEST_GET_TRAINEES:
            return {
                ...state,
                loading: true,
                traineesLoading: true,
                trainees: [],
            };
        case GET_TRAINEES_SUCCESS:
            return {
                ...state,
                success: true,
                loading: null,
                error: null,
                trainees: action.payload,
            };
        case GET_TRAINEES_FAILED:
            return {
                ...state,
                getTraineeError: action.payload,
                success: false,
                loading: null,
            };
        case REQUEST_SEARCH_CERTIFICATE:
            return {
                ...state,
                loading: true,
                traineesLoading: true,
                trainees: [],
            };
        case SEARCH_CERTIFICATE_SUCCESS:
            return {
                ...state,
                success: true,
                loading: null,
                error: null,
                trainee: action.payload,
            };
        case SEARCH_CERTIFICATE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };
        case REQUEST_VIEW_CERTIFICATE:
            return {
                ...state,
                loading: true,
                traineesLoading: true,
                trainees: [],
            };
        case VIEW_CERTIFICATE_SUCCESS:
            return {
                ...state,
                success: true,
                loading: null,
                error: null,
                trainee: action.payload,
            };
        case VIEW_CERTIFICATE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };

        case REQUEST_UPDATE_STATUS:
            return {
                ...state,
                loading: true,
                updateSuccess: false,
                trainees: [],
            };
        case UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                updateSuccess: true,
                message: action.payload,
            };
        case UPDATE_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case REQUEST_CAPTAIN_MAKING:
            return {
                ...state,
                loading: true,
                makeCaptain: false,
                trainees: [],
            };
        case MAKE_CAPTAIN_SUCCESS:
            return {
                ...state,
                loading: false,
                makeCaptain: true,
                message: action.payload,
            };
        case MAKE_CAPTAIN_FAILED:
            return {
                ...state,
                loading: false,
                makeCaptain: false,
                error: action.payload,
            };
        case REQUEST_TRAINEE_DELETE:
            return {
                ...state,
                loading: true,
                trainees: [],
            };
        case DELETE_TRAINEE_SUCCESS:
            return {
                ...state,
                loading: false,
                updateSuccess: true,
                message: action.payload,
            };
        case DELETE_TRAINEE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case REQUEST_GET_DA_TRAINEES:
            return {
                ...state,
                loading: true,
                traineesLoading: true,
                trainees: [],
            };
        case GET_DA_TRAINEES_SUCCESS:
            return {
                ...state,
                success: true,
                loading: null,
                error: null,
                trainees: [...action.payload.data],
            };
        case GET_DA_TRAINEES_FAILED:
            return {
                ...state,
                getTraineeError: action.payload,
                success: false,
                loading: null,
            };
        case REQUEST_GET_PENDING_TRAINEES:
            return {
                ...state,
                loading: true,
                traineesLoading: true,
                trainees: [],
            };
        case GET_PENDING_TRAINEES_SUCCESS:
            return {
                ...state,
                success: true,
                loading: null,
                error: null,
                trainees: [...action.payload.data],
            };
        case GET_PENDING_TRAINEES_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };
        case REQUEST_APPROVE_TRAINEES:
            return {
                ...state,
                loading: true,
                success: false,
                // trainees: []
            };

        case APPROVE_TRAINEES_SUCCESS:
            return {
                ...state,
                message: action.payload,
                success: true,
                loading: null,
                error: null,
            };
        case APPROVE_TRAINEES_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };
        case REQUEST_GET_ALL_TRAINEE_DA_BY_PROVINCE:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_TRAINEE_DA_BY_PROVINCE_SUCCESS:
            return {
                ...state,
                success: true,
                loading: null,
                error: null,
                data: action.payload,
            };
        case GET_ALL_TRAINEE_DA_BY_PROVINCE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };
        case TRANSFER_TRAINEE_SUCCESS:
            return {
                ...state,
                message: action.payload,
                loading: null,
                success: true,
            };
        case REQUEST_TRANSFER_TRAINEE:
            return {
                loading: true,
                trainees: [],
                success: false,
            };
        case TRANSFER_TRAINEE_FAILED:
            return {
                ...state,
                loading: null,
                error: action.payload,
            };
        case REQUEST_GET_PENDING_TRANSFER:
            return {
                ...state,
                loading: true,
                trainees: [],
            };
        case GET_PENDING_TRANSFER_SUCCESS:
            return {
                ...state,
                loading: false,
                pendingTransfers: action.payload,
            };
        case GET_PENDING_TRANSFER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case REQUEST_APPROVE_TRANSFERS:
            return {
                ...state,
                loading: true,
                trainees: [],
            };

        case APPROVE_TRANSFERS_SUCCESS:
            return {
                ...state,
                message: action.payload,
                success: true,
                loading: null,
                error: null,
            };
        case APPROVE_TRANSFERS_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };
        case REQUEST_UPDATE_GROUP:
            return {
                ...state,
                loading: true,
                trainees: [],
            };
        case UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                updateSuccess: true,
                message: action.payload,
            };
        case UPDATE_GROUP_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case REQUEST_GET_TRAINEE:
            return {
                ...state,
                loading: true,
                success: false,
                trainee: {},
            };
        case GET_TRAINEE_SUCCESS:
            return {
                ...state,
                success: true,
                loading: null,
                error: null,
                trainee: { ...action.payload.data },
            };
        case GET_TRAINEE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };
        case CLEAR_SUCCESS_STATUS:
            return {
                ...state,
                updateSuccess: false,
                success: false,
            };

        case VIEW_TRAINEE:
            return {
                ...state,
                view: action.payload,
            };
        case REQUEST_DOWNLOAD_CREATION:
            return {
                ...state,
                loading: true,
                downloads: null,
            };

        case DOWNLOAD_CREATION_SUCCESS:
            return {
                ...state,
                message: action.payload,
                success: true,
                loading: null,
                error: null,
            };
        case DOWNLOAD_CREATION_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };
        default:
            return state;
    }
};
