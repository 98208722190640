import 'dotenv/config';
import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	SET_UNAUTHENTICATED,
	SENDLINK_REQUEST,
	RESET_REQUEST,
	RESET_PASSWORD_FAILURE,
	RESET_PASSWORD_SUCCESS,
	SEND_RESET_LINK_FAILURE,
	SEND_RESET_LINK_SUCCESS,
	RESET_ACCOUNT_SUCCESS,
	RESET_ACCOUNT_FAILURE,
	SET_EXPIRED,
	SUBMIT_DATA,
	OTP_SUCCESS,
	OTP_FAILURE,
} from '../types';
import axios from 'axios';
import jwtDecode from "jwt-decode";
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import {checkTokenExpiration} from '../../utils/checkExpiration';
import store from '../store/index'

const { REACT_APP_BACKEND } = process.env;

export const loginUser = (loginData, setValidated=undefined, setCredentialsValid=undefined, msg) => dispatch => {
	dispatch({ type: LOGIN_REQUEST, payload: loginData });
	axios
		.post(`${REACT_APP_BACKEND}/api/auth/login`, loginData)
		.then(res => {
			if(isEmpty(res)&&isEmpty(res.data)&&isEmpty(res.data.token)){
				dispatch({	type: LOGIN_FAILURE,	payload: "Invalid credentials",});
				return;
			}
			const { roles } = jwtDecode(res.data.token);
			setAuthorization(res.data.token);
			dispatch({ type: LOGIN_SUCCESS, payload: res.data.message, roles });
			checkTokenExpiration(res.data.token, store)
			toast.success(msg.success)
		})
		.catch(err => {
			if(typeof setValidated ==="function") setValidated(false)
			if(typeof setCredentialsValid ==="function") setCredentialsValid(false)
			toast.error(msg.error)
			dispatch({ type: LOGIN_FAILURE, payload: null });
		});
};

export const checkOTP = (otpData,msg) => dispatch => {
	dispatch({ type: SUBMIT_DATA, payload: 'submit' });
	axios
		.post(`${REACT_APP_BACKEND}/api/auth/check-otp`, otpData)
		.then(res => {
			dispatch({ type: OTP_SUCCESS, payload: res.data.message });
			localStorage.setItem('otp', res.data.otp);
			toast.success(msg.success)
		})
		.catch(err => {
			toast.error(msg.error)
			dispatch({
				type: OTP_FAILURE,
				payload: err.response ? err.response.data.error : null,
			});
		});
};

export const sendLinkResetPassword = sendtoData => dispatch => {
	dispatch({ type: SENDLINK_REQUEST, payload: sendtoData });
	axios
		.post(`${REACT_APP_BACKEND}/api/auth/send-forgot-password`, sendtoData)
		.then(res => {
			dispatch({ type: SEND_RESET_LINK_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			dispatch({
				type: SEND_RESET_LINK_FAILURE,
				payload: err.response ? err.response.data.message : null,
			});
		});
};

export const resetPassword = (resetData, msg, routeHistory=undefined) => dispatch => {
	dispatch({ type: RESET_REQUEST, payload: resetData });
	axios
		.post(`${REACT_APP_BACKEND}/api/auth/reset-password`, resetData)
		.then(res => {
			if(!isEmpty(res) && !isEmpty(res.data.data)) toast.success(msg.success)
			dispatch({ type: RESET_PASSWORD_SUCCESS, payload: res.data.message });
            toast.success(res.data.message)
            localStorage.removeItem('otp');
            if(typeof routeHistory==="object") routeHistory.push("/")
		})
		.catch(err => {
			toast.error(msg.error)
			dispatch({
				type: RESET_PASSWORD_FAILURE,
				payload: err.response
					? err.response.data.Errors || err.response.data.error
					: null,
			});
		});
};

export const resetAccount = userData => dispatch => {
	axios
		.post(`${REACT_APP_BACKEND}/api/auth/reset-password-account`, userData)
		.then(res => {
			dispatch({
				type: RESET_ACCOUNT_SUCCESS,
				payload: res.data.message,
			});
		})
		.catch(err => {
			dispatch({
				type: RESET_ACCOUNT_FAILURE,
				payload: err.response ? err.response.data.Errors : null,
			});
		});
};

export const refreshToken = () => dispatch => {
	axios
		.get(`${REACT_APP_BACKEND}/api/auth/refresh-token`)
		.then(res => {
			const { roles } = jwtDecode(res.data.token);
			setAuthorization(res.data.token);
			dispatch({ type: LOGIN_SUCCESS, payload: res.data.message, roles });
			dispatch({type: SET_EXPIRED, payload: false})
			checkTokenExpiration(res.data.token, store)
		})
		.catch(error => {
			logoutUser();
			dispatch({type: SET_EXPIRED, payload: false})
			window.location.reload();
		})
}

export const setAuthorization = token => {
	const IdToken = token;
	localStorage.setItem('IdToken', IdToken);
	//seting authorization to the header axios
	axios.defaults.headers.common['token'] = IdToken;
};

export const logoutUser = () => dispatch => {
	// set logout on backend later
	localStorage.removeItem('IdToken');
	delete axios.defaults.headers.common['token'];
	dispatch({ type: SET_UNAUTHENTICATED });
};
