/* eslint-disable import/no-anonymous-default-export */
import {
	SEND_FEEDBACK,
	SEND_FEEDBACK_SUCCESS,
	SEND_FEEDBACK_FAILURE,
	GET_SEND_FEEDBACK_SUCCESS,
	GET_SEND_FEEDBACK_FAILURE,

	REQUEST_GET_FEEDBACKS,
	GET_FEEDBACKS_SUCCESS,
	GET_FEEDBACKS_FAILED,
} from '../types';

const initialState = {
	sendFeedbackData: null,
	sendFeedbackSuccess: null,
	sendFeedbackFailure: null,
	authenticated: false,
	success: false,
	message: "",
	error: "",
	loading: null,
	feedbackLoading: null,
	feedbacks: null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SEND_FEEDBACK:
			return {
				...state,
				sendFeedbackData: action.payload,
			};
		case SEND_FEEDBACK_SUCCESS:
			return {
				...state,
				sendFeedbackSuccess: action.payload,
				sendFeedbackFailure: null,
				sendFeedbackData: null,
			};
		case SEND_FEEDBACK_FAILURE:
			return {
				...state,
				sendFeedbackSuccess: null,
				sendFeedbackData: null,
				sendFeedbackFailure: action.payload,
			};
		case GET_SEND_FEEDBACK_SUCCESS:
			return {
				...state,
				sendFeedbackSuccess: action.payload,
				sendFeedbackFailure: null,
			};
		case GET_SEND_FEEDBACK_FAILURE:
			return {
				...state,
				sendFeedbackSuccess: action.payload,
				sendFeedbackFailure: null,
			};
		case REQUEST_GET_FEEDBACKS:
			return {
				...state,
				feedbackLoading: true
			};
		case GET_FEEDBACKS_SUCCESS:
			return {
				...state,
				success: true,
				feedbackLoading: null,
				error: null,
				feedbacks: action.payload
			};
		case GET_FEEDBACKS_FAILED:
			return {
				...state,
				error: action.payload,
				success: false,
				feedbackLoading: null
			};
		default:
			return state; //or return initialState
	}
}
