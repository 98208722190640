import React, { useState, useEffect } from 'react';
import CpanelLayout from '../../../../layouts/AdminLayout';
import Check from '../../../../assets/images/check.svg';
import Cancel from '../../../../assets/images/cancel.svg';
import Home from '../../../../assets/images/home.svg';
import School from '../../../../assets/images/school.svg';
import Loading from '../../../../assets/images/loading.svg';
import Idle from '../../../../assets/images/idle.svg';
import Fail from '../../../../assets/images/Polygon 2.png';
import Success from '../../../../assets/images/Polygon 1.png';
import Rwanda from '../../../../assets/images/rwanda.svg';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DownloadIcon from '../../../../assets/icons/download.svg';
import HouseIcon from '../../../../assets/icons/house-circe.svg';
import { Doughnut, Bar } from 'react-chartjs-2';
import LinearChart from '../../../../pages/client/components/LinearChart';
import { RegistrationMeans, ReportPerMetric } from '../../../admin-dashboard/components/special-reports';

import { Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { getAllAdminTrainingCenter, getAllUsersChart, getAllTraineesChart, getAllTraineesByAgeChart, getLatestReport, getAllTraineesStatusChart, getAllAmbsadorsChart } from '../../../../redux/actions';

const age = {
    labels: ['10-20', '20-30', '30-40', '40-50'],
    datasets: [
        {
            label: 'By Age',
            data: [12, 28, 22, 5],
            backgroundColor: '#078ece',
            borderColor: '#078ece',
            borderWidth: 1,
        },
    ],
};

const platform = {
    datasets: [
        {
            label: '# of Votes',
            data: [40, 30, 30],
            backgroundColor: ['#CF1B33', '#078ECE', '#CFC71B'],
            borderWidth: 1,
        },
    ],
};

const ReportsContent = ({ print, active }) => {
    const [show, setShow] = useState(active);

    const allUsers = useSelector(state => state.chart.users);
    const allTrainees = useSelector(state => state.chart.trainees);
    const allTraineesStatus = useSelector(state => state.chart.traineeStatus);
    const traineeByAge = useSelector(state => state.chart.traineeByAge);
    const { report } = useSelector(state => state.reports);

    const ambsadors = useSelector(state => state.chart.ambsadors);
    const ambsadorsActive = ambsadors.filter(value => value.isActive === true);

    const countDaActive = ambsadors.filter(value => value.isActive === true).length;
    const countDaInactive = ambsadors.filter(value => value.isActive === false).length;

    const DaCountMale = ambsadors.filter(value => value.gender.toLowerCase() === 'male' || value.gender.toLowerCase() === 'm').length;
    const DaCountFemale = ambsadors.filter(value => value.gender.toLowerCase() === 'female' || value.gender.toLowerCase() === 'f').length;

    const divideCountDaActive = countDaActive === 0 ? 1 : countDaActive;

    const DaGender = { male: (DaCountMale * 100) / divideCountDaActive, female: (DaCountFemale * 100) / divideCountDaActive };

    const DaGenderChart = {
        datasets: [
            {
                label: '# of Votes',
                data: [Number(DaGender?.female).toFixed(0), Number(DaGender?.male).toFixed(0)],
                backgroundColor: ['#CF1B33', '#078ECE'],
                borderWidth: 1,
            },
        ],
    };

    const divideCountPlatform = ambsadors.length === 0 ? 1 : ambsadors.length;

    const DaCountPlatForm = {
        mobile: (ambsadors.filter(value => value.isActive === true && value.createFrom === 'mobile').length * 100) / divideCountPlatform,
        web: (ambsadors.filter(value => value.isActive === true && value.createFrom === 'web').length * 100) / divideCountPlatform,
        ussd: (ambsadors.filter(value => value.isActive === true && value.createFrom === 'ussd').length * 100) / divideCountPlatform,
    };

    const DaPlatFormUsed = {
        datasets: [
            {
                label: '# of Votes',
                data: [DaCountPlatForm.mobile, DaCountPlatForm.web, DaCountPlatForm.ussd],
                backgroundColor: ['#CF1B33', '#078ECE', '#40A55B'],
                borderWidth: 1,
            },
        ],
    };

    const countTraineesThisWeek = allTrainees.filter(value => moment(value.createdAt).isSame(moment(), 'week')).length;

    const countTraineesAllDropout = allTraineesStatus.filter(value => value.status.toLowerCase() === 'dropout').length;
    const countTraineesDropoutThisWeek = allTraineesStatus.filter(value => value.status === 'dropout' && moment(value.createdAt).isSame(moment(), 'week')).length;

    const countTraineesGraduate = allTrainees.filter(value => Number(value.attendenceCount) >= Number(value.courseNumber)).length;
    const countTraineesGraduateThisWeek = allTrainees.filter(value => value.attendenceCount >= value.courseNumber && moment(value.createdAt).isSame(moment(), 'week')).length;

    const countTraineesPending = allTraineesStatus.filter(value => value.status === 'pending').length;
    const countTraineesPendingThisWeek = allTraineesStatus.filter(value => value.status === 'pending' && moment(value.createdAt).isSame(moment(), 'week')).length;

    const divideCountRegister = allTraineesStatus.length === 0 ? 1 : allTraineesStatus.length;

    const countRegistrationMeans = {
        mobile: (allTraineesStatus.filter(value => value.createFrom === 'mobile').length * 100) / divideCountRegister,
        web: (allTraineesStatus.filter(value => value.createFrom === 'web').length * 100) / divideCountRegister,
        ussd: (allTraineesStatus.filter(value => value.createFrom === 'ussd').length * 100) / divideCountRegister,
    };

    const registrationDataChart = {
        datasets: [
            {
                label: '# of Votes',
                data: [countRegistrationMeans.mobile, countRegistrationMeans.web, countRegistrationMeans.ussd],
                backgroundColor: ['#CF1B33', '#078ECE', '#40A55B'],
                borderWidth: 1,
            },
        ],
    };

    const dispatch = useDispatch();

    const trainingCenter = useSelector(state => state.chart.adminCenter);

    useEffect(() => {
        dispatch(getAllUsersChart());
        dispatch(getAllTraineesChart());
        dispatch(getAllTraineesStatusChart());
        dispatch(getAllTraineesByAgeChart());
        dispatch(getLatestReport());
        dispatch(getAllAmbsadorsChart());
        dispatch(getAllAdminTrainingCenter());

        handleGetProfileWork();

        if (print) {
            setTimeout(() => {
                window.print();
            }, 2000);
        }
    }, [dispatch, print]);

    // const token = localStorage.getItem('idToken');

    const TraineeCountMale = allTrainees.filter(value => value.gender === 'Male').length;
    const TraineeCountFemale = allTrainees.filter(value => value.gender === 'Female').length;

    const divideTraineeCount = allTrainees.length === 0 ? 1 : allTrainees.length;

    const traineeGender = { male: (TraineeCountMale * 100) / divideTraineeCount, female: (TraineeCountFemale * 100) / divideTraineeCount };

    const TraineeByGenderChart = {
        datasets: [
            {
                label: '# of Votes',
                data: [Number(traineeGender?.female).toFixed(0), Number(traineeGender?.male).toFixed(0)],
                backgroundColor: ['#CF1B33', '#078ECE'],
                borderWidth: 1,
            },
        ],
    };

    const traineedByDayChart = {
        labels: [
            moment().subtract(5, 'day').format('MMMM DD'),
            moment().subtract(4, 'day').format('MMMM DD'),
            moment().subtract(3, 'day').format('MMMM DD'),
            moment().subtract(2, 'day').format('MMMM DD'),
            moment().subtract(1, 'day').format('MMMM DD'),
            moment().subtract(0, 'day').format('MMMM DD'),
        ],

        datasets: [
            {
                label: 'Trained',
                data: [
                    allTrainees.filter(value => moment(value.createdAt).isSame(moment().subtract(5, 'day'), 'day')).length,
                    allTrainees.filter(value => moment(value.createdAt).isSame(moment().subtract(4, 'day'), 'day')).length,
                    allTrainees.filter(value => moment(value.createdAt).isSame(moment().subtract(3, 'day'), 'day')).length,
                    allTrainees.filter(value => moment(value.createdAt).isSame(moment().subtract(2, 'day'), 'day')).length,
                    allTrainees.filter(value => moment(value.createdAt).isSame(moment().subtract(1, 'day'), 'day')).length,
                    allTrainees.filter(value => moment(value.createdAt).isSame(moment().subtract(0, 'day'), 'day')).length,
                ],
                fill: true,
                backgroundColor: 'rgba(103, 189, 255, 0.7)',
                borderColor: '#115e82',
            },
        ],
    };

    const [completationRate, setCompletationRate] = useState(0);
    const [moduleCompletion, setModuleCompletion] = useState({ label: [], data: [] });

    const handleGetProfileWork = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sessions/completion`);

        const { data: responseData } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/attendences`);
        const moduleData = responseData.data;

        const labelArray = [];
        const dataArray = [];

        for (let $i = 0; $i < moduleData.length; $i++) {
            labelArray.push(moduleData[$i].courseIdentity);
            dataArray.push(moduleData[$i].attendenceCount);
        }

        setCompletationRate(data?.data);
        setModuleCompletion({
            label: labelArray,
            data: dataArray,
        });
    };

    const ModuleAttendanceChart = {
        labels: moduleCompletion?.label,
        datasets: [
            {
                label: 'By Module',
                data: moduleCompletion?.data,
                backgroundColor: '#078ece',
                borderColor: '#078ece',
                borderWidth: 1,
            },
        ],
    };

    return (
        <main>
            <div className="d-flex justify-content-between align-items-center mt-4">
                <h1>Special Reports</h1>
                <div className="d-flex align-items-center">
                    {!print && (
                        <a href={`/cpanel/reports?print=true&active=${show}`} target="_blank" className="btn text-primary">
                            <i className="fa fa-print" /> &nbsp; Print report
                        </a>
                    )}

                    {/* <div className="border-bottom ml-3">
              <input
                type="text"
                placeholder="Search reports"
                className="border-bottom border-0"
              />
              <button className="btn">
                <i className="fa fa-search" />
              </button>
            </div> */}
                </div>
            </div>
            <div className="mt-3">
                <div className="border-bottom d-flex align-items-center">
                    <a href="#" className={`mx-3 px-2 ${show == 1 && `border-bottom border-primary`}`} onClick={() => setShow(1)}>
                        <h5> Trainings</h5>
                    </a>
                    <a href="#" className={`mx-3 px-2 ${show == 2 && `border-bottom border-primary`}`} onClick={() => setShow(2)}>
                        <h5> DA Reports</h5>
                    </a>
                    <a href="#" className={`mx-3 px-2 ${show == 3 && `border-bottom border-primary`}`} onClick={() => setShow(3)}>
                        <h5> Modules</h5>
                    </a>
                    <a href="#" className={`mx-3 px-2 ${show == 4 && `border-bottom border-primary`}`} onClick={() => setShow(4)}>
                        <h5> System Reports</h5>
                    </a>
                </div>
                <div className="mt-4 ml-2">
                    {show == 1 ? (
                        <>
                            <div className="row my-4">
                                <div className="col-md-3 my-2">
                                    <div className="media align-items-center mx-4">
                                        <img src={Check} />
                                        <div className="media-body ml-3">
                                            <h4>{allTrainees.length}</h4>
                                            <h6>Active trainees</h6>
                                            <span>
                                                <img src={Success} width={16} />
                                                <span>&nbsp; +{countTraineesThisWeek} this week</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 my-2">
                                    <div className="media align-items-center mx-4">
                                        <img src={Cancel} />
                                        <div className="media-body ml-3">
                                            <h4>{countTraineesAllDropout}</h4>
                                            <h6>Dropouts</h6>
                                            <span>
                                                <img src={Fail} width={16} />
                                                <span>&nbsp; +{countTraineesDropoutThisWeek} this week</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 my-2">
                                    <div className="media align-items-center mx-4">
                                        <img src={Home} />
                                        <div className="media-body ml-3">
                                            <h4>{trainingCenter.length}</h4>
                                            <h6>Training centres</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 my-2">
                                    <div className="media align-items-center mx-4">
                                        <img src={Loading} />
                                        <div className="media-body ml-3">
                                            <h4>{countTraineesPending}</h4>
                                            <h6>Waiting</h6>
                                            <span>
                                                <img src={Success} width={16} />
                                                <span>&nbsp; +{countTraineesPendingThisWeek} this week</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 my-2">
                                    <div className="media align-items-center mx-4">
                                        <img src={School} />
                                        <div className="media-body ml-3">
                                            <h4>{countTraineesGraduate}</h4>
                                            <h6>Graduates</h6>
                                            <span>
                                                <img src={Success} width={16} />
                                                <span>&nbsp; +{countTraineesGraduateThisWeek} this week</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col-md-6 my-3">
                                    <h3>Trainees by gender</h3>
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <Doughnut data={TraineeByGenderChart} />
                                        </div>
                                        <div>
                                            <p>{(traineeGender?.male).toFixed(2) ?? 0}% Male</p>
                                            <p>{(traineeGender?.female).toFixed(2) ?? 0}% Female</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3">
                                    <RegistrationMeans data={registrationDataChart} mobilePerc={countRegistrationMeans?.mobile} webPerc={countRegistrationMeans?.web} ussdPerc={countRegistrationMeans?.ussd} />
                                </div>
                                <div className="col-md-6 my-3">
                                    <h3>Trainees by age</h3>
                                    <div className="col-8">
                                        <Bar data={traineedByDayChart} />
                                    </div>
                                </div>
                                <div className="col-md-6 my-3">
                                    <h3>By Location</h3>
                                    <div className="media align-items-center">
                                        <img src={Rwanda} className="img-fluid w-50" />
                                        <div className="media-body ml-5">
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#286d8e' }}></i> Kigali city {allTraineesStatus.filter(value => value.province === 'Kigali City').length}
                                            </p>
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#709eb4' }}></i> Nothern {allTraineesStatus.filter(value => value.province === 'Nothern Province').length}
                                            </p>
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#578ea7' }}></i> Southern {allTraineesStatus.filter(value => value.province === 'Southern Province').length}
                                            </p>
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#87aec0' }}></i> Eastern {allTraineesStatus.filter(value => value.province === 'Eastern Province').length}
                                            </p>
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#407e9b' }}></i> Western {allTraineesStatus.filter(value => value.province === 'Western Province').length}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : show == 2 ? (
                        <>
                            <div className="row my-4">
                                <div className="col-md-4 my-2">
                                    <div className="media align-items-center mx-4">
                                        <img src={Check} />
                                        <div className="media-body ml-3">
                                            <h4>{countDaActive}</h4>
                                            <h6>Active DAs</h6>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-3 my-2">
                    <div className="media align-items-center mx-4">
                      <img src={Idle} />
                      <div className="media-body ml-3">
                        <h4>12</h4>
                        <h6>Idle DAs</h6>
                      </div>
                    </div>
                  </div> */}
                                <div className="col-md-4 my-2">
                                    <div className="media align-items-center mx-4">
                                        <img src={Cancel} />
                                        <div className="media-body ml-3">
                                            <h4>{countDaInactive}</h4>
                                            <h6>Inactive DAs</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-2">
                                    <div className="media align-items-center mx-4">
                                        <img src={Home} />
                                        <div className="media-body ml-3">
                                            <h4>{trainingCenter.length}</h4>
                                            <h6>SAPs</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 my-3">
                                    <h4>DAs</h4>
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <Doughnut data={DaGenderChart} />
                                        </div>
                                        <div>
                                            <p>{Number(DaGender?.male).toFixed(0)}% Male</p>
                                            <p>{Number(DaGender?.female).toFixed(0)}% Female</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 my-3">
                                    <h4>Platform used</h4>
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <Doughnut data={DaPlatFormUsed} />
                                        </div>
                                        <div>
                                            <p>{DaCountPlatForm?.mobile}% Mobile</p>
                                            <p>{DaCountPlatForm?.web}% Web</p>
                                            <p>{DaCountPlatForm?.ussd}% USSD</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6 my-3">
                    <h4>DA Trainee reach</h4>
                    <div className="row align-items-center mt-4">
                      <div className="col-8">
                        <LinearChart />
                      </div>
                      <div>
                        <p>With SAP</p>
                        <p>Without SAP</p>
                      </div>
                    </div>
                  </div> */}
                                <div className="col-md-6 my-3">
                                    <h3>By Location</h3>
                                    <div className="media align-items-center">
                                        <img src={Rwanda} className="img-fluid w-50" />
                                        <div className="media-body ml-5">
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#286d8e' }}></i> Kigali city {ambsadorsActive.filter(value => value.province === 'Kigali City').length}
                                            </p>
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#709eb4' }}></i> Nothern {ambsadorsActive.filter(value => value.province === 'Nothern Province').length}
                                            </p>
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#578ea7' }}></i> Southern {ambsadorsActive.filter(value => value.province === 'Southern Province').length}
                                            </p>
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#87aec0' }}></i> Eastern {ambsadorsActive.filter(value => value.province === 'Eastern Province').length}
                                            </p>
                                            <p>
                                                <i className="fa fa-square" style={{ color: '#407e9b' }}></i> Western {ambsadorsActive.filter(value => value.province === 'Western Province').length}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : show == 3 ? (
                        <>
                            <div className="media align-items-center mb-4">
                                <img src={Check} />
                                <div className="media-body ml-4">
                                    <h2>{!isNaN(completationRate) ? Number(completationRate).toFixed(2) : (0).toFixed(2)}%</h2>
                                    <h6>Completion rate</h6>
                                </div>
                            </div>
                            <h4>Module attendance rate</h4>
                            <div className="col-6">
                                <Bar data={ModuleAttendanceChart} />
                            </div>
                        </>
                    ) : (
                        <>
                            <Row>
                                <div className="col-12 col-md-6 col-lg-4 pb-sm-4 pb-lg-0">
                                    {report && <ReportPerMetric metric="Platform users" currentValue={Number(report.currentUsers ?? 0)} previousValue={Number(report.previousUsers ?? 0)} icon={HouseIcon} />}
                                </div>

                                <div className="col-12 col-md-6 col-lg-4">
                                    <ReportPerMetric metric="Certificate downloads" currentValue={Number(report.currentDownloads ?? 0)} previousValue={Number(report.previousDonwloads ?? 0)} icon={DownloadIcon} />
                                </div>
                            </Row>
                            <div className="py-4">
                                <Row>
                                    <div className="col-md-6 my-3">
                                        <RegistrationMeans webPerc={report.webUsers ?? 0} ussdPerc={report.ussdUsers ?? 0} mobilePerc={report.mobileUsers ?? 0} />
                                    </div>
                                </Row>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ReportsContent;
