import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import Layout from '../../layouts';
import './index.css';

import { useParams } from 'react-router-dom';

import clx from 'classnames';
import { Page, Document, StyleSheet, PDFDownloadLink, PDFViewer, usePDF } from '@react-pdf/renderer';
import routes from '../../routes/names';
import { Container, Spinner } from 'react-bootstrap';
import BackLink from '../../components/commons/back-link/BackLink';
import { fetchTraineeProfileByTraineeIdentity } from '../../redux/actions';

import FrontPage from './FrontPage';
import BackPage from './BackPage';

const getFileName = ({ firstName, lastName }) => {
    return `DAP-Certificate-${String(firstName).trim()}-${String(lastName).trim()}-${new Date().getTime()}.pdf`;
};
const styles = StyleSheet.create({
    page: {
        width: 600,
        margin: 8,
    },
});

const CertificatePDF = props => {
    return (
        <Document>
            {props && props.firstName && (
                <>
                    <Page size="A4" style={styles.page} orientation="landscape">
                        <FrontPage {...props} />
                    </Page>

                </>
            )}
        </Document>
    );
};

const TraineeCertificate = ({ t }) => {
    var traineeId = localStorage.getItem('traineeId');

    const dispatch = useDispatch();
    const { trainee } = useSelector(state => state.trainees);

    useEffect(() => {
        dispatch(fetchTraineeProfileByTraineeIdentity(traineeId));
    }, [dispatch, traineeId]);

    const [instance, updateInstance] = usePDF({
        document: <CertificatePDF traineeId_={trainee && traineeId} lastName={trainee && trainee.lastName} firstName={trainee && trainee.firstName} createdAt={trainee && moment(trainee.createdAt).format('DD/MM/YYYY')} />,
    });

    console.log(instance);
    return (
        <Layout>
            <div className="body-content bg-dark-primary">
                <Container className="w-75">
                    <div className="py-4 ">
                        <div className=" pr-5 pt-5">
                            <BackLink path={routes.register.verify} />
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <div className="ml-5 mr-5 p-5 box  ">
                            <p className="text-title my-4">{`${t(`Impamyabumenyi`)}`}</p>

                            {instance.loading && (
                                <div className="d-flex align-items-center justify-content-center py-5 mt-5">
                                    {' '}
                                    <div>
                                        <span className="text-title text-center font-italic">
                                            {' '}
                                            <Spinner animation="border" /> Preparing Certificate ...
                                        </span>{' '}
                                    </div>
                                </div>
                            )}
                            <div className="d-flex align-items-center justify-content-center">
                                <PDFViewer width={960} height={720} style={{ border: 'none' }}>
                                    <CertificatePDF traineeId_={trainee && traineeId} trainee={trainee} lastName={trainee && trainee.lastName} firstName={trainee && trainee.firstName} createdAt={trainee && moment(trainee.createdAt).format('DD/MM/YYYY')} />
                                </PDFViewer>
                            </div>

                            <div className="d-flex align-items-end justify-content-end pr-5 pt-5">
                                <PDFDownloadLink
                                    className={'btn btn-primary btn-lg px-5 py-3 btn-download' + clx({ disabled: instance.loading })}
                                    document={<CertificatePDF traineeId_={trainee && traineeId} trainee={trainee} lastName={trainee && trainee.lastName} firstName={trainee && trainee.firstName} createdAt={trainee && moment(trainee.createdAt).format('DD/MM/YYYY')} />}
                                    fileName={getFileName({
                                        firstName: trainee && trainee.firstName,
                                        lastName: trainee && trainee.lastName,
                                    })}
                                    role="button"
                                    aria-disabled="true"
                                >
                                    {({ blob, url, loading, error }) => (loading ? t(`Loading`) + `...` : t(`Gusohora impamyabumenyi`))}
                                </PDFDownloadLink>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </Layout>
    );
};
export default withNamespaces()(TraineeCertificate);
