import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import {
    REQUEST_GET_USERS,
    REQUEST_USER_CREATION,
    USER_CREATION_SUCCESS,
    USER_CREATION_FAILED,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,
    REQUEST_MAKE_COORD,
    MAKE_COORD_SUCCESS,
    MAKE_COORD_FAILED,
    REQUEST_USER_UPDATE,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILED,
    REQUEST_UPDATE_USER,
    REQUEST_GET_DA,
    GET_DA_SUCCESS,
    GET_DA_FAILED,
    EMPTIFY_RESIDENCE_LOCATIONS,
    REQUEST_USER_INFO,
    GET_USER_INFO,
    USER_INFO_FAILED,
} from '../types';
import { setAuthorization } from './authActions';

const { REACT_APP_BACKEND } = process.env;

export const createUser =
    (user, resetResidence = undefined, resetForm = undefined, history = undefined) =>
    dispatch => {
        dispatch({ type: REQUEST_USER_CREATION });
        axios
            .post(`${REACT_APP_BACKEND}/api/users/create`, user)
            .then(res => {
                if (!res) {
                    dispatch({ type: USER_CREATION_FAILED, payload: 'Phone number or Email already exist' });
                    toast.error('Phone number or Email already exist');
                    return;
                }
                toast.success(res.data.message);
                dispatch({ type: USER_CREATION_SUCCESS, payload: res.data.message });
                if (typeof resetResidence === 'function') {
                    dispatch({ type: EMPTIFY_RESIDENCE_LOCATIONS });
                    resetResidence();
                }

                if (typeof resetForm === 'function') {
                    resetForm({
                        nationalIdentity: '',
                        firstName: '',
                        lastName: '',
                        middleName: '',
                        phone: '',
                        gender: '',
                        dob: '',
                        email: '',
                        manageSap: false,
                    });
                }
                if (typeof history === 'object') history.push('/cpanel/ambassadors/list');
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
                dispatch({
                    type: USER_CREATION_FAILED,
                    payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
                });
            });
    };

export const getUsers = () => dispatch => {
    dispatch({ type: REQUEST_GET_USERS });
    axios
        .get(`${REACT_APP_BACKEND}/api/users`)
        .then(res => {
            dispatch({ type: GET_USERS_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_USERS_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const makeLeader = (userId, role) => dispatch => {
    dispatch({ type: REQUEST_MAKE_COORD });
    axios
        .post(`${REACT_APP_BACKEND}/api/ambassadors/make-leader/${userId}`, role)
        .then(res => {
            console.log({ res });

            dispatch({ type: MAKE_COORD_SUCCESS, payload: res.data.message });
            dispatch(getDigitalAmbs());
            toast.success(res.data.message);
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: MAKE_COORD_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const updateUser = (id, data) => dispatch => {
    dispatch({ type: REQUEST_USER_UPDATE });
    const values = Object.entries(data);
    const formData = new FormData();
    values.forEach(field => {
        formData.append(field[0], field[1]);
    });
    axios
        .put(`${REACT_APP_BACKEND}/api/users/${id}`, formData)
        .then(res => {
            toast.success(res.data.message);
            dispatch({ type: USER_UPDATE_SUCCESS, payload: res.data.message });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: USER_UPDATE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const UpdateUser = (payload, setIsSubmitted, onClose, setUser, setUserDetail) => dispatch => {
    dispatch({ type: REQUEST_UPDATE_USER });
    const values = Object.entries(payload);
    const formData = new FormData();
    values.forEach(field => {
        if (field[1]) {
            formData.append(field[0], field[1]);
        }
    });

    axios
        .put(`${REACT_APP_BACKEND}/api/users/myprofile/update/${payload.id}`, formData)
        .then(res => {
            dispatch({ type: REQUEST_UPDATE_USER, payload: res.data });
            setAuthorization(res.data.token);
            const { iat, exp, ...rest } = jwtDecode(res.data.token);
            setUser({ ...rest });
            setUserDetail(res.data.data);
            toast.success(res.data.message);
            setIsSubmitted(false);
            onClose();
            // window.location.reload();
        })
        .catch(err => {
            console.error('123', err);
            dispatch({
                type: REQUEST_UPDATE_USER,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getAmbassadors = () => dispatch => {
    dispatch({ type: REQUEST_GET_DA });
    axios
        .get(`${REACT_APP_BACKEND}/api/users/digital-ambs`)
        .then(res => {
            dispatch({ type: GET_DA_SUCCESS, payload: res.data.data });
        })
        .catch(err => {
            dispatch({
                type: GET_DA_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const updateUserData =
    (id, data, routeHistory = undefined, goToPartnerList = undefined) =>
    dispatch => {
        dispatch({ type: REQUEST_USER_UPDATE });
        axios
            .put(`${REACT_APP_BACKEND}/api/users/update/${id}`, data)
            .then(res => {
                toast.success(res.data.message);
                dispatch({ type: USER_UPDATE_SUCCESS, payload: res.data.message });
                if (typeof routeHistory === 'object') routeHistory.push('/cpanel/ambassadors/list');
                if (goToPartnerList && typeof routeHistory === 'object') routeHistory.push('/cpanel/partners/list');
                dispatch(getDigitalAmbs());
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
                dispatch({
                    type: USER_UPDATE_FAILED,
                    payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
                });
            });
    };

export const getDigitalAmbs = () => dispatch => {
    dispatch({ type: REQUEST_GET_USERS });
    axios
        .get(`${REACT_APP_BACKEND}/api/users/digital-ambs`)
        .then(res => {
            dispatch({ type: GET_USERS_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_USERS_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};
export const getActiveDigitalAmbs = () => dispatch => {
    dispatch({ type: REQUEST_GET_USERS });
    axios
        .get(`${REACT_APP_BACKEND}/api/users/digital-ambs/active`)
        .then(res => {
            dispatch({ type: GET_USERS_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_USERS_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getUserInfo = id => dispatch => {
    dispatch({ type: REQUEST_USER_INFO });
    axios
        .get(`${REACT_APP_BACKEND}/api/users/${id}`)
        .then(res => {
            dispatch({ type: GET_USER_INFO, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_USERS_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

