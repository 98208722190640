import React from "react";
import Header from "../pages/client/components/Header";
import Footer from "../pages/client/components/Footer";
import "./index.css";
function Layout({ children }) {
  return (
    <div>
      <Header />
      <div className="wrapper">{children}</div>

      <Footer />
    </div>
  );
}

export default Layout;
