import 'dotenv/config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { REQUEST_GET_SERVICES, CREATE_SERVICE_START, CREATE_SERVICE_SUCCESS, CREATE_SERVICE_FAILURE, UPDATE_SERVICE_START, UPDATE_SERVICE_SUCCESS, UPDATE_SERVICE_FAILURE } from '../types';
import { getSingleCenter } from './centers';
const { REACT_APP_BACKEND } = process.env;

export const listService = () => dispatch => {
    axios
        .get(`${REACT_APP_BACKEND}/api/sapservices/primary`)
        .then(res => {
            dispatch({ type: REQUEST_GET_SERVICES, payload: res.data.data });
        })
        .catch(err => {
            console.log(err);
        });
};

export const createService = ({ payload, onClose, resetForm }) =>
    dispatch => {
        dispatch({ type: CREATE_SERVICE_START, loading: true, error: '' });
        axios
            .post(`${REACT_APP_BACKEND}/api/sapservices/create`, payload)
            .then(({ status, data }) => {
                dispatch({ type: CREATE_SERVICE_SUCCESS, loading: false, payload: 'Service Created' });
                onClose && onClose();
                resetForm && resetForm();
                if (status === 201) toast.success(data.message);
                dispatch(listService());
                if (payload.trainingcenterId) dispatch(getSingleCenter(payload.trainingcenterId));
            })
            .catch(err => {
                console.log(err);
                dispatch({ type: CREATE_SERVICE_FAILURE, loading: false, error: 'Something went wrong - We could not create service' });
            });
    };

export const updateService =
    ({ payload, onClose, resetForm }) =>
    dispatch => {
        dispatch({ type: UPDATE_SERVICE_START, loading: true, error: '' });
        axios
            .patch(`${REACT_APP_BACKEND}/api/sapservices/${payload.id}`, payload)
            .then(res => {
                dispatch({ type: UPDATE_SERVICE_SUCCESS, loading: false, payload: 'Service updated' });

                onClose && onClose();
                resetForm && resetForm();

                if (payload.trainingcenterId) {
                    return dispatch(getSingleCenter(payload.trainingcenterId));
                }

                dispatch(listService());
            })
            .catch(err => {
                console.log(err);
                dispatch({ type: UPDATE_SERVICE_FAILURE, loading: false, error: 'Something went wrong - We could not update service' });
            });
    };

export const deleteService = payload => dispatch => {
    axios
        .delete(`${REACT_APP_BACKEND}/api/sapservices/${payload.id}`)
        .then(res => {
            toast.success('Service successfully deleted');

            if (payload.trainingcenterId) {
                return dispatch(getSingleCenter(payload.trainingcenterId));
            }
            dispatch(listService());
        })
        .catch(err => {
            console.log(err);
            toast.error('Sorry, We could not delete service');
        });
};
