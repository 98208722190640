import React, { useState, useEffect } from 'react';
import Layout from '../../../../layouts/AdminLayout';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Routes from '../../../../routes/names';
import axios from 'axios';
import moment from 'moment';
import { convertMonthInKinyaDOB } from '../../../../helpers/convertMonthInKiny';
import { ViewTraineeAction, downloadCertificate } from '../../../../redux/actions/trainees';
import routes from '../../../../routes/names';

const DAViewTrainee = ({ match }) => {
    const id = match.params.id;
    const trainee = useSelector(state => state.trainees.view);

    const dispatch = useDispatch();

    const saveDownloadCertificate = async () => {
        localStorage.setItem('traineeId', trainee.traineeIdentity);
        await dispatch(downloadCertificate(trainee.traineeIdentity));
        console.log('===traineeId===>', trainee.traineeIdentity);
        setTimeout(() => {
            window.location.href = `${routes.traineeCertificate}`;
        }, 500);
    };

    useEffect(() => {
        dispatch(ViewTraineeAction(id));
    }, [dispatch, id]);

    return (
        <Layout>
            <div className="pt-4">
                <Container>
                    <Row>
                        <Col md={12}>
                            <Breadcrumb>
                                <Breadcrumb.Item className="text-primary">
                                    <Link to="/cpanel/overview">Overview</Link>
                                </Breadcrumb.Item>

                                <Breadcrumb.Item className="text-primary">
                                    <Link to={Routes.dashboard.trainees.all}>Trainees</Link>
                                </Breadcrumb.Item>

                                <Breadcrumb.Item active>More details</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-lg-8 col-md-9">
                            <div>
                                <span className="text-title">More details</span>
                            </div>
                        </Col>
                        <Col className="col-lg-4 col-md-9">
                            <div>
                                {/* <button className="btn btn-primary px-5 mt-2" onClick={() => saveDownloadCertificate() }>
                View Certificate
              </button> */}
                            </div>
                        </Col>
                    </Row>

                    <div className="pt-3 body-content">
                        <Row>
                            <Col md={12}>
                                <div className="box">
                                    <Form>
                                        <p className="text-title mb-4">Basic information</p>

                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>National ID</h5>
                                                <p>{trainee?.nationalIdentity}</p>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>First Name</h5>
                                                <p>{trainee?.firstName}</p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Last Name</h5>
                                                <p>{trainee?.lastName}</p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Middle Name</h5>
                                                <p>{trainee?.middleName}</p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Gender</h5>
                                                <p>{trainee?.gender}</p>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Date of Birth</h5>
                                                <p>
                                                    {moment(trainee?.dob).format('DD')} {convertMonthInKinyaDOB(moment(trainee?.dob).format('MM'))} {moment(trainee?.dob).format('Y')}
                                                </p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Phone</h5>
                                                <p>{trainee?.phone}</p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Email</h5>
                                                <p>{trainee?.email}</p>
                                            </Col>
                                        </Row>

                                        <p className="text-title mb-3 mt-4">Location details</p>

                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Province</h5>
                                                <p>{trainee?.province}</p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>District</h5>
                                                <p>{trainee?.district}</p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Sector</h5>
                                                <p>{trainee?.sector}</p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Cell</h5>
                                                <p>{trainee?.cell}</p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Umudugudu</h5>
                                                <p>{trainee?.village}</p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Training Center</h5>
                                                <p>N/A</p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Cell Leader Names</h5>
                                                <p>{}</p>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <h5>Cell Leader Contact</h5>
                                                <p>N/A</p>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </Layout>
    );
};

export default DAViewTrainee;
