export const GET_CENTER_LOCATION = 'GET_CENTER_LOCATION';
export const CENTER_ERROR = 'CENTER_ERROR';
export const GET_ALL_TRAINING_CENTERS = 'GET_ALL_TRAINING_CENTERS';
export const GET_ALL_TRAINING_CENTERS_ERROR = 'GET_ALL_TRAINING_CENTERS_ERROR';
export const CENTER_LOCATION_LOADING = 'CENTER_LOCATION_LOADING';

export const GET_SINGLE_TRAINING_CENTER = 'GET_SINGLE_TRAINING_CENTER';

export const GET_DA_TRAINING_CENTERS = 'GET_DA_TRAINING_CENTERS';
export const GET_DA_TRAINING_CENTERS_ERROR = 'GET_DA_TRAINING_CENTERS_ERROR';
export const CLEAR_TRAINING_CENTERS = 'CLEAR_TRAINING_CENTERS';
