export const REQUEST_CREATE_SESSION = "REQUEST_CREATE_SESSION";
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS";
export const CREATE_SESSION_FAILED = "CREATE_SESSION_FAILED";

export const REQUEST_GET_SESSION = "REQUEST_GET_SESSION";
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";
export const GET_SESSION_FAILED = "GET_SESSION_FAILED";

export const REQUEST_UPDATE_SESSION = "REQUEST_UPDATE_SESSION";
export const UPDATE_SESSION_SUCCESS = "UPDATE_SESSION_SUCCESS";
export const UPDATE_SESSION_FAILED = "UPDATE_SESSION_FAILED";

export const REQUEST_DELETE_SESSION = "REQUEST_DELETE_SESSION";
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS";
export const DELETE_SESSION_FAILED = "DELETE_SESSION_FAILED";

