/* eslint-disable import/no-anonymous-default-export */
import {
  REQUEST_CREATE_ATTENDENCE,
  CREATE_ATTENDENCE_FAILED,
  CREATE_ATTENDENCE_SUCCESS,
  REQUEST_GET_ATTENDENCE,
  GET_ATTENDENCE_FAILED,
  GET_ATTENDENCE_SUCCESS,
  REQUEST_UPDATE_ATTENDENCE,
  UPDATE_ATTENDENCE_FAILED,
  UPDATE_ATTENDENCE_SUCCESS,
  REQUEST_DELETE_ATTENDENCE,
  DELETE_ATTENDENCE_FAILED,
  DELETE_ATTENDENCE_SUCCESS,
  
} from "../types"

const initialState = {
  loading: null,
  createLoading: null,
  updateLoading: null,
  deleteLoading: null,
  attendences: [],
  message: null,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_CREATE_ATTENDENCE:
      return {
        ...state,
        createLoading: true
      }
    
    case CREATE_ATTENDENCE_SUCCESS:
      return {
        ...state,
        createLoading: null,
        message: action.payload
      }
    case CREATE_ATTENDENCE_FAILED:
      return {
        ...state,
        createLoading: null,
        error: action.payload
      }
    case REQUEST_GET_ATTENDENCE:
      return {
        ...state,
        loading: true
      }
      case GET_ATTENDENCE_SUCCESS:
        return {
          ...state,
          loading: null,
          attendences: action.payload
        }
    default:
      return state;
  }
}