import { combineReducers } from 'redux';
import auth from './authReducer';
import feedback from './feedbackReducer';
import ui from './uiReducer';
import residence from './locations';
import centers from './centers';
import trainees from './trainees';
import users from './users';
import sessions from './sessions';
import courses from './courses';
import groups from './groups';
import attendence from './attendence';
import materials from './materialReducer';
import services from './services';
import roles from './roles';
import communications from './communications';
import chart from './charts';
import reports from './reports';
import partners from './partners';
import transactions from './transactions';
import notifications from './notification';

export default combineReducers({
    auth,
    feedback,
    residence,
    centers,
    trainees,
    users,
    sessions,
    courses,
    groups,
    attendence,
    materials,
    services,
    roles,
    communications,
    chart,
    reports,
    partners,
    transactions,
    notifications,
});
