import axios from 'axios';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import {
    REQUEST_TRAINEE_CREATION,
    TRAINEE_CREATION_FAILED,
    TRAINEE_CREATION_SUCCESS,
    REQUEST_GET_TRAINEES,
    GET_TRAINEES_SUCCESS,
    GET_TRAINEES_FAILED,
    REQUEST_SEARCH_CERTIFICATE,
    SEARCH_CERTIFICATE_SUCCESS,
    SEARCH_CERTIFICATE_FAILED,
    REQUEST_VIEW_CERTIFICATE,
    VIEW_CERTIFICATE_SUCCESS,
    VIEW_CERTIFICATE_FAILED,
    REQUEST_UPDATE_STATUS,
    UPDATE_STATUS_SUCCESS,
    UPDATE_STATUS_ERROR,
    REQUEST_CAPTAIN_MAKING,
    MAKE_CAPTAIN_SUCCESS,
    MAKE_CAPTAIN_FAILED,
    REQUEST_TRAINEE_DELETE,
    DELETE_TRAINEE_FAILED,
    DELETE_TRAINEE_SUCCESS,
    REQUEST_GET_DA_TRAINEES,
    GET_DA_TRAINEES_SUCCESS,
    GET_DA_TRAINEES_FAILED,
    REQUEST_GET_PENDING_TRAINEES,
    GET_PENDING_TRAINEES_SUCCESS,
    GET_PENDING_TRAINEES_FAILED,
    REQUEST_APPROVE_TRAINEES,
    APPROVE_TRAINEES_SUCCESS,
    APPROVE_TRAINEES_FAILED,
    REQUEST_GET_ALL_TRAINEE_DA_BY_PROVINCE,
    GET_ALL_TRAINEE_DA_BY_PROVINCE_SUCCESS,
    GET_ALL_TRAINEE_DA_BY_PROVINCE_FAILED,
    REQUEST_TRANSFER_TRAINEE,
    TRANSFER_TRAINEE_SUCCESS,
    TRANSFER_TRAINEE_FAILED,
    REQUEST_GET_PENDING_TRANSFER,
    GET_PENDING_TRANSFER_SUCCESS,
    GET_PENDING_TRANSFER_FAILED,
    REQUEST_APPROVE_TRANSFERS,
    APPROVE_TRANSFERS_SUCCESS,
    APPROVE_TRANSFERS_FAILED,
    REQUEST_UPDATE_GROUP,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_ERROR,
    REQUEST_GET_TRAINEE,
    GET_TRAINEE_SUCCESS,
    GET_TRAINEE_FAILED,
    CLEAR_SUCCESS_STATUS,
    VIEW_TRAINEE,
    REQUEST_DOWNLOAD_CREATION,
    DOWNLOAD_CREATION_SUCCESS,
    DOWNLOAD_CREATION_FAILED,
} from '../types';

const { REACT_APP_BACKEND } = process.env;

export const createTrainee =
    (trainee, routeHistory = undefined, resetForm = undefined) =>
    dispatch => {
        dispatch({ type: REQUEST_TRAINEE_CREATION });
        axios
            .post(`${REACT_APP_BACKEND}/api/trainees/create`, trainee)
            .then(res => {
                if (!res) {
                    dispatch({ type: TRAINEE_CREATION_FAILED, payload: 'Phone number or Email already exist' });
                    toast.error('Phone number or Email already exist');
                    return;
                }
                toast.success(res.data.message);
                dispatch({ type: TRAINEE_CREATION_SUCCESS, payload: res.data.message });
                if (routeHistory) routeHistory.push('/register/success');
                resetForm && resetForm();
            })
            .catch(err => {
                console.log('===response error===>', err);
                toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
                dispatch({
                    type: TRAINEE_CREATION_FAILED,
                    payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
                });
            });
    };

export const getAllTrainees = () => dispatch => {
    dispatch({ type: REQUEST_GET_TRAINEES });
    axios
        .get(`${REACT_APP_BACKEND}/api/trainees`)
        .then(res => {
            dispatch({ type: GET_TRAINEES_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_TRAINEES_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const findTraineeByTraineeIdentity = (data, message) => dispatch => {
    dispatch({ type: REQUEST_SEARCH_CERTIFICATE });
    axios
        .post(`${REACT_APP_BACKEND}/api/trainees/search-certificate`, data)
        .then(res => {
            if (res.status === 200) {
                toast.success(message.success);
            }
            dispatch({ type: SEARCH_CERTIFICATE_SUCCESS, payload: res.data.message });
        })
        .catch(err => {
            toast.error(message.error);
            dispatch({
                type: SEARCH_CERTIFICATE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const fetchTraineeProfileByTraineeIdentity = traineeId => dispatch => {
    dispatch({ type: REQUEST_VIEW_CERTIFICATE });
    axios
        .get(`${REACT_APP_BACKEND}/api/trainees/view-certificate/${traineeId}`)
        .then(res => {
            dispatch({ type: VIEW_CERTIFICATE_SUCCESS, payload: res.data.data });
        })
        .catch(err => {
            dispatch({
                type: VIEW_CERTIFICATE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const updateTraineeStatus = (traineeId, status) => dispatch => {
    dispatch({ type: REQUEST_UPDATE_STATUS });
    axios
        .put(`${REACT_APP_BACKEND}/api/trainees/${traineeId}`, { status })
        .then(res => {
            toast.success(res.data.message);
            dispatch({ type: UPDATE_STATUS_SUCCESS, payload: res.data });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: UPDATE_STATUS_ERROR,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};
export const makeTraineeCaptain = traineeId => dispatch => {
    dispatch({ type: REQUEST_CAPTAIN_MAKING });
    axios
        .put(`${REACT_APP_BACKEND}/api/trainees/${traineeId}/make-captain`)
        .then(res => {
            toast.success(res.data.message);
            dispatch({ type: MAKE_CAPTAIN_SUCCESS, payload: res.data });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: MAKE_CAPTAIN_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};
export const deleteTraineeAction = traineeId => dispatch => {
    dispatch({ type: REQUEST_TRAINEE_DELETE });
    axios
        .delete(`${REACT_APP_BACKEND}/api/trainees/${traineeId}`)
        .then(res => {
            toast.success(res.data.message);
            dispatch({ type: DELETE_TRAINEE_SUCCESS, payload: res.data });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: DELETE_TRAINEE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getAllTraineesByDa = () => dispatch => {
    dispatch({ type: REQUEST_GET_DA_TRAINEES });
    axios
        .get(`${REACT_APP_BACKEND}/api/trainees/my-trainees`)
        .then(res => {
            dispatch({ type: GET_DA_TRAINEES_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_DA_TRAINEES_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getPendingTrainees = () => dispatch => {
    dispatch({ type: REQUEST_GET_PENDING_TRAINEES });
    axios
        .get(`${REACT_APP_BACKEND}/api/trainees/pending`)
        .then(res => {
            dispatch({ type: GET_PENDING_TRAINEES_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_PENDING_TRAINEES_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const approveManyTrainees = trainees => dispatch => {
    dispatch({ type: REQUEST_APPROVE_TRAINEES });
    axios
        .put(`${REACT_APP_BACKEND}/api/trainees/approve/all`, trainees)
        .then(res => {
            toast.success(res.data.message);
            dispatch({ type: APPROVE_TRAINEES_SUCCESS, payload: res.data.message });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: APPROVE_TRAINEES_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getDasAndTraineesPerProvince = () => dispatch => {
    dispatch({ type: REQUEST_GET_ALL_TRAINEE_DA_BY_PROVINCE });
    axios
        .get(`${REACT_APP_BACKEND}/api/geolocations/das_and_trainee_per_province`)
        .then(res => {
            dispatch({ type: GET_ALL_TRAINEE_DA_BY_PROVINCE_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_TRAINEE_DA_BY_PROVINCE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};
export const transferTrainee = (id, trainee) => dispatch => {
    dispatch({ type: REQUEST_TRANSFER_TRAINEE });
    axios
        .put(`${REACT_APP_BACKEND}/api/trainees/transfer/${id}`, trainee)
        .then(res => {
            toast.success(res.data.message);
            dispatch({ type: TRANSFER_TRAINEE_SUCCESS, payload: res.data.data });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: TRANSFER_TRAINEE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getPendingTransfers = () => dispatch => {
    dispatch({ type: REQUEST_GET_PENDING_TRANSFER });
    axios
        .get(`${REACT_APP_BACKEND}/api/trainees/transfers/pending`)
        .then(res => {
            dispatch({ type: GET_PENDING_TRANSFER_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_PENDING_TRANSFER_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const approveTransfers = transfers => dispatch => {
    dispatch({ type: REQUEST_APPROVE_TRANSFERS });
    axios
        .put(`${REACT_APP_BACKEND}/api/trainees/transfer/approve/many`, transfers)
        .then(res => {
            toast.success(res.data.message);
            window.location.reload();
            dispatch({ type: APPROVE_TRANSFERS_SUCCESS, payload: res.data.data });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: APPROVE_TRANSFERS_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const updateTraineeGroup = (traineeId, groupId) => dispatch => {
    dispatch({ type: REQUEST_UPDATE_GROUP });
    axios
        .put(`${REACT_APP_BACKEND}/api/trainees/${traineeId}`, { groupId })
        .then(res => {
            toast.success(res.data.message);
            window.location.reload();
            dispatch({ type: UPDATE_GROUP_SUCCESS, payload: res.data });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: UPDATE_GROUP_ERROR,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getOneTrainee = id => dispatch => {
    dispatch({ type: REQUEST_GET_TRAINEE });
    axios
        .get(`${REACT_APP_BACKEND}/api/trainees/${id}`)
        .then(res => {
            dispatch({ type: GET_TRAINEE_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_TRAINEE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const updateTraineeData = (traineeId, data) => dispatch => {
    dispatch({ type: REQUEST_UPDATE_STATUS });
    axios
        .put(`${REACT_APP_BACKEND}/api/trainees/${traineeId}`, data)
        .then(res => {
            toast.success(res.data.message);
            dispatch({ type: UPDATE_STATUS_SUCCESS, payload: res.data });
            dispatch(clearTraineeSuccessState());
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: UPDATE_STATUS_ERROR,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const clearTraineeSuccessState = () => dispatch => {
    setTimeout(() => {
        dispatch({ type: CLEAR_SUCCESS_STATUS });
    }, 6000);
};

export const ViewTraineeAction = id => async dispatch => {
    const { data } = await axios.get(`${REACT_APP_BACKEND}/api/trainees/${id}`);

    dispatch({
        type: VIEW_TRAINEE,
        payload: data?.data,
    });
};
export const downloadCertificate = traineeIdentity => dispatch => {
    dispatch({ type: REQUEST_DOWNLOAD_CREATION });
    axios
        .post(`${REACT_APP_BACKEND}/api/downloads/create`, { traineeIdentity })
        .then(res => {
            // toast.success(res.data.message);
            dispatch({ type: DOWNLOAD_CREATION_SUCCESS, payload: res.data.message });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: DOWNLOAD_CREATION_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};
