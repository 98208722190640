import React from "react";
import "./index.css";

function arrayBufferToBase64(buffer) {
  let binary = "";
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return `data:image/jpg;base64,${window.btoa(binary)}`;
}
export default function UploadImage({ onUpload }) {
  const onChangeHandler = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      const base64 = arrayBufferToBase64(binaryStr);

      onUpload(base64,file);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="d-flex align-items-center justify-content-center placeholder ">
      <div className="d-flex align-items-center justify-content-center">
        <label htmlFor="single">
          <i className="fas fa-plus-circle icon"></i>
        </label>
        <input
          type="file"
          id="single"
          onChange={onChangeHandler}
          accept="image/*"
        />
      </div>
    </div>
  );
}
