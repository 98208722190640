import { 
    REQUEST_CHART_HOME,
    REQUEST_ALL_USERS,
    REQUEST_ALL_TRAINEES,
    REQUEST_ALL_TRAINEES_STATUS,
    REQUEST_ALL_TRAINEES_BY_AGE,
    REQUEST_ALL_AMBSADORS,
    REQUEST_ALL_CO_TRAINEE,
    REQUEST_ALL_CO_TRAINING_CENTER,
    REQUEST_ALL_CO_TRAINING_AMBASADROS,
    REQUEST_ALL_CO_DAS,
    REQUEST_ALL_CO_TRAINEE_RANGE,
    REQUEST_ALL_ADMIN_TRAINING_CENTER,
    REQUEST_ALL_TRAINEE_CENTER
  } from '../types';
  
  const initialState = {
    home: [],

    users: [],
    trainees: [],
    traineeByAge: {},

    traineeStatus: [],
    trainingCenter: [],

    ambsadors: [],

    coTrainee: [],
    coTrainingCenter: [],
    coAmbasadors: [],
    coDAS: [],
    coRangeTrainee: [],

    adminCenter: []
  }
  
  const charts = (state = initialState, action) =>{
    switch (action.type) {
      case REQUEST_CHART_HOME:
        return {
          ...state,
          home: action.payload
        }

      case REQUEST_ALL_USERS:
        return {
          ...state,
          users: action.payload
        }

        case REQUEST_ALL_TRAINEES:
          return {
            ...state,
            trainees: action.payload
          }

        case REQUEST_ALL_TRAINEES_STATUS:
          return {
            ...state,
            traineeStatus: action.payload
          }

          case REQUEST_ALL_TRAINEES_BY_AGE:
            return {
              ...state,
              traineeByAge: action.payload
            }

          case REQUEST_ALL_AMBSADORS:
            return {
              ...state,
              ambsadors: action.payload
            }

          case REQUEST_ALL_CO_TRAINEE:
            return {
              ...state,
              coTrainee: action.payload
            }

          case REQUEST_ALL_CO_TRAINING_CENTER:
            return {
              ...state,
              coTrainingCenter: action.payload
            }

          case REQUEST_ALL_CO_TRAINING_AMBASADROS:
            return {
              ...state,
              coAmbasadors: action.payload
            }

          case REQUEST_ALL_CO_DAS:
            return {
              ...state,
              coDAS: action.payload
            }

          case REQUEST_ALL_CO_TRAINEE_RANGE:
            return {
              ...state,
              coRangeTrainee: action.payload
            }

          case REQUEST_ALL_ADMIN_TRAINING_CENTER:
            return {
              ...state,
              adminCenter: action.payload
            }

          case REQUEST_ALL_TRAINEE_CENTER:
            return {
              ...state,
              trainingCenter: action.payload
            }
    
      default:
        return state
    }
  }

  export default charts;