import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import {} from 'react-redux';

const getYearsInProgress = () => {
    const currentYear = moment().year();

    const startingYear = 2015;
    let progressYears = Math.abs(currentYear - startingYear);

    let years = [startingYear];

    new Array(progressYears).fill(startingYear).forEach((year, index) => {
        console.log(index);
        years.push(year + index + 1);
    });

    return years;
};

const getProvinceProgress = users => {
    let allDistrict = [];
    users.map(user => {
        allDistrict.push(user.province);
        allDistrict = [...new Set(allDistrict)];
        return allDistrict;
    });
    return allDistrict;
};

const getDistrictProgress = users => {
    let allDistrict = [];
    users.map(user => {
        allDistrict.push(user.district);
        allDistrict = [...new Set(allDistrict)];
        return allDistrict;
    });
    return allDistrict;
};
const getSectorProgress = users => {
    let allDistrict = [];
    users.map(user => {
        allDistrict.push(user.sector);
        allDistrict = [...new Set(allDistrict)];
        return allDistrict;
    });
    return allDistrict;
};

const FilterButton = ({ setDataset, sanitizedDataset }) => {
    const filterByYear = criteria => {
        if (criteria === 'all') {
            return setDataset(sanitizedDataset);
        }
        const newDataset = sanitizedDataset.filter(el => String(el.registrationYear).includes(criteria));
        return setDataset([...newDataset]);
    };

    const fitlerByProvince = criteria => {
        if (criteria === 'all') {
            return setDataset(sanitizedDataset);
        }
        const newDataset = sanitizedDataset.filter(el => String(el.province).includes(criteria));
        return setDataset([...newDataset]);
    };

    const fitlerByDistrict = criteria => {
        if (criteria === 'all') {
            return setDataset(sanitizedDataset);
        }
        const newDataset = sanitizedDataset.filter(el => String(el.district).includes(criteria));
        return setDataset([...newDataset]);
    };
    const fitlerBySector = criteria => {
        if (criteria === 'all') {
            return setDataset(sanitizedDataset);
        }
        const newDataset = sanitizedDataset.filter(el => String(el.sector).includes(criteria));
        return setDataset([...newDataset]);
    };

    return (
        <div className="pull-right">
            <Dropdown>
                <Dropdown.Toggle variant="light" className="text-dark">
                    Filter By &nbsp; <i className="fas fa-angle-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="pl-3">
                        <Dropdown drop="left">
                            <Dropdown.Toggle variant="light" className="text-dark text-small">
                                <i className="fas fa-angle-left"></i> &nbsp;Year
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <span onClick={() => filterByYear('all')}> All</span>
                                </Dropdown.Item>
                                {getYearsInProgress().map(year => (
                                    <Dropdown.Item key={year} value={year}>
                                        {' '}
                                        <span onClick={() => filterByYear(year)}>{year}</span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="pl-3">
                        <Dropdown drop="left">
                            <Dropdown.Toggle variant="light" className="text-dark text-small">
                                <i className="fas fa-angle-left"></i> &nbsp; &nbsp;Province
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <span onClick={() => fitlerByProvince('all')} className="text-small">
                                        All
                                    </span>
                                </Dropdown.Item>
                                {getProvinceProgress(sanitizedDataset).map((province, index) => (
                                    <Dropdown.Item key={index}>
                                        <span onClick={() => fitlerByProvince(province)} className="text-small">
                                            {' '}
                                            {province}
                                        </span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="pl-3">
                        <Dropdown drop="left">
                            <Dropdown.Toggle variant="light" className="text-dark text-small">
                                <i className="fas fa-angle-left"></i> &nbsp; &nbsp;District
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <span onClick={() => fitlerByDistrict('all')} className="text-small">
                                        All
                                    </span>
                                </Dropdown.Item>
                                {getDistrictProgress(sanitizedDataset).map((district, index) => (
                                    <Dropdown.Item key={index}>
                                        <span onClick={() => fitlerByDistrict(district)} className="text-small">
                                            {district}
                                        </span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="pl-3">
                        <Dropdown drop="left">
                            <Dropdown.Toggle variant="light" className="text-dark text-small">
                                <i className="fas fa-angle-left"></i> &nbsp; &nbsp;Sectors
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <span onClick={() => fitlerBySector('all')} className="text-small">
                                        All
                                    </span>
                                </Dropdown.Item>
                                {getSectorProgress(sanitizedDataset).map((district, index) => (
                                    <Dropdown.Item key={index}>
                                        <span onClick={() => fitlerBySector(district)} className="text-small">
                                            {district}
                                        </span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default FilterButton;
