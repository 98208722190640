import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Button, Breadcrumb } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { reverse } from 'named-urls';
import CpanelLayout from '../../../../layouts/AdminLayout';

import './index.css';
import Routes from '../../../../routes/names';
import checkAuthorized from '../../../../utils/checkAuthorized';
import { onlyAdmin, onlyDA, forAdmin } from '../../../../utils/roles';
import AddSap from '../../../admin-dashboard/components/sap/add-sap/AddSap';
import { AddTransaction, AddService } from '../../components/sap';

import { getSingleCenter } from '../../../../redux/actions';

import { getAllTransactions } from '../../../../redux/actions';

import ManageMaterials from '../../components/sap/manage-materials/ManageMaterials';
import Spinner from '../../../../components/spinner/Spinner';

function SAPDetailsOverview() {
    const dispatch = useDispatch();
    const { roles } = useSelector(state => state.auth);

    const { singleCenter, loading, error } = useSelector(state => state.centers);
    const materials = singleCenter?.Material;

    const { centerName, DAInfo, Location, Transactions } = singleCenter;
    const { transactions, loading: transactionLoading, error: transactionError } = useSelector(state => state.transactions);
    const [isLoading, setIsLoading] = useState(true);
    const services = singleCenter?.Services || [];

    const primaryServices = services.filter(el => el.serviceType === 'primary-service');
    const secondaryServices = services.filter(el => el.serviceType === 'secondary-service');

    const [modals, setModals] = useState({
        addSap: false,
        addTransaction: false,
        addService: false,
    });
    const openAddSap = () => setModals({ ...modals, addSap: true });
    const closeAddSap = () => setModals({ ...modals, addSap: false });

    const openAddTransaction = () => setModals({ ...modals, addTransaction: true });
    const closeAddTransaction = () => {
        setModals({ ...modals, addTransaction: false });
        AddTransactionRef.current.resetForm();
    };

    const openAddService = () => setModals({ ...modals, addService: true });
    const closeAddService = () => setModals({ ...modals, addService: false });
    const AddTransactionRef = useRef();
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            dispatch(getSingleCenter(id));
        }

        if (isLoading) {
            dispatch(getAllTransactions());
            setIsLoading(false);
        }
        console.log('==error===>', error);
        console.log('====center====>', singleCenter);
    }, [id, isLoading, dispatch]);

    if (loading) {
        return (
            <CpanelLayout>
                {' '}
                <Spinner />
            </CpanelLayout>
        );
    }

    if (!Boolean(singleCenter.id) && Boolean(error)) {
        return (
            <CpanelLayout>
                <div className="w-100 h-100 d-flex algin-items-center justify-content-center py-5">
                    <div>SAP you're looking is not existed or has been deleted</div>
                </div>
            </CpanelLayout>
        );
    }

    return (
        Boolean(singleCenter.id) && (
            <CpanelLayout>
                <Container className="sap">
                    {checkAuthorized(forAdmin, roles) && (
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <Breadcrumb.Item className="text-primary">
                                        <Link to="/cpanel/overview">Overview</Link>
                                    </Breadcrumb.Item>

                                    <Breadcrumb.Item className="text-primary">
                                        <Link to="/cpanel/sap/overview">SAP Management</Link>
                                    </Breadcrumb.Item>

                                    <Breadcrumb.Item active>SAP Services</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col md={8}>
                            <span className="text-medium">SAP Management</span>

                            {checkAuthorized(onlyDA, roles) && (
                                <>
                                    <span className="text-bold text-small mx-4">{centerName || ''}</span>
                                    <span className="text-small">{Location?.sector || ''}</span>
                                </>
                            )}
                        </Col>

                        {checkAuthorized(forAdmin, roles) && (
                            <Col md={4}>
                                <Button className="pull-right btn-light" onClick={openAddSap}>
                                    <i className="fas fa-pen"></i> Add a SAP
                                </Button>
                            </Col>
                        )}
                    </Row>

                    {checkAuthorized(onlyAdmin, roles) && (
                        <Row className="mt-3">
                            <Col md={12}>
                                <span className="text-bold text-small">SAP Name:</span>
                                <span className="text-small mx-3">{centerName || ''}</span>
                                <span className="text-bold text-small mx-3">Location:</span>
                                <span className="text-small mr-3">{Location?.sector || ''}</span>
                                <span className="text-bold text-small mx-3">DA:</span>
                                <span className="text-small">{DAInfo ? `${DAInfo.firstName} ${DAInfo.lastName}` : ''}</span>
                            </Col>
                        </Row>
                    )}

                    <Row className="mt-3">
                        <Col md={8}>
                            <Row>
                                <Col md={12}>{materials && <ManageMaterials id={id} materials={materials} />}</Col>
                            </Row>

                            {Transactions && Boolean(Transactions.length) ? (
                                <div className="shadow p-4 mt-4">
                                    <Col lg={12}>
                                        <div className="d-flex align-items-end justify-content-end">
                                            {checkAuthorized([...onlyDA], roles) && (
                                                <Button className="pull-right  btn-light" onClick={openAddTransaction}>
                                                    <i className="fas fa-plus"></i> Add transaction
                                                </Button>
                                            )}
                                        </div>
                                        <div className="row">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">#</th>
                                                        <th className="text-small">tem</th>
                                                        <th className="text-small">Type</th>
                                                        <th className="text-small">Amount</th>
                                                        <th className="text-small">Time</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {Transactions.slice(0, 11).map((transaction, index) => (
                                                        <tr key={index + 1}>
                                                            <td className="text-center">{index + 1}</td>
                                                            <td>{transaction.name}</td>
                                                            <td>
                                                                <i className={`fas ${transaction.expensesType === 'income' ? 'fa-caret-up text-success' : 'fa-caret-down text-danger'}`}></i>
                                                            </td>
                                                            <td>{Number(transaction.amount).toLocaleString()}</td>
                                                            <td>{moment(transaction.createdAt).fromNow()}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            <Link to={reverse(Routes.dashboard.sap.transactions, { id })} className="btn btn-primary ml-4 px-4">
                                                View more
                                            </Link>
                                        </div>
                                    </Col>
                                </div>
                            ) : (
                                <div className="d-flex align-items-end justify-content-end my-4">
                                    {checkAuthorized([...onlyDA], roles) && (
                                        <Button className="pull-right  btn-light" onClick={openAddTransaction}>
                                            <i className="fas fa-plus"></i> Add transaction
                                        </Button>
                                    )}
                                </div>
                            )}
                        </Col>

                        <Col md={4} className="spa-list">
                            <div className="shadow px-5 py-4">
                                <div className="row">
                                    <div className="col-md-8">
                                        <p className="text-medium">Services offered</p>
                                    </div>
                                </div>

                                {Boolean(primaryServices.length) && <p className="text-normal text-bold mb-2">Primary services</p>}

                                <ul className="p-0">
                                    {primaryServices.map(service => {
                                        return <li key={service.id}> {service.name}</li>;
                                    })}
                                </ul>

                                {Boolean(secondaryServices.length) && <p className="text-normal text-bold mb-2">Secondary services</p>}

                                <ul className="p-0">
                                    {secondaryServices.map(service => {
                                        return <li key={service.id}> {service.name}</li>;
                                    })}
                                </ul>

                                {checkAuthorized(onlyDA, roles) && (
                                    <div className="py-3">
                                        <Button className=" btn-light" onClick={openAddService}>
                                            <i className="fas fa-plus"></i> Add service
                                        </Button>
                                    </div>
                                )}

                                <Link to={reverse(Routes.dashboard.sap.services, { id })} className="btn btn-primary">
                                    View more
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <AddSap open={modals.addSap} onClose={closeAddSap} />

                <AddTransaction open={modals.addTransaction} ref={AddTransactionRef} onClose={closeAddTransaction} />

                <AddService id={id} open={modals.addService} onClose={closeAddService} />
            </CpanelLayout>
        )
    );
}

export default SAPDetailsOverview;
