// User reducer types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const RESET_REQUEST = 'RESET_REQUEST';
export const SENDLINK_REQUEST = 'SENDLINK_REQUEST';
export const SEND_RESET_LINK_SUCCESS = 'SEND_RESET_LINK_SUCCESS';
export const SEND_RESET_LINK_FAILURE = 'SEND_RESET_LINK_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const RESET_ACCOUNT_FAILURE = 'RESET_ACCOUNT_FAILURE';
export const RESET_ACCOUNT_SUCCESS = 'RESET_ACCOUNT_SUCCESS';
export const OTP_SUCCESS = 'OTP_SUCCESS';
export const OTP_FAILURE = 'OTP_FAILURE';
export const SUBMIT_DATA = 'SUBMIT_DATA';
export const SET_EXPIRED = 'SET_EXPIRED';
