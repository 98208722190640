import {
	Container,
	Navbar,
	Nav,
	NavDropdown,
	Form,
	FormControl,
	Button,
	InputGroup,
	Image,
	Modal,
	FormGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import i18n from '../../../utils/i18n';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from "react-redux";
import './index.css';

import Logo from '../../../assets/img/logo.png';
import RW from '../../../assets/img/svg/rw.svg';
import EN from '../../../assets/img/svg/en.svg';
import FR from '../../../assets/img/svg/fr.svg';
import { useEffect, useState } from 'react';
import Login  from '../../../components/auth/Login';
import { ForgotPassword } from '../../../components/auth/ForgotPassword';
import { CheckOTP } from '../../../components/auth/CheckOTP';
import { ResetPassword } from '../../../components/auth/ResetPassword';

import { logoutUser } from '../../../redux/actions/authActions';
import jwtDecode from 'jwt-decode';
import routes from '../../../routes/names';
import { LOGIN_FAILURE } from '../../../redux/types';

const Header = ({ t }) => {
	const lang = localStorage.getItem('i18nextLng');

	const [search, setSearch] = useState(false);
	const [step, setStep] = useState(1);

	const dispatch = useDispatch();
	const [user, setUser] = useState('');

	const [show, setShow] = useState(false);

	const handleClose = () => {
		setStep(1);
		setShow(false);
		dispatch({type: LOGIN_FAILURE,payload:null})
	};

	const handleShow = () => setShow(true);

	const handleLang = value => {
		i18n.changeLanguage(value);
	};

	const handleLogout = () => {
		dispatch(logoutUser());
		window.location.href = '/';
	  }

	useEffect(() => {
		const IdToken = localStorage.getItem('IdToken');
      
		if (IdToken) {
			const token = jwtDecode(IdToken);
			setUser(`${token.lastName}`);
		}

		function handleResize() {
			const width = window.innerWidth;
			if (width <= 820) return setSearch(true);

			return setSearch(false);
		}

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			<Navbar expand='lg' sticky='top' className='shadow-sm custom-navbar'>
				<Container>
					<Navbar.Brand>
						<Link to='/'>
							<Image
								src={Logo}
								width='70'
								height='70'
								alt='DIGITAL AMBASSADORS PROGRAM'
							/>{' '}
							<span className='logo-text web-view'>
								DIGITAL AMBASSADORS PROGRAM
							</span>
							<span className='logo-text mobile-view'>DAP</span>
						</Link>
					</Navbar.Brand>

					<Navbar.Toggle />

					<Navbar.Collapse>
						<Nav className='mx-auto'></Nav>

						<Nav className='ml-auto'>
							{/* <Form inline>
								<InputGroup>
									{search && (
										<FormControl
											type='text'
											placeholder={`${t(`Search`)}`}
											className='mr-sm-2 border-top-0 border-right-0 border-left-0'
										/>
									)}

									<InputGroup.Append>
										<button
											type='button'
											className='btn btn-light search-btn'
											onClick={() => setSearch(true)}
										>
											<i className='fas fa-search'></i>
										</button>
									</InputGroup.Append>
								</InputGroup>
							</Form> */}

							{
								user !== '' ? <NavDropdown
								className="trans mx-4"
								title={user}
								menuAlign={{ lg: "left" }}
							>
								<NavDropdown.Item><Link to={routes.dashboard.profile}>Profile</Link></NavDropdown.Item>
								<NavDropdown.Item onClick={handleLogout}>Sign Out</NavDropdown.Item>
							</NavDropdown> : <Nav.Link className='sign-btn' onClick={handleShow}>
								{`${t(`Kwinjira`)}`}
							</Nav.Link>
							}

							<NavDropdown
								className='trans'
								title={
									<>
										<Image
											src={lang === 'en-US' || lang === 'en' ? EN : lang === 'fr' ? FR : RW}
											width='25'
											height='25'
											className='img-fluid'
											alt='DIGITAL AMBASSADORS PROGRAM'
										/>
										&nbsp;
										{lang === 'en-US' || lang === 'en'
											? 'English'
											: lang === 'fr'
											? 'French'
											: 'Kinyarwanda'}
									</>
								}
							>
								<NavDropdown.Item onClick={() => handleLang('rw')}>
									<Image
										src={RW}
										width='25'
										height='25'
										className='img-fluid'
										alt='DIGITAL AMBASSADORS PROGRAM'
									/>
									&nbsp;Kinyarwanda
								</NavDropdown.Item>
								<NavDropdown.Item onClick={() => handleLang('en')}>
									<Image
										src={EN}
										width='25'
										height='25'
										className='img-fluid'
										alt='English'
									/>
									&nbsp;English
								</NavDropdown.Item>
								<NavDropdown.Item onClick={() => handleLang('fr')}>
									<Image
										src={FR}
										width='25'
										height='25'
										className='img-fluid'
										alt='French'
									/>
									&nbsp;French
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>

			<Modal
				show={show}
				onHide={handleClose}
				animation={false}
				className='auth-modal'
				backdrop='static'
			>
				<Modal.Header closeButton>
					<Modal.Title className='text-primary'>
						{step === 1 ? t(`Kwinjira`) : t(`Ijambo banga`)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='show-grid'>
					{step === 1 && <Login onNextPage2={() => setStep(2)} t={t}/>}

					{step === 2 && <ForgotPassword onNextPage1={() => setStep(1)} t={t} />}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default withNamespaces()(Header);
