import React from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { createMaterial } from '../../../../../redux/actions/materials';
import isEmpty from 'lodash/isEmpty';
import LoadingIndicator from '../../../../../utils/LoadingSmall';

import * as Yup from 'yup';
function AddMaterial({ open, onClose, id }) {
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Material Name is required'),
        materialType: Yup.string().required('Please select material type'),
        status: Yup.string().required('Please select material status'),
        owner: Yup.string().required('Please select material owner'),
    });

    const initialValues = {
        name: '',
        materialType: '',
        description: ',',
        depreciationRate: '0',
        sapmaterialIdentity: '',
        owner: '',
        status: '',
        otherMaterial: '',
        otherOwner: '',
    };

    const { loading, error } = useSelector(state => state.centers);

    const { values, errors, touched, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const payload = {
                ...values,
                trainingcenterId: id,
                materialType: values.materialType === 'other' && !isEmpty(values.otherMaterial) ? values.otherMaterial : values.materialType,
                owner: values.owner === 'other' && !isEmpty(values.otherOwner) ? values.otherOwner : values.owner,
            };
            dispatch(createMaterial({ payload, onClose, resetForm }));
        },
    });

    return (
        <div>
            <Container>
                <Modal show={open} size="md" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Material</Modal.Title>
                    </Modal.Header>

                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            {' '}
                            <Container>
                                <Row>
                                    <Form.Group as={Col} className="col-12">
                                        <Form.Control name="name" required placeholder="Name" onChange={handleChange} />

                                        {touched.name && errors.name && (
                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group as={Col} className="col-12">
                                        <Form.Control name="materialType" required as="select" className="custom-select" onChange={handleChange}>
                                            <option value="" selected disabled>
                                                {' '}
                                                Material Type
                                            </option>
                                            <option value="laptop"> Laptop</option>
                                            <option value="smartphone"> Smartphone</option>
                                            <option value="printer"> Printer</option>
                                            <option value="screen"> Screen</option>
                                            <option value="other"> Other</option>
                                        </Form.Control>

                                        {values.materialType === 'other' && (
                                            <div className="pt-3">
                                                {' '}
                                                <Form.Group>
                                                    <Form.Control type="text" name="otherMaterial" value={values.otherMaterial} placeholder="Type other material type..." disabled={loading} onChange={handleChange} />
                                                    {touched.otherMaterial && errors.otherMaterial && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.otherMaterial}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </div>
                                        )}

                                        {touched.materialType && errors.materialType && (
                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                {errors.materialType}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-12">
                                        <Form.Control name="status" required as="select" className="custom-select" onChange={handleChange} value={values.status}>
                                            <option value="" selected disabled>
                                                {' '}
                                                Condition
                                            </option>
                                            <option value="functional">Functional</option>
                                            <option value="damaged">Damaged</option>
                                            <option value="removed">Removed</option>
                                        </Form.Control>

                                        {touched.status && errors.status && (
                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                {errors.status}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-12">
                                        <Form.Control name="owner" required as="select" className="custom-select" onChange={handleChange}>
                                            <option value="" selected disabled>
                                                {' '}
                                                Owner
                                            </option>
                                            <option value="minict">MINICT</option>
                                            <option value="dot-rwanda">DOT RWANDA</option>
                                            <option value="other">Other</option>
                                        </Form.Control>

                                        {values.owner === 'other' && (
                                            <div className="pt-3">
                                                {' '}
                                                <Form.Group>
                                                    <Form.Control type="text" name="otherOwner" value={values.otherOwner} placeholder="Type other material owner..." disabled={loading} onChange={handleChange} />
                                                    {touched.otherOwner && errors.otherOwner && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.otherOwner}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </div>
                                        )}

                                        {touched.owner && errors.owner && (
                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                {errors.owner}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} className="col-12">
                                        <Form.Control name="sapmaterialIdentity" placeholder="Serial Number (if applicable)" onChange={handleChange} />
                                    </Form.Group>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Container className="pt-2">
                                <Form.Control.Feedback className="d-block" type="invalid">
                                    {error}
                                </Form.Control.Feedback>
                                <Row>
                                    <Col className="col-lg-6 col-sm-12">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Button className={`px-5 py-2 mt-3 rounded ${loading && 'd-flex'}`} onClick={handleSubmit} disabled={loading}>
                                                Save {loading && <LoadingIndicator color="#FFF" marginTop=" " center={true} />}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Container>
        </div>
    );
}

export default AddMaterial;
