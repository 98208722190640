import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Container } from "react-bootstrap";
import "./index.css";

export default class CourseDetailsModal extends React.Component {
  state = {
    show: false,
  };

  open = () => this.setState({ show: true });

  close = () => this.setState({ show: false });

  render() {
    const { id, title, description, duration, link, t } = this.props;
    return (
      <div>
        <Container>
          <Modal
            show={this.state.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="course-modal"
            className="course-modal"
            animation={false}
            onHide={this.close}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {id}.{title}
              </Modal.Title>
            </Modal.Header>
            <div className="pt-2 px-2">
              <span className="text-extra-small"> {`${t(`Iminota`)}`}  {duration}</span>
            </div>
            <Modal.Body>{description}</Modal.Body>
            <Modal.Footer>
              <a 
                className="btn btn-sm btn-primary" 
                href={link} 
                target='_blank'
                rel='noopener noreferrer'
              >
                {`${t(`Kureba ibijyanye n'isomo`)}`} 
              </a>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    );
  }
}
