export const REQUEST_CHART_HOME = 'REQUEST_CHART_HOME';
export const REQUEST_ALL_USERS = 'REQUEST_ALL_USERS';
export const REQUEST_ALL_TRAINEES = 'REQUEST_ALL_TRAINEES';
export const REQUEST_ALL_TRAINEES_BY_AGE = 'REQUEST_ALL_TRAINEES_BY_AGE';
export const REQUEST_ALL_TRAINEES_STATUS = 'REQUEST_ALL_TRAINEES_STATUS';
export const REQUEST_ALL_AMBSADORS = 'REQUEST_ALL_AMBSADORS';
export const REQUEST_ALL_CO_TRAINEE = 'REQUEST_ALL_CO_TRAINEE';
export const REQUEST_ALL_CO_TRAINING_CENTER = 'REQUEST_ALL_CO_TRAINING_CENTER';
export const REQUEST_ALL_CO_TRAINING_AMBASADROS = 'REQUEST_ALL_CO_TRAINING_AMBASADROS';
export const REQUEST_ALL_CO_DAS = 'REQUEST_ALL_CO_DAS';
export const REQUEST_ALL_CO_TRAINEE_RANGE = 'REQUEST_ALL_CO_TRAINEE_RANGE';
export const REQUEST_ALL_ADMIN_TRAINING_CENTER = 'REQUEST_ALL_ADMIN_TRAINING_CENTER';
export const REQUEST_ALL_TRAINEE_CENTER = 'REQUEST_ALL_TRAINEE_CENTER';