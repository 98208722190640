import React from "react";
import "./index.css";

import { Container, Row, Col } from "react-bootstrap";

const Title = ({ children }) => {
  return (
    <div className="py-3">
      <span className="text-medium">{children}</span>
    </div>
  );
};

const Registrants = [
  {
    id: 1,
    firstName: "Kalisa",
    lastName: "John",
  },
  {
    id: 2,
    firstName: "Umulisa",
    lastName: "Ange",
  },

  {
    id: 3,
    firstName: "KAREMERA",
    lastName: "Patrick",
  },

  {
    id: 4,
    firstName: "Umulisa",
    lastName: "Ange",
  },
];

export default function PendingRegistrants({ onAdmit, registrants, trainees }) {
  return (
    <Container>
      <Row>
        <Col className="">
          <div className="registrant-pending-card  px-4 py-3 ">
            <Title> Pending registrants</Title>
            {trainees.map((registrant) => {
              return (
                <div key={registrant.id}>
                  <div className="pb-1">
                    <span className="text-extra-small">
                      {registrant.firstName} {registrant.lastName}
                    </span>
                  </div>
                </div>
              );
            })}

            <div className="pt-2">
              <div
                onClick={() => onAdmit && onAdmit(registrants)}
                className="btn btn-light"
              >
                Review and add
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}