
import axios from 'axios';

import { toast } from 'react-toastify';
import {
  REQUEST_SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED,
  REQUEST_GET_SENT_MESSAGE,
  GET_SENT_MESSAGE_SUCCESS,
  GET_SENT_MESSAGE_FAILED,
  REQUEST_GET_RECEIVED_MESSAGE,
  GET_RECEIVED_MESSAGE_SUCCESS,
  GET_RECEIVED_MESSAGE_FAILED,
} from '../types';

const { REACT_APP_BACKEND } = process.env;

export const sendMessage = message => dispatch => {
	dispatch({ type: REQUEST_SEND_MESSAGE });
	axios
		.post(`${REACT_APP_BACKEND}/api/communications/create`, message)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: SEND_MESSAGE_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: SEND_MESSAGE_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const sendCaptainMessage = message => dispatch => {
	dispatch({ type: REQUEST_SEND_MESSAGE });
	axios
		.post(`${REACT_APP_BACKEND}/api/communications/create/captain`, message)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: SEND_MESSAGE_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: SEND_MESSAGE_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const getSentMessage = () => (dispatch) => {
  dispatch({ type: REQUEST_GET_SENT_MESSAGE });
  axios
    .get(`${REACT_APP_BACKEND}/api/communications/sent`)
    .then((res) => {
      dispatch({ type: GET_SENT_MESSAGE_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_SENT_MESSAGE_FAILED,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};

export const getReceivedMessage = () => (dispatch) => {
  dispatch({ type: REQUEST_GET_RECEIVED_MESSAGE });
  axios
    .get(`${REACT_APP_BACKEND}/api/communications/receiver`)
    .then((res) => {
      dispatch({ type: GET_RECEIVED_MESSAGE_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_RECEIVED_MESSAGE_FAILED,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};

