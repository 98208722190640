import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_NOTIFICATION_FAILURE, GET_ONE_NOTIFICATION, GET_UNSEEN_NOTIFICATIONS, REQUEST_ONE_NOTIFICATION, REQUEST_UNSEEN_NOTIFICATIONS, REQUEST_ALL_NOTIFICATIONS, GET_ALL_NOTIFICATIONS, ALL_NOTIFICATIONS_FAILED } from '../types';

const { REACT_APP_BACKEND } = process.env;

export const getAllUnseenNotifications = () => dispatch => {
    dispatch({ type: REQUEST_UNSEEN_NOTIFICATIONS });
    axios
        .get(`${REACT_APP_BACKEND}/api/notifications/unseen`)
        .then(res => {
            dispatch({ type: GET_UNSEEN_NOTIFICATIONS, payload: res.data });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: GET_NOTIFICATION_FAILURE,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getAllNotifications = () => dispatch => {
    dispatch({ type: REQUEST_ALL_NOTIFICATIONS });
    axios
        .get(`${REACT_APP_BACKEND}/api/notifications`)
        .then(res => {
            dispatch({ type: GET_ALL_NOTIFICATIONS, payload: res.data });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: ALL_NOTIFICATIONS_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getUnseenNotification = notification => dispatch => {
    dispatch({ type: REQUEST_ONE_NOTIFICATION });
    axios
        .get(`${REACT_APP_BACKEND}/api/notifications/unseen/${notification}`)
        .then(res => {
            dispatch({ type: GET_ONE_NOTIFICATION, payload: res.data.message });
        })
        .catch(err => {
            toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
            dispatch({
                type: GET_NOTIFICATION_FAILURE,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};
