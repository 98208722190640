import React from "react";
import { useDispatch, useSelector} from 'react-redux'
import { withNamespaces } from 'react-i18next';
import "./index.css";

import { useHistory } from "react-router-dom";

import { reverse } from 'named-urls';

import { Form, Col, Row, Container, Button } from "react-bootstrap";
import Routes from "../../routes/names";
import Header from "../client/components/Header";
import Footer from "../client/components/Footer";
import BackLink from "../../components/commons/back-link/BackLink";
import { findTraineeByTraineeIdentity } from '../../redux/actions';
import { useFormik } from "formik";
import * as Yup from "yup";

const SearchCertificate = ({ t }) => {
  const history = useHistory();
  const dispatch = useDispatch();

	// const fetchTraineeFailure = useSelector(state => state.trainees.error);
	const fetchTraineeSuccess =  useSelector(state => state.trainees.success);

  const initialValues = {
    // fullname: "",
    traineeIdentity: "",
  };

  const validationSchema = Yup.object().shape({
    // fullname: Yup.string().required(`${t(`Full Name is required`)}`),
    traineeIdentity: Yup.string().required(`${t(`Trainee Identity is required`)}`),
  });

  const message = {
    success : `${t(`Trainee account is exist`)}`,
    error : `${t(`Trainee account not exist`)}!`,
  };

  const { values, handleSubmit, errors, handleChange, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        dispatch(findTraineeByTraineeIdentity(values, message));
      },
    });

    if (fetchTraineeSuccess && values.traineeIdentity) {
      setTimeout(() => {
        // window.location.href = reverse(Routes.register.verify, { traineeIdentity: values.traineeIdentity });
        history.push(reverse(Routes.register.verify, { traineeIdentity: values.traineeIdentity }));
      }, 800);
    }

  return (
    <div className="App">
      <Header />

      <div className="body-content bg-dark-primary pt-5">
        <Container className=" container ">
          <Row>
            <Col md={12}>
              <BackLink path={Routes.home} />{" "}
            </Col>
          </Row>

          <div className="box mt-3">
            <Container>
              <div>
                <span className="heading">{`${t(`Gushaka impamyabumenyi`)}`}</span>
              </div>

              <Form>
                {/** Form section - Identity*/}

                <div>
                  <div className="py-3">
                    <span className="subtitle">
                      {`${t(`Andika amazina yawe na kode wahawe n'intore mu ikoranabuhanga ubashe kubona niba ufite impamyabumenyi`)}`}.
                    </span>
                  </div>
                  {/* <Row className="py-2">
                    <Col lg={4} sm={12}>
                      <Form.Group>
                        <Form.Control 
                          placeholder={`${t(`Amazina`)}`} 
                          name='fullname' 
                          value={ values.fullname } 
                          onChange={handleChange} 
                          autoComplete="off"
                        />

                        {touched.fullname && errors.fullname && (
                          <Form.Control.Feedback
                            className="d-block"
                            type="invalid"
                          >
                            {errors.fullname}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row> */}

                  <Row>
                    <Col lg={4} sm={12}>
                      <Form.Group>
                        <Form.Control
                          placeholder={`${t(`Code`)}`} 
                          name='traineeIdentity' 
                          value={ values.traineeIdentity } 
                          onChange={handleChange} 
                          required={true}
                          autoComplete="off"
                        />

                        {touched.traineeIdentity && errors.traineeIdentity && (
                          <Form.Control.Feedback
                            className="d-block"
                            type="invalid"
                          >
                            {errors.traineeIdentity}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                {/** Submit button */}
                  <Row>
                    <Col lg={6} sm={12}>
                    {/* {fetchTraineeSuccess && <Alert className='alert-info'>{`${t(`Trainee account is exist`)}`} !</Alert>}
						        {fetchTraineeFailure && <Alert className='alert-danger'>{`${t(`Trainee account not exist`)}`}!</Alert>} */}
                    </Col>
                  </Row>

                <div className="pt-3">
                  <Button className="btn btn-primary px-5 mt-4" onClick={handleSubmit}>
                    {`${t(`Gushaka`)}`}
                  </Button>
                </div>

                <div className="py-4">
                  <span>
                    {`${t(`Niba utibuka kode yawe`)}`}.{" "}
                    {/* <Link className=" btn-link" to={Routes.searchCode}>
                      {`${t(`Kanda Hano`)}`}
                    </Link> */}
                  </span>
                </div>
              </Form>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
export default withNamespaces()(SearchCertificate);
