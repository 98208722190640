import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import { toast } from 'react-toastify';

import { createCenterLocation } from '../../../../../redux/actions';
import { listService } from '../../../../../redux/actions/services';
import { matchCoordinates } from '../../../../../helpers';
import Loading from '../../../../../utils/LoadingSmall';

export default function AddSap({ open, onClose }) {
    const validationSchema = Yup.object().shape({
        centerName: Yup.string().required(' First name is required'),
        province: Yup.string().required(' Province is required'),
        district: Yup.string().required(' District is required'),
        sector: Yup.string().required(' Sector is required'),
        cell: Yup.string().required(' Cell is required'),
        coordinates: Yup.object().shape({
            latitude: Yup.number('Latitude must be a number').min(-90, 'latitude should be above -90').max(90, 'latitude should be less than 90').required(' SAP Latitude coordinate is required'),
            longitude: Yup.number('Longitude must be a number').min(-180, 'longitude should be above -180').max(180, 'longitude should be less than 180').required(' SAP Longitude coordinate is required'),
        }),
        daInCharge: Yup.string().required('DA ID In Charge for SAP is required'),
        sapServices: Yup.array().of(Yup.object().shape({ label: Yup.string().notRequired(), key: Yup.string().notRequired() })),
    });

    const initialValues = {
        centerName: '',
        province: '',
        district: '',
        sector: '',
        cell: '',
        coordinates: { latitude: '-1.9507305', longitude: '30.1212607' },
        daInCharge: '',
        sapServices: [],
    };
    const dispatch = useDispatch();

    const [allSapServices, setAllSapServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedService, setSelectedService] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);

    const { loading, error } = useSelector(state => state.centers);
    const { services, loading: ServicesLoading } = useSelector(state => state.services);

    const [findDaInChargeError, setFindDaInChargeError] = useState('');

    useEffect(() => {
        isLoading && dispatch(listService());
        if (services && services.length) {
            const realServices = services.map(service => ({ label: service.name, key: service.id }));
            setAllSapServices(realServices);
            setIsLoading(false);
        }
    }, [isLoading, services]);

    const { values, handleSubmit, errors, handleChange, setFieldValue, touched, setErrors, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const payload = {
                ...values,
                coordinates: {
                    latitude: parseFloat(values.coordinates.latitude),
                    longitude: parseFloat(values.coordinates.longitude),
                },
                // province: values.province === 'Kigali' ? `${values.province} City` : `${values.province} Province`,
                sapServices: isEmpty(selectedServices) ? [] : selectedServices,
            };

            const searchUrl = `${process.env.REACT_APP_BACKEND}/api/users/search/${payload.daInCharge}`;

            axios
                .get(searchUrl)
                .then(() => {
                    dispatch(createCenterLocation(payload, onClose, resetForm));
                })
                .catch(err => {
                    if (err.response?.status === 404) {
                        const fieldErrors = {};
                        fieldErrors['daInCharge'] = err.response.data.error;
                        setErrors(fieldErrors);
                    }
                });
        },
    });

    const onFindDAHandler = e => {
        e.preventDefault();
        if (values.daInCharge) {
            const searchUrl = `${process.env.REACT_APP_BACKEND}/api/users/search/${values.daInCharge}`;
            setFindDaInChargeError('');
            axios
                .get(searchUrl)
                .then(res => {
                    if (res?.status && res?.data?.data && res?.status === 200) {
                        const { province, district, sector, cell, userIdentity } = res.data.data;
                        setFieldValue('province', province);
                        setFieldValue('district', district);
                        setFieldValue('sector', sector);
                        setFieldValue('cell', cell);
                        setFieldValue('daInCharge', userIdentity);
                        toast.success('DA Identity exists 👍');
                    } else {
                        setFindDaInChargeError('DA Identity not found');
                    }
                })
                .catch(err => {
                    setFindDaInChargeError('DA Identity not found');
                    if (err.response.status === 404) {
                        toast.error(err.response.data.error);
                    }
                });
        }
    };

    const addService = () => {
        if (selectedService) {
            const service = allSapServices.find(el => el.key === selectedService);
            const unique = [...new Set([...selectedServices, service])];
            setSelectedServices(unique);
            setFieldValue('sapServices', unique);
        }
    };

    const removeService = id => {
        setSelectedServices(selectedServices.filter(el => el.key !== id));
    };

    // Getting current location
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setFieldValue('coordinates.latitude', position.coords.latitude);
                setFieldValue('coordinates.longitude', position.coords.longitude);
            });
        } else {
            setFieldValue('coordinates.latitude', '-1.9507305');
            setFieldValue('coordinates.longitude', '30.1212607');
        }
    };

    useEffect(() => {
        getLocation();
    }, []);

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal"
            className="custom-modal"
            show={open}
            onHide={() => {
                onClose();
                resetForm();
            }}
            backdrop="static"
            animation={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>New SAP</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <div className="py-3">
                        {' '}
                        <span className="text-medium">Basic Information</span>
                    </div>

                    <Form.Group>
                        <Form.Control type="text" placeholder="SAP Name" name="centerName" onChange={handleChange} value={values.centerName} />

                        {touched.centerName && errors.centerName && (
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {errors.centerName}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Row className="mt-2">
                        <Col md={7}>
                            <Form.Group>
                                <Form.Control type="text" placeholder="User ID of DA in charge" name="daInCharge" onChange={handleChange} value={values.daInCharge} />
                                {touched.daInCharge && errors.daInCharge && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.daInCharge}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Button type="button" className="btn-block py-2 rounded " onClick={onFindDAHandler} disabled={loading}>
                                Find
                            </Button>
                        </Col>
                    </Row>

                    {findDaInChargeError && (
                        <div>
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {findDaInChargeError}
                            </Form.Control.Feedback>
                        </div>
                    )}
                    <Row className="mt-3">
                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control type="text" name="province" placeholder="Province" disabled value={values.province} />
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control type="text" name="district" placeholder="District" disabled value={values.district} />
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control type="text" name="sector" placeholder="Sector" disabled value={values.sector} />
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control type="text" name="cell" placeholder="Cellular" disabled value={values.cell} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="pt-3">
                        <span className="text-medium">SAP Geolocation</span>
                    </div>

                    <Row className="mt-2">
                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Label>Latitude</Form.Label>
                                <Form.Control
                                    name="coordinates.latitude"
                                    placeholder="Latitude"
                                    onChange={e => {
                                        const { value } = e.target;

                                        setFieldValue('coordinates.latitude', matchCoordinates(value));
                                    }}
                                    value={values.coordinates.latitude}
                                />

                                {touched.coordinates?.latitude && errors.coordinates?.latitude && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.coordinates?.latitude}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Label>Longitude</Form.Label>
                                <Form.Control
                                    name="coordinates.longitude"
                                    placeholder="Longitude"
                                    onChange={e => {
                                        const { value } = e.target;

                                        setFieldValue('coordinates.longitude', matchCoordinates(value));
                                    }}
                                    value={values.coordinates.longitude}
                                />

                                {touched.coordinates?.longitude && errors.coordinates?.longitude && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.coordinates?.longitude}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <p>
                            {' '}
                            <i style={{ color: 'red' }} className="fa fa-info-circle"></i> Geolocation (Latitude and Longitude) values should be a decimal number with more than 7 decimals
                        </p>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            <Form.Label>SAP Services</Form.Label>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={7}>
                            <Form.Group>
                                {
                                    <Form.Control
                                        as="select"
                                        onChange={e => {
                                            const { value } = e.target;
                                            setSelectedService(value);
                                        }}
                                        custom
                                    >
                                        <option value="" disabled selected>
                                            Search or add service
                                        </option>
                                        {Boolean(allSapServices.length) &&
                                            allSapServices.map(service => {
                                                return <option value={service.key}>{service.label}</option>;
                                            })}
                                    </Form.Control>
                                }
                                {Boolean(selectedServices.length) &&
                                    selectedServices.map(el => {
                                        return (
                                            <div
                                                key={el.key}
                                                className="bg-white rounded p-2 my-2 border-1 d-flex align-items-center justify-content-between"
                                                style={{
                                                    borderWidth: 1,
                                                    border: '1px solid #ced4da',
                                                }}
                                            >
                                                <div>
                                                    <span className="text-small">{el.label} </span>
                                                </div>

                                                <div onClick={() => removeService(el.key)}>
                                                    <i className="fas fa-times-circle"></i>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Button className="btn-block py-2 rounded" disabled={loading} onClick={addService}>
                                Save
                            </Button>
                        </Col>
                    </Row>

                    {error && (
                        <div>
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {error}
                            </Form.Control.Feedback>
                        </div>
                    )}

                    <Button className={`px-5 py-2 mt-3 rounded ${loading && 'd-flex'}`} onClick={handleSubmit} disabled={loading}>
                        Save {loading && <Loading color="#FFF" marginTop=" " center={true} />}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
