/* eslint-disable import/no-anonymous-default-export */
import { GET_CENTER_LOCATION, CENTER_ERROR, CENTER_LOCATION_LOADING, GET_ALL_TRAINING_CENTERS, GET_DA_TRAINING_CENTERS, GET_SINGLE_TRAINING_CENTER, GET_ALL_TRAINING_CENTERS_ERROR, CLEAR_TRAINING_CENTERS } from '../types';
import { REQUEST_GET_MATERIALS, CREATE_MATERIAL_START, CREATE_MATERIAL_SUCCESS, CREATE_MATERIAL_FAIL } from '../types';

const initialState = {
    centers: [],
    error: null,
    loading: false,
    allCenters: [],
    singleCenter: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CENTER_LOCATION:
            return {
                ...state,
                centers: action.payload,
                error: null,
            };
        case GET_DA_TRAINING_CENTERS:
            return {
                ...state,
                centers: action.payload,
                error: null,
            };

        case CENTER_ERROR:
            return {
                ...state,
                centers: [],
                error: action.payload,
            };

        case CENTER_LOCATION_LOADING:
            return {
                ...state,
                loading: action.payload.loading,
            };

        case GET_ALL_TRAINING_CENTERS:
            return {
                ...state,
                allCenters: action.payload,
            };
        case GET_ALL_TRAINING_CENTERS_ERROR:
            return {
                ...state,
                allCenters: [],
                error: null,
            };

        case GET_SINGLE_TRAINING_CENTER:
            return {
                ...state,
                singleCenter: action.payload,
                error: null,
            };

        // SAP MATERIALS

        case CREATE_MATERIAL_START:
            return {
                ...state,
                loading: action.loading,
            };

        case CREATE_MATERIAL_SUCCESS:
            return {
                ...state,
                loading: action.loading,
            };

        case CREATE_MATERIAL_FAIL:
            return {
                ...state,
                loading: action.loading,
                error: action.error,
            };

        case CLEAR_TRAINING_CENTERS:
            return {
                ...state,
                centers: [],
                allCenters: [],
                singleCenter: [],
            };

        default:
            return state;
    }
};
