export const REQUEST_USER_CREATION = 'REQUEST_USER_CREATION';
export const USER_CREATION_SUCCESS = 'USER_CREATION_SUCCESS';
export const USER_CREATION_FAILED = 'USER_CREATION_FAILIED';
export const REQUEST_GET_USERS = 'REQUEST_GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USERS_FAILED = 'GET_USER_FAILED';
export const REQUEST_MAKE_COORD = 'REQUEST_MAKE_CORD';
export const MAKE_COORD_SUCCESS = 'MAKE_COORD_SUCCESS';
export const MAKE_COORD_FAILED = 'MAKE_COORD_FAILED';
export const REQUEST_USER_UPDATE = 'REQUEST_USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILIED';
export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';

export const REQUEST_GET_DA = 'REQUEST_GET_DA';
export const GET_DA_SUCCESS = 'GET_DA_SUCCESS';
export const GET_DA_FAILED = 'GET_DA_FAILED';

export const REQUEST_USER_INFO = 'REQUEST_USER_INFO';
export const GET_USER_INFO = 'GET_USER_INFO';
export const USER_INFO_FAILED = 'USER_INFO_FAILED';

