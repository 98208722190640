/* eslint-disable import/no-anonymous-default-export */
import { ERROR_TRANSACTION, GET_ALL_TRANSACTION, LOAD_TRANSACTION } from '../types';

const initialState = {
    loading: false,
    error: null,
    transactions: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ERROR_TRANSACTION:
            return {
                ...state,
                error: action.payload,
            };
        case GET_ALL_TRANSACTION:
            return {
                ...state,
                error: null,
                transactions: action.payload,
            };
        case LOAD_TRANSACTION:
            return {
                ...state,
                error: null,
                loading: action.payload.loading,
            };

        default:
            return state;
    }
}
