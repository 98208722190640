import React, { useState } from 'react';
import Layout from '../../../../layouts/AdminLayout';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import SapLocations from '../../components/sap/sap-locations/SapLocations';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import moment from 'moment';
import AddSap from '../../components/sap/add-sap/AddSap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllCenters, deleteCenterLocation } from '../../../../redux/actions';
import isEmpty from 'lodash/isEmpty';
import UpdateSap from '../../components/sap/update-sap/UpdateSap';
import { reverse } from 'named-urls';
import routes from '../../../../routes/names';
import { Link } from 'react-router-dom';
import Spinner from '../../../../components/spinner/Spinner';
import { emptifyFalseValues } from '../../../../helpers';

const sanitizeData = saps =>
    saps.map((sap, index) => {
        const sapInfo = {
            ...sap,
            province: sap.Location.province,
            district: sap.Location.district,
            sector: sap.Location.sector,
            cell: sap.Location.cell,
            index: index + 1,
            daInCharge: (sap && sap.DAInfo && `${sap.DAInfo.firstName} ${sap.DAInfo.lastName}`) || '',
            dateOpened: moment(sap.createdAt).format('YYYY-MM-DD'),
        };

        return { ...emptifyFalseValues(sapInfo) };
    });

export default function Overview() {
    const dispatch = useDispatch();
    const [modals, setModals] = useState({ addSap: false, updateSap: false });
    const [isLoading, setIsLoading] = useState(true);

    const [sap, setSap] = useState({});
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const openAddSap = () => setModals({ ...modals, addSap: true });
    const closeAddSap = () => setModals({ ...modals, addSap: false });

    const openUpdateSap = () => setModals({ ...modals, updateSap: true });
    const closeUpdateSap = () => {
        setSap({});
        dispatch(getAllCenters());
        setModals({ ...modals, updateSap: false });
    };

    useEffect(() => {
        if (isLoading) {
            dispatch(getAllCenters());
            setIsLoading(false);
        }
    }, [isLoading]);

    const { loading, allCenters } = useSelector(state => state.centers);
    const { roles } = useSelector(state => state.auth);
    const onDeleteSapHandler = centerId => {
        dispatch(deleteCenterLocation(centerId));
    };
    const renderOptons = (cell, row) => {
        const id = row.id;
        const editSap = () => {
            setSap({});

            openUpdateSap();

            setSap(row);
        };

        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle variant="light" className="text-dark">
                        Actions &nbsp; <i class="fas fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {!roles.includes('SU') && (
                            <Dropdown.Item onClick={editSap}>
                                <i class="fas fa-pencil-alt text-primary "></i> &nbsp;Edit
                            </Dropdown.Item>
                        )}

                        {!roles.includes('SU') && (
                            <Dropdown.Item onClick={() => onDeleteSapHandler(id)}>
                                <span className="text-small">
                                    {' '}
                                    <i class="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                                </span>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item>
                            <Link to={reverse(routes.dashboard.sap.view, { id })}>
                                {' '}
                                <span className="text-small">
                                    {' '}
                                    <i class="fas fa-info-circle text-primary "></i> &nbsp; More details
                                </span>
                            </Link>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const columns = [
        { dataField: 'index', text: '#', csvExport: true },
        {
            dataField: 'centerName',
            text: 'SAP name',
            formatter: (_, row) => (
                <Link to={reverse(routes.dashboard.sap.view, { id: row.id })} className="text-black text-nowrap">
                    {row.centerName}
                </Link>
            ),

            sort: true,
            csvExport: true,
        },

        {
            dataField: 'province',
            text: 'Province',
            formatter: (cell, row) => <span className="text-nowrap">{row.province}</span>,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'district',
            text: 'District',
            filterValue: (cell, row) => row.district,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'sector',
            text: 'Sector',
            filterValue: (cell, row) => row.sector,
            formatter: (cell, row) => <span className="text-nowrap">{row.sector}</span>,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'cell',
            text: 'Cell',
            filterValue: (cell, row) => row.sector,
            formatter: (cell, row) => <span className="text-nowrap">{row.cell}</span>,
            sort: true,
            csvExport: true,
        },

        { dataField: 'daInCharge', text: 'DA in charge', formatter: (_, row) => row && row.DAInfo && `${row.DAInfo.firstName} ${row.DAInfo.lastName}` },
        {
            dataField: 'dateOpened',
            text: 'Date Opened',
            formatter: (_, row) => !isEmpty(row.createdAt) && <span className="text-nowrap">{moment(row.createdAt).format('MMMM Do, YYYY')}</span>,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'options',
            text: 'Options',
            formatter: renderOptons,
            headerClasses: 'pull-right',
            csvExport: false,
        },
    ];

    const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: 'All',
                value: Array.isArray(allCenters) ? allCenters.length : 0,
            },
        ],
    };

    return (
        <Layout>
            <Container>
                <Row>
                    <Col className="col-6">
                        <div>
                            <span className="text-title">SAP Management</span>
                        </div>
                    </Col>

                    <Col className="col-6">
                        <div className="d-flex align-items-end justify-content-end">
                            {!roles.includes('SU') && (
                                <Button className="btn btn-light" onClick={openAddSap}>
                                    {' '}
                                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
                                    <span className="text-dark">Add SAP</span>
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>

                {loading ? (
                    <Spinner />
                ) : isEmpty(allCenters) ? (
                    <div className="d-flex align-item-center justify-content-center py-3">
                        <span> There's no registered Service access Point</span>
                    </div>
                ) : (
                    <>
                        <Row>
                            <Col>
                                <div className="py-4">
                                    <span className="text-medium">SAP Locations</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-10">
                                {!isEmpty(allCenters) && (
                                    <div className="px-5 mx-5">
                                        <SapLocations saps={allCenters} />
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <ToolkitProvider
                            keyField="id"
                            data={sanitizeData(allCenters)}
                            columns={columns}
                            search
                            exportCSV={{
                                fileName: `DAP-SAP-${new Date().toLocaleTimeString()}.csv`,
                                separator: ',',
                                ignoreHeader: false,
                                noAutoBOM: false,
                            }}
                        >
                            {props => (
                                <div className="pt-4  px-1 material">
                                    <span className="text-title">Working SAPs</span>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                                <i class="fas fa-file-excel text-small"></i> &nbsp;Export to CSV
                                            </ExportCSVButton>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0" placeholder="Search SAPs ..." />
                                        </div>
                                    </div>

                                    <BootstrapTable {...props.baseProps} classes="data-table" bordered={false} pagination={paginationFactory(options)} options={options} bootstrap4 />
                                </div>
                            )}
                        </ToolkitProvider>{' '}
                    </>
                )}
            </Container>

            <AddSap open={modals.addSap} onClose={closeAddSap} />

            {!isEmpty(sap) && <UpdateSap open={modals.updateSap} onClose={closeUpdateSap} sap={sap} />}
        </Layout>
    );
}
