import axios from 'axios';
import { toast } from 'react-toastify';
import {
    REQUEST_PARTNER_CREATION,
    PARTNER_CREATION_SUCCESS,
    PARTNER_CREATION_FAILED,
    REQUEST_GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILED,
    GET_PARTNER_ATTENDENCE,
    REQUEST_GET_PARTNERS_ATTENDENCE,
    GET_PARTNER_ATTENDENCE_FAILED,
} from '../types';

const { REACT_APP_BACKEND } = process.env;

export const createPartner =
    (user, routeHistory = undefined) =>
    dispatch => {
        dispatch({ type: REQUEST_PARTNER_CREATION });
        axios
            .post(`${REACT_APP_BACKEND}/api/partners/create`, user)
            .then(res => {
                if (!res) {
                    dispatch({ type: PARTNER_CREATION_FAILED, payload: 'Phone number or Email already exist' });
                    toast.error('Phone number or Email already exist');
                    return;
                }
                toast.success(res.data.message);
                dispatch({ type: PARTNER_CREATION_SUCCESS, payload: res.data.message });
                if (typeof routeHistory === 'object') routeHistory.push('/cpanel/partners/list');
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
                dispatch({
                    type: PARTNER_CREATION_FAILED,
                    payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
                });
            });
    };

export const getAllPartners = () => dispatch => {
    dispatch({ type: REQUEST_GET_PARTNERS });
    axios
        .get(`${REACT_APP_BACKEND}/api/partners`)
        .then(res => {
            dispatch({ type: GET_PARTNERS_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({
                type: GET_PARTNERS_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const createPartnerUser =
    (user, routeHistory = undefined) =>
    dispatch => {
        dispatch({ type: REQUEST_PARTNER_CREATION });
        axios
            .post(`${REACT_APP_BACKEND}/api/partners/create`, user)
            .then(res => {
                if (!res) {
                    dispatch({ type: PARTNER_CREATION_FAILED, payload: 'Phone number or Email already exist' });
                    toast.error('Phone number or Email already exist');
                    return;
                }
                toast.success(res.data.message);
                dispatch({ type: PARTNER_CREATION_SUCCESS, payload: res.data.message });
                if (typeof routeHistory === 'object') routeHistory.push('/cpanel/roles');
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
                dispatch({
                    type: PARTNER_CREATION_FAILED,
                    payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
                });
            });
    };

export const getAllPartnersTraineeAttendance = () => dispatch => {
    dispatch({ type: REQUEST_GET_PARTNERS_ATTENDENCE });
    axios
        .get(`${REACT_APP_BACKEND}/api/partners/my-trainees/attendence-count`)
        .then(res => {
            dispatch({ type: GET_PARTNER_ATTENDENCE, payload: res.data.data });
        })
        .catch(err => {
            dispatch({
                type: GET_PARTNER_ATTENDENCE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};
