import { include } from 'named-urls';

const routes = {
    home: '/',
    courses: '/courses',
    searchCertificate: '/search-certificate',
    traineeCertificate: '/trainee-certificate',
    importRecords: '/import-records',

    register: include('/register', {
        start: '',
        verify: 'verify/:traineeIdentity',
        success: 'success',
    }),

    dashboard: include('/cpanel', {
        overview: 'overview',
        feedback: 'feedback',
        communication: 'communication',
        notification: 'notifications',

        ambassadors: include('ambassadors', {
            list: 'list',
            create: 'create',
            edit: 'edit/:id',
            view: 'view/:id',
            assesment: 'assesment',
            myTrainees: 'my-trainees',
        }),

        partners: include('partners', {
            list: 'list',
            create: 'create',
            edit: 'edit/:id',
            view: 'view/:id',
        }),

        trainees: include('trainees', {
            all: '',
            overview: 'overview',
            create: 'create',
            view: 'view/:id',
            edit: 'edit/:id',
            // details: "group/:id",
            // view: "view/:id",
        }),
        landingPage: 'settings',

        // Temporary routes for development purpose only
        training: include('training', {
            overview: 'overview',
            manage: 'manage',
            // create: "create",
            // edit: "edit/:id",
            // view: "view/:id",
        }),

        sap: include('sap', {
            overview: 'overview',
            materials: 'materials/:id',
            services: 'services/:id',
            transactions: 'transactions/:id',
            management: 'management',
            edit: 'edit/:id',
            view: 'view/:id',
        }),

        listGroup: include('groups', {
            groups: 'overview',
            details: 'group/:id',
        }),

        curriculum: 'curriculum',
        reports: 'reports',
        profile: 'profile',
        editRoles: 'edit-roles',
        userRoles: 'roles',
        searchCode: 'search-code',

        captain: include('captain', {
            group: 'group',
            details: 'details',
            communication: 'communication',
        }),

        co: include('co', {
            overview: 'overview',
            reports: 'reports',
        }),

        services: include('services', {
            list: 'list',
        }),
        users: include('users', {
            create: 'create',
        }),
    }),
};

export default routes;
