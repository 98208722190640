import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Container, Table, FormCheck, Row, Col, Form, Spinner } from 'react-bootstrap';
import has from 'lodash/has';
import omit from 'lodash/omit';
import moment from 'moment';

function SessionDetails({ open, onClose, session, submit }) {
    const { name, cohort, date, startAt: time, completed, attendences, attendeesConfirmed, groups } = session;

    const { createLoading } = useSelector(state => state.attendence);

    return (
        <div>
            <Container>
                <Modal show={open} size="lg" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Session Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="d-flex flex-row text-small">
                            <div>
                                <span> {moment(date?.split(' ')[0]).format('MMMM  YYYY')} Cohort</span>
                            </div>
                            <div>
                                <span> {cohort} </span>
                            </div>
                            <div className="px-3">
                                <span> {groups?.name} </span>
                            </div>
                            <div className="px-3">
                                <span> {name} </span>
                            </div>
                        </div>

                        <div>
                            <span>
                                {/* On {moment(date).format('MMMM DD, YYYY ')} at {moment(time).format('h:mm a')} */}
                                on {moment(date?.split(' ')[0]).format('MMM Do YYYY')} at {time}
                            </span>
                        </div>
                        <div>
                            <span>
                                Module: &nbsp;<span className="session-label-bold">{session.course ? session.course.title : null}</span>{' '}
                            </span>
                        </div>
                        <div className="confirm-attendees-scrollbar table-wrapper-scroll-y pt-4 bg-white rounded my-4">
                            <div className="px-4">
                                <span className="text-small text-bold">Attendance:</span>
                            </div>

                            <Table responsive="sm" borderless>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Names</th>
                                        <th>Trainee ID</th>

                                        <th className="text-center">Attended</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attendences &&
                                        attendences.map((attendence, i) => {
                                            return (
                                                <tr key={attendence.trainees.id}>
                                                    <td>{i + 1}</td>
                                                    <td>{attendence.trainees.lastName + ' ' + attendence.trainees.firstName}</td>
                                                    <td>{attendence.trainees.traineeIdentity}</td>
                                                    <td>
                                                        {' '}
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <span> Yes </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="pt-2">
                            <Row>
                                <Col>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Button variant="primary" onClick={onClose} className="rounded px-5">
                                            Back
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
}

export default SessionDetails;
