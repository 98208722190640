import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import './index.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

import Routes from '../../../../../routes/names';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { updateTraineeStatus, makeTraineeCaptain, deleteTraineeAction } from '../../../../../redux/actions';

import { emptifyFalseValues } from '../../../../../helpers';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { reverse } from 'named-urls';
import isEmpty from "lodash/isEmpty"

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const sanitizeData = trainees =>
    trainees.map((trainee, index) => ({
        ...emptifyFalseValues(trainee),
        names: trainee.firstName + ' ' + trainee.lastName + ' ' + trainee.middleName,
        index: index + 1,
    }));

function SearchTraineesTable({ trainees, search, error="" }) {
    const history = useHistory()
    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const renderOptons = (cell, row) => {
        return (
            <div className="pull-right" >
                <button disabled={isEmpty(row.traineeIdentity)} onClick={()=>history.push(reverse(Routes.register.verify, { traineeIdentity: row.traineeIdentity }))} className="btn btn-primary" as="a">
                    View Details
                </button>
            </div>
        );
    };

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: 'All',
                value: trainees?.length || 0,
            },
        ],
    };
    const columns = [
        { dataField: 'index', text: '#', csvExport: false, sort: true },
        {
            dataField: 'names',
            text: 'Names',
            sort: true,
            csvExport: true,
            filterValue: (cell, row) => row.names,
        },
        {
            dataField: 'traineeIdentity',
            text: 'Trainee_ID',
            filterValue: (cell, row) => (row.traineeIdentity ? row.traineeIdentity : 'N/A'),

            csvExport: true,
        },

        {
            dataField: 'sector',
            text: 'Sector',
            filterValue: (cell, row) => row.sector,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'cell',
            text: 'Cell',
            filterValue: (cell, row) => row.sector,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'gender',
            text: 'Gender',
            filterValue: (cell, row) => (row.gender ? row.gender : 'N/A'),
            csvExport: true,
        },
        {
            dataField: 'createdAt',
            text: 'Joined_Date',
            formatter: (cell, row) => (row.createdAt ? moment(row.createdAt).format('MMMM, Do YYYY') : 'N/A'),
            csvExport: true,
        },
        {
            dataField: 'options',
            text: 'Options',
            formatter: renderOptons,
            csvExport: false,
        },
    ];
    return (
        <div>
            {
                Boolean(trainees.length)?
                <ToolkitProvider
                keyField="traineeIdentity"
                data={sanitizeData(trainees)}
                columns={columns}
                search
                exportCSV={{
                    fileName: `DAP-TRAINEES-${new Date().toLocaleTimeString()}.csv`,
                    separator: ',',
                    ignoreHeader: false,
                    noAutoBOM: false,
                }}
            >
                {props => (
                    <div>
                        <div className="d-flex align-items-end justify-content-between">{search && <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0" placeholder="Search Trainees ..." />}</div>

                        <div className="pt-3">
                            <BootstrapTable classes="data-table" bordered={false} {...props.baseProps} pagination={paginationFactory(options)} options={options} bootstrap4 />
                        </div>
                    </div>
                )}
            </ToolkitProvider>
                :
                !isEmpty(error) && 
                <div className="row shadow py-4 my-3">
                    {error}
                </div>
            
            }
        </div>
    );
}

export default SearchTraineesTable;
