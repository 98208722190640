import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CpanelLayout from '../../../../../layouts/AdminLayout';
// import Edit from '../../../../../assets/images/edit-24px.svg';
// import Grade from '../../../../../assets/images/grade-24px.svg';
// import Change from '../../../../../assets/images/change_circle_black_24dp.svg';
// import Inbox from '../../../../../assets/images/move_to_inbox_black_24dp.svg';
// import Info from '../../../../../assets/images/info_black_24dp.svg';
import { Dropdown } from 'react-bootstrap';
import routes from '../../../../../routes/names';
import { reverse } from 'named-urls';
import { Link } from 'react-router-dom';
import { getOneTrainee, getCaptainGroup } from '../../../../../redux/actions';

const CaptainGroup = () => {
    const dispatch = useDispatch();
    const { group, updateLoading, success: updateSuccess, deleteSuccess, deleteLoading } = useSelector(state => state.groups);

    useEffect(() => {
        dispatch(getCaptainGroup());
    }, [dispatch]);

    return (
        <CpanelLayout>
            <main>
                <div className="d-flex flex-nowrap justify-content-between align-items-center">
                    <div className="d-flex align-items-center flex-nowrap">
                        <h2 className="mx-4">{group?.name || 'Group'}</h2>
                        <h3 className="mx-4">{group?.cohort ? group.cohort.name : null}</h3>
                    </div>
                    {/* <div className="d-flex align-items-center text-primary">
                        <button className="btn text-primary">Sort <i className="fa fa-chevron-down" /></button>
                        <div className="border-bottom ml-3">
                            <input type="text" placeholder="Search trainees" className="border-bottom border-0" />
                            <button className="btn"><i className="fa fa-search" /></button>
                        </div>
                    </div> */}
                </div>
                <table className="table table-borderless mt-3">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Names</th>
                            <th scope="col">Trainee ID</th>
                            <th scope="col">Status</th>
                            <th scope="col">Attended Sessions</th>
                            {/* <th scope="col">Options</th> */}
                        </tr>
                    </thead>
                    <tbody className="text-primary">
                        {group &&
                            group.trainees.map((trainee, i) => {
                                const { id } = trainee;
                                return (
                                    <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>
                                            {trainee.firstName + ' ' + trainee.lastName} {trainee.isCaptain === 'true' && <i className="fa fa-star text-primary" />}
                                        </td>
                                        <td>{trainee.traineeIdentity}</td>
                                        <td>{trainee.status}</td>
                                        <td>
                                            {trainee.attendenceCount ? trainee.attendenceCount : 0}/{trainee.courseNumber}
                                        </td>
                                        <td>
                                            <div className="pull-right">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="light" className="text-dark">
                                                        Actions &nbsp; <i className="fas fa-angle-down"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as="div">
                                                            <Link to={reverse(routes.dashboard.trainees.edit, { id })}>
                                                                {' '}
                                                                <div className="w-100 ">
                                                                    {' '}
                                                                    <i className="fas fa-pencil-alt text-primary "></i> &nbsp;Edit
                                                                </div>
                                                            </Link>{' '}
                                                        </Dropdown.Item>

                                                        <Dropdown.Item>
                                                            <Link to={reverse(routes.dashboard.trainees.view, { id })}>
                                                                {' '}
                                                                <span className="text-small">
                                                                    {' '}
                                                                    <i className="fas fa-info-circle text-primary "></i> &nbsp; More details
                                                                </span>
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        {/* <tr>
                            <th scope="row">1</th>
                            <td>Munyaneza Iradukunda placide</td>
                            <td>1234567890</td>
                            <td>Active</td>
                            <td>3/9</td>
                            <td>
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    actions
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">
                                        <img src={Edit} /> <span> Edit</span>
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Grade} /> <span> Mark as captain</span>
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Change} /> <span> Change status</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-4" />
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Inbox} /> <span> Move to</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-1" />
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Info} /> <span> More details</span>
                                    </a>
                                </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Munyaneza Iradukunda placide</td>
                            <td>1234567890</td>
                            <td>Active</td>
                            <td>3/9</td>
                            <td>
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    actions
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">
                                        <img src={Edit} /> <span> Edit</span>
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Grade} /> <span> Mark as captain</span>
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Change} /> <span> Change status</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-4" />
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Inbox} /> <span> Move to</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-1" />
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Info} /> <span> More details</span>
                                    </a>
                                </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Munyaneza Iradukunda placide</td>
                            <td>1234567890</td>
                            <td>Active</td>
                            <td>3/9</td>
                            <td>
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    actions
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">
                                        <img src={Edit} /> <span> Edit</span>
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Grade} /> <span> Mark as captain</span>
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Change} /> <span> Change status</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-4" />
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Inbox} /> <span> Move to</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-1" />
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Info} /> <span> More details</span>
                                    </a>
                                </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Munyaneza Iradukunda placide</td>
                            <td>1234567890</td>
                            <td>Active</td>
                            <td>3/9</td>
                            <td>
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    actions
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">
                                        <img src={Edit} /> <span> Edit</span>
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Grade} /> <span> Mark as captain</span>
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Change} /> <span> Change status</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-4" />
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Inbox} /> <span> Move to</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-1" />
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Info} /> <span> More details</span>
                                    </a>
                                </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>Munyaneza Iradukunda placide</td>
                            <td>1234567890</td>
                            <td>Active</td>
                            <td>3/9</td>
                            <td>
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    actions
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href="#">
                                        <img src={Edit} /> <span> Edit</span>
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Grade} /> <span> Mark as captain</span>
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Change} /> <span> Change status</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-4" />
                                    </a>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span><img src={Inbox} /> <span> Move to</span></span>
                                        <i className="fa fa-chevron-right mt-1 ml-1" />
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <img src={Info} /> <span> More details</span>
                                    </a>
                                </div>
                                </div>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </main>
        </CpanelLayout>
    );
};

export default CaptainGroup;
