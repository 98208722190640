import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';

import { Form, Col, Row, FormGroup, InputGroup, FormControl, Breadcrumb, Spinner } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { getLocationByProvince, getLocationByDistrict, getLocationBySector, getLocationByCell } from '../../../../../redux/actions';
// import { getCenterLocation } from "../../../../../redux/actions";
import { updateUserData } from '../../../../../redux/actions';
import { matchDigit, checkDates } from '../../../../../helpers';

import CpanelLayout from '../../../../../layouts/AdminLayout';
import { useParams, useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import moment from 'moment';
const { REACT_APP_BACKEND } = process.env;
export default function UpdateAmbassador() {
    const { id } = useParams();
    const history = useHistory();
    const { success, message, error, users, updateLoading: loading } = useSelector(state => state.users);
    
    let selectedDa = id && !isEmpty(users) && users.find(da => da.id === id);
    selectedDa = isObject(selectedDa) ? { ...selectedDa, dob: moment(selectedDa.dob).format('YYYY-MM-DD') } : {};
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        nationalIdentity: Yup.string().min(16, 'National ID is invalid'),
        firstName: Yup.string().required(' First name is required'),
        lastName: Yup.string().required(' Last name is required'),
        dob: Yup.string().required('Date of birth is required '),
        gender: Yup.string().required('Please select gender'),
        email: Yup.string().email('Email is Invalid').required('Email is required'),
        phone: Yup.string().length(10, 'Phone number is invalid').required('Phone is required'),
        bankName: Yup.string().required('Bank name is required'),
        accountNumber: Yup.string().required('Account Number is required'),
        allowance: Yup.string().required('Monthly Allowance is required'),
    });
    const payment = {
        bankName: selectedDa?.payment?.bankName ?? '',
        accountNumber: selectedDa?.payment?.bankAccount ?? '',
        allowance: selectedDa?.payment?.allowance ?? '',
    };
    const initialValues = {
        nationalIdentity: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        gender: '',
        dob: '',
        email: '',
        manageSap: false,
        bankName: '',
        accountNumber: '',
        ...selectedDa,
        ...payment,
    };
    const preloadBankInformation=()=>{
        axios
        .get(`${REACT_APP_BACKEND}/api/users/${id}`)
        .then(res => {
            const reslt=res.data;
            //console.log(reslt.data.bankInfo.allowance);
            initialValues.bankName=reslt.data.bankInfo.bankName;
            initialValues.accountNumber=reslt.data.bankInfo.bankAccount;
            initialValues.allowance=reslt.data.bankInfo.allowance;
            

            
        })
        .catch(err => {
            preloadBankInformation();
        });
    }
    

    const [userResidence, setUserResidence] = useState({
        province: selectedDa.province || '',
        district: selectedDa.district || '',
        sector: selectedDa.sector || '',
        village: selectedDa.village || '',
    });
       preloadBankInformation()
    const { values, handleSubmit, errors, handleChange, setFieldValue, touched } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            if (checkDates(values.dob, 18)) {
                dispatch(updateUserData(id, { ...values, ...userResidence }, history));
            } else {
                toast.error('Age range should be between 18 and 100 years old');
            }
        },
    });

    const { residence, districts, sectors, cells, villages } = useSelector(state => state.residence);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading && selectedDa['province']) dispatch(getLocationByProvince(selectedDa['province']));
        if (isLoading && selectedDa['district']) dispatch(getLocationByDistrict(selectedDa['district']));
        if (isLoading && selectedDa['sector']) dispatch(getLocationBySector(selectedDa['sector']));
        if (isLoading && selectedDa['cell']) {
            dispatch(getLocationByCell(selectedDa['sector'], selectedDa['cell']));
            setIsLoading(false);
        }
    }, [selectedDa, isLoading]);

    const onProvinceChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, province: value });
        dispatch(getLocationByProvince(value));
    };
    const onDistrictChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, district: value });
        dispatch(getLocationByDistrict(value));
    };
    const onSectorChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, sector: value });
        dispatch(getLocationBySector(value));
    };
    const onCellChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, cell: value });
        dispatch(getLocationByCell(userResidence.sector, value));
    };
    const onVillageChange = e => {
        const { value } = e.target;
        
        setUserResidence({ ...userResidence, village: value });
    };
  
    

  
    
    return (
        
        <CpanelLayout>
            <div className="body-content">
                <Container>
                    <div>
                        {/* <Breadcrumb>
              <Link>
                <Breadcrumb.Item href="">DA Management</Breadcrumb.Item>
              </Link>

              <Link>
                <Breadcrumb.Item active>Add a new DA</Breadcrumb.Item>
              </Link>
            </Breadcrumb> */}
                    </div>
                    <p className="text-title mb-2"> Update DA</p>
                    <Row className="mt-3">
                        <Col md={12}>
                            <div className="box">
                                <Form>
                                    <p className="text-medium">Basic information</p>

                                    <Row>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            {/* <FormGroup>
                        <InputGroup>
                          <FormControl
                            placeholder={"National ID"}
                            value={values.nationalIdentity}
                            name="nationalIdentity"
                            onChange={(event) => {
                              let sanitized = matchDigit(
                                String(event.target.value)
                              );

                              sanitized = sanitized.substring(0, 16);

                              setFieldValue("nationalIdentity", sanitized);
                            }}
                          />

                          {touched.nationalIdentity && errors.nationalIdentity && (
                            <Form.Control.Feedback
                              className="d-block"
                              type="invalid"
                            >
                              {errors.nationalIdentity}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </FormGroup> */}
                                        </Col>

                                        {/* <Col className="col-12 col-md-6 col-lg-3">
                      <FormGroup>
                        <Button className="px-5">Search</Button>
                      </FormGroup>
                    </Col> */}
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`First Name`} value={values.firstName} name="firstName" onChange={handleChange} />

                                                    {touched.firstName && errors.firstName && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.firstName}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`Last Name`} value={values.lastName} name="lastName" onChange={handleChange} />

                                                    {touched.lastName && errors.lastName && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.lastName}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <FormControl value={values.middleName} name="middleName" onChange={handleChange} placeholder={`Middle Name (Optional)`} />
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <Form.Control as="select" className="custom-select" name="gender" value={values.gender} onChange={handleChange}>
                                                        <option value="" disabled selected>
                                                            Gender
                                                        </option>
                                                        <option> Female</option>
                                                        <option>Male</option>
                                                    </Form.Control>

                                                    {touched.gender && errors.gender && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.gender}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`Date of Birth`} name="dob" value={values.dob} onChange={handleChange} type="date" />

                                                    {touched.dob && errors.dob && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.dob}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl
                                                        placeholder={`Phone Eg: 0788899488`}
                                                        name="phone"
                                                        value={values.phone}
                                                        onChange={event => {
                                                            let sanitized = matchDigit(String(event.target.value));

                                                            sanitized = sanitized.substring(0, 10);

                                                            setFieldValue('phone', sanitized);
                                                        }}
                                                    />

                                                    {touched.phone && errors.phone && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.phone}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-4">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`Email`} name="email" value={values.email} onChange={handleChange} type="email" />

                                                    {touched.email && errors.email && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <p className="text-medium mb-3 mt-4">Deployment</p>

                                    <Row>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" onChange={onProvinceChange} name="province" value={userResidence.province}>
                                                    <option>Province</option>
                                                    <option value="Kigali City">Kigali City</option>
                                                    <option value="Western Province">Western Province</option>
                                                    <option value="Southern Province">Southern Province</option>
                                                    <option value="Northern Province">Northern Province</option>
                                                    <option value="Eastern Province">Eastern Province</option>
                                                </Form.Control>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" onChange={onDistrictChange} name="district" value={userResidence.district}>
                                                    <option>District</option>
                                                    {districts &&
                                                        districts.map((district, index) => (
                                                            <option key={index} selected={district === selectedDa['district']} value={district}>
                                                                {district}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" onChange={onSectorChange} name="sector" defaultValue={userResidence.sector}>
                                                    <option>Sector</option>
                                                    {sectors &&
                                                        sectors.map((sector, index) => (
                                                            <option key={index} selected={sector === selectedDa['sector']} value={sector}>
                                                                {sector}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" onChange={onCellChange} name="cell" defaultValue={userResidence.village}>
                                                    <option>Cell</option>
                                                    {cells &&
                                                        cells.map((cell, index) => (
                                                            <option key={index} selected={cell === selectedDa['cell']} value={cell}>
                                                                {cell}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" name="village" onChange={onVillageChange}>
                                                    <option>village</option>
                                                    {villages &&
                                                        villages.map((village, index) => (
                                                            <option key={index} selected={village === selectedDa['village']} value={village}>
                                                                {village}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                                <p className="text-medium mb-3 mt-4">Bank Information</p>

                                    <Row>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl
                                                        className={`${touched.bankName && errors.bankName ? 'border border-danger' : ''}`}
                                                        placeholder={`Bank Name`}
                                                        name="bankName"
                                                        value={values.bankName}
                                                        onChange={handleChange}
                                                        type="text"
                                                    />

                                                    {touched.bankName && errors.bankName && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.bankName}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl
                                                        className={`${touched.accountNumber && errors.accountNumber ? 'border border-danger' : ''}`}
                                                        placeholder={`Account Number`}
                                                        name="accountNumber"
                                                        value={values.accountNumber}
                                                        onChange={handleChange}
                                                        type="text"
                                                    />

                                                    {touched.accountNumber && errors.accountNumber && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.accountNumber}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl
                                                        className={`${touched.allowance && errors.allowance ? 'border border-danger' : ''}`}
                                                        placeholder={`Monthly Allowance`}
                                                        name="allowance"
                                                        value={values.allowance}
                                                        onChange={handleChange}
                                                        type="text"
                                                    />

                                                    {touched.allowance && errors.allowance && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.allowance}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {/* <p className="text-medium mb-3 mt-4">Work details</p>

                  <div className="d-flex align-items-center text-normal ">
                    <span>This DA manages a SAP &nbsp;</span>
                    <Form.Check
                      type="checkbox"
                      name="manageSap"
                      checked={values.manageSap}
                      onChange={handleChange}
                    />
                  </div> */}

                                    <Button type="submit" onClick={handleSubmit} className="btn btn-primary px-5 mt-4">
                                        {loading ? <Spinner size="sm" animation="border" variant="light" /> : `Update`}
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </CpanelLayout>
    );
}
