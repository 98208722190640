import React, { useState, useEffect } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import './index.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { updateTraineeStatus, makeTraineeCaptain, deleteTraineeAction, getAllDaGroups, updateTraineeGroup, getAllCourses } from '../../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import TransferTraineeToCenter from '../transferees/TransferTraineeToCenter';
import TransferTraineeToGroup from '../transferees/TransferTraineeToGroup';

import routes from '../../../../../routes/names';
import { reverse } from 'named-urls';
import { Link } from 'react-router-dom';

const { SearchBar } = Search;

function GroupTrainees({ centers, trainees, search }) {
    const [modals, setModals] = useState({
        addGroupTrainees: false,
        transferTraineeToCenter: false,
        transferTraineeToGroup: false,
    });
    const [id, setId] = useState('');

    const { error, success: traineeSuccess, traineesLoading } = useSelector(state => state.trainees);
    const { groups } = useSelector(state => state.groups);
    const { courses } = useSelector(state => state.courses);

    const openTransferTraineeToCenter = () => setModals({ ...modals, transferTraineeToCenter: true });

    const closeTransferTraineeToCenter = () => {
        setModals({ ...modals, transferTraineeToCenter: false });
    };
    const openTransferTraineeToGroup = () => setModals({ ...modals, transferTraineeToGroup: true });

    const closeTransferTraineeToGroup = () => setModals({ ...modals, transferTraineeToGroup: false });

    const dispatch = useDispatch();
    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const renderID = (cell, row, rowIndex) => {
        return <span>{rowIndex + 1}</span>;
    };

    const renderNames = (cell, row, rowIndex) => {
        return (
            <span>
                {row.firstName} {row.middleName} {row.lastName}{' '}
            </span>
        );
    };

    useEffect(() => {
        closeTransferTraineeToCenter();
    }, [traineeSuccess]);

    const onTraineeStatusUpdate = (id, status) => {
        dispatch(updateTraineeStatus(id, status));
    };
    const onMakingTraineeCapt = id => {
        dispatch(makeTraineeCaptain(id));
    };

    const onDelete = id => {
        dispatch(deleteTraineeAction(id));
    };

    const renderCaptain = (cell, row) => {
        if (row.isCaptain === 'true') return <i className="fas fa-star text-primary "></i>;
        return null;
    };

    const handleTransferToGroup = (id, group) => {
        dispatch(updateTraineeGroup(id, group));
    };

    useEffect(() => {
        dispatch(getAllDaGroups());
        dispatch(getAllCourses());
    }, [dispatch]);
    const renderSessions = (cell, row) => {
        return (
            <span>
                {' '}
                {row.attendenceCount || 0}/{row.courseNumber}
            </span>
        );
    };

    const renderOptons = (cell, row) => {
        const id = row.id;
        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle variant="light" className="text-dark">
                        Actions &nbsp; <i className="fas fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as="div">
                            <Link to={reverse(routes.dashboard.trainees.edit, { id })}>
                                {' '}
                                <div className="w-100 ">
                                    {' '}
                                    <i className="fas fa-pencil-alt text-primary "></i> &nbsp;Edit
                                </div>
                            </Link>{' '}
                        </Dropdown.Item>

                        <Dropdown.Item>
                            {' '}
                            <span className="text-small" onClick={() => onMakingTraineeCapt(row.id)}>
                                {' '}
                                <i className="fas fa-star text-primary "></i> &nbsp; Make Captain
                            </span>
                        </Dropdown.Item>
                        <div className="pl-3">
                            <Dropdown drop="left">
                                <Dropdown.Toggle variant="light" className="text-dark text-small">
                                    <i className="fas fa-angle-left"></i> &nbsp; <i className="fas fa-sync text-primary"></i> &nbsp;Change Status
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <span onClick={() => onTraineeStatusUpdate(row.id, 'Active')} className="text-small">
                                            Active
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <span onClick={() => onTraineeStatusUpdate(row.id, 'Inactive')} className="text-small">
                                            Inactive
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <span onClick={() => onTraineeStatusUpdate(row.id, 'Dropout')} className="text-small">
                                            Dropout
                                        </span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Dropdown.Item>
                            <span className="text-small" onClick={() => onDelete(row.id)}>
                                {' '}
                                <i className="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                            </span>
                        </Dropdown.Item>

                        <div className="pl-3">
                            <Dropdown drop="left">
                                <Dropdown.Toggle variant="light" className="text-dark text-small">
                                    <i className="fas fa-angle-left"></i> &nbsp; <i className="fas fa-arrow-circle-left text-primary"></i>
                                    &nbsp;Move To
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={openTransferTraineeToGroup}>
                                        <span
                                            onClick={() => {
                                                setId(row.id);
                                            }}
                                            className="text-small"
                                        >
                                            Group
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={openTransferTraineeToCenter}>
                                        <span
                                            onClick={() => {
                                                setId(row.id);
                                            }}
                                            className="text-small"
                                        >
                                            Other DA
                                        </span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Dropdown.Item>
                            <Link to={reverse(routes.dashboard.trainees.view, { id: row.id })}>
                                {' '}
                                <span className="text-small">
                                    {' '}
                                    <i className="fas fa-info-circle text-primary "></i> &nbsp; More details
                                </span>
                            </Link>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            {
                text: '5',
                value: 5,
            },
            {
                text: '10',
                value: 10,
            },
            {
                text: 'All',
                value: trainees.length,
            },
        ],
    };
    const columns = [
        { dataField: 'id', text: '#', formatter: renderID },
        {
            dataField: 'names',
            text: 'Names',
            formatter: renderNames,
            filterValue: (cell, row) => row.firstName + row.lastName + row.middleName,
        },
        {
            dataField: 'isCaptain',
            // text: 'The captain',
            formatter: renderCaptain,
            filterValue: (cell, row) => row.isCaptain,
        },
        {
            dataField: 'traineeIdentity',
            text: 'Trainee ID',
            filterValue: (cell, row) => (row.traineeIdentity ? row.traineeIdentity : 'N/A'),
        },

        {
            dataField: 'status',
            text: 'Status',
            filterValue: (cell, row) => row.status,
        },
        {
            dataField: 'sessions',
            text: 'Attended sessions',
            filterValue: (cell, row) => row.affiliated,
            formatter: renderSessions,
        },
        {
            dataField: 'options',
            text: 'Options',
            formatter: renderOptons,
            headerClasses: 'pull-right',
        },
    ];
    return (
        <div>
            {courses.length > 0 && (
                <ToolkitProvider keyField="traineeIdentity" data={trainees} columns={columns} search>
                    {props => (
                        <div>
                            <div className="d-flex align-items-end justify-content-end"> {search && <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0" placeholder="Search Participants ..." />}</div>

                            <div className="pt-3">
                                {' '}
                                <BootstrapTable bordered={false} bootstrap4 {...props.baseProps} pagination={paginationFactory(options)} options={options} />
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
            )}

            <TransferTraineeToCenter open={modals.transferTraineeToCenter} onClose={closeTransferTraineeToCenter} centers={centers} id={id} />

            {id && <TransferTraineeToGroup open={modals.transferTraineeToGroup} onClose={closeTransferTraineeToGroup} groups={groups} submit={handleTransferToGroup} id={id} />}
        </div>
    );
}

export default GroupTrainees;
