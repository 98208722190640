import React from 'react';
import moment from 'moment';
import './index.css';

export default function FeedBackMessage({ feedback }) {
    return (
        <div className="feedback-message-card px-4 py-4">
            <div className="d-flex align-items-center justify-content-between">
                <div className="py-2">
                    <span className="text-normal">
                        <b>{feedback.fullName ? feedback.fullName : 'Guest'}</b>
                    </span>
                </div>
                <div className="py-2">
                    <span className="text-normal">
                        <b>{feedback.email ? feedback.email : null}</b>
                    </span>
                </div>
            </div>

            <div className="text-small">
                <span>{feedback.body}</span>
            </div>

            <div className="py-2">
                <span className="text-extra-small text-primary">{moment(feedback.createdAt).format('MMM Do YY')}</span>
            </div>
        </div>
    );
}
