import React, { useImperativeHandle } from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createTransaction } from '../../../../../redux/actions';
import Loading from '../../../../../utils/LoadingSmall';
import { matchDigit } from '../../../../../helpers';
import isEmpty from 'lodash/isEmpty';

function AddTransaction({ open, onClose }, ref) {
    const params = useParams();
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Service name is required'),
        amount: Yup.number().required('Amount is required'),
        transactionType: Yup.string().required('Transaction Type is required'),
    });

    const initialValues = { transactionType: 'income', name: '', amount: '', other: '' };
    const { values, handleSubmit, errors, handleChange, touched, setErrors, resetForm, setFieldValue } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const payload = {
                ...values,
                amount: parseFloat(values.amount).toFixed(2),
                trainingCenterId: params && params.id,
                name: values.name === 'other' && !isEmpty(values.other) ? values.other : values.name,
            };
            // console.log("=== payload ===", payload)
            dispatch(createTransaction(payload, onClose));
        },
    });

    useImperativeHandle(ref, () => ({ resetForm }), []);
    const { loading } = useSelector(state => state.transactions);
    const { services } = useSelector(state => state.services);
    const { singleCenter } = useSelector(state => state.centers);

    return (
        <div>
            <Container>
                <Modal show={open} backdrop="static" size="md" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Transaction</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Control custom as="select" name="transactionType" value={values.transactionType} onChange={handleChange} disabled={loading}>
                                    <option value="" disabled>
                                        {' '}
                                        Transaction Type
                                    </option>
                                    <option value="expense" selected>
                                        {' '}
                                        Expense
                                    </option>
                                    <option value="income">Income</option>
                                </Form.Control>
                                {touched.transactionType && errors.transactionType && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.transactionType}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Control as="select" name="name" value={values.name} onChange={handleChange} disabled={loading} custom>
                                    {values.transactionType === 'expense' && (
                                        <>
                                            <option value="" disabled selected>
                                                {' '}
                                                Please select
                                            </option>
                                            {singleCenter && Boolean(singleCenter.Services.length) && singleCenter.Services.map(service => <option value={service.name}>{service.name}</option>)}
                                            <option>Hygiene</option>
                                            <option>Electricity</option>
                                            <option>Cleaning</option>
                                            <option>Tax</option>
                                            <option>Security</option>
                                            <option value="other">Other</option>
                                        </>
                                    )}

                                    {values.transactionType === 'income' && (
                                        <>
                                            <option value="" disabled selected>
                                                {' '}
                                                Please select
                                            </option>
                                            {singleCenter &&
                                                !isEmpty(singleCenter.Services) &&
                                                singleCenter.Services.map(service => {
                                                    return (
                                                        <>
                                                            {' '}
                                                            <option value={service.name}>{service.name}</option>
                                                            <option value="other">Other</option>
                                                        </>
                                                    );
                                                })}
                                        </>
                                    )}
                                </Form.Control>

                                {values.name === 'other' && (
                                    <div className="py-3">
                                        {' '}
                                        <Form.Group>
                                            <Form.Control type="text" name="other" value={values.other} placeholder="Type other service..." disabled={loading} onChange={handleChange} />
                                            {touched.other && errors.other && (
                                                <Form.Control.Feedback className="d-block" type="invalid">
                                                    {errors.other}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </div>
                                )}
                                {touched.name && errors.name && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>

                            <Form.Group>
                                <Form.Control type="text" name="amount" value={values.amount} placeholder="Amount" disabled={loading} onChange={({ target: { value } }) => setFieldValue('amount', matchDigit(value))} />
                                {touched.amount && errors.amount && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.amount}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="pt-2">
                            <Row>
                                <Col>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Button variant="primary" onClick={handleSubmit} disabled={loading} block>
                                            {loading ? <Loading color="#FFF" marginTop=" " center={true} /> : 'Add'}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
}

export default React.forwardRef(AddTransaction);
