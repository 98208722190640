import { useEffect, useState } from 'react';
import { Container, Navbar, Nav, NavDropdown, Col, Row, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import routes from '../routes/names';
import Footer from '../pages/client/components/Footer';
import SideBar from './SideBarMenu';

import Logo from '../assets/img/logo.png';

import './index.css';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../redux/actions/authActions';
import jwtDecode from 'jwt-decode';

const AdminLayout = ({ t, children }) => {
    const [sidebar, setSidebar] = useState(true);
    const [menuClick, setMenuClick] = useState(false);
    const [width, setWidth] = useState(0);

    const [user, setUser] = useState('');
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logoutUser());
        window.location.href = '/';
    };

    useEffect(() => {
        const IdToken = localStorage.getItem('IdToken');

        if (IdToken) {
            const token = jwtDecode(IdToken);
            setUser(`${token.lastName}`);
        }

        function handleResize() {
            const width = window.innerWidth;
            setWidth(width);

            if (width <= 780) return setSidebar(false);

            setMenuClick(false);
            return setSidebar(true);
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleToggleSidebar = () => {
        setSidebar(!sidebar);
        setMenuClick(!menuClick);
    };

    return (
        <>
            <Navbar expand="lg" sticky="top" className="shadow-sm custom-navbar">
                <Container fluid className="pr-5">
                    <Navbar.Brand>
                        <Link to="/">
                            <Image src={Logo} width="50" height="50" alt="DIGITAL AMBASSADORS PROGRAM" /> <span className="logo-text web-view">DIGITAL AMBASSADORS PROGRAM</span>
                            <span className="logo-text mobile-view">DAP</span>
                        </Link>
                    </Navbar.Brand>

                    {width <= 780 && (
                        <Button className="btn btn-primary sidebar-menu-icon" onClick={handleToggleSidebar}>
                            {menuClick ? <i className="fas fa-close"></i> : <i className="fas fa-bars"></i>}
                        </Button>
                    )}

                    <Navbar>
                        <Nav className="mx-auto"></Nav>

                        <Nav className="ml-auto">
                            {/* <Nav.Link className='count-btn'>
								<i className='fas fa-bell'></i>
                <span className='count-badge btn-danger'>0</span>
							</Nav.Link> */}

                            <NavDropdown className="trans" title={user} menuAlign={{ lg: 'left' }}>
                                <NavDropdown.Item>
                                    <Link to={routes.dashboard.profile}>Profile</Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={handleLogout}>Sign Out</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar>
                </Container>
            </Navbar>

            <div className="layout-content">
                <Container fluid>
                    <Row>
                        {sidebar && (
                            <Col lg={2} className="p-0 bg-dark-primary sidebar">
                                <SideBar />
                            </Col>
                        )}

                        {!menuClick && (
                            <Col lg={!sidebar ? 12 : 10}>
                                <div className="p-3">{children}</div>
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>

            <Footer fluid={true} />
        </>
    );
};

export default withNamespaces()(AdminLayout);
