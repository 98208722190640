import React from 'react';
import { Line } from 'react-chartjs-2';

const TraineChart = ({ data }) => (
	<>
		<Line data={data} height={70}  />
	</>
);

export default TraineChart;
