import axios from 'axios';
import {
    REQUEST_CHART_HOME,
    REQUEST_ALL_USERS,
    REQUEST_ALL_TRAINEES,
    REQUEST_ALL_TRAINEES_STATUS,
    REQUEST_ALL_TRAINEES_BY_AGE,
    REQUEST_ALL_AMBSADORS,
    REQUEST_ALL_CO_TRAINEE,
    REQUEST_ALL_CO_TRAINING_CENTER,
    REQUEST_ALL_CO_TRAINING_AMBASADROS,
    REQUEST_ALL_CO_DAS,
    REQUEST_ALL_CO_TRAINEE_RANGE,
    REQUEST_ALL_ADMIN_TRAINING_CENTER,
    REQUEST_ALL_TRAINEE_CENTER,
} from '../types';

const { REACT_APP_BACKEND } = process.env;

export const getHomeChart = () => async dispatch => {
    try {
        const { data: maleData } = await axios.get(`${REACT_APP_BACKEND}/api/trainees/all/male/graduated`);
        const { data: femaleData } = await axios.get(`${REACT_APP_BACKEND}/api/trainees/all/female/graduated`);
        const payload = {
            years: maleData?.years,
            male: maleData?.data,
            female: femaleData?.data,
        };
        dispatch({ type: REQUEST_CHART_HOME, payload });
    } catch (error) {
        console.error(error);
    }
};

export const getAllUsersChart = () => async dispatch => {
    try {
        const { data } = await axios.get(`${REACT_APP_BACKEND}/api/users`);
        dispatch({ type: REQUEST_ALL_USERS, payload: data?.data?.filter(value => value.isActive === true) });
    } catch (error) {
        console.error(error);
    }
};

export const getAllTraineesChart = () => async dispatch => {
    try {
        const { data } = await axios.get(`${REACT_APP_BACKEND}/api/trainees`);
        dispatch({ type: REQUEST_ALL_TRAINEES, payload: data?.data?.filter(value => value.status.toLowerCase() === 'active') });
    } catch (error) {
        console.error(error);
    }
};

export const getAllTraineesStatusChart = () => async dispatch => {
    try {
        const { data } = await axios.get(`${REACT_APP_BACKEND}/api/trainees`);
        dispatch({ type: REQUEST_ALL_TRAINEES_STATUS, payload: data?.data });
    } catch (error) {
        console.error(error);
    }
};

export const getAllTraineesByAgeChart = () => async dispatch => {
    try {
        const { data } = await axios.get(`${REACT_APP_BACKEND}/api/trainees/all/byage`);
        dispatch({ type: REQUEST_ALL_TRAINEES_BY_AGE, payload: { labels: data.ageRange, data: data.data } });
    } catch (error) {
        console.log(error);
    }
};

export const getAllAmbsadorsChart = () => async dispatch => {
    try {
        const { data } = await axios.get(`${REACT_APP_BACKEND}/api/users/digital-ambs`);
        dispatch({ type: REQUEST_ALL_AMBSADORS, payload: data.data });
    } catch (error) {
        console.error(error);
    }
};
export const getAllActiveAmbsadorsChart = () => async dispatch => {
    try {
        const { data } = await axios.get(`${REACT_APP_BACKEND}/api/users/digital-ambs/active`);
        dispatch({ type: REQUEST_ALL_AMBSADORS, payload: data.data });
    } catch (error) {
        console.error(error);
    }
};

export const getAllCoTraineee = () => async dispatch => {
    try {
        const { data, status } = await axios.get(`${REACT_APP_BACKEND}/api/partners/my-trainees`);

        if (status === 200) {
            return dispatch({ type: REQUEST_ALL_CO_TRAINEE, payload: data.data });
        }
        return dispatch({ type: REQUEST_ALL_CO_TRAINEE, payload: [] });
    } catch (error) {
        console.error(error);
    }
};

export const getAllAdminTrainingCenter = () => async dispatch => {
    try {
        const { data, status } = await axios.get(`${REACT_APP_BACKEND}/api/centers`);

        if (status === 200) {
            return dispatch({ type: REQUEST_ALL_ADMIN_TRAINING_CENTER, payload: data.data });
        }
        return dispatch({ type: REQUEST_ALL_ADMIN_TRAINING_CENTER, payload: [] });
    } catch (error) {
        console.error(error);
    }
};

export const getAllCoTrainingCenter = () => async dispatch => {
    try {
        const { data, status } = await axios.get(`${REACT_APP_BACKEND}/api/partners/my-centers`);

        if (status === 200) {
            return dispatch({ type: REQUEST_ALL_CO_TRAINING_CENTER, payload: data.data });
        }
        return dispatch({ type: REQUEST_ALL_CO_TRAINING_CENTER, payload: [] });
    } catch (error) {
        console.error('centers', error);
    }
};

export const getAllCoAmbasadors = () => async dispatch => {
    try {
        const { data, status } = await axios.get(`${REACT_APP_BACKEND}/api/partners/my-ambs`);
        if (status === 200) {
            return dispatch({ type: REQUEST_ALL_CO_TRAINING_AMBASADROS, payload: data.data });
        }
        return dispatch({ type: REQUEST_ALL_CO_TRAINING_AMBASADROS, payload: [] });
    } catch (error) {
        console.error('centers', error);
    }
};

export const getTraineesAgeRangeByDa = () => async dispatch => {
    try {
        const { data } = await axios.get(`${REACT_APP_BACKEND}/api/trainees/my-trainees/ranges`);

        dispatch({ type: REQUEST_ALL_TRAINEES_BY_AGE, payload: { labels: data.ageRange, data: data.data } });
    } catch (error) {
        console.error(error);
    }
};

export const getAllCoDAS = () => async dispatch => {
    try {
        const { data, status } = await axios.get(`${REACT_APP_BACKEND}/api/partners/my-ambs`);

        if (status === 200) {
            return dispatch({ type: REQUEST_ALL_CO_DAS, payload: data.data });
        }
        return dispatch({ type: REQUEST_ALL_CO_DAS, payload: [] });
    } catch (error) {
        console.error(error);
    }
};

export const getAllPartnerTraineeRange = () => async dispatch => {
    try {
        const { data, status } = await axios.get(`${REACT_APP_BACKEND}/api/partners/my-trainees/ranges`);

        if (status === 200) {
            return dispatch({ type: REQUEST_ALL_CO_TRAINEE_RANGE, payload: data.data });
        }
        return dispatch({ type: REQUEST_ALL_CO_TRAINEE_RANGE, payload: [] });
    } catch (error) {
        console.error(error);
    }
};

export const getAllTrainingCenter = () => async dispatch => {
    try {
        const { data, status } = await axios.get(`${REACT_APP_BACKEND}/api/centers`);
        if (status === 200) {
            return dispatch({ type: REQUEST_ALL_TRAINEE_CENTER, payload: data.data });
        }
        return dispatch({ type: REQUEST_ALL_TRAINEE_CENTER, payload: [] });
    } catch (error) {
        console.error(error);
    }
};
