import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { matchDigit } from '../../../../helpers';
import Loading from '../../../../utils/LoadingSmall';
import { createCourse } from '../../../../redux/actions';

export default function AddModule({ open, onClose }) {
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Course name is required'),
        link: Yup.string(),
        duration: Yup.number('Course duration should be a number').required('Course duration is required'),
    });
    const { createLoading, error } = useSelector(state => state.courses);

    const initialValues = {
        title: '',
        description: '',
        duration: '',
        link: '',
    };

    const { values, errors, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const payload = { ...values };
            dispatch(createCourse({ payload, onClose, resetForm }));
        },
    });
    return (
        <div>
            <Modal show={open} size="md" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Module</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Control type="text" placeholder="Module Title" className="form-control rounded my-2" value={values.title} name="title" onChange={handleChange} disabled={createLoading} />
                            {errors.title && (
                                <Form.Control.Feedback className="d-block" type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Duration"
                                className="form-control rounded my-2"
                                value={values.duration}
                                name="duration"
                                disabled={createLoading}
                                onChange={event => {
                                    let value = matchDigit(event.target.value);

                                    value = value.substr(0, 3);

                                    value = Number(value) > 120 ? 120 : value;

                                    setFieldValue('duration', value);
                                }}
                            />

                            {errors.duration && (
                                <Form.Control.Feedback className="d-block" type="invalid">
                                    {errors.duration}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control as="textarea" placeholder="Description" className="form-control rounded my-2" rows="5" value={values.description} name="description" onChange={handleChange} disabled={createLoading} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="text" placeholder="Link to course" className="form-control rounded my-2" name="link" value={values.link} onChange={handleChange} disabled={createLoading} />
                        </Form.Group>
                    </Form>

                    <Form.Control.Feedback className="d-block" type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Modal.Body>

                <Modal.Footer>
                    <Button className={`px-5 py-2 mt-3 rounded ${createLoading && 'd-flex'}`} onClick={handleSubmit} disabled={createLoading}>
                        Save {createLoading && <Loading color="#FFF" marginTop=" " center={true} />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
