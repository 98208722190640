/* eslint-disable import/no-anonymous-default-export */
import { 
	RESIDENCE_ERROR,
	GET_RESIDENCE_LOCATIONS,
	GET_USER_CELL,
	GET_USER_DISTRICT,
	GET_USER_VILLAGE,
    GET_USER_SECTOR,
    REQUEST_DISTRICT,
    REQUEST_SECTOR,
    REQUEST_CELSS,
    REQUEST_VILLAGES,
    EMPTIFY_RESIDENCE_LOCATIONS,
} from '../types';

const initialState = {
    residence: [],
    districts: [],
    sectors: [],
    cells: [],
    villages: [],
    districtLoading: null,
    sectorLoading: null,
    cellLoading: null,
    villageLoading: null,
    error: ''
}

export default function(state=initialState, action){
    switch (action.type)
    {
        case REQUEST_DISTRICT:
            return {
                ...state,
                residence: [],
                districts: [],
                sectors: [],
                cells: [],
                villages: [],
                districtLoading: true
            }
        case REQUEST_SECTOR:
            return {
                ...state,
                residence: [],
                sectors: [],
                sectorLoading: true
            }
        case REQUEST_CELSS:
            return {
                ...state,
                residence: [],
                cells: [],
                cellLoading: true
            }
        case REQUEST_VILLAGES:
            return {
                ...state,
                residence: [],
                villages: [],
                villageLoading: true
            }
        case GET_RESIDENCE_LOCATIONS:
            return {
                ...state,
                residence: action.payload,
            }
        case GET_USER_DISTRICT:
            return {
                ...state,
                districtLoading: null,
                districts: action.payload
            };
        case GET_USER_SECTOR:
            return {
                ...state,
                sectorLoading: null,
                sectors: action.payload,
                residence: action.residence
            };
        case GET_USER_CELL:
            return {
                ...state,
                cellLoading: null,
                cells: action.payload,
                residence: action.residence
            };
        case GET_USER_VILLAGE:
            return {
                ...state,
                villageLoading: null,
                villages: action.payload,
                residence: action.residence
            };

        case RESIDENCE_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case EMPTIFY_RESIDENCE_LOCATIONS:
            return {
                ...state,
                residence: [],
                districts: [],
                sectors: [],
                cells: [],
                villages: [],
                districtLoading: null,
                sectorLoading: null,
                cellLoading: null,
                villageLoading: null,
                error: ''
            }
        default:
            return state
    }
}