export const GET_RESIDENCE_LOCATIONS = "GET_RESIDENCE_LOCATIONS";
export const RESIDENCE_ERROR = "RESIDENCE_ERROR";
export const GET_USER_DISTRICT = "GET_USER_DISTRICT";
export const GET_USER_CELL = "GET_USER_CELL";
export const GET_USER_VILLAGE = "GET_USER_VILLAGE";
export const GET_USER_SECTOR = "GET_USER_SECTOR";
export const REQUEST_DISTRICT = "REQUEST_DISTRICT";
export const REQUEST_SECTOR = "REQUEST_SECTOR";
export const REQUEST_CELSS = "REQUEST_CELLS";
export const REQUEST_VILLAGES = "REQUEST_VILLAGES";
export const EMPTIFY_RESIDENCE_LOCATIONS = "EMPTIFY_RESIDENCE_LOCATIONS";
