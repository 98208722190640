import React, { useEffect, useState } from 'react';
import Layout from '../../../../layouts/AdminLayout';
import Spinner from '../../../../components/spinner/Spinner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Button } from 'react-bootstrap';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleCenter } from '../../../../redux/actions';
import { deleteService } from '../../../../redux/actions/services';
import { Dropdown } from 'react-bootstrap';
import { AddService } from '../../components/sap';
import { useParams } from 'react-router-dom';
import { useSuperAdmin } from '../../../../hooks';
import { emptifyFalseValues } from '../../../../helpers';
import UpdateService from '../../components/sap/add-service/UpdateService';
import { isEmpty } from 'lodash';
import { Link } from "react-router-dom";
import { Breadcrumb, Row, Col } from "react-bootstrap";
import Routes from "../../../../routes/names";
import { reverse } from "named-urls";

const sanitizeData = items =>
    items.map((item, index) => ({
        ...emptifyFalseValues(item),
        index: index + 1,
    }));

const customTotal = (from, to, size) => (
    <span className="pull-left color-grey text-small">
        Showing {from}- {to} in {size}
    </span>
);

function Services({ match }) {
    const [modals, setModals] = useState({ addService: false, updateService: false });

    const { id } = match.params;

    const { singleCenter, loading, error } = useSelector(state => state.centers);
    const { roles } = useSelector(state => state.auth);

    const services = singleCenter?.Services || [];
    const [service, setService] = useState({});
    const isAdmin = useSuperAdmin();

    const openAddService = () => setModals({ addService: true });
    const closeAddService = () => setModals({ addService: false });
    const openUpdateService = () => setModals({ updateService: true });
    const closeUpdateService = () => {
        setService({});
        setModals({ updateService: false });
    };

    const { id: centerId } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        if (centerId) {
            dispatch(getSingleCenter(centerId));
        }
    }, [dispatch, centerId]);

    const renderRate = (cell, row) => <span> {row.rate}%</span>;

    const renderOptons = (cell, row) => {
        const id = row.id;

        const updateService = () => {
            setService({});
            setService(row);

            openUpdateService();
        };

        const removeService = () => {
            dispatch(deleteService(row));
        };

        const isPrimaryService = row.serviceType === 'primary-service' && !isAdmin;

        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle disabled={roles.includes('SU')} variant="light" className="text-dark">
                        Actions &nbsp; <i class="fas fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={updateService} disabled={isPrimaryService}>
                            <i class="fas fa-pencil-alt text-primary "></i> &nbsp;Edit
                        </Dropdown.Item>

                        <Dropdown.Item onClick={removeService} disabled={isPrimaryService}>
                            <span className="text-small">
                                {' '}
                                <i class="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                            </span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };
    const columns = [
        { dataField: 'index', text: '#' },
        { dataField: 'name', text: 'Service Name' },
        { dataField: 'rate', text: 'Rate', formatter: renderRate },
        { dataField: 'options', text: 'Options', formatter: renderOptons },
    ];

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPageList: [
            {
                text: '5',
                value: 5,
            },
            {
                text: '10',
                value: 10,
            },
            {
                text: 'All',
                value: services?.length || 0,
            },
        ],
    };
    return (
        <Layout>
            <div className="pt-4  px-1 transaction">
                <div className="d-flex align-items-center justify-content-between">
                    <Row>
                        <Col md={12}>
                            <Breadcrumb>
                                <Breadcrumb.Item className='text-primary'>
                                    <Link to='/cpanel/overview'>Overview</Link>
                                </Breadcrumb.Item>
                                
                                <Breadcrumb.Item className='text-primary'>
                                    <Link to={reverse(Routes.dashboard.sap.view, { id })}>SAP Management</Link>
                                </Breadcrumb.Item>
                                
                                <Breadcrumb.Item active>SAP Services</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <span className="text-title">SAP Services</span>

                    {!isAdmin && (
                        <div className="d-flex align-items-center">
                            <Button className="btn btn-light" onClick={openAddService}>
                                {' '}
                                <i className="fas fa-plus"></i>&nbsp;
                                <span className="text-dark">Add a Service</span>
                            </Button>
                        </div>
                    )}
                </div>

                <div className="pt-3">
                    {loading ? (
                        <Spinner />
                    ) : Boolean(services.length) ? (
                        <BootstrapTable classes="data-table" bordered={false} bootstrap4 keyField="id" data={sanitizeData(services)} columns={columns} pagination={paginationFactory(options)} />
                    ) : (
                        <div className="d-flex align-item-center justify-content-center py-3">
                            <span> There's no service assigned to this SAP.</span>
                        </div>
                    )}
                </div>
            </div>

            <AddService id={centerId} open={modals.addService} onClose={closeAddService} />

            {!isEmpty(service) && <UpdateService id={centerId} open={modals.updateService} onClose={closeUpdateService} service={service} />}
        </Layout>
    );
}

export default Services;
