import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Routes from '../../../routes/names';
import { withNamespaces } from 'react-i18next';
import Axios from "axios";

const Hero = ({ t }) => {
	const [backgroundIndex, setBackgroundIndex] = useState(0);
	const [backgrounds,setBackgrounds]=useState([]);
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const interval = setInterval(() => {
			const active = backgroundIndex + 1;
			const greater = backgrounds.length - 1;

			if (active > greater) return setBackgroundIndex(0);
			return setBackgroundIndex(active);
		}, 5000);
		isLoading && fetchBackgrounds();
		return () => clearInterval(interval);
	}, [backgroundIndex, backgrounds.length]);

	const fetchBackgrounds = async()=>{
		try {
			const url = `${process.env.REACT_APP_BACKEND}/api/sliders/image/fetch`
		const response = await Axios.get(url)
		if(response.status===200){
			setBackgrounds(response.data.images.slice(0,3))
			setIsLoading(false)
		}
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div
			className='header text-white'
			style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND}/${backgrounds&&backgrounds[backgroundIndex]&&backgrounds[backgroundIndex].url})` }}
		>
			<div className='overlay py-4'>
				<Container>
					<Row>
						<Col lg={12} className='text-center'>
							<p className='text-bold text-medium'>
								{`${t(`Gahunda y'Intore mu ikoranabuhanga`)}`}
							</p>

							<p>
								{`${t('The Government of Rwanda has embarked on developing a vibrant ICT eco-system through digitization of its services and facilitating citizens to access services online using ICT devices available in their home and their communities.')}`} &nbsp;
								{`${t('Services digitization reduces administration process and costs as well as data security, improves service delivery for better lives of the citizens and increases productivity.')}`} &nbsp;
								{`${t('Digital services are also being developed by private sector companies like telecommunication companies that are exerting efforts towards ease of payment transactions and transfer of money from one citizen to another using a mobile phone or a computer.')}`}
							</p>
						</Col>
					</Row>

					<Row className='mt-3 mb-0'>
						<Col lg={12} className='text-center'>
							<p><Link to="/#moreinfo" className="text-white">{`${t(`Andi makuru`)}`}</Link></p>
						</Col>
					</Row>

					<Row className='justify-content-center'>
						<Col lg={7} className='buttons text-center'>
							<Link
								to={Routes.register.start}
								className='btn btn-light text-bold'
							>
								{`${t(`Kwiyandikisha`)}`}
							</Link>{' '}
							&nbsp;
							<Link to={Routes.courses} className='btn btn-light text-bold'>
								{`${t(`Kureba amasomo`)}`}
							</Link>{' '}
							&nbsp;
							<Link
								className='btn btn-light text-bold'
								to={Routes.searchCertificate}
							>
								{`${t('Gusaba impamyabumenyi')}`}
							</Link>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default withNamespaces()(Hero);
