import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, FormControl, Dropdown, Form, Spinner } from 'react-bootstrap';
import CpanelLayout from '../../../../layouts/AdminLayout';
import { sendMessage, getSentMessage, getReceivedMessage } from '../../../../redux/actions';

import './index.css';

function CommunicationDash() {
    const dispatch = useDispatch();

    const { sentCommunications, receivedCommunications, loading, success } = useSelector(state => state.communications);
    const [data, setData] = useState([]);
    const [active, setActive] = useState('Received');
    const [activeTop, setActiveTop] = useState('To DAs');
    const [activeBottom, setActiveBottom] = useState('All');
    const [message, setMessage] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        const payload = {
            group: activeTop.split(' ')[1],
            messageType: activeBottom,
            content: message,
        };
        console.log('====sent message===>', payload);
        dispatch(sendMessage(payload));
    };

    useEffect(() => {
        dispatch(getSentMessage());
        dispatch(getReceivedMessage());
    }, []);

    useEffect(() => {
        setMessage('');
        dispatch(getSentMessage());
        dispatch(getReceivedMessage());
    }, [success]);

    useEffect(() => {
        if (active == 'Sent') {
            setData(sentCommunications);
        }
        if (active == 'Received') {
            setData(receivedCommunications);
        }
        if (active == 'Scheduled') {
            setData([]);
        }
    }, [active, sentCommunications, receivedCommunications]);

    const checkReceived = () => {
        dispatch(getReceivedMessage());
        setActive('Received');
    };
    const checkSent = () => {
        dispatch(getSentMessage());
        setActive('Sent');
    };
    const checkScheduled = () => {
        // dispatching scheduled messages
        setActive('Scheduled');
    };

    return (
        <CpanelLayout>
            <Container fluid>
                <Row>
                    <Col className="col-md-5">
                        <span className="text-title">Communication</span>
                    </Col>

                    {/* <Col className="col-md-5">
              <span className="pull-right mt-3"><i className='fas fa-envelope'></i> &nbsp; SMS available: <span className='text-bold'>40</span> &nbsp; <i className='fas fa-plus'></i></span>
            </Col> */}
                </Row>

                <Row className="mt-3">
                    <Col md={7}>
                        <div className="shadow p-4">
                            <p className="text-normal mb-1">
                                New Message
                                <div className="pull-right">
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn btn-light">{activeTop}</Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#" onClick={() => setActiveTop('To DAs')}>
                                                To DAs
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => setActiveTop('To Trainees')}>
                                                To Trainees
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item href="#" onClick={() => setActiveTop('To DAP Manager')}>To DAP Manager</Dropdown.Item> */}
                                            <Dropdown.Item href="#" onClick={() => setActiveTop('To Captain')}>
                                                To Captain
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </p>

                            <FormControl className="border-0 px-0" value={message} onChange={e => setMessage(e.target.value)} />

                            <Row className="mt-2">
                                <Col md={12}>
                                    <div className="pull-right">
                                        <div className="d-flex">
                                            <Dropdown>
                                                <Dropdown.Toggle className="btn btn-light">{activeBottom}</Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#" onClick={() => setActiveBottom('SMS')}>
                                                        <Form.Check type="checkbox" checked={activeBottom === 'SMS' ? true : false} label="SMS" />
                                                    </Dropdown.Item>

                                                    <Dropdown.Item href="#" onClick={() => setActiveBottom('Email')}>
                                                        <Form.Check type="checkbox" checked={activeBottom === 'Email' ? true : false} label="Email" />
                                                    </Dropdown.Item>

                                                    <Dropdown.Item href="#" onClick={() => setActiveBottom('All')}>
                                                        <Form.Check type="checkbox" checked={activeBottom === 'All' ? true : false} label="All" />
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <Button className="btn bg-dark-primary px-4" onClick={handleSubmit}>
                                                {loading ? <Spinner size="sm" animation="border" variant="light" /> : 'Send'}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col md={12}>
                        <Button onClick={checkReceived} className={`py-2 btn btn-light tab-btn ${active === 'Received' && `tab-btn-active`}`}>
                            Received Messages
                        </Button>
                        <Button onClick={checkSent} className={`py-2 mx-3 btn btn-light tab-btn ${active === 'Sent' && `tab-btn-active`}`}>
                            Sent Messages
                        </Button>
                        {/* <Button onClick={checkScheduled} className={`py-2 btn btn-light tab-btn ${active === 'Scheduled' && `tab-btn-active`}`}>Schedule Messages</Button> */}
                    </Col>
                </Row>

                <Row className="mt-3">
                    {data.length ? (
                        data.map(data => {
                            return (
                                <Col md={12}>
                                    <div className="shadow mb-4 p-4">
                                        <p className="text-medium mb-1">
                                            {data.sender && data.sender.firstName} ({active})
                                            <span className="mt-1 pull-right">
                                                <i className="fas fa-caret-down"></i>
                                            </span>
                                        </p>
                                        <p className="text-small color-grey">{data.content}</p>

                                        <p className="text-small color-grey mt-4 mb-0">
                                            {/* {active == "Sent" && ({moment(data.createdAt).format('MMM Do YYYY')} {data.receiverCategory && `to ${data.receiverCategory}`})} */}
                                            {active === 'Sent' && `${moment(data.createdAt).format('MMM Do YYYY')}, to ${data.receiverCategory}`}
                                            {active === 'Received' && `${moment(data.createdAt).format('MMM Do YYYY')}, from ${data.sender.firstName}`}
                                            <Link to="#" className="ml-5 text-primary">
                                                Read more
                                            </Link>
                                        </p>
                                    </div>
                                </Col>
                            );
                        })
                    ) : (
                        <div>
                            <p className="text-small mb-1"> No Message Found</p>
                        </div>
                    )}
                </Row>
            </Container>
        </CpanelLayout>
    );
}

export default CommunicationDash;
