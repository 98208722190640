import { useSelector } from "react-redux";

const useSuperAdmin = () => {
  const { roles } = useSelector((state) => state.auth);

  if (Array.isArray(roles) && (roles.includes("super_admin") || roles.includes("SU") || roles.includes("progr-manager")))
  {
   return true;
  }

  return false;
};

export default useSuperAdmin;
