import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Col, Row } from 'react-bootstrap';
import './index.css';
import { withNamespaces } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { matchDigit } from '../../helpers';

import Layout from '../../layouts/AdminLayout';
import Spinner from '../../components/spinner/Spinner';
import { Redirect } from 'react-router';
import routes from '../../routes/names';
import { reverse } from 'named-urls';
import SearchTraineesTable from '../../dashboards/da-dashboard/components/trainees/search-trainees/SearchTrainees';
import { toast } from 'react-toastify';
import { SpinnerWrapper } from '../../dashboards/da-dashboard/pages/roles/viewRoles';

const SearchCode = ({ t }) => {
    const dispatch = useDispatch();

    const initialValues = {
        nationalIdentity: '',
        firstName: '',
        middleName: '',
        lastName: '',
        phone: '',
        email: '',
        centerId: '',
    };

    const [userDetail, setUserDetail] = useState({});
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchError, setSearchError] = useState('');

    const { values, handleSubmit, errors, handleChange, setFieldValue, touched } = useFormik({
        initialValues,
        onSubmit: values => {
            const url = `${process.env.REACT_APP_BACKEND}/api/trainees/search-trainee`;
            setSearchLoading(true);
            setSearchError('');
            setSearchResult([]);
            axios
                .post(url, values)
                .then(response => {
                    if (!response) toast.error('Trainees not found');
                    if (response && response.status == 200) setSearchResult(response.data.data);
                    setSearchLoading(false);
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        toast.error(error.response.data.error);
                        setSearchError(error.response.data.error);
                    }
                    setSearchResult([]);
                    setSearchLoading(false);
                });
        },
    });

    const centers = userDetail.DAInfo || [];
    const fetchUserDetail = async () => {
        try {
            setLoading(true);
            const token = jwtDecode(localStorage.getItem('IdToken'));
            const userID = token?.id;
            const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/users/${userID}`);
            setUserDetail(data?.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserDetail();
    }, []);

    if (loading) {
        return (
            <Layout>
                <Spinner />
            </Layout>
        );
    }

    if (!loading && !userDetail.DAInfo.length) {
        return <Redirect to={routes.dashboard.profile} />;
    }

    return (
        <Layout>
            <div className="body-content  pt-5">
                <Container>
                    <div {...{ style: { ...{ position: searchLoading ? 'relative' : '', minHeight: 'auto' } } }}>
                        <div className="box mt-3 pb-4" style={{ minHeight: 'auto' }}>
                            <div>
                                <div className="py-4">
                                    <span className="text-title"> Search trainee code</span>
                                </div>

                                <Form>
                                    {/** Form section - Identity*/}
                                    <div>
                                        <div>
                                            {Boolean(centers) && (
                                                <Row>
                                                    <Col className="col-3">
                                                        <div>
                                                            <Form.Group>
                                                                <Form.Control as="select" className="custom-select" name="centerId" defaultValue="default" onChange={handleChange}>
                                                                    <option value="default" disabled>
                                                                        Select Training Center
                                                                    </option>

                                                                    {centers.map(center => {
                                                                        return (
                                                                            <option key={center.id} selected={values.center===center.id} value={center.id}>
                                                                                {center.centerName}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )} 
                                        </div>

                                        <Row className="py-1">
                                            <Form.Group as={Col} className="col-12 col-md-6 col-lg-3 ">
                                                <Form.Control placeholder="First name" value={values.firstName} name="firstName" onChange={handleChange} />

                                                {touched.firstName && errors.firstName && (
                                                    <Form.Control.Feedback className="d-block" type="invalid">
                                                        {errors.firstName}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>

                                            <Form.Group as={Col} className="col-12 col-md-6 col-lg-3 ">
                                                <Form.Control placeholder="Middle Name" value={values.middleName} name="middleName" onChange={handleChange} />

                                                {touched.middleName && errors.middleName && (
                                                    <Form.Control.Feedback className="d-block" type="invalid">
                                                        {errors.middleName}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>

                                            <Form.Group as={Col} className="col-12 col-md-6 col-lg-3 ">
                                                <Form.Control placeholder="Last Name" value={values.lastName} name="lastName" onChange={handleChange} />

                                                {touched.lastName && errors.lastName && (
                                                    <Form.Control.Feedback className="d-block" type="invalid">
                                                        {errors.lastName}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>

                                            <Form.Group as={Col} className="col-12 col-md-6 col-lg-3 ">
                                                <Form.Control
                                                    placeholder="Phone Number"
                                                    value={values.phone}
                                                    onChange={event => {
                                                        let sanitized = matchDigit(String(event.target.value));

                                                        sanitized = sanitized.substring(0, 10);

                                                        setFieldValue('phone', sanitized);
                                                    }}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} className="col-12 col-md-6 col-lg-3 ">
                                                <Form.Control
                                                    placeholder="National ID"
                                                    value={values.nationalIdentity}
                                                    name="nationalIdentity"
                                                    onChange={event => {
                                                        let sanitized = matchDigit(String(event.target.value));

                                                        sanitized = sanitized.substring(0, 16);

                                                        setFieldValue('nationalIdentity', sanitized);
                                                    }}
                                                />

                                                {touched.nationalIdentity && errors.nationalIdentity && (
                                                    <Form.Control.Feedback className="d-block" type="invalid">
                                                        {errors.nationalIdentity}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>

                                            <Form.Group as={Col} className="col-12 col-md-6 col-lg-3 ">
                                                <Form.Control placeholder="Email" value={values.email} onChange={handleChange} type="email" name="email" />

                                                {touched.email && errors.email && (
                                                    <Form.Control.Feedback className="d-block" type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Row>
                                    </div>

                                    {/** Submit button */}
                                    <div className="pt-3">
                                        <Button onClick={handleSubmit}>Search</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        {searchLoading && (
                            <SpinnerWrapper>
                                <Spinner isUpload={true} />
                            </SpinnerWrapper>
                        )}
                    </div>

                    <SearchTraineesTable trainees={searchResult} error={searchError} />
                </Container>
            </div>
        </Layout>
    );
};
export default withNamespaces()(SearchCode);
