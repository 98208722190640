import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import locations from "./rwanda-province.json";
import ReactTooltip from "react-tooltip";
import CircleLegend from "../legends/circle-legend";
import "./index.css";

const mapColors = {
  "City of Kigali": "#286D8E",
  "Southern Province": "#578EA7",
  "Northern Province": "#709EB4",
  "Eastern Province": "#87AEC0",
  "Western Province": "#407E9B",
};

const mapKeys = (type, province) => {
  const traineesKeys = {
    "City of Kigali": "traineesKigali",
    "Southern Province": "traineesSouth",
    "Northern Province": "traineesNorth",
    "Eastern Province": "traineesEastern",
    "Western Province": "traineesWestern",
  };

  const dasKeys = {
    "City of Kigali": "usersKigali",
    "Southern Province": "usersSouth",
    "Northern Province": "usersNorth",
    "Eastern Province": "usersEastern",
    "Western Province": "usersWestern",
  };

  if (type === "das" || type === "trainees") {
    if (type === "das") {
      return dasKeys[province];
    }

    if (type === "trainees") {
      return traineesKeys[province];
    }
  }

  throw new Error("type must be das or trainees");
};

const OverviewProvincesMap = ({ data }) => {
  const [content, setContent] = useState("");

  const getDataByProvince = (type, province) => {
    const key = mapKeys(type, province);

    return data[key];
  };

  return (
    <div>
      <div>
        <ComposableMap
          height={700}
          width={900}
          projectionConfig={{
            center: [29.9, -2.3],
            scale: 18000,
          }}
          projection="geoTransverseMercator"
        >
          <Geographies geography={locations} data-tip="">
            {({ geographies }) =>
              geographies.map((geo) => {
                const province = geo.properties.Province;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={mapColors[province]}
                    onMouseEnter={() => {
                      const { Province } = geo.properties;
                      setContent({ province: Province });
                    }}
                    onMouseLeave={() => {
                      setContent({});
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
      </div>

      <ReactTooltip
        backgroundColor="#eef1f8"
        textColor="#000f16"
        className="tooltip"
        style={{ opacity: 0 }}
      >
        <div>
          <strong className="text-small py-3">{content?.province}</strong>
          <CircleLegend
            label={` ${getDataByProvince("das", content?.province)} DAs`}
            type="das"
          />
          <CircleLegend
            label={` ${getDataByProvince(
              "trainees",
              content?.province
            )} Trainees`}
            type="trainees"
          />
        </div>
      </ReactTooltip>
    </div>
  );
};

export default OverviewProvincesMap;
