import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withNamespaces } from "react-i18next";

import LinearChart from "./LinearChart";
import { SendFeedback } from "../../../components/feedbacks/SendFeedback";

import Hero from "./Hero";
import { useDispatch, useSelector } from "react-redux";
import { getHomeChart } from "../../../redux/actions/charts";
import SapLocations from "./sap-locations/SapLocations";

const Body = ({ t }) => {
  const courseUrl = `${process.env.REACT_APP_FRONTEND_URL ? process.env.REACT_APP_FRONTEND_URL : 'https://dap.ogeniuspriority.com'}/courses`;
  const mapUrlGis = `${process.env.REACT_APP_FRONTEND_URL ? process.env.REACT_APP_FRONTEND_URL : 'https://dap.ogeniuspriority.com'}/#mapGis`;
  const registrationUrl = `${process.env.REACT_APP_FRONTEND_URL ? process.env.REACT_APP_FRONTEND_URL : 'https://dap.ogeniuspriority.com'}/register`;
  const certificateUrl = `${process.env.REACT_APP_FRONTEND_URL ? process.env.REACT_APP_FRONTEND_URL : 'https://dap.ogeniuspriority.com'}/search-certificate`;
  // const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  // ${t('What is the purpose of the Digital Ambassadors Program?') +' '+ t('Male')}`
  
  const faqs = [
    {
      title: `${t('What is the purpose of the Digital Ambassadors Program?')}`,
      description: `${t('Ni gahunda yashyizweho na Leta y’u Rwanda igamije kuziba icyuho mu ikoreshwa ry’ikoranabuhanga mu buzima bw’abaturage bwa buri munsi.')}`
    },
    {
      title: `${t('What knowledge would you gain from Intore program in technology?')}`,
      description: `${t('Iyi gahunda ifite amasomo 9 ari aya akurikira.')}`,
      theLink: courseUrl,
      theName: `${t('Amasomo')}`
    },
    {
      title: `${t('Where does Intore program in technology work?')}`,
      description: `${t('Ikorera mu gihugu cyose mu turere 30. Dore urutonde rw’abarimu b’iyi gahunda naho baherereye ndetse nuko wabageraho.')}`,
      theLink: mapUrlGis,
      theName: `${t('GIS Map')}`
    },
    {
      title: `${t('What would it take to be trained?')}`,
      description: `${t('Ushobora guhamagara umwalimu w’intore mu ikoranabuhanga uri mu karere utuyemo akakwandika akaguha gahunda y’amasomo naho azabera. Ushobora no kwiyandikisha ukoresheje uru rubuga hanyuma ababishinzwe bakaza kumenyesha gahunda yo kwiga. Kwiyandikisha unyura aha.')}`,
      theLink: registrationUrl,
      theName: `${t('Kwiyandikisha')}`
    },
    {
      title: `${t('Is it possible to give you lessons and learn from your computer or modern phone?')}`,
      description: `${t('YEGO, urasabwa kuba ufite murandasi, ufite mudasobwa cyangwa telephone igezweho, ukatwandikira kuri dapminict@gmail.com tukaguha aya masomo yose ukayiga ukoresheje ikoranabuhanga, utavuye aho uri.')}`
    },
    {
      title: `${t('How did you graduate and get a degree?')}`,
      description: `${t('Ujya ku rubuga rwacu urabona aho wakura impamyabumenyi.')}`,
      theLink: certificateUrl,
      theName: `${t('Impamyabumenyi')}`
    },
    {
      title: `${t('Where can I ask for more information?')}`,
      description: `${t('Niba ufite ikindi kibazo twandikire.')}`
    },
  ];

  const [limit, setLimit] = useState({ prev: 0, next: 5 });
  const lastStep = ~~(
    Math.round((faqs.length / 5 + Number.EPSILON) * 100) / 100
  );
  const [step, setStep] = useState(0);

  const handleChangeFaq = (type) => {
    if (type === "prev" && limit.prev > 0) {
      setStep(step - 1);
      return setLimit({ prev: limit.prev - 5, next: limit.next - 5 });
    }

    if (type === "next" && limit.next >= 3) {
      setStep(step + 1);
      return setLimit({ prev: limit.prev + 5, next: limit.next + 5 });
    }

    return;
  };

  const dispatch = useDispatch();

  const trainee = useSelector((state) => state.chart.home);

  const chartData = {
    labels: trainee?.years,

    datasets: [
      {
        label: `# ${t(`Male`)}`,
        data: trainee?.male,
        fill: true,
        backgroundColor: "rgba(213, 213, 47, 0.5)",
        borderColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y-axis-1",
      },
      {
        label: `# ${t(`Female`)}`,
        data: trainee?.female,
        fill: true,
        backgroundColor: "rgba(242, 121, 121, 0.35)",
        borderColor: "rgba(0, 0, 0, 0.6)",
        yAxisID: "y-axis-1",
      },
    ],
  };

  useEffect(() => {
    dispatch(getHomeChart());
  }, [dispatch]);

  return (
    <>
      <Hero />

      <div className="info bg-grey py-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="pb-2">
                <Col md={7}>
                  <p className="text-primary text-title font-bold">
                    {`${t(`Akamaro k'iyi gahunda`)}`}
                  </p>
                  <p className="text-small text-justify">
                    {`${t(
                      `The Digital Ambassadors Program (DAP) is an initiative led by the Rwandan Ministry of ICT and Innovation (MINICT) aiming to increase the number of digitally literate citizens and their use of e-Government and e-Business services.`
                    )}`}{" "}
                    {/* &bsps; */}
                    {`${t(
                      `In alignment with the Rwanda Digital Talent Policy, DAP is expected to mobilize young ambassadors to transform the lives of 8,460,000 (five million) citizens through digital skills and adoption of e-Services, driving inclusion and growth.`
                    )}`}{" "}
                    {/* &bsps; */}
                    {`${t(
                      `For complementarity and sustainability perspective, DAP will support Service Access Points (SAP) by deploying Digital Ambassadors to work in SAPs and deliver e-Services to citizens.`
                    )}`}
                  </p>
                </Col>
                <Col md={5}>
                  {/* <Image
										src={ChartImage}
										className='img-fluid w-100'
										alt='Chart'
									/> */}
                  <LinearChart data={chartData} />

                  <p className="text-small text-primary mt-3">{`${t(
                    `Abantu bahuguwe mu myaka yashize`
                  )}`}</p>
                </Col>
              </Row>

              <hr />

              <Row className="pt-2 pb-2" id="mapGis">
                <Col md={12}>
                  <p className="text-center mb-0 text-primary text-title font-bold">
                    {`${t(`Aho SAP ziherereye`)}`}
                  </p>
                </Col>

                <Col md={6}>
                  <p className="text-small text-justify zap-content mt-4">
                    {`${t(
                      `A Service Access Point (SAP) is a place that holds all necessary ICT equipment and infrastructure that facilitate a citizen to access digital services.`
                    )}`}{" "}
                    &nbsp;
                    {`${t(
                      `The place should have electricity, internet, computer, scanner, printer and an agent who facilitates citizens to access services and train them with digital skills.`
                    )}`}
                  </p>
                </Col>

                <Col md={6}>
                  <SapLocations />
                </Col>
              </Row>

              <hr />

              <Row className="faq-sect pt-2 pb-2">
                <Col md={12} className="title">
                  <p className="text-center mb-3 text-primary text-title font-bold">
                    {`${t(`General FAQs`)}`}
                  </p>
                </Col>

                <Col md={12} className="content">
                  <Row>
                    <Col xs={1} className="align-self-center">
                      <div className="text-large prev">
                        {limit.prev > 0 ? (
                          <i
                            className="fas fa-caret-left color-grey cursor"
                            onClick={() => handleChangeFaq("prev")}
                          ></i>
                        ) : (
                          <i className="fas fa-caret-left color-grey-disable"></i>
                        )}
                      </div>
                    </Col>

                    <Col xs={10}>
                      <div className="content">
                        {faqs.slice(limit.prev, limit.next).map((faq, i) => {
                          return (
                            <div className="mb-3" key={i}>
                              <p className="text-bold text-normal mb-2">
                                {faq.title}
                              </p>
                              <p className="mb-0 text-justify">
                                {faq.description} <a href={faq.theLink} className="text-primary">{faq.theName}</a>
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </Col>

                    <Col xs={1} className="align-self-center">
                      <div className="text-large next pull-right">
                        {step < lastStep ? (
                          <i
                            className="fas fa-caret-right color-grey cursor"
                            onClick={() => handleChangeFaq("next")}
                          ></i>
                        ) : (
                          <i className="fas fa-caret-right color-grey-disable"></i>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <hr />

              <Row className="justify-content-center pt-2">
                <Col md={12}>
                  <p className="text-primary text-title font-bold text-center">
                    {`${t(`Agasanduku k'ibitekerezo`)}`}
                  </p>
                </Col>

                <Col lg={8}>
                  {/* <Form className='text-center'>
										<FormControl
											placeholder={`${t(`Email ntabwo ari ngombwa`)}`}
										/>
										<Form.Control
											as='textarea'
											placeholder={`${t(`Andika igitekerezo cyawe hano`)}`}
											rows={5}
											className='mt-2'
											required
										/>
										<Button variant='primary' className='mt-3'>
											{`${t(`Ohereza`)}`}
										</Button>
									</Form> */}
                  <SendFeedback t={t} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withNamespaces()(Body);
