import React, { useState, useEffect } from 'react';
import CpanelLayout from '../../../../../layouts/AdminLayout';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button, FormControl, Dropdown, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getSentMessage, getReceivedMessage, sendCaptainMessage } from '../../../../../redux/actions';

const Communication = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSentMessage());
        dispatch(getReceivedMessage());
    }, [dispatch]);
    const [show, setShow] = useState(1);
    const [message, setMessage] = useState('');
    const [activeBottom, setActiveBottom] = useState('All');
    const [activeTop, setActiveTop] = useState('To DA inCharge');
    const { sentCommunications, receivedCommunications, loading, success } = useSelector(state => state.communications);

    const handleSubmit = e => {
        e.preventDefault();
        const payload = {
            group: activeTop.split(' ')[1],
            messageType: activeBottom,
            content: message,
        };
        dispatch(sendCaptainMessage(payload));
    };

    const checkReceived = () => {
        dispatch(getReceivedMessage());
        setShow(1);
    };
    const checkSent = () => {
        dispatch(getSentMessage());
        setShow(2);
    };
    const checkScheduled = () => {
        // dispatching scheduled messages
        setShow(3);
    };
    return (
        <CpanelLayout>
            <main>
                <h2>Communication</h2>
                <div className="col-md-9 pt-2 px-0">
                    <siv className="card card-body shadow border-0">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4>New Message</h4>
                            <Dropdown>
                                <Dropdown.Toggle className="btn btn-light">{activeTop}</Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={() => setActiveTop('To DA inCharge')}>
                                        To DA inCharge
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#" onClick={() => setActiveTop('To Collegues')}>
                                        To Collegues
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <textarea value={message} className="form-control border-0" rows="5" onChange={e => setMessage(e.target.value)}></textarea>
                        <div className="mt-4">
                            <div className="pull-right">
                                <div className="d-flex">
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn btn-light">{activeBottom}</Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#" onClick={() => setActiveBottom('SMS')}>
                                                <Form.Check type="checkbox" checked={activeBottom === 'SMS' ? true : false} label="SMS" />
                                            </Dropdown.Item>

                                            <Dropdown.Item href="#" onClick={() => setActiveBottom('Email')}>
                                                <Form.Check type="checkbox" checked={activeBottom === 'Email' ? true : false} label="Email" />
                                            </Dropdown.Item>

                                            <Dropdown.Item href="#" onClick={() => setActiveBottom('All')}>
                                                <Form.Check type="checkbox" checked={activeBottom === 'All' ? true : false} label="All" />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Button className="btn bg-dark-primary px-4" onClick={handleSubmit}>
                                        {loading ? <Spinner size="sm" animation="border" variant="light" /> : 'Send'}
                                    </Button>
                                </div>
                            </div>
                            {/* <button onClick={handleSubmit} className="btn btn-primary px-4 py-2 rounded float-right">
                                {loading ?
                                <Spinner size="sm" animation="border" variant="light" />: "Send"
                                }
                            </button> */}
                        </div>
                    </siv>
                </div>
                <div className="m-5">
                    <div className="border-bottom d-flex align-items-center">
                        <a href="#" className={`mx-3 px-2 ${show == 1 && `border-bottom border-primary`}`} onClick={checkReceived}>
                            <h5> Received Messages</h5>
                        </a>
                        <a href="#" className={`mx-3 px-2 ${show == 2 && `border-bottom border-primary`}`} onClick={checkSent}>
                            <h5> Sent Messages</h5>
                        </a>
                        {/* <a href="#" className={`mx-3 px-2 ${show == 3 && `border-bottom border-primary`}`} onClick={checkScheduled}><h5> Scheduled Messages</h5></a> */}
                    </div>
                    <div className="mt-4 ml-2">
                        {show == 1 ? (
                            <>
                                {receivedCommunications &&
                                    receivedCommunications.map((data, index) => (
                                        <div className="card shadow border-0">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                    <h4>{data.sender && data.sender.firstName}</h4>
                                                    <i className="fa fa-chevron-down" />
                                                </div>
                                                <p className="text-muted">{data.content && data.content}</p>
                                                <p className="text-small color-grey mt-4 mb-0">
                                                    {moment(data.createdAt).format('MMM Do YYYY')} {data.receiverCategory && `to ${data.receiverCategory}`}
                                                    <Link to="#" className="ml-5 text-primary">
                                                        Read more
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                            </>
                        ) : show == 2 ? (
                            <>
                                {sentCommunications &&
                                    sentCommunications.map((data, index) => (
                                        <div key={index} className="card shadow border-0">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                    <h4>{data.receiverCategory && `to ${data.receiverCategory}`}</h4>
                                                    <i className="fa fa-chevron-down" />
                                                </div>
                                                <p className="text-muted">{data.content && data.content}</p>
                                                <p className="text-small color-grey mt-4 mb-0">
                                                    {moment(data.createdAt).format('MMM Do YYYY')}{' '}
                                                    <Link to="#" className="ml-5 text-primary">
                                                        Read more
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                            </>
                        ) : (
                            <>
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <h4>DAP Manager</h4>
                                            <i className="fa fa-chevron-down" />
                                        </div>
                                        <p className="text-muted">All DAs should report to their respective cell leaders this friday</p>
                                        <div className="d-flex align-items-center mt-4">
                                            <p className="m-0 mr-4 p-0">Feb 23, 2021</p>
                                            <a className="ml-4 text-primary" href="#">
                                                Read more
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </main>
        </CpanelLayout>
    );
};

export default Communication;
