/* eslint-disable import/no-anonymous-default-export */
import {
  REQUEST_CREATE_ROLE,
  CREATE_ROLE_FAILED,
  CREATE_ROLE_SUCCESS,
  REQUEST_GET_ROLE,
  GET_ROLE_FAILED,
  GET_ROLES_SUCCESS,
  REQUEST_ASSIGN_ROLE,
} from "../types"

const initialState = {
  loading: null,
  createLoading: null,
  updateLoading: null,
  deleteLoading: null,
  assignRoleLoading:null,
  roles: [],
  message: null,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_CREATE_ROLE:
      return {
        ...state,
        createLoading: true
      }
    case REQUEST_ASSIGN_ROLE:
      const { loading } = action.payload
      return {
        ...state,
        assignRoleLoading: loading
      }  
    case CREATE_ROLE_FAILED:
      return {
        ...state,
        createLoading: null,
        message: action.payload
      }
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        error: action.payload
      }
    case REQUEST_GET_ROLE:
      return {
        ...state,
        loading: true
      }
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: null,
        roles: action.payload,
        success: false,
      }
    case GET_ROLE_FAILED:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
}
