import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Dropdown, Form, Spinner } from 'react-bootstrap';
import CpanelLayout from '../../../../layouts/AdminLayout';
import { getUnseenNotification, getAllNotifications } from '../../../../redux/actions';

import './index.css';

function CommunicationDash() {
    const dispatch = useDispatch();

    const { notifications, notification, loading } = useSelector(state => state.notifications);

    useEffect(() => {
        dispatch(getAllNotifications());
    }, [dispatch]);

    useEffect(() => {
        notifications.forEach(notification => dispatch(getUnseenNotification(notification.id)));
    }, [notifications, dispatch]);

    return (
        <CpanelLayout>
            <Container fluid>
                <div className="col-md-8 mx-auto nopadding">
                    <Card style={{ width: '100%' }}>
                        <Card.Title className="text-center p-4">Notifications</Card.Title>
                        {notifications.length > 0 ? (
                            notifications.map((notification, index) => (
                                <Row key={notification.id} className={`${notification.status === 'unseen' ? 'bg-light' : 'bg-white'} border nomargin`}>
                                    <Col className="nopadding" md={2}>
                                        <div style={{ height: '100%', fontSize: '0.5rem' }} className="p-3 text-center">
                                            <i className="fas fa-bell fa-10x"></i>
                                        </div>
                                    </Col>
                                    <Col className="nopadding" md={10}>
                                        <div className="pt-3 pb-2">
                                            <p>{notification.description}</p>
                                            <p className="text-primary">{moment(notification.createdAt.split(' ')[0]).format('MMM Do YYYY')}</p>
                                        </div>
                                    </Col>
                                </Row>
                            ))
                        ) : (
                            <p>No notification you have so far</p>
                        )}
                    </Card>
                </div>
            </Container>
        </CpanelLayout>
    );
}

export default CommunicationDash;
