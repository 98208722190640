import axios from 'axios';
import { toast } from 'react-toastify';
import * as types from '../types';

const { REACT_APP_BACKEND } = process.env;

export const getCenterLocation = locationIds => dispatch => {
    axios
        .post(`${REACT_APP_BACKEND}/api/centers/location`, { locationIds })
        .then(res => {
            dispatch({ type: types.GET_CENTER_LOCATION, payload: res.data });
        })
        .catch(err => {
            dispatch({ type: types.CENTER_ERROR, payload: err.response ? err.response.data.error : null });
        });
};

export const createCenterLocation = (payload, onClose, resetForm) => dispatch => {
    dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: true } });
    axios
        .post(`${REACT_APP_BACKEND}/api/centers/create`, payload)
        .then(res => {
            if (res.status === 201) {
                toast.success(res.data.message);
                onClose();
                resetForm();
                dispatch(getAllCenters());
                dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
            }
        })
        .catch(err => {
            dispatch({ type: types.CENTER_ERROR, payload: err.response ? err.response.data.error : null });
            dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
        });
};

export const getAllCenters = () => dispatch => {
    dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: true } });
    axios
        .get(`${REACT_APP_BACKEND}/api/centers`)
        .then(res => {
            dispatch({ type: types.GET_ALL_TRAINING_CENTERS, payload: res.data.data });
            dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
        })
        .catch(err => {
            dispatch({ type: types.CENTER_ERROR, payload: err.response ? err.response.data.error : null });
            dispatch({ type: types.GET_DA_TRAINING_CENTERS_ERROR });
            dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
        });
};

export const getDaCenters = (id) => dispatch => {
    dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: true } });
    axios
        .get(`${REACT_APP_BACKEND}/api/centers/ambs/${id}`)
        .then(res => {
            dispatch({ type: types.GET_DA_TRAINING_CENTERS, payload: res.data });
            dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
        })
        .catch(err => {
            dispatch({ type: types.CENTER_ERROR, payload: err.response ? err.response.data.error : null });
            dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
        });
};

export const getSingleCenter = centerId => dispatch => {
    dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: true } });
    axios
        .get(`${REACT_APP_BACKEND}/api/centers/${centerId}`)
        .then(res => {
            dispatch({ type: types.GET_SINGLE_TRAINING_CENTER, payload: res.data });
            dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
        })
        .catch(err => {
            dispatch({ type: types.CENTER_ERROR, payload: err.response ? err.response.data.error : null });
            dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
        });
};

export const updateCenterLocation = (centerId, payload, onClose, resetForm) => dispatch => {
    dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: true } });

    dispatch({ type: types.CENTER_ERROR, payload: '' });
    axios
        .put(`${REACT_APP_BACKEND}/api/centers/${centerId}`, payload)
        .then(res => {
            if (res.status === 200) {
                toast.success(res.data.message);
                onClose();
                resetForm();
                dispatch(getAllCenters());
                dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
            }
        })
        .catch(err => {
            dispatch({ type: types.CENTER_ERROR, payload: err.response ? err.response.data.error : 'Something went wrong, we could not proceed this operation' });
            dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });

            console.log(err.response);
        });
};

export const deleteCenterLocation = centerId => dispatch => {
    dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: true } });
    axios
        .delete(`${REACT_APP_BACKEND}/api/centers/${centerId}`)
        .then(res => {
            if (res.status === 200) {
                toast.success(res.data.message);
                dispatch(getAllCenters());
                dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
            }
        })
        .catch(err => {
            dispatch({ type: types.CENTER_ERROR, payload: err.response ? err.response.data.error : null });
            dispatch({ type: types.CENTER_LOCATION_LOADING, payload: { loading: false } });
        });
};
