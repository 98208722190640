import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import Header from './components/Header';
import Footer from './components/Footer';

import './index.css';
import BackLink from '../../components/commons/back-link/BackLink';
import routes from '../../routes/names';
import { fetchTraineeProfileByTraineeIdentity, downloadCertificate, getAllCourses } from '../../redux/actions';

const RegisterVerifyPage = ({ match, t }) => {
    const traineeId = match.params.traineeIdentity;

    const dispatch = useDispatch();
    const { trainee } = useSelector(state => state.trainees);
    const { courses } = useSelector(state => state.courses);

    useEffect(() => {
        dispatch(fetchTraineeProfileByTraineeIdentity(traineeId));
        dispatch(getAllCourses());
    }, [dispatch, traineeId]);

    if (trainee && trainee.attendenceCount >= Number(trainee.courseNumber)) {
        localStorage.setItem('traineeId', traineeId);
    } else {
        console.log('===No certificate allowed===');
    }

    const datas = {
        traineeIdentity: traineeId,
    };

    const saveDownloadCertificate = async () => {
        await dispatch(downloadCertificate(datas.traineeIdentity));
        setTimeout(() => {
            window.location.href = `${routes.traineeCertificate}`;
        }, 500);
    };

    return (
        <div className="App">
            <Header />

            <div className="body-content verify bg-dark-primary pt-3">
                <Container>
                    <Row>
                        <Col md={12}>
                            <BackLink path={routes.searchCertificate} />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col md={12}>
                            <div className="box">
                                <p className="text-title mb-2">{`${t(`Umwirondoro wawe`)}`}</p>

                                <p className="text-title mb-3">{`${t(`Amakuru y'ibanze`)}`}</p>

                                <Row>
                                    <Col className="col-12 col-md-6 col-lg-3  py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Amazina`)}`}</p>
                                        <p className="mb-0 text-normal">
                                            {trainee && trainee.lastName} {trainee && trainee.firstName} {trainee && trainee.middleName}
                                        </p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3  py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Code`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.traineeIdentity}</p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3  py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Igitsina`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.gender}</p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Itariki y'amavuko`)}`}</p>
                                        <p className="mb-0 text-normal">{moment(trainee && trainee.dob).format('MMMM DD YYYY')}</p>
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Telefoni`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.phone}</p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Email`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.email}</p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Indangamuntu`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.nationalIdentity}</p>
                                    </Col>
                                </Row>

                                <p className="text-title mb-3 mt-4">{`${t(`Aho utuye`)}`}</p>

                                <Row>
                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Intara`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.province}</p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Akarere`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.district}</p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Umurenge`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.sector}</p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Akagari`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.cell}</p>
                                    </Col>
                                </Row>

                                <p className="text-title mb-3 mt-4">{`${t(`Ibyerekeye amahugurwa`)}`}</p>

                                <Row>
                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Igihe watorejwe`)}`}</p>
                                        <p className="mb-0 text-normal">{moment(trainee && trainee.createdAt).format('DD MMM YYYY')}</p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Umutoza`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.affiliated}</p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Amasomo warangije`)}`}</p>
                                        <p className="mb-0 text-normal">
                                            {trainee && trainee.attendenceCount > 0 ? trainee.attendenceCount : '0'}/{Number(trainee.courseNumber)}
                                        </p>
                                    </Col>

                                    <Col className="col-12 col-md-6 col-lg-3 py-sm-2">
                                        <p className="mb-0 text-small">{`${t(`Ushobora kubona impamyabumenyi`)}`}</p>
                                        <p className="mb-0 text-normal">{trainee && trainee.attendenceCount >= Number(trainee.courseNumber) ? 'Yego' : 'Oya'} </p>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <div className="pull-right mt-5">
                                            <Link to={routes.register.start} className="btn btn-primary px-5 mt-2">
                                                {`${t(`Kongeramo amakuru`)}`}
                                            </Link>{' '}
                                            &nbsp;
                                            {trainee && trainee.attendenceCount >= Number(trainee.courseNumber) ? (
                                                <button className="btn btn-primary px-5 mt-2" onClick={() => saveDownloadCertificate()}>
                                                    {`${t(`Reba impamyabumenyi`)}`}
                                                </button>
                                            ) : (
                                                <span className="btn btn-primary px-5 mt-2 disabled">{`${t(`Reba impamyabumenyi`)}`}</span>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer />
        </div>
    );
};

export default withNamespaces()(RegisterVerifyPage);
