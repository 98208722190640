/* eslint-disable import/no-anonymous-default-export */
import { REQUEST_GET_MATERIALS } from '../types';

const initialState = {
    materials: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REQUEST_GET_MATERIALS:
            return {
                ...state,
                materials: action.payload,
            };
        default:
            return state; //or return initialState
    }
}
