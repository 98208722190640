import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { approveManyTrainees, getPendingTrainees, getAllDaGroups, getLocationBySector, getCenterLocation, getPendingTransfers, approveTransfers } from '../../../../redux/actions';
// import Spinner from "../../../../components/spinner/Spinner";
import jwtDecode from 'jwt-decode';

import { Row, Col, Container, Button, Dropdown } from 'react-bootstrap';
import { AddGroup, Groups, PendingRegistrants, Transferees, AdmitTransferees, AdmitRegistrants } from '../../components/trainees';
import CpanelLayout from '../../../../layouts/AdminLayout';
import routes from '../../../../routes/names';
import moment from 'moment';
import axios from 'axios';

const Title = ({ children }) => {
    return (
        <div className="py-3">
            <span className="text-medium">{children}</span>
        </div>
    );
};
function DATrainees() {
    const [modals, setModals] = useState({
        addGroup: false,
        admitRegistrants: false,
        admitTransferees: false,
    });
    const [residentIds, setResidentIds] = useState([]);
    const [sector, setSector] = useState('');
    const { error, success: traineesSucess, loading: traineesLoading, trainees, pendingTransfers } = useSelector(state => state.trainees);
    const { centers } = useSelector(state => state.centers);
    const { residence } = useSelector(state => state.residence);
    const dispatch = useDispatch();

    const openAddGroup = () => {
        setModals({ addGroup: true });
        let ids = [];
        let sectors = residence.filter(res => res.sector === sector);
        sectors.forEach(c => ids.push(c.id));
        setResidentIds([...new Set(ids)]);
    };
    const closeAddGroup = () => setModals({ addGroup: false });

    const openAdmitTransferees = () => setModals({ admitTransferees: true });
    const closeAdmitTransferees = () => setModals({ admitTransferees: false });

    const openAdmitRegistrants = () => setModals({ admitRegistrants: true });
    const closeAdmitRegistrants = () => setModals({ admitRegistrants: false });

    const onAdmitRegistrants = registrants => {
        openAdmitRegistrants();
    };

    const onAdmitTransferees = transferees => {
        openAdmitTransferees();
    };
    const [pendingGroup, setPendingGoup] = useState([]);
    const [currentGroup, setCurrentGruop] = useState([]);
    const [userDetail, setUserDetail] = useState({});

    const { groups, loading, getLoading, success: groupSuccess, createLoading } = useSelector(state => state.groups);
    const { courses } = useSelector(state => state.courses);

    const dispatchTraineeApproval = trainees => {
        dispatch(approveManyTrainees(trainees));
    };

    useEffect(() => {
        dispatch(getCenterLocation(residentIds));
    }, [residentIds, dispatch]);

    useEffect(() => {
        dispatch(getPendingTransfers());
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            const token = jwtDecode(localStorage.getItem('IdToken'));
            const userID = token?.id;

            if (token && userID) {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/users/${userID}`);
                setUserDetail(data?.data);
            }
        })();
    }, []);

    useEffect(() => {
        const pending = groups.filter(group => {
            if (group.cohort) {
                return (
                    // group.status.toLowerCase() == "pending-cohort" ||
                    group.cohort.status.toLowerCase() === 'pending'
                );
            }
            return [];
        });

        const current = groups.filter(group => {
            if (group.cohort) {
                return (
                    // group.status.toLowerCase() == "current-cohort" ||
                    group.cohort.status.toLowerCase() === 'current'
                );
            }
            return [];
        });
        setPendingGoup(pending);
        setCurrentGruop(current);
    }, [groups]);

    useEffect(() => {
        const token = localStorage.IdToken;
        if (token) {
            let decodedToken = jwtDecode(token);
            dispatch(getPendingTrainees());
            dispatch(getAllDaGroups());
            dispatch(getLocationBySector(decodedToken.sector));
            setSector(decodedToken.sector);
        }
    }, [dispatch]);
    useEffect(() => {
        if (groupSuccess) {
            dispatch(getAllDaGroups());
            closeAddGroup();
        }
    }, [groupSuccess, dispatch]);

    useEffect(() => {
        // closeAdmitRegistrants();
        dispatch(getPendingTrainees());
    }, [traineesSucess, dispatch]);

    const handleApproveTransfer = transfers => {
        dispatch(approveTransfers(transfers));
    };

    const today = new Date();

    return (
        <CpanelLayout>
            <Container>
                <div className="d-flex align-items-center justify-content-between">
                    <span className="text-title">Trainees</span>

                    <div className="d-flex align-items-center">
                        {/* <Link className="btn btn-light" to={routes.dashboard.trainees.create}> */}
                        <Link className="btn btn-light" to={routes.dashboard.ambassadors.myTrainees}>
                            {' '}
                            <i className="fas fa-list-ol" aria-hidden="true"></i>&nbsp;
                            <span className="text-dark">All trainees</span>
                        </Link>
                        {/* <Link className="btn btn-light" to={routes.dashboard.trainees.create}>
                            {' '}
                            <i className="fa fa-users" aria-hidden="true"></i>&nbsp;
                            <span className="text-dark">Add trainees</span>
                        </Link> */}
                        <Dropdown drop="down">
                            <Dropdown.Toggle variant="light" className="text-dark text-small">
                                <span>
                                    {' '}
                                    <i className="fa fa-user-plus text-primary" aria-hidden="true">
                                        {' '}
                                    </i>
                                    &nbsp; <span className="text-dark">Add trainees</span>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <Link to={routes.dashboard.trainees.create} className="text-dark">
                                        <span>
                                            {' '}
                                            <i className="fas fa-plus-circle text-primary"></i>
                                            &nbsp; Add One
                                        </span>
                                    </Link>
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                        as="div"
                                        role="button"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            fileInputRef.current?.click();
                                        }}
                                    >
                                        <input type="file" id="file" ref={fileInputRef} onChange={uploadSpreadsheet} accept=".xlsx, .xls, .csv" multiple={false} />

                                        <span>
                                            <i className="fas fa-file-upload text-primary"></i>
                                            &nbsp; Import from file
                                        </span>
                                    </Dropdown.Item> */}

                                {/* <Dropdown.Item as="button" role="button" disabled={downloading} onClick={onDownload}>
                                        <span>
                                            <i className="fas fa-file-export text-primary"></i>
                                            &nbsp; {downloading ? 'Downloading' : 'Download Template'}
                                        </span>
                                    </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button className="btn btn-light" onClick={openAddGroup}>
                            {' '}
                            <i className="fa fa-user-plus" aria-hidden="true"></i>&nbsp;
                            <span className="text-dark">Add group</span>
                        </Button>
                    </div>
                </div>
                <Row>
                    <Col className="col-lg-9  col-md-6 col-sm-12">
                        <div>
                            <Title>
                                Current cohort <span> ({moment(today).format('MMM YYYY')})</span>{' '}
                            </Title>
                            {currentGroup && <Groups centers={userDetail.DAInfo || []} groups={currentGroup} />}
                        </div>

                        <div className="py-3">
                            <Title>Pending Groups</Title>
                            {pendingGroup && <Groups centers={userDetail.DAInfo || []} groups={pendingGroup} />}
                        </div>
                    </Col>

                    <Col className="col-lg-3 col-md-6 col-sm-12">
                        <div> {trainees.length !== 0 && <PendingRegistrants onAdmit={onAdmitRegistrants} trainees={trainees} />}</div>
                        <div className="py-3"> {pendingTransfers && <Transferees transferees={pendingTransfers.data} onAdmit={onAdmitTransferees} />}</div>
                    </Col>
                </Row>

                <AddGroup centers={userDetail.DAInfo || []} open={modals.addGroup} onClose={closeAddGroup} />

                {trainees.length !== 0 && <AdmitRegistrants open={modals.admitRegistrants} onClose={closeAdmitRegistrants} registrants={trainees} submit={dispatchTraineeApproval} loading={traineesLoading} />}
                {pendingTransfers.data !== 0 && <AdmitTransferees open={modals.admitTransferees} onClose={closeAdmitTransferees} transferees={pendingTransfers.data} loading={traineesLoading} submit={handleApproveTransfer} />}
            </Container>
        </CpanelLayout>
    );
}

export default DATrainees;
