/* eslint-disable import/no-anonymous-default-export */
import { REQUEST_CREATE_COURSE, CREATE_COURSE_FAILED, CREATE_COURSE_SUCCESS, REQUEST_GET_COURSE, GET_COURSE_SUCCESS, REQUEST_UPDATE_COURSE, UPDATE_COURSE_FAILED, UPDATE_COURSE_SUCCESS } from '../types';

const initialState = {
    loading: null,
    createLoading: null,
    updateLoading: null,
    deleteLoading: null,
    courses: [],
    message: null,
    error: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REQUEST_CREATE_COURSE:
            return {
                ...state,
                createLoading: true,
                error: '',
            };

        case CREATE_COURSE_SUCCESS:
            return {
                ...state,
                createLoading: null,
                message: action.payload,
            };
        case CREATE_COURSE_FAILED:
            return {
                ...state,
                createLogin: null,
                error: action.payload,
            };
        case REQUEST_GET_COURSE:
            return {
                ...state,
                getLoading: true,
            };
        case GET_COURSE_SUCCESS:
            return {
                ...state,
                createLoading: null,
                courses: action.payload,
            };
        case REQUEST_UPDATE_COURSE:
            return {
                ...state,
                updateLoading: true,
                error: '',
            };
        case UPDATE_COURSE_SUCCESS:
            return {
                ...state,
                message: action.payload,
                success: true,
                updateLoading: null,
            };
        case UPDATE_COURSE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                updateLoading: null,
            };
        default:
            return state;
    }
}
