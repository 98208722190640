/**
 * Change false value (null,undefined,...) object propery to empty string -
 * `{middleName: null }` => `{ middleName: "" }`
 * @param {
 * } obj
 * @returns
 */

const emptifyFalseValues = (obj) => {
  let result = {};
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    if (!Boolean(obj[key])) {
      return (result[key] = "");
    }

    result[key] = obj[key];
  });

  return result;
};

export default emptifyFalseValues;
