import React from 'react';
import './index.css';

import { Col, Row } from 'react-bootstrap';
import Session from './Session';

export default function Sessions({ sessions, confirmAttendees, editSession, deleteSession, viewDetails }) {
    return (
        <Row>
            {sessions.map(session => {
                return (
                    <Col className="col-sm-12 col-md-6 col-lg-3">
                        <Session session={session} confirmAttendees={confirmAttendees} sessionStatus={session.status} editSession={editSession} deleteSession={deleteSession} viewDetails={viewDetails} />
                    </Col>
                );
            })}
        </Row>
    );
}
