import React from "react";
import FeedBackMessage from "../feedback-message/FeedBackMessage";

export default function FeedBackMessagesList({feedbacks}) {
  return (
    <div>
      { feedbacks&&feedbacks.data.map((feedback, index) => {
        return (
          <div key={index} className="pb-5">
            <FeedBackMessage  feedback = { feedback } />
          </div>
        );
      })}
    </div>
  );
}
