import React from 'react';
import CpanelLayout from '../../../../../layouts/AdminLayout';

const Details = () => {
    return (  
        <CpanelLayout>
            <main>
                <h3>Umwirondoro</h3>
                <div className="card card-body bg-light border-0">
                    <h4 className="font-weight-bold my-2">Basic info</h4>
                    <div className="d-flex align-items-center my-3">
                        <div className="mr-5">
                            <p className="my-0">Names</p>
                            <h5>Munyaneza Iradukunda Placide</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">Trainee ID</p>
                            <h5>0420428</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">Sex</p>
                            <h5>Male</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">Date of birth</p>
                            <h5>1 January 1993</h5> 
                        </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                        <div className="mr-5">
                            <p className="my-0">Telephone</p>
                            <h5>078888888</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">Email</p>
                            <h5>plamunya@gmail.com</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">ID Number</p>
                            <h5>1 xxx xxx xxx xxx</h5> 
                        </div>
                    </div>
                    <h4 className="font-weight-bold my-3">Local Information</h4>
                    <div className="d-flex align-items-center my-3">
                        <div className="mr-5">
                            <p className="my-0">Province</p>
                            <h5>City of Kigali</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">District</p>
                            <h5>Nyarugenge</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">Sector</p>
                            <h5>Nyamirambo</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">Cell</p>
                            <h5>Cyivugiza</h5> 
                        </div>
                    </div>
                    <h4 className="font-weight-bold my-3">About the training</h4>
                    <div className="d-flex align-items-center my-3">
                        <div className="mr-5">
                            <p className="my-0">Cohort</p>
                            <h5>March 2021 Cohort</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">Trainer</p>
                            <h5>Gashugi Joseph</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">Completed Modules</p>
                            <h5>15/9</h5> 
                        </div>
                        <div className="mr-5">
                            <p className="my-0">Certificate Eligibility</p>
                            <h5>Eligible</h5> 
                        </div>
                    </div>
                </div>
            </main>
        </CpanelLayout>
    );
}
 
export default Details;
