import React from 'react';
import { Modal, Form, Button, InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { matchDigit } from '../../../../../helpers';
import { updateService } from '../../../../../redux/actions/services';
import * as Yup from 'yup';
import isObject from 'lodash/isObject';
import LoadingIndicator from '../../../../../utils/LoadingSmall';

export default function UpdateService({ open, onClose, id, service }) {
    const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.services);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Service name is required'),
        rate: Yup.number('Rate must be number').required('Service rate is required'),
    });

    const getPreviousValues = values => {
        if (isObject(values)) {
            return { ...values, rate: parseInt(values.rate) };
        }

        return {};
    };

    const initialValues = {
        name: '',
        rate: '',
        serviceType: 'secondary-service',
        ...getPreviousValues(service),
    };

    console.log({ initialValues, previousValues: getPreviousValues(service) });
    const { handleSubmit, handleChange, values, errors, setFieldValue, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const payload = { ...values, trainingcenterId: id, rate: String(values.rate) };

            dispatch(updateService({ payload, onClose, resetForm }));
        },
    });

    return (
        <Modal show={open} size="md" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose && onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Service</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {' '}
                <Form>
                    <Form.Group>
                        <Form.Control type="text" placeholder="Service name" name="name" onChange={handleChange} value={values.name} />

                        <Form.Control.Feedback className="d-block" type="invalid">
                            {errors.name}{' '}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className="text-small">Service commission rate percentage. Write 100 for no-commissioned services (printing, app installations, hardware maintainance,...).</Form.Label>
                        <InputGroup>
                            <Form.Control
                                disabled={loading}
                                type="text"
                                placeholder="12.5%"
                                name="rate"
                                value={values.rate}
                                onChange={e => {
                                    const { value } = e.target;
                                    let rate = matchDigit(value);

                                    rate = rate > 100 ? 100 : rate;
                                    setFieldValue('rate', rate);
                                }}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>

                        <Form.Control.Feedback className="d-block" type="invalid">
                            {errors.rate}{' '}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
                <div>
                    <Form.Control.Feedback className="d-block" type="invalid">
                        {error}{' '}
                    </Form.Control.Feedback>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" block onClick={handleSubmit} disabled={loading}>
                    {loading ? <LoadingIndicator color="#fff" /> : 'Update '}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
