import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { Container, Row, Col, Form, Dropdown } from 'react-bootstrap';

import Spinner from '../../../../components/spinner/Spinner';

import { PartnerTable } from '../../components/ambassadors/da-table';
import { Link, useHistory } from 'react-router-dom';
import routes from '../../../../routes/names';
import { getAllPartners, updateUser } from '../../../../redux/actions';

import CpanelLayout from '../../../../layouts/AdminLayout';
import { csvToJson, emptifyFalseValues } from '../../../../helpers';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { REQUEST_GET_USERS } from '../../../../redux/types';
import moment from 'moment';
import FilterButton from '../../../../components/commons/buttons/filter-button/filter-button';

const sanitizeData = partners => {
    return partners.map((partner, index) => ({
        names: partner.firstName + ' ' + partner.lastName + ' ' + partner.middleName,
        status: partner.isActive ? 'Active' : 'Inactive',
        index: index + 1,
        registrationYear: moment(partner.createdAt),
        ...emptifyFalseValues(partner),
    }));
};

// const removeInvalidData = data => {
//     /* Remove invalid data and duplicates */
//     return [...new Set(data.filter(el => Boolean(el.firstName) && Boolean(el.lastName)))];
// };

function Partners() {
    const dispatch = useDispatch();
    const fileInputRef = React.useRef();
    const history = useHistory();

    const { success, message, error, loading, partners } = useSelector(state => state.partners);
    const { roles: LoggedInUserRoles } = useSelector(state => state.auth);

    let sanitizedDataset = sanitizeData(partners);

    const [dataset, setDataset] = useState(sanitizedDataset);
    useEffect(() => {
        dispatch(getAllPartners());
    }, [dispatch, success]);

    // const dispatchMakeCoordinator = id => {
    //     dispatch(makeCoordinator(id));
    // };

    const dispatchUpdate = (id, data) => {
        dispatch(updateUser(id, data));
    };

    // const filterByYear = criteria => {
    //     if (criteria === 'all') {
    //         return setDataset(sanitizedDataset);
    //     }
    //     const newDataset = sanitizedDataset.filter(el => String(el.registrationYear).includes(criteria));
    //     return setDataset([...newDataset]);
    // };

    useEffect(() => {
        sanitizedDataset = sanitizeData(partners);
        setDataset(sanitizedDataset);
    }, [partners]);

    const isAllowed = LoggedInUserRoles && LoggedInUserRoles.some(role => role === 'super_admin' || role === 'progr-manager');
    return (
        <CpanelLayout>
            <Row>
                <Col className="col-12 col-md-6">
                    <div>
                        <span className="text-title">Partners Management</span>
                    </div>
                </Col>

                <Col className=" col-12 col-md-6">
                    <div className="d-flex align-items-baseline justify-content-end">
                        {/* <Form.Group>
                            <Form.Control
                                as="select"
                                className=" border-1 border-radius-3 custom-select text-small"
                                style={{ borderRadius: 10 }}
                                onChange={({ target: { value } }) => {
                                    filterByYear(value);
                                }}
                            >
                                <option value="all" selected>
                                    All
                                </option>
                                {getYearsInProgress().map(year => (
                                    <option key={year} value={year}>
                                        {' '}
                                        {year}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group> */}
                        <FilterButton setDataset={setDataset} sanitizedDataset={sanitizedDataset} />

                        {isAllowed && (
                            <Dropdown drop="down">
                                <Dropdown.Toggle variant="light" className="text-dark text-small">
                                    <span>
                                        {' '}
                                        <i className="fa fa-user-plus text-primary" aria-hidden="true">
                                            {' '}
                                        </i>
                                        &nbsp; Add a Partner
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <Link to={routes.dashboard.partners.create} className="text-dark">
                                            <span>
                                                {' '}
                                                <i className="fas fa-plus-circle text-primary"></i>
                                                &nbsp; Add One
                                            </span>
                                        </Link>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                </Col>
            </Row>

            <div className="pt-5">
                <Row>
                    <Col className="col-12">
                        {loading ? (
                            <Spinner />
                        ) : Boolean(dataset.length) ? (
                            <PartnerTable partners={[...partners]} search updateStatus={dispatchUpdate} isAllowed={isAllowed} />
                        ) : (
                            Boolean(error?.length) && (
                                <div className="d-flex align-item-center justify-content-center py-3">
                                    <span> There's no registered partners in this period.</span>
                                </div>
                            )
                        )}
                    </Col>
                </Row>
            </div>
        </CpanelLayout>
    );
}

export default Partners;
