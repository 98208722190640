import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import { withNamespaces } from 'react-i18next';
import { resetPassword } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../utils/LoadingSmall';
import BackLink from '../commons/back-link/BackLink';
import routes from '../../routes/names';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

const ResetPassword = ({ t }) => {
    const otp = localStorage.otp;

    const dispatch = useDispatch();
    const history = useHistory();

    const [user, setUser] = useState({ password: '', confirmPassword: '' });
    const [notMatchErrors, setNotMatchErrors] = useState(null);
    const [validated, setValidated] = useState(false);
    const loading = useSelector(state => state.auth.resetData);

    const handleChange = e => {
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
    };

    const msg = {
        error: `${t(`Password must contain at least One Digit, One Special Character, One Lowercase and One Uppercase Letter!`)}`,
        success: `${t(`Password changed successfully. Please continue to login!`)}`,
    };

    const handleSubmit = e => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) e.stopPropagation();
        e.preventDefault();
        setValidated(true);
        const userData = { otp, password: user.password };
        if (user.password !== user.confirmPassword) {
            toast.error(`${t(`New password and confirm password did not match and greater than 8 characters`)}!`, { autoClose: 6000 });
        } else if (user.password.length < 8) {
            toast.error(`${t(`New password must be greater than 8 characters`)}!`, { autoClose: 6000 });
        } else {
            if (user.password && user.confirmPassword) {
                setNotMatchErrors(null);
                dispatch(resetPassword(userData, msg, history));
            }
        }
    };

    return (
        <div className="body-content bg-dark-primary pt-5">
            <Container>
                <div className="pb-4">
                    <Row>
                        <Col md={12}>
                            <BackLink path={routes.home} />
                        </Col>
                    </Row>
                </div>

                <div className="box">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <p className="text-small mt-3">{`${t(`Set Password that you are going to use from now on`)}`}.</p>

                        <Row>
                            <Col lg={6} sm={12}>
                                <Form.Group className="mt-3">
                                    <Form.Control required type="password" name="password" value={user.password} placeholder={`${t(`New password`)}`} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">{`${t(`New password is required`)}`}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6} sm={12}>
                                <Form.Group className="mt-3">
                                    <Form.Control required type="password" name="confirmPassword" value={user.confirmPassword} placeholder={`${t(`Confirm password`)}`} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">{`${t(`Confirm password is required`)}`}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6} sm={12}>
                                <div>{notMatchErrors ? <Alert className="alert-danger">{notMatchErrors}</Alert> : ''}</div>
                            </Col>
                        </Row>
                        <p className="text-small mt-3 mb-0">
                            {`${t(`Remember to set a strong password that you will remember`)}`}. &nbsp;
                            <i className="fa fa-info-circle" data-tip="info" style={{ fontSize: 20, color: 'brown' }}></i>
                            <ReactTooltip backgroundColor="#fff" textColor="#000f16" className="tooltip" style={{ opacity: 0 }}>
                                <span className="text-bold">{t(`Password should have at least`)}:</span>
                                <br />
                                <span>{`${t('One or more lowercase characters')}`} , </span>
                                <br />
                                <span>{`${t('One or more uppercase characters')}`}, </span>
                                <br />
                                <span>
                                    {`${t('One or more special characters eg')}`}: {'@, !, #, &, etc.'}
                                </span>
                            </ReactTooltip>
                        </p>

                        <Row>
                            <Col lg={4} sm={12}>
                                <Form.Group className="mt-3">
                                    <Button type="submit" variant="primary" className="btn-block" style={{ width: '100%' }}>
                                        {`${t(`Submit`)}`}
                                    </Button>
                                    {loading && <Loading />}
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <Link to="/" className="btn-link text-primary text-small mt-3">
                            {`${t(`Gusubira inyuma`)}`}
                        </Link>
                    </Form>
                </div>
            </Container>
        </div>
    );
};

export default withNamespaces()(ResetPassword);
