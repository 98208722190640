/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CpanelLayout from '../../../../layouts/AdminLayout';
import { useDispatch, useSelector } from 'react-redux';
import Import from '../../../../assets/images/import.svg';

import routes from '../../../../routes/names';
import { Link } from 'react-router-dom';
import { AddGroupTrainees, GroupTrainees } from '../../components/trainees';
import { Button, Spinner } from 'react-bootstrap';
import Loader from '../../../../components/spinner/Spinner';
import { getOneGroup, getAllTraineesByDa, addGroupTrainee, deleteGroup, getCenterLocation, getAllLocations } from '../../../../redux/actions';

const ListGroup = ({ match }) => {
    const dispatch = useDispatch();
    const { error, success: traineeSuccess, updateSuccess: updateTraineeSuccess, loading: traineesLoading, trainees, makeCaptain } = useSelector(state => state.trainees);
    const { group, updateLoading, success: updateSuccess, loading, deleteSuccess, deleteLoading } = useSelector(state => state.groups);
    const { sector } = useSelector(state => state.auth);
    const { residence, districts, sectors, cells, villages } = useSelector(state => state.residence);
    const { centers } = useSelector(state => state.centers);

    const [modals, setModals] = useState({ addGroupTrainees: false });
    const [waiting, setWaiting] = useState([]);
    const [residentIds, setResidentIds] = useState([]);

    const openGroupTrainees = () => setModals({ ...modals, addGroupTrainees: true });

    const closeGroupTrainees = () => setModals({ ...modals, addGroupTrainees: false });

    const { id } = match.params;

    const handleAddGroupTrainee = ids => {
        dispatch(addGroupTrainee(id, { traineeIds: [...ids] }));
    };

    useEffect(() => {
        dispatch(getAllLocations());
    }, [sector, dispatch]);

    useEffect(() => {
        let ids = [];
        let sectors = residence.filter(res => res.sector == sector);
        sectors.forEach(c => ids.push(c.id));
        setResidentIds([...new Set(ids)]);
    }, [residence]);

    useEffect(() => {
        dispatch(getCenterLocation(residentIds));
    }, [residentIds]);

    useEffect(() => {
        dispatch(getOneGroup(id));
        dispatch(getAllTraineesByDa());
    }, [deleteSuccess]);

    useEffect(() => {
        dispatch(getOneGroup(id));
        dispatch(getAllTraineesByDa());
    }, [dispatch]);

    useEffect(() => {
        if (trainees) {
            const waitingList = trainees.filter(trainee => (trainee.groupId == null || undefined) && trainee.status.toLowerCase() !== 'pending');
            setWaiting([...waitingList]);
        }
    }, [trainees]);

    const handleDelete = () => {
        dispatch(deleteGroup(id));
    };

    useEffect(() => {
        closeGroupTrainees();
        dispatch(getAllTraineesByDa());
        dispatch(getOneGroup(id));
    }, [updateSuccess, traineeSuccess, updateTraineeSuccess, makeCaptain, dispatch]);

    const SpinnerWrapper = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ccccccba;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 20;
    `;

    return (
        <CpanelLayout>
            {group && (
                <main>
                    <p>
                        Trainees &gt; {group.cohort ? group.cohort.name : null} &gt; {group.name || null}
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                        <h1>{group.name || null}</h1>
                        <div className="d-flex align-items-center text-primary">
                            <div className="dropdown">
                                <Button className="btn btn-light" onClick={openGroupTrainees}>
                                    <span>
                                        <i className="fa fa-plus" /> &nbsp; Add trainees
                                    </span>
                                </Button>

                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <Link className="dropdown-item" to={routes.dashboard.trainees.create}>
                                        <i className="fa fa-plus-circle text-primary" /> One
                                    </Link>
                                    <a className="dropdown-item d-flex justify-content-between align-items-center" href="#">
                                        <span>
                                            <img src={Import} />
                                            Import
                                        </span>
                                        <i className="fa fa-chevron-right" />
                                    </a>
                                    <a className="dropdown-item" href="#">
                                        <i className="fa fa-download text-primary" /> Download Template
                                    </a>
                                </div>
                            </div>
                            {/* <button className="btn text-primary">
                Sort <i className="fa fa-chevron-down" />
            </button>
            <Button variant="danger" onClick={handleDelete}>
              {deleteLoading ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : (
                  <i className="fas fa-trash-alt  text-white"></i>
                )}
              {" "}
              Delete
            </Button> */}
                        </div>
                    </div>

                    {loading ||
                        (traineesLoading && (
                            <SpinnerWrapper>
                                <Loader isUpload={true} />
                            </SpinnerWrapper>
                        ))}
                    <>{group.trainees?.length !== 0 ? <GroupTrainees centers={centers} trainees={group.trainees} search /> : <p className="text-small mb-5"> There is no Trainee in this group so far</p>}</>

                    {trainees && <AddGroupTrainees trainees={waiting} group={group} open={modals.addGroupTrainees} onClose={closeGroupTrainees} submit={handleAddGroupTrainee} loading={updateLoading} />}
                </main>
            )}
        </CpanelLayout>
    );
};

export default ListGroup;
