import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, Button, FormGroup, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import { withNamespaces } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';

import './index.css';

import * as Yup from 'yup';
import { matchDigit, checkDates } from '../../../../helpers';
import { getLocationByProvince, getLocationByDistrict, getLocationBySector, getLocationByCell, getOneTrainee, updateTraineeData, getCenterLocation } from '../../../../redux/actions';

import Layout from '../../../../layouts/AdminLayout';

const UpdateTrainee = ({ t }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { trainee, updateSuccess, message, error, loading } = useSelector(state => state.trainees);
    const validationSchema = Yup.object().shape({
        nationalIdentity: Yup.string().min(16, 'National ID is invalid'),
        firstName: Yup.string().required(' First name is required'),
        lastName: Yup.string().required(' Last name is required'),
        gender: Yup.string().required('Please select gender'),
        dob: Yup.string().required('Date of birth is required '),
        email: Yup.string().email('Email is Invalid'),
        phone: Yup.string().length(10, 'Phone number is invalid'),
    });

    const initialValues = {
        nationalIdentity: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        gender: '',
        dob: '',
        email: '',
    };

    const [residentId, setResidentId] = useState('');
    const [residentIds, setResidentIds] = useState([]);
    const [centerId, setCenterId] = useState('');
    const [userResidence, setUserResidence] = useState({
        province: '',
        district: '',
        sector: '',
        cell: '',
        village: '',
    });

    const { residence, districts, sectors, cells, villages } = useSelector(state => state.residence);
    const { id } = useParams();
    const { centers } = useSelector(state => state.centers);

    useEffect(() => {
        dispatch(getOneTrainee(id));
    }, [id, dispatch]);

    useEffect(() => {
        setUserResidence({
            province: trainee.province,
            district: trainee.district,
            sector: trainee.sector,
            cell: trainee.cell,
            village: trainee.village,
        });
        setResidentId(trainee.residenceLocationId);
        setCenterId(trainee.trainingcenterId);
    }, [trainee]);

    useEffect(() => {
        let theTimout;
        if (updateSuccess) {
            theTimout = setTimeout(() => {
                history.push('/cpanel/groups/overview');
            }, 6000);
        }
        dispatch(getOneTrainee(id));
        clearTimeout(theTimout);
    }, [updateSuccess, dispatch]);

    useEffect(() => {
        if (trainee['province']) dispatch(getLocationByProvince(trainee['province']));
        if (trainee['district']) dispatch(getLocationByDistrict(trainee['district']));
        if (trainee['sector']) {
            dispatch(getLocationBySector(trainee['sector']));
            let ids = [];
            let sectors = residence.filter(res => res.sector === trainee['sector']);
            sectors.forEach(c => ids.push(c.id));
            setResidentIds([...new Set(ids)]);
        }
        if (trainee['cell']) {
            dispatch(getLocationByCell(trainee['sector'], trainee['cell']));
        }
    }, [trainee, dispatch]);

    useEffect(() => {
        dispatch(getCenterLocation(residentIds));
    }, [residentIds, dispatch]);

    const onProvinceChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, province: value });
        dispatch(getLocationByProvince(value));
    };
    const onDistrictChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, district: value });
        dispatch(getLocationByDistrict(value));
    };
    const onSectorChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, sector: value });
        dispatch(getLocationBySector(value));
        let ids = [];
        let sectors = residence.filter(res => res.sector === value);
        sectors.forEach(c => ids.push(c.id));
        setResidentIds([...new Set(ids)]);
    };
    const onCellChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, cell: value });
        dispatch(getLocationByCell(userResidence.sector, value));
    };
    const onVillageChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, village: value });
        let village = residence.filter(res => res.village === value);
        setResidentId(village[0].id);
        dispatch(getCenterLocation(residentIds));
    };

    const onCenterChange = e => {
        const { value } = e.target;
        let center = centers.filter(center => center.centerName === value);
        setCenterId(center[0].id);
    };

    const { values, handleSubmit, errors, handleChange, setFieldValue, touched } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            if (checkDates(values.dob, 12)) {
                dispatch(
                    updateTraineeData(id, {
                        ...values,
                        residenceLocationId: residentId + '',
                        trainingcenterId: centerId,
                        ...userResidence,
                    }),
                );
            } else {
                toast.error('Age range should be between 12 and 100 years old');
            }
        },
    });
    useEffect(() => {
        setUserResidence({
            province: trainee.province,
            district: trainee.district,
            sector: trainee.sector,
            cell: trainee.cell,
            village: trainee.village,
        });
        setResidentId(trainee.residenceLocationId);
        setCenterId(trainee.trainingcenterId);
        let keys = Object.keys(trainee);
        let values = Object.values(trainee);
        keys.forEach((key, i) => setFieldValue(`${key}`, values[i]));
        setFieldValue('dob', trainee?.dob?.split('T')[0]);
    }, [trainee]);
    return (
        <Layout>
            <div className="body-content  pt-5">
                {trainee && (
                    <Container>
                        <span className="text-title mb-2">Update Trainee Information</span>
                        <Row className="mt-3">
                            <Col md={12}>
                                <div className="box">
                                    <Form>
                                        <p className="text-medium mb-3">Important information</p>
                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                {/* <FormGroup>
                          <InputGroup>
                            <FormControl
                              placeholder="National Identity"
                              value={values.nationalIdentity}
                              name="nationalIdentity"
                              onChange={(event) => {
                                let sanitized = matchDigit(
                                  String(event.target.value)
                                );

                                sanitized = sanitized.substring(0, 16);

                                setFieldValue("nationalIdentity", sanitized);
                              }}
                            />

                            {touched.nationalIdentity && errors.nationalIdentity && (
                              <Form.Control.Feedback
                                className="d-block"
                                type="invalid"
                              >
                                {errors.nationalIdentity}
                              </Form.Control.Feedback>
                            )}
                          </InputGroup>
                        </FormGroup> */}
                                            </Col>

                                            {/* <Col className="col-12 col-md-6 col-lg-3">
                      <FormGroup>
                        <Button className="px-5">{`${t(`Shakisha`)}`}</Button>
                      </FormGroup>
                    </Col> */}
                                        </Row>

                                        <Row className="mt-3">
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl placeholder="First name" value={values.firstName} name="firstName" onChange={handleChange} />

                                                        {touched.firstName && errors.firstName && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.firstName}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl placeholder="Second name" value={values.lastName} name="lastName" onChange={handleChange} />
                                                    </InputGroup>

                                                    {touched.lastName && errors.lastName && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.lastName}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <FormControl value={values.middleName} name="middleName" onChange={handleChange} placeholder="Middle Name" />
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <Form.Control as="select" className="custom-select" name="gender" value={values.gender} onChange={handleChange}>
                                                            <option value="" disabled selected>
                                                                Gender
                                                            </option>
                                                            <option>Male</option>
                                                            <option>Female</option>
                                                        </Form.Control>

                                                        {touched.gender && errors.gender && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.gender}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl placeholder="Date of birth" name="dob" value={values.dob} onChange={handleChange} type="date" />

                                                        {touched.dob && errors.dob && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.dob}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>

                                                    <Form.Text className="text-muted">Date of birth</Form.Text>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl
                                                            placeholder="Phone"
                                                            name="phone"
                                                            value={values.phone}
                                                            onChange={event => {
                                                                let sanitized = matchDigit(String(event.target.value));

                                                                sanitized = sanitized.substring(0, 10);

                                                                setFieldValue('phone', sanitized);
                                                            }}
                                                        />

                                                        {touched.phone && errors.phone && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.phone}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl placeholder="Email" name="email" value={values.email} onChange={handleChange} type="email" />

                                                        {touched.email && errors.email && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.email}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div className="my-4">
                                            {' '}
                                            <span className="text-medium mb-3 my-4">Residance location</span>
                                        </div>

                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="province" onChange={onProvinceChange} value={userResidence.province}>
                                                        <option value="" selected disabled>
                                                            {' '}
                                                            Province
                                                        </option>
                                                        <option>Kigali City</option>
                                                        <option>Western Province</option>
                                                        <option>Southern Province</option>
                                                        <option>Northern Province</option>
                                                        <option>Eastern Province</option>
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="district" onChange={onDistrictChange} value={userResidence.district}>
                                                        <option disabled selected>
                                                            District
                                                        </option>
                                                        {districts &&
                                                            districts.map((district, index) => (
                                                                <option key={index} selected={district === trainee['district']} value={district}>
                                                                    {district}
                                                                </option>
                                                            ))}
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="sector" onChange={onSectorChange}>
                                                        <option value="" selected disabled>
                                                            {' '}
                                                            Sector
                                                        </option>
                                                        {sectors &&
                                                            sectors.map((sector, index) => (
                                                                <option key={index} selected={sector === trainee['sector']} value={sector}>
                                                                    {sector}
                                                                </option>
                                                            ))}
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="cell" onChange={onCellChange}>
                                                        <option value="" selected disabled>
                                                            {' '}
                                                            Cell
                                                        </option>
                                                        {cells &&
                                                            cells.map((cell, index) => (
                                                                <option key={index} selected={cell === trainee['cell']} value={cell}>
                                                                    {cell}
                                                                </option>
                                                            ))}
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="village" onChange={onVillageChange}>
                                                        <option value="" selected disabled>
                                                            {' '}
                                                            Village
                                                        </option>
                                                        {villages &&
                                                            villages.map((village, index) => (
                                                                <option key={index} selected={village === trainee['village']} value={village}>
                                                                    {village}
                                                                </option>
                                                            ))}
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="center" onChange={onCenterChange}>
                                                        <option value="">Where you will be trained</option>
                                                        {centers &&
                                                            centers.map((center, index) => (
                                                                <option key={index} selected={trainee['trainingcenterId'] === center.id}>
                                                                    {center.centerName}
                                                                </option>
                                                            ))}
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Button
                                            type="submit"
                                            onClick={handleSubmit}
                                            // to={Routes.register.verify}
                                            className="btn btn-primary px-5 mt-4"
                                        >
                                            {loading ? <Spinner size="sm" animation="border" variant="light" /> : 'Update'}
                                        </Button>
                                        <ToastContainer />
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        </Layout>
    );
};

export default withNamespaces()(UpdateTrainee);
