import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup, Marker } from 'react-simple-maps';
import locations from './rwanda-province.json';
import ReactTooltip from 'react-tooltip';

import './index.css';
import axios from 'axios';

const sanitizeData = centers => {
    if (Array.isArray(centers)) {
        return centers.map(center => {
            if (center?.longitude && center?.latitude) {
                return {
                    id: center.id,
                    sap: center.centerName,
                    coordinates: [parseFloat(center.longitude), parseFloat(center.latitude)],
                    provice: center?.province || '',
                    district: center?.district || '',
                    sector: center.sector || '',
                    cell: center.cell || '',
                    ...center.CenterInLocation,
                };
            }
        });
    }
};

const mapColors = {
    'City of Kigali': '#286D8E',
    'Southern Province': '#578EA7',
    'Northern Province': '#709EB4',
    'Eastern Province': '#87AEC0',
    'Western Province': '#407E9B',
};

const SapLocations = () => {
    const [position, setPosition] = useState({
        coordinates: [29.9, -1.9],
        zoom: 1,
    });
    const [content, setContent] = useState({});

    const [centers, setCenters] = useState([]);

    function handleZoomIn() {
        if (position.zoom >= 64) return;
        setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));

        console.log('Zoom level : ', position.zoom);
    }

    function handleZoomOut() {
        if (position.zoom <= 1) return;
        setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }));
    }

    function handleMoveEnd(position) {
        setPosition(position);
    }

    const getAllCenters = () => {
        const searchUrl = `${process.env.REACT_APP_BACKEND}/api/centers/public`;
        axios
            .get(searchUrl)
            .then(res => {
                if (res?.status && res?.status === 200 && res?.data) {
                    setCenters(sanitizeData(res.data.data));
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        getAllCenters();
    }, []);

    if (!Boolean(centers.length)) {
        return null;
    }

    return (
        <div>
            <div>
                <ComposableMap
                    projection="geoTransverseMercator"
                    height={1100}
                    width={1200}
                    projectionConfig={{
                        center: [29.9, -2.3],
                        scale: 26000,
                    }}
                >
                    <ZoomableGroup
                        zoom={position.zoom}
                        onMoveEnd={handleMoveEnd}
                        center={position.coordinates}
                        onZoomStart={(event, position) => {
                            console.log({ position, event });
                        }}
                        maxZoom={200}
                    >
                        <Geographies geography={locations}>
                            {({ geographies }) =>
                                geographies.map(geo => {
                                    const province = geo.properties.Province;
                                    return <Geography key={geo.rsmKey} geography={geo} fill={mapColors[province]} />;
                                })
                            }
                        </Geographies>

                        {Array.isArray(centers) &&
                            centers.map(({ id, province, district, sector, sap, coordinates }) => (
                                <Marker
                                    data-tip=""
                                    key={id}
                                    coordinates={coordinates}
                                    onMouseEnter={() => {
                                        setContent({ province, district, sector, sap });
                                    }}
                                    onMouseLeave={() => {
                                        setContent({});
                                    }}
                                >
                                    <g fill="#cf1b33" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="translate(-12, -24)">
                                        <circle cx="12" cy="10" r="3" />
                                        <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                    </g>
                                </Marker>
                            ))}
                    </ZoomableGroup>
                </ComposableMap>

                <div className="controls">
                    <div className="btn-zoom my-1" onClick={handleZoomIn}>
                        <span className="text-extra-small">
                            {' '}
                            <i className="fas fa-plus"></i>
                        </span>
                    </div>
                    <div className="btn-zoom my-1" onClick={handleZoomOut}>
                        <span className="text-extra-small">
                            {' '}
                            <i className="fas fa-minus"></i>
                        </span>
                    </div>
                </div>
            </div>

            <ReactTooltip backgroundColor="#eef1f8" textColor="#000f16" className="tooltip" style={{ opacity: 0 }}>
                <div>
                    <span className="text-small py-3">{content?.province}</span>
                </div>
                <div>
                    <span className="text-small py-3">{content?.district ? ` ${content?.district} District` : ''} </span>
                </div>

                <div>
                    <span className="text-small py-3">{content?.sector} Sector </span>
                </div>

                <div>
                    <b className="text-small py-3">{content?.sap} SAP </b>
                </div>
            </ReactTooltip>
        </div>
    );
};

export default SapLocations;
