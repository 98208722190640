import React, { useState } from "react";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import "./index.css";

import { useFormik } from "formik";

// const groups = [
//   {
//     id: "070987",
//     name: "Group 1",
//   },

//   {
//     id: "070987",
//     name: "Group 2",
//   },

//   {
//     id: "070987",
//     name: "Group 3",
//   },
// ];

function TransferTraineeToGroup({ open, onClose, groups, submit, id }) {
  const [groupId, setGroupId] = useState("");
  
  const handleSubmit = e => {
    e.preventDefault();
    return submit(id, groupId)
  }
  return (
    <div>
      <Container>
        <Modal
          show={open}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="custom-modal"
          className="custom-modal"
          animation={false}
          onHide={onClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transfer Trainee</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Row className="py-1">
                <Col>
                  {" "}
                  <Form.Group>
                    <Form.Control
                      as="select"
                      className="custom-select"
                      name="cell"
                      // value={values.cell}
                      onChange={e=>setGroupId(e.target.value)}
                    >
                      <option value="" selected disabled>
                        Group
                      </option>

                      {groups.map((group) => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default TransferTraineeToGroup;
