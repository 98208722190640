export const REQUEST_PARTNER_CREATION = 'REQUEST_PARTNER_CREATION';
export const PARTNER_CREATION_SUCCESS = 'PARTNER_CREATION_SUCCESS';
export const PARTNER_CREATION_FAILED = 'PARTNER_CREATION_FAILIED';

export const REQUEST_GET_PARTNERS = 'REQUEST_GET_PARTNERS';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_FAILED = "GET_PARTNERS_FAILED";

export const  GET_PARTNER_ATTENDENCE = "GET_PARTNER_ATTENDENCE";
export const REQUEST_GET_PARTNERS_ATTENDENCE = "REQUEST_GET_PARTNERS_ATTENDENCE";
export const GET_PARTNER_ATTENDENCE_FAILED = "GET_PARTNER_ATTENDENCE_FAILED";