/* eslint-disable import/no-anonymous-default-export */
import {
  REQUEST_PARTNER_CREATION,
  PARTNER_CREATION_SUCCESS,
  PARTNER_CREATION_FAILED,
  REQUEST_GET_PARTNERS,
  GET_PARTNERS_SUCCESS,
  GET_PARTNERS_FAILED,
  GET_PARTNER_ATTENDENCE,
  REQUEST_GET_PARTNERS_ATTENDENCE,
  GET_PARTNER_ATTENDENCE_FAILED
} from '../types';

const initialState = {
  partners: [],
  attendences: [],
  partner: null,
  loading: null,
  success: false,
  message: "",
  error: ""
};

export default (state = initialState, action) => {
  switch (action.type)
  {
    case REQUEST_PARTNER_CREATION:
      return {
        ...state,
        loading: true,
        success: false,
      }
  
    case PARTNER_CREATION_SUCCESS:
      return {
        ...state,
        message: action.payload,
        success: true,
        loading: null,
        error: null
      }
    case PARTNER_CREATION_FAILED:
      return {
        ...state,
        error: action.payload,
        success: false,
        loading: null
      }
    case REQUEST_GET_PARTNERS:
      return {
        ...state,
        loading: true,
        partners: []
      }
    case GET_PARTNERS_SUCCESS:
      return {
        ...state,
        success: true,
        loading: null,
        error: null,
        partners: action.payload.data
      }
    case GET_PARTNERS_FAILED:
      return {
        ...state,
        error: action.payload,
        success: false,
        loading: null
      }
    case REQUEST_GET_PARTNERS_ATTENDENCE:
      return {
        ...state,
        attendenceLoading: true,
      }
    case GET_PARTNER_ATTENDENCE:
      return {
        ...state,
        attendenceLoading: null,
        attendences: action.payload,
      }
    case GET_PARTNER_ATTENDENCE_FAILED:
      return {
        ...state,
        attendenceLoading: null,
        error: action.payload
      }
    default:
      return state;
  }
}