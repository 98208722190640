import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { Row, Col, Form, Dropdown, Card, Button } from 'react-bootstrap';

import Spinner from '../../../../components/spinner/Spinner';

import { DATable } from '../../components/ambassadors/da-table';
import { Link, useHistory } from 'react-router-dom';
import routes from '../../../../routes/names';
import { getDigitalAmbs, makeLeader, updateUserData } from '../../../../redux/actions';

import CpanelLayout from '../../../../layouts/AdminLayout';
import { csvToJson, emptifyFalseValues } from '../../../../helpers';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { REQUEST_GET_USERS } from '../../../../redux/types';
import moment from 'moment';
import { useFileDownload } from '../../../../hooks';
import './index.css';

const getYearsInProgress = () => {
    const currentYear = moment().year();

    const startingYear = 2020;
    let progressYears = Math.abs(currentYear - startingYear);

    let years = [startingYear];

    new Array(progressYears).fill(startingYear).forEach((year, index) => {
        console.log(index);
        years.push(year + index + 1);
    });

    return years;
};

const sanitizeData = users => {
    return users.map((user, index) => ({
        ...user,
        registrationYear: moment(user.createdAt),
    }));
};

function PastRecords() {
    const dispatch = useDispatch();
    const fileInputRef = React.useRef();
    const routeHistory = useHistory();

    const { downloading, onDownload } = useFileDownload('/assets/templates/dap_ambassadors.xlsx', 'dap_ambassadors_template.xlsx');

    const removeCenterInvalidData = data => {
        /* Remove invalid data and duplicates */
        return [...new Set(data.filter(el => Boolean(el.centerName) && Boolean(el.centerIdentity)))];
    };
    const removeInvalidData = data => {
        /* Remove invalid data and duplicates */
        return [...new Set(data.filter(el => Boolean(el.firstName) && Boolean(el.lastName)))];
    };

    const { success, message, error, userLoading, users } = useSelector(state => state.users);
    const { roles: LoggedInUserRoles } = useSelector(state => state.auth);

    let sanitizedDataset = sanitizeData(users);
    const [dataset, setDataset] = useState(sanitizedDataset);

    const filterByYear = criteria => {
        if (criteria === 'all') {
            return setDataset(sanitizedDataset);
        }
        const newDataset = sanitizedDataset.filter(el => String(el.registrationYear).includes(criteria));
        return setDataset([...newDataset]);
    };

    useEffect(() => {
        dispatch(getDigitalAmbs());
    }, [dispatch, success]);

    useEffect(() => {
        sanitizedDataset = sanitizeData(users);
        setDataset(sanitizedDataset);
    }, [users]);

    const dispatchMakeLeader = (id, role) => {
        dispatch(makeLeader(id, role));
    };

    const dispatchUpdate = (id, data) => {
        dispatch(updateUserData(id, data, routeHistory));
    };

    const uploadDASpreadsheet = async e => {
        e.stopPropagation();
        e.preventDefault();
        let file = e.target.files[0];
        await readFile(file, "ambassadors");
    };

    const uploadCenterSpreadsheet = async e => {
        e.stopPropagation();
        e.preventDefault();
        let file = e.target.files[0];
        await readFile(file, "centers");
    };
    const uploadTraineeSpreadsheet = async e => {
        e.stopPropagation();
        e.preventDefault();
        let file = e.target.files[0];
        await readFile(file, "trainees");
    };

    const readFile = async (rawData, separator) => {
        dispatch({ type: REQUEST_GET_USERS });
        let file = rawData;

        const reader = new FileReader();
        reader.onload = async evt => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */

            if(separator === "centers"){
                const jsonData = removeCenterInvalidData(csvToJson(data));

                /** Note: Backend team should use this data as payload  */
                const dapData = { list: jsonData };
                try {
                    const response = await Axios.post(`${process.env.REACT_APP_BACKEND}/api/records/centers`, dapData)
                    if (response.status === 201) {
                        toast.success(response?.data?.message);
                    }
                } catch (error) {
                    console.error(error);
                }
                // dispatch(getDigitalAmbs());
                // setTimeout(() => {
                //     window.location.reload();
                // }, 1000);
                console.log("===subimitted data ====>",dapData)
            };
            if(separator === "ambassadors"){
                const jsonData = removeInvalidData(csvToJson(data));

                /** Note: Backend team should use this data as payload  */
                const dapData = { list: jsonData };
                try {
                    const response = await Axios.post(`${process.env.REACT_APP_BACKEND}/api/records/ambassadors`, dapData);
                    if (response.status === 201) {
                        toast.success(response?.data?.message);
                    }
                } catch (error) {
                    console.error(error);
                }
                // dispatch(getDigitalAmbs());
                // setTimeout(() => {
                //     window.location.reload();
                // }, 1000);
                console.log("===subimitted data ====>",dapData)
            }
            if(separator === "trainees"){
                const jsonData = removeInvalidData(csvToJson(data));

                /** Note: Backend team should use this data as payload  */
                const dapData = { list: jsonData };
                try {
                    const response = await Axios.post(`${process.env.REACT_APP_BACKEND}/api/records/trainees`, dapData)
                    if (response.status === 201) {
                        toast.success(response?.data?.message);
                    }
                } catch (error) {
                    console.error(error);
                }
                // dispatch(getDigitalAmbs());
                // setTimeout(() => {
                //     window.location.reload();
                // }, 1000);
                console.log("===subimitted data ====>",dapData)
            }
        };
        reader.readAsBinaryString(file);
    };

    const isAllowed = LoggedInUserRoles && LoggedInUserRoles.some(role => role === 'super_admin' || role === 'progr-manager');

    return (
        <CpanelLayout>
            <Row>
                <Col className="col-12 col-md-6">
                    <div>
                        <span className="text-title">Past Records</span>
                    </div>
                </Col>

                <Col className=" col-12 col-md-6">
                    {/* <div className="d-flex align-items-baseline justify-content-end">
                        <Form.Group>
                            <Form.Control
                                as="select"
                                className=" border-1 border-radius-3 custom-select text-small"
                                style={{ borderRadius: 10 }}
                                onChange={({ target: { value } }) => {
                                    filterByYear(value);
                                }}
                            >
                                <option value="all" selected>
                                    All
                                </option>
                                {getYearsInProgress().map(year => (
                                    <option key={year} value={year}>
                                        {' '}
                                        {year}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        {isAllowed && (
                            <Dropdown drop="down">
                                <Dropdown.Toggle variant="light" className="text-dark text-small">
                                    <span>
                                        {' '}
                                        <i className="fa fa-user-plus text-primary" aria-hidden="true">
                                            {' '}
                                        </i>
                                        &nbsp; Add a DA
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <Link to={routes.dashboard.ambassadors.create} className="text-dark">
                                            <span>
                                                {' '}
                                                <i className="fas fa-plus-circle text-primary"></i>
                                                &nbsp; Add One
                                            </span>
                                        </Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        as="div"
                                        role="button"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            fileInputRef.current?.click();
                                        }}
                                    >
                                        <input type="file" id="file" ref={fileInputRef} onChange={uploadSpreadsheet} accept=".xlsx, .xls, .csv" multiple={false} />

                                        <span>
                                            <i className="fas fa-file-upload text-primary"></i>
                                            &nbsp; Import from file
                                        </span>
                                    </Dropdown.Item>

                                    <Dropdown.Item as="button" role="button" disabled={downloading} onClick={onDownload}>
                                        <span>
                                            <i className="fas fa-file-export text-primary"></i>
                                            &nbsp; {downloading ? 'Downloading' : 'Download Template'}
                                        </span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div> */}
                </Col>
            </Row>

            <div className="pt-5">
                <Row>
                    <Col className="col-12">
                        <div >
                            <Row>
                                <Col className="col-lg-4 col-md-6 col-sm-12">
                                    <label style={{backgroundColor: "#185B8F"}} className="button" variant="primary" size="lg">
                                        Import DAS
                                        <input type="file" id="file" ref={fileInputRef} onChange={uploadDASpreadsheet} accept=".xlsx, .xls, .csv" multiple={false} />
                                    </label>
                                </Col>
                                <Col className="col-lg-4 col-md-6 col-sm-12">
                                    <label style={{backgroundColor: "#1083DB"}} className="button" variant="secondary" size="lg">
                                        Import Training centers
                                        <input type="file" id="file" ref={fileInputRef} onChange={uploadCenterSpreadsheet} accept=".xlsx, .xls, .csv" multiple={false} />
                                    </label>
                                </Col>
                                <Col className="col-lg-4 col-md-6 col-sm-12">
                                    <label style={{backgroundColor: "#8D8F03"}} className="button" variant="secondary" size="lg">
                                        Import Trainees
                                        <input type="file" id="file" ref={fileInputRef} onChange={uploadTraineeSpreadsheet} accept=".xlsx, .xls, .csv" multiple={false} />
                                    </label>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </CpanelLayout>
    );
}

export default PastRecords;
