import React from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import "./index.css";

export default function SearchBox({ toggled, onChange, placeholder }) {
  return (
    <div>
      <Form inline>
        <InputGroup>
          {toggled && (
            <FormControl
              type="text"
              placeholder={placeholder || "Search"}
              className="mr-sm-2 border-top-0 border-right-0 border-left-0"
              onChange={(event) => onChange && onChange(event.target.value)}
            />
          )}

          <InputGroup.Append>
            <button
              type="button"
              className="btn btn-light search-btn"
              onClick={() => {}}
            >
              <i className="fas fa-search"></i>
            </button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    </div>
  );
}
