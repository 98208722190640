import React from 'react';
import './index.css';

import { Row, Col, Button } from 'react-bootstrap';
function Course({ id, title, description, link, duration, onViewCourseDetails, t }) {
    return (
        <div className="course-container p-4 dap-bg-secondary mb-4">
            <div className="course-content">
                <span className="text-normal text-bold">
                    {id}. {title}
                </span>

                <div className="py-3">
                    {' '}
                    <span className="text-small">{description}</span>
                </div>
            </div>

            <div className="d-flex align-items-end justify-content-end py-1 ">
                <span className="text-extra-small ">
                    {`${t(`Iminota`)}`} {duration}
                </span>
            </div>

            <div className="footer">
                <Row>
                    <Col>
                        <Button onClick={onViewCourseDetails} className="btn btn-light bg-grey  border-0">
                            {`${t(`Andi makuru`)}`}
                        </Button>
                    </Col>

                    <Col>
                        <Button className="btn btn-light bg-grey border-0" as="a" href={link} target="_blank" rel="noopener noreferrer">
                            {`${t(`Soma imfashanyigisho`)}`}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Course;
