import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CpanelLayout from '../../../../layouts/AdminLayout';
import { Dropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { AddModule, EditModule } from '../../components/curriculum';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { getAllCourses, deleteCourse } from '../../../../redux/actions';
import * as XLSX from 'xlsx';
import { csvToJson, emptifyFalseValues } from '../../../../helpers';
import Spinner from '../../../../components/spinner/Spinner';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { useFileDownload } from '../../../../hooks';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const sanitizeData = courses =>
    courses.map((course, index) => ({
        ...emptifyFalseValues(course),
        duration: course.duration + ' minutes',
        index: index + 1,
    }));

const Curriculum = () => {
    const dispatch = useDispatch();

    const removeInvalidData = data => {
        /* Remove invalid data and duplicates */

        return [...new Set(data.filter(el => Boolean(el.title) && Boolean(el.duration)))];
    };

    const [fileJsonData, setfileJsonData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fileInputRef = React.useRef();
    const { courses } = useSelector(state => state.courses);
    const { roles:LoggedInUserRoles } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(getAllCourses());
    }, [dispatch]);

    const [modals, setModals] = useState({ addModule: false, editModule: false });

    const [module, setModule] = useState({});

    const { downloading, onDownload } = useFileDownload('/assets/templates/dap_courses.xlsx', 'dap_courses_template.xlsx');

    const isAllowed = LoggedInUserRoles && LoggedInUserRoles.some((role)=>role==="super_admin" ||  role==="progr-manager")

    const openAddModule = () => setModals({ ...modals, addModule: true });
    const closeAddModule = () => setModals({ ...modals, addModule: false });

    const openEditModule = () => setModals({ ...modals, editModule: true });
    const closeEditModule = () => setModals({ ...modals, editModule: false });

    const renderDescription = (cell, row, rowIndex) => {
        return <span>{truncate(row.description, { length: 80 })}</span>;
    };

    const renderOptons = (cell, row) => {
        const removeCourse = () => dispatch(deleteCourse({ id: row.id }));
        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle variant="light" className="text-dark">
                        Actions &nbsp; <i className="fas fa-angle-down"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                setModule(row);
                                openEditModule();
                            }}
                        >
                            {' '}
                            <span className="text-small">
                                {' '}
                                <i className="fas fa-pencil-alt text-primary "></i> &nbsp; Edit Module
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={removeCourse}>
                            <span className="text-small">
                                {' '}
                                <i className="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                            </span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',

        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: 'All',
                value: courses.length,
            },
        ],
    };

    const columns = [
        {
            dataField: 'index',
            text: '#',

            filterValue: (cell, row, rowIndex) => rowIndex,
            csvExport: false,
            sort: true,
        },
        {
            dataField: 'courseIdentity',
            text: 'Module ID',
            sort: true,
            filterValue: (cell, row) => row.courseIdentity,
            csvExport: true,
        },
        {
            dataField: 'title',
            text: 'Title',
            filterValue: (cell, row) => row.title,
            csvExport: true,
        },
        { dataField: 'description', text: 'Description', csvExport: true, formatter: renderDescription },
        {
            dataField: 'duration',
            text: 'Duration',
            sort: true,
            csvExport: true,
        },
        { ...(isAllowed && { dataField: 'options', text: 'Options', formatter: renderOptons, headerClasses: 'pull-right', csvExport: false }) },
    ];

    const uploadSpreadsheet = async e => {
        e.stopPropagation();
        e.preventDefault();
        let file = e.target.files[0];
        await readFile(file);
    };

    const readFile = async rawData => {
        setIsLoading(true);
        let file = rawData;

        const reader = new FileReader();
        reader.onload = async evt => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            const jsonData = removeInvalidData(csvToJson(data));
            setfileJsonData(jsonData);

            /** Note: Backend team should use this data as payload  */
            const dapCourses = { coursesList: jsonData };
            try {
                const response = await Axios.post(`${process.env.REACT_APP_BACKEND}/api/courses/imported`, dapCourses);
                if (response.status === 201) {
                    toast.success(response.data.message);
                }
            } catch (error) {
                console.error(error);
            }
            dispatch(getAllCourses());
            setIsLoading(false);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        };
        reader.readAsBinaryString(file);
    };

    return (
        <CpanelLayout>
            <main>
                <div className="d-flex justify-content-between align-items-center">
                    <span className="text-title">Curriculum Management</span>
                    <div className="d-flex align-items-center text-primary">
                        {LoggedInUserRoles.includes('super_admin') && <Dropdown drop="down">
                            <Dropdown.Toggle variant="light" className="text-dark text-small">
                                <span>
                                    {' '}
                                    <i className="fas fa-plus text-primary" aria-hidden="true">
                                        {' '}
                                    </i>
                                    &nbsp; Add Course
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={openAddModule}>
                                    {' '}
                                    <i className="fas fa-plus text-primary"></i>&nbsp;
                                    <span>Add one course</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    as="div"
                                    role="button"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        fileInputRef.current?.click();
                                    }}
                                >
                                    <input type="file" id="file" ref={fileInputRef} onChange={uploadSpreadsheet} accept=".xlsx, .xls, .csv" multiple={false} />

                                    <span>
                                        <i class="fas fa-file-upload text-primary"></i>
                                        &nbsp; Import from file
                                    </span>
                                </Dropdown.Item>

                                <Dropdown.Item as="button" role="button" disabled={downloading} onClick={onDownload}>
                                    <span>
                                        <i className="fas fa-file-export text-primary"></i>
                                        &nbsp; {downloading ? 'Downloading' : 'Download Template'}
                                    </span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                </div>
            </main>

            <div>
                <ToolkitProvider
                    keyField="courseIdentity"
                    data={sanitizeData([...fileJsonData, ...courses])}
                    columns={columns}
                    search
                    exportCSV={{
                        fileName: `DAP-AMBASSADORS-${new Date().toLocaleTimeString()}.csv`,
                        separator: ',',
                        ignoreHeader: false,
                        noAutoBOM: false,
                    }}
                >
                    {props => (
                        <div className="py-4">
                            <span className="text-medium">Offered modules</span>
                            <div className="d-flex align-items-center justify-content-between pt-4">
                                <div>
                                    <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                        <i class="fas fa-file-excel text-small"></i> &nbsp;Export to CSV
                                    </ExportCSVButton>
                                </div>
                                <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0 text-small" placeholder="Search Modules ..." />
                            </div>

                            <div className="pt-3">{isLoading ? <Spinner /> : <BootstrapTable classes="data-table" bordered={false} {...props.baseProps} options={options} pagination={paginationFactory(options)} bootstrap4 />}</div>
                        </div>
                    )}
                </ToolkitProvider>
            </div>

            <AddModule open={modals.addModule} onClose={closeAddModule} />

            {!isEmpty(module) && (
                <EditModule
                    open={modals.editModule}
                    onClose={() => {
                        setModule({});
                        closeEditModule();
                    }}
                    module={module}
                />
            )}
        </CpanelLayout>
    );
};

export default Curriculum;
