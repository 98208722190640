import React from 'react';
import { Text, View, StyleSheet, Image, Font, } from '@react-pdf/renderer';
import Header from "../../assets/images/certificate-header.png";
import Body from "../../assets/images/certificate-body.png";
import Footer from "../../assets/images/certificate-footer.png";
//----------------------
import HeaderNew from "../../assets/images/headerNew.png";
import FooterNew from "../../assets/images/footerNew.png";
import BodyNew from "../../assets/images/background_new_certificate_2022.png";
const FrontPage = ({ firstName, lastName, createdAt, traineeId_, trainee }) => {
  //Font.register({ family: 'Montserrat', src: "../../assets/fonts/Montserrat-Regular.ttf" });
  //Font.register({ family: 'Century', src: "../../assets/fonts/CENSCBK.TTF" });
  return (
    <View style={styles.container}>


      <Image style={styles.image} src={BodyNew} />

      <View style={styles.positionningTheNameContainer}>
        <Text style={styles.positionningTheName}>
          {" "}
          {firstName.toUpperCase()} {lastName}
        </Text>
      </View>
      <View style={styles.positionningTheNameContainer}>
        <Text style={styles.positionningTheTraineeLocationDistrict}>
          {" "}
          {trainee?.district}
        </Text>
        <Text style={styles.positionningTheTraineeLocationSector}>
          {" "}
          {trainee?.sector}
        </Text>
      </View>
      <View style={styles.certificationContainerNew}>
        <Text>{traineeId_}</Text>
      </View>
      <View style={styles.completionDateContainerNew}>
        <Text>{createdAt}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  imagePadding: {
    paddingTop: 90,
    objectFit: 'contain',
    with: "100%",
    height: "100%",
    border: 2,
    borderColor: "yellow"
  },
  container: {
    width: "98%",
    height: "98%",
  },
  image: {
    height: "130vh",
    width: "auto",



  },
  positionningTheName: {
    position: "absolute",
    color: "#135F83",
    top: 690,



  },
  positionningTheTraineeLocation: {
    position: "absolute",
    color: "#333333",
    top: 810,
  },
  positionningTheTraineeLocationDistrict: {
    position: "absolute",
    color: "#333333",
    top: 768,
    left: 220
  },
  positionningTheTraineeLocationSector: {
    position: "absolute",
    color: "#333333",
    top: 788,
    left: 180
  },
  positionningTheNameContainer: {
    paddingTop: 20,
    paddingBottom: 10,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: 200,
    top: -430,
    left: 300
  },
  traineNameContainer: {
    paddingTop: 20,
    paddingBottom: 10,
    alignItems: "center",
    justifyContent: "center",
  },

  dividerContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 4,
    paddingBottom: 10,
  },

  divider: {
    borderColor: "#ccc",
    borderWidth: 1,
    width: "65%",
  },

  traineName: {
    fontSize: 32,
    fontWeight: "regular",
    color: "#3c3737",
  },
  completionDateContainer: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "10%",
    paddingVertical: 15,
    position: "absolute"
  },
  completionDateContainerNew: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "10%",
    paddingVertical: 15,
    color: "#231F20",
    position: "absolute",
    top: 405,
    left: 455,

  },
  certificationContainerNew: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "10%",
    paddingVertical: 15,
    color: "#231F20",
    position: "fixed",
    top: -140,
    left: -510,

  },
});

export default FrontPage;