import Groups from "./groups/Groups";
import Cohorts from "./cohorts/Cohorts";
import PendingRegistrants from "./registrants/PendingRegistrants";
import AdmitRegistrants from "./registrants/AdmitRegistrants";
import Transferees from "./transferees/Transferees";
import AdmitTransferees from "./transferees/AdmitTransferees";
import AddGroup from "./groups/AddGroup";
import TraineesTable from "./trainees-table/TraineesTable";
import DaTraineesTable from "./trainees-table/DatraineesTable";
import GroupTrainees from "./groups/GroupTrainees";
import AddGroupTrainees from "./groups/AddGroupTrainees";

export {
  Groups,
  Cohorts,
  PendingRegistrants,
  Transferees,
  AdmitTransferees,
  AdmitRegistrants,
  AddGroup,
  TraineesTable,
  GroupTrainees,
  AddGroupTrainees,
  DaTraineesTable
};
