import React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './index.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import routes from '../../../../../../routes/names';
import { emptifyFalseValues } from '../../../../../../helpers';

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const sanitizeData = users => {
    return users.map((user, index) => ({
        ...emptifyFalseValues(user),
        names: user.firstName + ' ' + user.lastName + ' ' + user.middleName,
        status: user.isActive ? 'Active' : 'Inactive',
        index: index + 1,
        createdAt: user.createdAt ? moment(String(user.createdAt)).format('DD-MM-YYYY').toString() : 'N/A',
    }));
};

function DATables({ digitalAmbassadors, search, makeLeader, updateStatus, isAllowed }) {
    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const sanitizeRole = (arr, role) => {
        const mathings = [];
        arr.forEach(roles => {
            if (roles.roles.name === role) {
                mathings.push('T');
            } else {
                mathings.push('F');
            }
        });
        return mathings.includes('T');
    };

    const CustomTooltip = ({ children, role }) => {
        return (
            <OverlayTrigger key={role} placement="top" overlay={<Tooltip id="top">{role}</Tooltip>}>
                {children}
            </OverlayTrigger>
        );
    };

    const renderNames = (cell, row, rowIndex) => {
        console.log(row.createdAt);
        return (
            <span className="text-nowrap">
                {row.firstName} {row.middleName} {row.lastName}{' '}
                {sanitizeRole(row.userRoles, 'sector_DA_leader') && (
                    <CustomTooltip role="sector DA leader">
                        <i style={{ color: '#40A1E6' }} className="fas fa-star"></i>
                    </CustomTooltip>
                )}
                {sanitizeRole(row.userRoles, 'district_DA_leader') && (
                    <CustomTooltip role="district DA leader">
                        <i style={{ color: '#434BF0' }} className="fas fa-star"></i>
                    </CustomTooltip>
                )}
                {sanitizeRole(row.userRoles, 'province_DA_leader') && (
                    <CustomTooltip role="province DA leader">
                        <i style={{ color: '#9348D9' }} className="fas fa-star"></i>
                    </CustomTooltip>
                )}
                {sanitizeRole(row.userRoles, 'country_DA_leader') && (
                    <CustomTooltip role="country DA leader">
                        <i style={{ color: '#F043D7' }} className="fas fa-star"></i>
                    </CustomTooltip>
                )}
            </span>
        );
    };

    const renderOptons = (cell, row) => {
        const id = row.id;

        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle variant="light" className="text-dark">
                        Actions &nbsp; <i className="fas fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {isAllowed && (
                            <Dropdown.Item as="div">
                                <Link to={reverse(routes.dashboard.ambassadors.edit, { id })}>
                                    <div className="w-100">
                                        {' '}
                                        <i className="fas fa-pencil-alt text-primary "></i> &nbsp;Edit{' '}
                                    </div>
                                </Link>{' '}
                            </Dropdown.Item>
                        )}
                        <div className="pl-3">
                            {isAllowed && (
                                <Dropdown drop="left">
                                    <Dropdown.Toggle variant="light" className="text-dark text-small">
                                        <i className="fas fa-angle-left"></i> &nbsp; <i className="fas fa-sync text-primary"></i> &nbsp;Change Status
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item>
                                            <span onClick={() => updateStatus(row.id, { isActive: true })} className="text-small">
                                                Active
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                            <span onClick={() => updateStatus(row.id, { isActive: false })} className="text-small">
                                                {' '}
                                                Inactive
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                        {/* <Dropdown.Item>
                            <span className="text-small">
                                {' '}
                                <i className="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                            </span>
                        </Dropdown.Item> */}
                        <Dropdown.Item>
                            <Link to={`/cpanel/profile?user=${row.id}`}>
                                {' '}
                                <span className="text-small">
                                    {' '}
                                    <i className="fas fa-info-circle text-primary "></i> &nbsp; More details
                                </span>
                            </Link>
                        </Dropdown.Item>

                        {/* <Dropdown.Item>
                            {' '}
                            <span className="text-small" onClick={() => makeCoordinator(row.id)}>
                                {' '}
                                <i className="fas fa-star text-primary "></i> &nbsp; Make Cordinator
                            </span>
                        </Dropdown.Item> */}
                        <div className="pl-3">
                            <Dropdown drop="left">
                                <Dropdown.Toggle variant="light" className="text-dark text-small">
                                    <i className="fas fa-angle-left"></i> &nbsp; &nbsp;Make a leader for
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <span onClick={() => makeLeader(row.id, { role: 'sector_DA_leader' })} className="text-small">
                                            Sector
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <span onClick={() => makeLeader(row.id, { role: 'district_DA_leader' })} className="text-small">
                                            {' '}
                                            District
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <span onClick={() => makeLeader(row.id, { role: 'province_DA_leader' })} className="text-small">
                                            {' '}
                                            Province
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <span onClick={() => makeLeader(row.id, { role: 'country_DA_leader' })} className="text-small">
                                            {' '}
                                            Country
                                        </span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: 'All',
                value: digitalAmbassadors.length,
            },
        ],
    };
    const columns = [
        { dataField: 'index', text: '#', csvExport: false, sort: true },
        {
            dataField: 'names',
            text: 'Names',
            formatter: renderNames,
            filterValue: (cell, row) => row.firstName + row.lastName + row.middleName,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'userIdentity',
            text: 'DA_ID',
            sort: true,
            filterValue: (cell, row) => (row.userIdentity ? row.userIdentity : 'N/A'),
            csvExport: true,
        },

        {
            dataField: 'email',
            text: ' Email',
            filterValue: (cell, row) => row.email,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'phone',
            text: ' Phone',
            filterValue: (cell, row) => row.phone,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'province',
            text: 'Province',
            filterValue: (cell, row) => row.province,
            formatter: (cell, row) => <span className="text-nowrap">{row.province}</span>,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'district',
            text: 'District',
            filterValue: (cell, row) => row.district,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'sector',
            text: 'Sector',
            filterValue: (cell, row) => <span className="text-nowrap">{row.sector}</span>,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'cell',
            text: 'Cell',
            filterValue: (cell, row) => row.sector,
            formatter: (cell, row) => <span className="text-nowrap">{row.cell}</span>,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            filterValue: (cell, row) => row.status,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'gender',
            text: 'Gender',
            filterValue: (cell, row) => (row.gender ? row.gender : 'N/A'),
            csvExport: true,
        },
        {
            dataField: 'createdAt',
            text: 'Joined_Date',
            formatter: (cell, row) => (row.createdAt ? <span className="text-nowrap">{row.createdAt}</span> : 'N/A'),
            csvExport: true,
        },
        {
            dataField: 'options',
            text: 'Options',
            formatter: renderOptons,
            csvExport: false,
        },
    ];

    return (
        <div>
            {digitalAmbassadors.length ? (
                <ToolkitProvider
                    keyField="id"
                    data={sanitizeData(digitalAmbassadors)}
                    columns={columns}
                    search
                    exportCSV={{
                        fileName: `DAP-AMBASSADORS-${new Date().toLocaleTimeString()}.csv`,
                        separator: ',',
                        ignoreHeader: false,
                        noAutoBOM: false,
                    }}
                >
                    {props => (
                        <div>
                            {/* <div>
                                <Button className="btn btn-primary rounded">
                                    {' '}
                                    <i class="fas fa-angle-double-left"></i> Back
                                </Button>
                            </div> */}
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                        <i class="fas fa-file-excel text-small"></i> &nbsp;Export to CSV
                                    </ExportCSVButton>
                                </div>
                                {search && <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0" placeholder="Search DAs ..." />}
                            </div>

                            <div className="pt-3">
                                <BootstrapTable classes="data-table" bordered={false} {...props.baseProps} pagination={paginationFactory(options)} options={options} bootstrap4 />
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
            ) : (
                <div className="d-flex align-item-center justify-content-center h-100">
                    <span> There's no currently registered Digital ambassadors</span>
                </div>
            )}
        </div>
    );
}

export default DATables;
