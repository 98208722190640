/* eslint-disable import/no-anonymous-default-export */
import { REQUEST_GET_SERVICES, CREATE_SERVICE_START, CREATE_SERVICE_SUCCESS, CREATE_SESSION_FAILED, CREATE_SERVICE_FAILURE, UPDATE_SERVICE_START, UPDATE_SERVICE_SUCCESS, UPDATE_SERVICE_FAILURE } from '../types';

const initialState = {
    services: [],
    loading: null,
    message: '',
    error: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REQUEST_GET_SERVICES:
            return {
                ...state,
                services: action.payload,
            };

        case CREATE_SERVICE_START:
            return {
                ...state,
                loading: action.loading,
                error: action.error,
            };

        case CREATE_SERVICE_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                message: action.payload,
            };

        case CREATE_SERVICE_FAILURE:
            return {
                ...state,
                loading: action.loading,
                error: action.error,
            };

        // Update service

        case UPDATE_SERVICE_START:
            return {
                ...state,
                loading: action.loading,
                error: action.error,
            };

        case UPDATE_SERVICE_SUCCESS:
            return {
                ...state,
                loading: action.loading,
                message: action.payload,
            };

        case UPDATE_SERVICE_FAILURE:
            return {
                ...state,
                loading: action.loading,
                error: action.error,
            };
        default:
            return state; //or return initialState
    }
}
