export const REQUEST_CREATE_GROUP = "REQUEST_CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILED = "CREATE_GROUP_FAILED";

export const REQUEST_GET_GROUP = "REQUEST_GET_GROUP";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAILED = "GET_GROUP_FAILED";

export const REQUEST_GET_GROUPS = "REQUEST_GET_GROUPS";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED";

export const REQUEST_UPDATE_GROUP = "REQUEST_UPDATE_GROUP";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAILED = "UPDATE_GROUP_FAILED";

export const REQUEST_DELETE_GROUP = "REQUEST_DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILED = "DELETE_GROUP_FAILED";

export const REQUEST_GET_ONE_GROUP = "REQUEST_GET_ONE_GROUP";
export const GET_ONE_GROUP_SUCCESS = "GET_ONE_GROUP_SUCCESS";
export const GET_ONE_GROUP_FAILED = "GET_ONE_GROUP_FAILED";

export const REQUEST_ADD_GROUP_TRAINEE = "REQUEST_ADD_GROUP_TRAINEE";
export const ADD_GROUP_TRAINEE_SUCCESS = "ADD_GROUP_TRAINEE_SUCCESS";
export const ADD_GROUP_TRAINEE_FAILED = "UPDATE_GROUP_FAILED";
