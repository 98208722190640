import React, { useState, useEffect } from 'react';
import CpanelLayout from '../../../../layouts/AdminLayout';
import Check from '../../../../assets/images/check.svg';
import Cancel from '../../../../assets/images/cancel.svg';
import Home from '../../../../assets/images/home.svg';
import School from '../../../../assets/images/school.svg';
import Loading from '../../../../assets/images/loading.svg';
import Fail from '../../../../assets/images/Polygon 2.png';
import Success from '../../../../assets/images/Polygon 1.png';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Doughnut, Bar } from 'react-chartjs-2';
import { RegistrationMeans } from '../../../admin-dashboard/components/special-reports';
import jwtDecode from 'jwt-decode';
import { getAllCoTrainingCenter, getAllCoTraineee, getAllCoDAS, getAllPartnerTraineeRange, getAllTraineesByAgeChart, getTraineesAgeRangeByDa } from '../../../../redux/actions';
import axios from 'axios';

const CoReports = () => {
    const [show, setShow] = useState(1);

    const trainingCenter = useSelector(state => state.chart.coTrainingCenter);

    const allTrainees = useSelector(state => state.chart.coTrainee);
    const allTraineesStatus = useSelector(state => state.chart.coTrainee);

    const allTraineesActive = allTrainees.filter(value => value.status.toLowerCase() === 'active').length;

    const traineeByAge = useSelector(state => state.chart.coRangeTrainee);

    // const ambsadors = useSelector(state => state.chart.coAmbasadors);

    const ambsadors = useSelector(state => state.chart.coDAS);
    const coRangeTrainee = useSelector(state => state.chart.coRangeTrainee);

    const countDaActive = ambsadors.filter(value => value.isActive === true).length;
    const countDaInactive = ambsadors.filter(value => value.isActive === false).length;

    const DaCountMale = ambsadors.filter(value => value.gender.toLowerCase() === 'male').length;
    const DaCountFemale = ambsadors.filter(value => value.gender.toLowerCase() === 'female').length;

    const diviceCountDaGender = countDaActive === 0 ? 1 : countDaActive;

    const DaGender = { male: (DaCountMale * 100) / diviceCountDaGender, female: (DaCountFemale * 100) / diviceCountDaGender };

    const DaGenderChart = {
        datasets: [
            {
                label: '# of Votes',
                data: [Number(DaGender?.female).toFixed(2), Number(DaGender?.male).toFixed(2)],
                backgroundColor: ['#CF1B33', '#078ECE'],
                borderWidth: 1,
            },
        ],
    };

    const divideCountPlatform = ambsadors.length === 0 ? 1 : ambsadors.length;

    const DaCountPlatForm = {
        mobile: ((ambsadors.filter(value => value.isActive === true && value.createFrom.toLowerCase() === 'mobile').length * 100) / divideCountPlatform).toFixed(2),
        web: ((ambsadors.filter(value => value.isActive === true && value.createFrom.toLowerCase() === 'web').length * 100) / divideCountPlatform).toFixed(2),
        ussd: ((ambsadors.filter(value => value.isActive === true && value.createFrom.toLowerCase() === 'ussd').length * 100) / divideCountPlatform).toFixed(2),
    };

    const DaPlatFormUsed = {
        datasets: [
            {
                label: '# of Votes',
                data: [DaCountPlatForm.mobile, DaCountPlatForm.web, DaCountPlatForm.ussd],
                backgroundColor: ['#CF1B33', '#078ECE', '#40A55B'],
                borderWidth: 1,
            },
        ],
    };

    const countTraineesThisWeek = allTrainees.filter(value => value.status.toLowerCase() === 'active' && moment(value.createdAt).isSame(moment(), 'week')).length;

    const countTraineesAllDropout = allTraineesStatus.filter(value => value.status.toLowerCase() === 'dropout').length;
    const countTraineesDropoutThisWeek = allTraineesStatus.filter(value => value.status.toLowerCase() === 'dropout' && moment(value.createdAt).isSame(moment(), 'week')).length;

    const countTraineesGraduate = allTrainees.filter(value => value.attendenceCount >= 9).length;
    const countTraineesGraduateThisWeek = allTrainees.filter(value => value.attendenceCount >= 9 && moment(value.createdAt).isSame(moment(), 'week')).length;

    const countTraineesPending = allTraineesStatus.filter(value => value.status === 'pending').length;
    const countTraineesPendingThisWeek = allTraineesStatus.filter(value => value.status === 'pending' && moment(value.createdAt).isSame(moment(), 'week')).length;

    const divideRegisterMeans = allTraineesStatus.length === 0 ? 1 : allTraineesStatus.length;

    const countRegistrationMeans = {
        mobile: (allTraineesStatus.filter(value => value.createFrom === 'mobile').length * 100) / divideRegisterMeans,
        web: (allTraineesStatus.filter(value => value.createFrom === 'web').length * 100) / divideRegisterMeans,
        ussd: (allTraineesStatus.filter(value => value.createFrom === 'ussd').length * 100) / divideRegisterMeans,
    };

    const registrationDataChart = {
        datasets: [
            {
                label: '# of Votes',
                data: [countRegistrationMeans.mobile, countRegistrationMeans.web, countRegistrationMeans.ussd],
                backgroundColor: ['#CF1B33', '#078ECE', '#40A55B'],
                borderWidth: 1,
            },
        ],
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCoTrainingCenter());
        dispatch(getAllCoTraineee());
        dispatch(getAllCoDAS());
        dispatch(getAllPartnerTraineeRange());
        // dispatch(getAllTraineesByAgeChart());
        // dispatch(getTraineesAgeRangeByDa());

        handleGetAllSap();
    }, [dispatch]);

    const TraineeCountMale = allTrainees.filter(value => value.gender === 'Male').length;
    const TraineeCountFemale = allTrainees.filter(value => value.gender === 'Female').length;

    const divideCountGender = allTrainees.length === 0 ? 1 : allTrainees.length;

    const traineeGender = { male: (TraineeCountMale * 100) / divideCountGender, female: (TraineeCountFemale * 100) / divideCountGender };

    const TraineeByGenderChart = {
        datasets: [
            {
                label: '# of Votes',
                data: [Number(traineeGender?.female).toFixed(2), Number(traineeGender?.male).toFixed(2)],
                backgroundColor: ['#CF1B33', '#078ECE'],
                borderWidth: 1,
            },
        ],
    };

    const traineedByDayChart = {
        labels: ['10-20', '20-30', '30-40', '40-50'],

        datasets: [
            {
                label: 'By Age',
                data: coRangeTrainee,
                fill: true,
                backgroundColor: 'rgba(103, 189, 255, 0.7)',
                borderColor: '#115e82',
            },
        ],
    };

    const token = jwtDecode(localStorage.getItem('IdToken'));

    const [countSAPS, setCountSAPS] = useState(0);

    const handleGetAllSap = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partners/my-centers`);

            setCountSAPS(data?.data.length ?? 0);
        } catch (error) {
            console.error(error);
        }
    };

    const myLocation = () => {
        if (token?.roles.includes('country_partner')) return 'Rwanda';
        if (token?.roles.includes('province_partner')) return token?.province;
        if (token?.roles.includes('district_partner')) return token?.district;
        if (token?.roles.includes('sector_partner')) return token?.sector;
    };

    console.log('data', token);

    return (
        <CpanelLayout>
            <main>
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <h1>Special Reports | {myLocation()}</h1>
                    {/* <div className="d-flex align-items-center">
            <div className="border-bottom ml-3">
              <input
                type="text"
                placeholder="Search reports"
                className="border-bottom border-0"
              />
              <button className="btn">
                <i className="fa fa-search" />
              </button>
            </div>
          </div> */}
                </div>
                <div className="mt-3">
                    <div className="border-bottom d-flex align-items-center">
                        <a href="#" className={`mx-3 px-2 ${show == 1 && `border-bottom border-primary`}`} onClick={() => setShow(1)}>
                            <h5> Trainings</h5>
                        </a>
                        <a href="#" className={`mx-3 px-2 ${show == 2 && `border-bottom border-primary`}`} onClick={() => setShow(2)}>
                            <h5> DA Reports</h5>
                        </a>
                    </div>
                    <div className="mt-4 ml-2">
                        {show == 1 ? (
                            <>
                                <div className="row my-4">
                                    <div className="col-md-3 my-2">
                                        <div className="media align-items-center mx-4">
                                            <img src={Check} />
                                            <div className="media-body ml-3">
                                                <h4>{allTraineesActive}</h4>
                                                <h6>Active trainees</h6>
                                                <span>
                                                    <img src={Success} width={16} />
                                                    <span>&nbsp; +{countTraineesThisWeek} this week</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <div className="media align-items-center mx-4">
                                            <img src={Cancel} />
                                            <div className="media-body ml-3">
                                                <h4>{countTraineesAllDropout}</h4>
                                                <h6>Dropouts</h6>
                                                <span>
                                                    <img src={Fail} width={16} />
                                                    <span>&nbsp; +{countTraineesDropoutThisWeek} this week</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <div className="media align-items-center mx-4">
                                            <img src={Home} />
                                            <div className="media-body ml-3">
                                                <h4>{trainingCenter.length}</h4>
                                                <h6>Training centres</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <div className="media align-items-center mx-4">
                                            <img src={Loading} />
                                            <div className="media-body ml-3">
                                                <h4>{countTraineesPending}</h4>
                                                <h6>Waiting</h6>
                                                <span>
                                                    <img src={Success} width={16} />
                                                    <span>&nbsp; +{countTraineesPendingThisWeek} this week</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 my-2">
                                        <div className="media align-items-center mx-4">
                                            <img src={School} />
                                            <div className="media-body ml-3">
                                                <h4>{countTraineesGraduate}</h4>
                                                <h6>Graduates</h6>
                                                <span>
                                                    <img src={Success} width={16} />
                                                    <span>&nbsp; +{countTraineesGraduateThisWeek} this week</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="col-md-6 my-3">
                                        <h3>Trainees by gender</h3>
                                        <div className="row align-items-center">
                                            <div className="col-4">
                                                <Doughnut data={TraineeByGenderChart} />
                                            </div>
                                            <div>
                                                <p>{Number(traineeGender?.male).toFixed(2)}% Male</p>
                                                <p>{Number(traineeGender?.female).toFixed(2)}% Female</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <RegistrationMeans mobilePerc={countRegistrationMeans?.mobile} webPerc={countRegistrationMeans?.web} ussdPerc={countRegistrationMeans?.ussd} />
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <h3>Trainees by age</h3>
                                        <div className="col-8">
                                            <Bar data={traineedByDayChart} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : show == 2 ? (
                            <>
                                <div className="row my-4">
                                    <div className="col-md-4 my-2">
                                        <div className="media align-items-center mx-4">
                                            <img src={Check} />
                                            <div className="media-body ml-3">
                                                <h4>{countDaActive}</h4>
                                                <h6>Active DAs</h6>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3 my-2">
                    <div className="media align-items-center mx-4">
                      <img src={Idle} />
                      <div className="media-body ml-3">
                        <h4>12</h4>
                        <h6>Idle DAs</h6>
                      </div>
                    </div>
                  </div> */}
                                    <div className="col-md-4 my-2">
                                        <div className="media align-items-center mx-4">
                                            <img src={Cancel} />
                                            <div className="media-body ml-3">
                                                <h4>{countDaInactive}</h4>
                                                <h6>Inactive DAs</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <div className="media align-items-center mx-4">
                                            <img src={Home} />
                                            <div className="media-body ml-3">
                                                <h4>{countSAPS}</h4>
                                                <h6>SAPs</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 my-3">
                                        <h4>DAs</h4>
                                        <div className="row align-items-center">
                                            <div className="col-4">
                                                <Doughnut data={DaGenderChart} />
                                            </div>
                                            <div>
                                                <p>{Number(DaGender?.male).toFixed(2)}% Male</p>
                                                <p>{Number(DaGender?.female).toFixed(2)}% Female</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 my-3">
                                        <h4>Platform used</h4>
                                        <div className="row align-items-center">
                                            <div className="col-4">
                                                <Doughnut data={DaPlatFormUsed} />
                                            </div>
                                            <div>
                                                <p>{DaCountPlatForm?.mobile}% Mobile</p>
                                                <p>{DaCountPlatForm?.web}% Web</p>
                                                <p>{DaCountPlatForm?.ussd}% USSD</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </main>
        </CpanelLayout>
    );
};

export default CoReports;
