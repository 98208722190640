import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { convertMonthInKinyaDOB } from '../../../../helpers/convertMonthInKiny';
import CpanelLayout from '../../../../layouts/AdminLayout';
import Check from '../../../../assets/images/check.svg';
import { Bar } from 'react-chartjs-2';
import { Modal, Form, Button, Col, Row, Breadcrumb } from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { UpdateUser } from '../../../../redux/actions/users';
import './index.css';
import axios from 'axios';
import Loading from '../../../../utils/LoadingSmall';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

const data = {
    labels: ['Module 1', 'Module 2', 'Module 3', 'Module 4', 'Module 5', 'Module 6', '', '', ''],
    datasets: [
        {
            label: 'By Age',
            data: [12, 28, 22, 5, 12, 28, 22, 26, 13],
            backgroundColor: '#078ece',
            borderColor: '#078ece',
            borderWidth: 1,
        },
    ],
};

const Profile = props => {
    const [user, setUser] = useState({});
    const [show, setShow] = useState(1);
    const [profileModal, setProfileModal] = useState(false);
    const [image, setImage] = useState(null);
    const [userDetail, setUserDetail] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const urlQuery = new URLSearchParams(props.location.search);
    const isUser = urlQuery.get('user');

    const dispatch = useDispatch();

    const [completationRate, setCompletationRate] = useState(0);

    useEffect(() => {
        (async () => {
            const token = jwtDecode(localStorage.getItem('IdToken'));
            const userID = isUser ?? token?.id;

            let userIdentity = null;
            let role = null;

            if (token && userID) {
                const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/users/${userID}`);
                setUserDetail(data?.data);

                userIdentity = data?.data?.userIdentity;
                role = data?.data?.rolesLabel[0];

                setUser({
                    id: !userID ? token.id : data?.data.id,
                    firstName: !userID ? token?.firstName : data?.data?.firstName,
                    lastName: !userID ? token?.lastName : data?.data?.lastName,
                    phone: !userID ? token?.phone : data?.data?.phone,
                    email: !userID ? token?.email : data?.data?.email,
                    profileImage: !userID ? token?.profileImage : data?.data?.profileImage,
                    roles: !userID ? token?.roles : data?.data?.roles,
                });
            }

            handleGetProfileWork(isUser, userID, userIdentity, role);
        })();
    }, [isUser]);

    const handleUserupdate = e => {
        e.preventDefault();
        setIsSubmitted(true);
        dispatch(UpdateUser(user, setIsSubmitted, setProfileModal, setUser, setUserDetail));
        setImage(null);
    };

    const handleChange = ({ target }) => {
        setUser({ ...user, [target.name]: target.value });
    };

    const onChangeHandler = ({ target }) => {
        const reader = new FileReader();
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = e => {
            setImage(e.target.result);
            setUser(user => ({ ...user, dir: 'profile', single_image: target.files[0] }));
        };
        reader.readAsDataURL(target.files[0]);
    };

    const [moduleCompletion, setModuleCompletion] = useState({ label: [], data: [] });
    const [attendanceCount, setAttendanceCount] = useState(0);

    const handleGetProfileWork = async (isUser, userID, userIdentity, role) => {
        try {
            const { data: responseData } = !isUser && role !== 'DA' ? await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/attendences`) : await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/attendences/${userID}`);

            const moduleData = responseData.data;

            const { data: traineeResponse } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/trainees`);

            const traineeData = !isUser && role !== 'DA' ? traineeResponse?.data : traineeResponse?.data?.filter(value => value.affiliated === userIdentity);

            setAttendanceCount(traineeData.length);

            const labelArray = [];
            const dataArray = [];

            for (let $i = 0; $i < moduleData.length; $i++) {
                labelArray.push(moduleData[$i].courseIdentity);
                dataArray.push(moduleData[$i].attendenceCount);
            }

            setModuleCompletion({ label: labelArray, data: dataArray });

            let sessionData = {};

            if (isUser) {
                sessionData = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sessions/completion/mine/${userID}`);
            } else {
                sessionData = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sessions/completion`);
            }

            setCompletationRate(sessionData?.data?.data);
        } catch (error) {
            console.log('error', error);
            return;
        }
    };

    const ModuleAttendanceChart = {
        labels: moduleCompletion?.label,
        datasets: [
            {
                label: 'By Module',
                data: moduleCompletion?.data,
                backgroundColor: '#078ece',
                borderColor: '#078ece',
                borderWidth: 1,
            },
        ],
    };
    const moneyFormater = user => {
            if (user?.bankInfo?.allowance) {
                return (
                    Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'RWF',
                    }).format(user?.bankInfo?.allowance)
                );
            }
            return 'N/A';
        };
    return (
        <CpanelLayout>
            {isUser && (
                <Row>
                    <Col md={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item className="text-primary">
                                <Link to="/cpanel/overview">Overview</Link>
                            </Breadcrumb.Item>

                            <Breadcrumb.Item className="text-primary">
                                {userDetail?.rolesLabel && userDetail?.rolesLabel.length !== 0 && (
                                    <Link to={`/cpanel/${userDetail?.rolesLabel[0].includes('Partner') ? 'partners' : 'ambassadors'}/list`}>{userDetail?.rolesLabel[0].includes('Partner') ? 'Partner Management' : 'DA Management'}</Link>
                                )}
                            </Breadcrumb.Item>

                            <Breadcrumb.Item active>{`${user?.firstName || ''} ${user?.lastName || ''}`}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            )}
            <main className="mt-5">
                <h3>Your Profile</h3>
                <div className="media align-items-center mt-5">
                    <img
                        src={image ? image : user?.profileImage ? `${process.env.REACT_APP_BACKEND}/${user.profileImage}` : 'https://pic.onlinewebfonts.com/svg/img_181369.png'}
                        width={170}
                        height={170}
                        className="rounded-circle"
                        style={{ objectFit: 'cover' }}
                        alt={user?.firstName}
                    />
                    <div className="media-body ml-4">
                        <h4>{`${user?.firstName || ''} ${user?.lastName || ''}`}</h4>
                        <h6>{!isEmpty(userDetail?.rolesLabel) ? userDetail.rolesLabel[0] : '-'}</h6>
                        <p className="text-muted">
                            {userDetail?.cell} {userDetail?.sector}
                        </p>
                        {!isUser && (
                            <button onClick={() => setProfileModal(true)} className="btn btn-primary px-3 rounded">
                                Edit Profile
                            </button>
                        )}
                    </div>
                </div>
                <div className="mt-5">
                    <div className="border-bottom d-flex align-items-center">
                        <a href="#" className={`mx-3 ${show == 1 && `border-bottom border-primary px-2`}`} onClick={() => setShow(1)}>
                            <h5> About</h5>
                        </a>
                        <a href="#" className={`mx-3 ${show == 2 && `border-bottom border-primary px-2`}`} onClick={() => setShow(2)}>
                            <h5> Work</h5>
                        </a>
                    </div>
                    <div className="mt-4 ml-2">
                        {show === 1 ? (
                            <>
                                <h4 className="font-weight-bold my-5">Basic info</h4>
                                {user.roles && user.roles.length && (user.roles.includes('SU') || user.roles.includes('super_admin') || user.roles.includes('progr-manager')) ? (
                                    <div className="d-flex align-items-center my-4">
                                        <div className="mr-5">
                                            <p className="my-0">ID</p>
                                            <h5>{userDetail?.userIdentity}</h5>
                                        </div>
                                        <div className="mr-5">
                                            <p className="my-0">Phone Number</p>
                                            <h5>{userDetail?.phone}</h5>
                                        </div>
                                        <div className="mr-5">
                                            <p className="my-0">Email</p>
                                            <h5>{userDetail?.email}</h5>
                                        </div>
                                        <div className="mr-5">
                                            <p className="my-0">Date of Birth</p>
                                            <h5>
                                                {moment(userDetail?.dob).format('DD')} {convertMonthInKinyaDOB(moment(userDetail?.dob).format('MM'))} {moment(userDetail?.dob).format('Y')}
                                            </h5>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="d-flex align-items-center my-4">
                                            <div className="mr-5">
                                                <p className="my-0">DA ID</p>
                                                <h5>{userDetail?.userIdentity}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">Sex</p>
                                                <h5>{userDetail?.gender}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">Date of Birth</p>
                                                <h5>
                                                    {moment(userDetail?.dob).format('DD')} {convertMonthInKinyaDOB(moment(userDetail?.dob).format('MM'))} {moment(userDetail?.dob).format('Y')}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center my-4">
                                            <div className="mr-5">
                                                <p className="my-0">Phone Number</p>
                                                <h5>{userDetail?.phone}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">Email</p>
                                                <h5>{userDetail?.email}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">ID Number</p>
                                                <h5>{userDetail?.nationalIdentity ?? 'N/A'}</h5>
                                            </div>
                                        </div>
                                        <h4 className="font-weight-bold my-5">Deployment</h4>
                                        <div className="d-flex align-items-center mt-4">
                                            <div className="mr-5">
                                                <p className="my-0">Intara</p>
                                                <h5>{userDetail?.province ?? 'N/A'}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">Akarere</p>
                                                <h5>{userDetail?.district ?? 'N/A'}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">Umurenge</p>
                                                <h5>{userDetail?.sector ?? 'N/A'}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">Akagari</p>
                                                <h5>{userDetail?.cell ?? 'N/A'}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">Umudugudu</p>
                                                <h5>{userDetail?.village ?? 'N/A'}</h5>
                                            </div>
                                        </div>
                                        <h4 className="font-weight-bold my-5">Bank Information</h4>
                                        <div className="d-flex align-items-center mt-4">
                                            <div className="mr-5">
                                                <p className="my-0">Bank Name</p>
                                                <h5>{userDetail?.bankInfo?.bankName ?? 'N/A'}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">Bank Account</p>
                                                <h5>{userDetail?.bankInfo?.bankAccount ?? 'N/A'}</h5>
                                            </div>
                                            <div className="mr-5">
                                                <p className="my-0">Monthly Allowance</p>
                                                <h5>{moneyFormater(userDetail)}</h5>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="d-flex">
                                    <div className="media align-items-center mx-4">
                                        <img src={Check} />
                                        <div className="media-body ml-3">
                                            <h5>{attendanceCount}</h5>
                                            <p>Trainee reach</p>
                                        </div>
                                    </div>
                                    <div className="media align-items-center mx-4">
                                        <img src={Check} />
                                        <div className="media-body ml-3">
                                            <h5>{completationRate?.toFixed(2) ?? 0} %</h5>
                                            <p>Module completion rate</p>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="mt-5">Module attendance rate</h4>
                                <div className="col-6">
                                    <Bar data={ModuleAttendanceChart} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </main>

            <Modal show={profileModal} backdrop="static" onHide={() => setProfileModal(false)} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-center justify-content-center flex-column mb-4">
                        <img
                            src={image ? image : user?.profileImage ? `${process.env.REACT_APP_BACKEND}/${user.profileImage}` : 'https://pic.onlinewebfonts.com/svg/img_181369.png'}
                            width={100}
                            height={100}
                            alt="Avatar"
                            style={{ borderRadius: '50%' }}
                        />
                        <div className="d-flex align-items-center justify-content-center mt-1" style={uploadbuttonstyles}>
                            <div className="d-flex align-items-center justify-content-center">
                                <label htmlFor="single" style={{ marginBottom: 0, cursor: 'pointer' }}>
                                    <i className="fas fa-plus-circle text-primary"></i>
                                    <span className="text-primary"> choose your avatar</span>
                                </label>
                                <input type="file" id="single" onChange={onChangeHandler} accept="image/*" />
                            </div>
                        </div>
                    </div>

                    <Form onSubmit={handleUserupdate}>
                        <Form.Group>
                            <Form.Control type="text" name="firstName" placeholder="John" value={user?.firstName} required onChange={handleChange} disabled={isSubmitted} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="text" name="lastName" placeholder="Doe" value={user?.lastName} required onChange={handleChange} disabled={isSubmitted} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="text" name="phone" placeholder="Phone number" value={user?.phone} required onChange={handleChange} disabled={isSubmitted} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="email" name="email" placeholder="Email" value={user?.email} required onChange={handleChange} disabled={isSubmitted} />
                        </Form.Group>

                        <Button type="submit" className="btn-block py-2 d-flex justify-content-center" disabled={isSubmitted}>
                            {!isSubmitted ? 'Save' : <Loading color="#FFF" marginTop=" " center={true} />}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </CpanelLayout>
    );
};

const uploadbuttonstyles = {
    width: 190,
    borderRadius: 6,
    padding: 8,
    border: 'none',
    cursor: 'pointer',
};

export default Profile;
