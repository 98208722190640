import {
  REQUEST_CREATE_SESSION,
  CREATE_SESSION_FAILED,
  CREATE_SESSION_SUCCESS,
  REQUEST_GET_SESSION,
  GET_SESSION_FAILED,
  GET_SESSION_SUCCESS,
  REQUEST_UPDATE_SESSION,
  UPDATE_SESSION_FAILED,
  UPDATE_SESSION_SUCCESS,
  REQUEST_GET_ATTENDEES,
  GET_ATTENDEES_FAILED,
  GET_ATTENDEES_SUCCESS,
  REQUEST_DELETE_SESSION,
  DELETE_SESSION_FAILED,
  DELETE_SESSION_SUCCESS,
  
} from "../types"
import axios from "axios";
import { toast } from 'react-toastify';

const { REACT_APP_BACKEND } = process.env;

export const createSession = trainee => dispatch => {
	dispatch({ type: REQUEST_CREATE_SESSION });
	axios
		.post(`${REACT_APP_BACKEND}/api/sessions`, trainee)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: CREATE_SESSION_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: CREATE_SESSION_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const getAllSessions = () => (dispatch) => {
  dispatch({ type: REQUEST_GET_SESSION });
  axios
    .get(`${REACT_APP_BACKEND}/api/sessions`)
    .then((res) => {
      dispatch({ type: GET_SESSION_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_SESSION_FAILED,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};

export const updateSession = (id, session) => dispatch => {
	dispatch({ type: REQUEST_UPDATE_SESSION });
	axios
		.put(`${REACT_APP_BACKEND}/api/sessions/${id}`, session)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: UPDATE_SESSION_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: UPDATE_SESSION_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const getSessionAttendees = (id) => dispatch => {
	dispatch({ type: REQUEST_GET_ATTENDEES });
	axios
		.put(`${REACT_APP_BACKEND}/api/sessions/${id}/attendees`)
		.then(res => {
			dispatch({ type: GET_ATTENDEES_SUCCESS, payload: res.data.data });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: GET_ATTENDEES_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const deleteSession = (id) => dispatch => {
	dispatch({ type: REQUEST_DELETE_SESSION });
	axios
		.delete(`${REACT_APP_BACKEND}/api/sessions/${id}`)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: DELETE_SESSION_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: DELETE_SESSION_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const getDaSessions = () => (dispatch) => {
  dispatch({ type: REQUEST_GET_SESSION });
  axios
    .get(`${REACT_APP_BACKEND}/api/sessions/my-sessions`)
    .then((res) => {
      dispatch({ type: GET_SESSION_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_SESSION_FAILED,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};
