import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import store from './redux/store/index';
import { LOGIN_REQUEST, SET_AUTHENTICATED } from '../src/redux/types';
import { logoutUser } from '../src/redux/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter as Router } from 'react-router-dom';
import ClientRoutes from './routes/client';
import CpanelRoutes from './routes/cpanel';
import isEmpty from 'lodash/isEmpty';

toast.configure({
    // position: "top-right",
    autoClose: 4000,
    // hideProgressBar: true,
    // closeOnClick: true,
    // pauseOnHover: true,
    // draggable: true,
    // progress: undefined,
    // delay: 10000,
});
const token = localStorage.IdToken;

(async () => {
    try {
        if (isEmpty(token)) {
            store.dispatch(logoutUser());
            return;
        }

        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
            store.dispatch(logoutUser());
            window.location.reload();
        } else {
            store.dispatch({ type: SET_AUTHENTICATED, payload: decodedToken.roles });
            store.dispatch({ type: LOGIN_REQUEST, payload: decodedToken });
            axios.defaults.headers.common['token'] = token;
        }
    } catch (err) {
        store.dispatch(logoutUser());
        window.location.reload();
        return;
    }
})();

const App = () => {
    useEffect(() => {
        if (!token) {
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = process.env.REACT_APP_CHAT_ID;

            (function () {
                let d = document;
                let s = d.createElement('script');

                s.src = process.env.REACT_APP_CHAT_URL;
                s.async = 1;
                d.getElementsByTagName('head')[0].appendChild(s);
            })();
        }
    });

    return (
        <Provider store={store}>
            <Router>
                <ClientRoutes>
                    <CpanelRoutes />
                </ClientRoutes>
            </Router>
        </Provider>
    );
};

export default App;
