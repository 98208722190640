import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTraineesByDa } from '../../../../redux/actions';
import Spinner from '../../../../components/spinner/Spinner';
import { Link } from 'react-router-dom';
import Routes from '../../../../routes/names';

import { Row, Col, Container, Form, Breadcrumb } from 'react-bootstrap';
import { DaTraineesTable } from '../../components/trainees';
import CpanelLayout from '../../../../layouts/AdminLayout';

import { emptifyFalseValues } from '../../../../helpers';
import moment from 'moment';

const sanitizeData = trainees =>
    trainees.map((trainee, index) => ({
        names: trainee.firstName + ' ' + trainee.lastName + ' ' + trainee.middleName,
        index: index + 1,
        registrationYear: moment(trainee.createdAt),
        ...emptifyFalseValues(trainee),
    }));

const getYearsInProgress = () => {
    const currentYear = moment().year();

    const startingYear = 2020;
    let progressYears = Math.abs(currentYear - startingYear);

    let years = [startingYear];

    new Array(progressYears).fill(startingYear).forEach((year, index) => {
        console.log(index);
        years.push(year + index + 1);
    });

    return years;
};

function AllDATrainees() {
    const { getTraineeError, error, success, loading, trainees, updateSuccess } = useSelector(state => state.trainees);
    const { roles } = useSelector(state => state.auth);

    let sanitizedDataset = sanitizeData(trainees || []);

    const [dataset, setDataset] = useState(sanitizedDataset);

    const filterByYear = criteria => {
        if (criteria === 'all') {
            return setDataset(sanitizedDataset);
        }
        const newDataset = sanitizedDataset.filter(el => String(el.registrationYear).includes(criteria));
        return setDataset([...newDataset]);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllTraineesByDa());
    }, [dispatch, updateSuccess]);

    useEffect(() => {
        sanitizedDataset = sanitizeData(trainees || []);
        setDataset(sanitizedDataset);
    }, [trainees]);

    return (
        <CpanelLayout>
            <Row>
                <Col md={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item className="text-primary">
                            <Link to="/cpanel/overview">Overview</Link>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item className="text-primary">
                            <Link to={Routes.dashboard.listGroup.groups}>Groups</Link>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item active>My trainees</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col className="col-lg-6  col-sm-12">
                    <span className="text-title">Trainees</span>
                </Col>
                <Col className="col-lg-6  col-sm-12">
                    <div className="d-flex align-items-end justify-content-end">
                        {' '}
                        <Form.Group>
                            <Form.Control
                                as="select"
                                className=" border-1 border-radius-3 custom-select text-small"
                                style={{ borderRadius: 10 }}
                                onChange={({ target: { value } }) => {
                                    filterByYear(value);
                                }}
                            >
                                <option value="all" selected>
                                    All
                                </option>
                                {getYearsInProgress().map(year => (
                                    <option key={year} value={year}>
                                        {' '}
                                        {year}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>{' '}
                    </div>
                </Col>
            </Row>

            <div className="pt-5">
                <Row>
                    <Col>
                        {loading && <Spinner />}
                        {dataset.length !== 0 && <DaTraineesTable trainees={dataset} search roles={roles} />}
                        {Boolean(getTraineeError.length) && (
                            <div className="d-flex align-item-center justify-content-center py-3">
                                <span> There's no registered trainees in this period.</span>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </CpanelLayout>
    );
}

export default AllDATrainees;
