import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from "axios";
import './index.css';
import { Form, Col, Row, FormGroup, InputGroup, FormControl, Breadcrumb, Spinner } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Routes from '../../../../../routes/names';

import { Link, useHistory } from 'react-router-dom';

import { getLocationByProvince, getLocationByDistrict, getLocationBySector, getLocationByCell } from '../../../../../redux/actions';
import { getCenterLocation } from '../../../../../redux/actions';
import { createPartner } from '../../../../../redux/actions';
import { matchDigit, checkDates, matchPhone } from '../../../../../helpers';

import CpanelLayout from '../../../../../layouts/AdminLayout';

const {REACT_APP_BACKEND} = process.env

export default function AddAmbassador() {
    const dispatch = useDispatch();
    const routeHistory = useHistory();

    const validationSchema = Yup.object().shape({
        nationalIdentity: Yup.string().min(16, 'National ID is invalid').required('National ID is required'),
        firstName: Yup.string().required(' First name is required'),
        lastName: Yup.string().required(' Last name is required'),
        dob: Yup.string().required('Date of birth is required '),
        gender: Yup.string().required('Please select gender'),
        email: Yup.string().email('Email is Invalid').required('Email is required'),
        partner: Yup.string().required('Please select partnership level'),
        phone: Yup.string().min(10, 'Phone number is invalid').required('Phone is required'),
    });
    const initialValues = {
        nationalIdentity: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        gender: '',
        dob: '',
        email: '',
        partner: '',
    };

    const { values, handleSubmit, errors, handleChange, setFieldValue, touched } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            if (checkDates(values.dob, 18)) {
                dispatch(createPartner({ ...values, ...userResidence },routeHistory));
            } else {
                toast.error('Age range should be between 18 and 100 years old');
            }
        },
    });

    const [userResidence, setUserResidence] = useState({
        province: '',
        district: '',
        sector: '',
        cell: '',
        village: '',
    });
    const [nidaResponse, setNidaResponse] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [identification, setIdentification] = useState({
        nationalIdentity: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        gender: '',
        dob: '',
        email: '',
    });

    const { residence, districts, sectors, cells, villages, districtLoading, sectorLoading, cellLoading, villageLoading } = useSelector(state => state.residence);
    const { success, message, error, loading } = useSelector(state => state.partners);

    // const onInputChange = e => {
    //   const { value, name } = e.target;
    //   setUser((state)=>({...state, [name]:value}))
    // }

    // const onSubmit = (e) => {
    //   e.preventDefault();
    //   // let dambs = { ...user }
    //   dispatch(createUser(user));
    // }

    const onProvinceChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, province: value });
        dispatch(getLocationByProvince(value));
    };
    const onDistrictChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, district: value });
        dispatch(getLocationByDistrict(value));
    };
    const onSectorChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, sector: value });
        dispatch(getLocationBySector(value));
    };
    const onCellChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, cell: value });
        dispatch(getLocationByCell(userResidence.sector, value));
    };
    const onVillageChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, village: value });
    };

    const handleSearch = async e => {
        e.preventDefault();
        setSearchLoading(true);
        try
        {
            // const identification = await nidaApi(values.nationalIdentity);
            const identifications = await axios.post(`${REACT_APP_BACKEND}/api/identification`, { nationalIdentification: values.nationalIdentity });

            if (identifications.data)
            {
                const { identification } = identifications.data.data
                setIdentification({
                    natiananalIdentity: identification.documentNumber,
                    firstName: identification.foreName,
                    lastName: identification.surnames,
                    gender: identification.sex == 'M' ? 'Male' : 'Female',
                    dob: identification.dateOfBirth.split('/').reverse().join('-'),
                });
                setNidaResponse(true);
            } else
            {
                setSearchLoading(false);
                toast.error("National ID does not exist")
            }
        } catch (err)
        {
            toast.error("National ID does not exist")
            setSearchLoading(false);
        }
    };

    useEffect(() => {
        setSearchLoading(false);
        if (nidaResponse) {
            Object.keys(identification).forEach((key, i) => setFieldValue(key, Object.values(identification)[i]));
        }
    }, [nidaResponse]);

    return (
        <CpanelLayout>
            <div>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Breadcrumb>
                                <Breadcrumb.Item className='text-primary'>
                                    <Link to='/cpanel/overview'>Overview</Link>
                                </Breadcrumb.Item>
                                
                                <Breadcrumb.Item className='text-primary'>
                                    <Link to={Routes.dashboard.partners.list}>Partner Management</Link>
                                </Breadcrumb.Item>
                                
                                <Breadcrumb.Item active>Add New Partner</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={!2}>
                        <div className="d-flex justify-content-between">
                            <p className="text-title mb-2">
                                Add a new Partner
                            </p>
                        </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="body-content">
                <Container>
                    <Row className="mt-3">
                        <Col md={12}>
                            <div className="box">
                                <Form>
                                    <p className="text-medium">Basic information</p>

                                    <Row>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl
                                                        placeholder={'National ID'}
                                                        value={values.nationalIdentity}
                                                        name="nationalIdentity"
                                                        onChange={event => {
                                                            let sanitized = matchDigit(String(event.target.value));

                                                            sanitized = sanitized.substring(0, 16);

                                                            setFieldValue('nationalIdentity', sanitized);
                                                        }}
                                                    />

                                                    {touched.nationalIdentity && errors.nationalIdentity && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.nationalIdentity}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Button onClick={handleSearch} className="px-5">
                                                    {searchLoading ? <Spinner size="sm" animation="border" variant="light" /> : 'Search'}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`First Name`} value={values.firstName} name="firstName" onChange={handleChange} />

                                                    {touched.firstName && errors.firstName && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.firstName}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`Last Name`} value={values.lastName} name="lastName" onChange={handleChange} />

                                                    {touched.lastName && errors.lastName && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.lastName}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <FormControl value={values.middleName} name="middleName" onChange={handleChange} placeholder={`Middle Name (Optional)`} />
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <Form.Control as="select" className="custom-select" name="gender" value={values.gender} onChange={handleChange}>
                                                        <option value="" disabled selected>
                                                            Gender
                                                        </option>
                                                        <option> Female</option>
                                                        <option>Male</option>
                                                    </Form.Control>

                                                    {touched.gender && errors.gender && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.gender}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`Date of Birth`} name="dob" value={values.dob} onChange={handleChange} type="date" />

                                                    {touched.dob && errors.dob && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.dob}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl
                                                        placeholder={`0788888888`}
                                                        name="phone"
                                                        value={values.phone}
                                                        onChange={event => {
                                                            let sanitized = matchPhone(String(event.target.value));

                                                            sanitized = sanitized.substring(0, 13);

                                                            setFieldValue('phone', sanitized);
                                                        }}
                                                    />

                                                    {touched.phone && errors.phone && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.phone}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`Email`} name="email" value={values.email} onChange={handleChange} type="email" />

                                                    {touched.email && errors.email && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <p className="text-medium mb-3 mt-4">Residences</p>

                                    <Row>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" onChange={onProvinceChange} name="province">
                                                    {['Province', 'Kigali City', 'Western Province', 'Southern Province', 'Northern Province', 'Eastern Province'].map((province, index) => {
                                                        return (
                                                            <option {...(index !== 0 && { value: province })} {...(index === 0 && { disabled: true, selected: true })}>
                                                                {province}
                                                            </option>
                                                        );
                                                    })}
                                                </Form.Control>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" onChange={onDistrictChange} name="district">
                                                    <option disabled selected>
                                                        District
                                                    </option>
                                                    {Boolean(districts.length) && districts.map((district, index) => <option value={district}> {district} </option>)}
                                                </Form.Control>
                                                {districtLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" onChange={onSectorChange} name="sector">
                                                    <option selected disabled>
                                                        Sector
                                                    </option>
                                                    {Boolean(sectors.length) && sectors.map((sector, index) => <option value={sector}>{sector}</option>)}
                                                </Form.Control>
                                                {sectorLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" onChange={onCellChange} name="cell">
                                                    <option selected disabled>
                                                        Cell
                                                    </option>

                                                    {Boolean(cells.length) && cells.map((cell, index) => <option value={cell}> {cell} </option>)}
                                                </Form.Control>
                                                {cellLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" name="village" onChange={onVillageChange}>
                                                    <option selected disabled>
                                                        Village
                                                    </option>
                                                    {Boolean(villages.length) && villages.map((village, index) => <option value={village}>{village}</option>)}
                                                </Form.Control>
                                                {villageLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <p className="text-medium mb-3 mt-4">Work details</p>

                                    <div className="d-flex align-items-center text-normal ">
                                        <span>This Partner is &nbsp;</span>
                                        <FormGroup>
                                            <Form.Control as="select" className="custom-select" name="partner" value={values.partner} onChange={handleChange}>
                                                <option>Partner</option>
                                                <option value="country_partner">Country partner</option>
                                                <option value="province_partner">Province partner</option>
                                                <option value="district_partner">District partner</option>
                                                <option value="sector_partner">Sector partner</option>
                                            </Form.Control>
                                            {touched.partner && errors.partner && (
                                                <Form.Control.Feedback className="d-block" type="invalid">
                                                    {errors.partner}
                                                </Form.Control.Feedback>
                                            )}
                                        </FormGroup>
                                    </div>

                                    <Button type="submit" onClick={handleSubmit} className="btn btn-primary px-5 mt-4">
                                        {loading ? <Spinner size="sm" animation="border" variant="light" /> : `Save`}
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </CpanelLayout>
    );
}
