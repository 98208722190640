import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, FormCheck, Row, Col, Form, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import moment from 'moment';
// import {
//   createSession,
// } from "../../../../redux/actions"

const todayDate = () => {
    const date = moment().toDate().toLocaleDateString();

    return date;
};

const nextMonthfromToday = () => {
    const date = moment().add(1, 'month').calendar();

    return date;
};

function ScheduleSession({ open, onClose, loading, groups, courses, submit, success }) {
    todayDate();

    const [session, setSession] = useState({
        groupId: '',
        courseId: '',
        startAt: '',
        endAt: '',
        date: '',
    });

    const validationSchema = Yup.object().shape({
        group: Yup.string().required('Please select group name'),
        module: Yup.string().required('Please select a module name'),
        date: Yup.date('Date should be a valid date').required('Please select a date for this schedule'),
        startAt: Yup.string().required('Please select your schedule time'),
    });
    const initialValues = {
        group: '',
        module: '',
        date: '',
        startAt: '',
    };

    const { handleSubmit, errors, setFieldValue, touched } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            submit(session);
        },
    });

    useEffect(() => {
        if (success) {
            setSession({ ...session, groupId: '', courseId: '', startAt: '', endAt: '', date: '' });
            setFieldValue('group', '');
            setFieldValue('module', '');
            setFieldValue('date', '');
            setFieldValue('startAt', '');
        }
    }, [success]);

    const onGroupChange = e => {
        let theGroup = groups.filter(group => group.name == e.target.value);
        setSession({ ...session, groupId: theGroup[0].id });
        setFieldValue('group', e.target.value);
    };
    const onCourseChange = e => {
        let theCourse = courses.filter(course => course.title == e.target.value);
        setSession({ ...session, courseId: theCourse[0].id });
        setFieldValue('module', e.target.value);
    };

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        setSession(session => ({ ...session, [name]: value }));
        setFieldValue(name, value);
    };
    return (
        <div>
            <Container>
                <Modal show={open} size="md" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Session</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Form.Group as={Col} className="col-12">
                                    <Form.Control name="group" required as="select" className="custom-select" onChange={onGroupChange}>
                                        <option value="" selected disabled>
                                            {' '}
                                            Group
                                        </option>
                                        {/* <option> Group 1</option>
                    <option> Group 2</option>
                    <option> Group 3</option> */}
                                        {groups && groups.map(group => <option>{group.name}</option>)}
                                    </Form.Control>
                                    {touched.group && errors.group && (
                                        <Form.Control.Feedback className="d-block" type="invalid">
                                            {errors.group}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                <Form.Group as={Col} className="col-12">
                                    <Form.Control name="module" required as="select" className="custom-select" onChange={onCourseChange}>
                                        <option value="" selected disabled>
                                            {' '}
                                            Module Name
                                        </option>
                                        {/* <option> Gukoresha Imbuga Nkoranyambaga</option>
                    <option>Service z'itangirwa ku rubuga rw'Irembo</option>
                    <option> Kwishyura ukoresheje Mobile Money</option> */}
                                        {courses && courses.map(course => <option>{course.title}</option>)}
                                    </Form.Control>
                                    {touched.module && errors.module && (
                                        <Form.Control.Feedback className="d-block" type="invalid">
                                            {errors.module}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                <Form.Group as={Col} className="col-12">
                                    <Form.Control type="time" name="startAt" value={session.value} placeholder="Time" min="08:00" max="17:00" onChange={handleInputChange} />
                                    {touched.startAt && errors.startAt && (
                                        <Form.Control.Feedback className="d-block" type="invalid">
                                            {errors.startAt}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                <Form.Group as={Col} className="col-12">
                                    <Form.Control type="date" name="date" value={session.date} placeholder="Date" min={todayDate()} max={nextMonthfromToday()} onChange={handleInputChange} />
                                    {touched.date && errors.date && (
                                        <Form.Control.Feedback className="d-block" type="invalid">
                                            {errors.date}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="pt-2">
                            <Row>
                                <Col className="col-9">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Button variant="primary" className="btn-primary" block onClick={handleSubmit}>
                                            {loading ? <Spinner size="sm" animation="border" variant="light" /> : 'Schedule Session'}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
}

export default ScheduleSession;
