import React from 'react';
import Download from '../../../../../assets/icons/download.svg';

const Differenciation = ({ currentValue, previousValue, period }) => {
    const isPositiveInteger = n => {
        return n >>> 0 === parseFloat(n);
    };
    let difference = parseInt(currentValue) - parseInt(previousValue);

    difference = isNaN(difference) ? 0 : difference;

    return (
        <span className="color-grey text-extra-small">
            {' '}
            {isPositiveInteger(difference) ? <i className="fas fa-caret-up text-success text-medium"></i> : <i className="fas fa-caret-down text-danger text-medium"></i>}
            &nbsp;
            {isPositiveInteger(difference) ? <span>+</span> : null}
            {Number(difference).toLocaleString() || 0} this {period}
        </span>
    );
};

function ReportPerMetric({ currentValue, previousValue, metric, icon, period }) {
    const Img = icon || Download;
    return (
        <div className="media align-items-center mx-4">
            <img src={Img} alt="" />
            <div className="media-body ml-3">
                <div>
                    {' '}
                    <span className="text-medium">{isNaN(currentValue) ? 0 : Number(currentValue).toLocaleString()}</span>
                </div>

                <div className="mt-n1">
                    {' '}
                    <span className="text-small"> {metric}</span>{' '}
                </div>

                <div className="mt-n1">
                    <Differenciation currentValue={currentValue} previousValue={previousValue} period={period || 'week'} />
                </div>
            </div>
        </div>
    );
}

export default ReportPerMetric;
