import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../../layouts/AdminLayout';
import { useFormik } from 'formik';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { SearchBox } from '../../../../components/commons/search-box';
import axios from 'axios';

import { Link } from "react-router-dom";
import Routes from "../../../../routes/names";

import { Form, Button, FormGroup, FormControl, InputGroup, Spinner } from 'react-bootstrap';

import * as Yup from 'yup';
import { matchDigit, checkDates, nidaApi, matchPhone } from '../../../../helpers';
import { withNamespaces } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { getLocationByProvince, getLocationByDistrict, getLocationBySector, getLocationByCell, createTrainee, getCenterLocation } from '../../../../redux/actions';
import routes from '../../../../routes/names';

const { REACT_APP_BACKEND } = process.env;

function AddTrainees() {
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        nationalIdentity: Yup.string().min(16, 'National ID is invalid'),
        firstName: Yup.string().required(' First name is required'),
        lastName: Yup.string().required(' Last name is required'),
        gender: Yup.string().required('Please select gender'),
        dob: Yup.string().required('Date of birth is required '),
        email: Yup.string().email('Email is Invalid'),
        phone: Yup.string().length(10, 'Phone number is invalid'),
    });

    const initialValues = {
        nationalIdentity: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        gender: '',
        dob: '',
        email: '',
        cellLeader: '',
        cellLeaderContact: '',
    };

    const { values, handleSubmit, errors, handleChange, setFieldValue, touched, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            if (checkDates(values.dob, 12)) {
                dispatch(createTrainee({ ...values, residenceLocationId: residentId + '', trainingcenterId: centerId, ...userResidence }, undefined, resetForm));
            } else {
                toast.error('Age range should be between 12 and 100 years old');
            }
        },
    });

    const [residentId, setResidentId] = useState('');
    const [residentIds, setResidentIds] = useState([]);
    const [centerId, setCenterId] = useState('');
    const [userResidence, setUserResidence] = useState({
        province: '',
        district: '',
        sector: '',
        cell: '',
        village: '',
    });
    const [nidaResponse, setNidaResponse] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [identification, setIdentification] = useState({
        nationalIdentity: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        gender: '',
        dob: '',
        email: '',
    });

    const { residence, districts, sectors, cells, villages, districtLoading, sectorLoading, cellLoading, villageLoading } = useSelector(state => state.residence);
    const { centers } = useSelector(state => state.centers);
    const { success, message, error, loading } = useSelector(state => state.trainees);

    const onProvinceChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, province: value });
        dispatch(getLocationByProvince(value));
    };
    const onDistrictChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, district: value });
        dispatch(getLocationByDistrict(value));
    };
    const onSectorChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, sector: value });
        dispatch(getLocationBySector(value));
        let ids = [];
        let sectors = residence.filter(res => res.sector === value);
        sectors.forEach(c => ids.push(c.id));
        setResidentIds([...new Set(ids)]);
    };
    const onCellChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, cell: value });
        dispatch(getLocationByCell(userResidence.sector, value));
    };
    const onVillageChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, village: value });
        let village = residence.filter(res => res.village === value);
        setResidentId(village[0].id);
        dispatch(getCenterLocation(residentIds));
    };

    const onCenterChange = e => {
        const { value } = e.target;
        let center = centers.filter(center => center.centerName === value);
        setCenterId(center[0].id);
    };

    const handleSearch = async e => {
        e.preventDefault();
        setSearchLoading(true);
        try
        {
            // const identification = await nidaApi(values.nationalIdentity);
            const identifications = await axios.post(`${REACT_APP_BACKEND}/api/identification`, { nationalIdentification: values.nationalIdentity });

            if (identifications.data)
            {
                const { identification } = identifications.data.data
                setIdentification({
                    natiananalIdentity: identification.documentNumber,
                    firstName: identification.foreName,
                    lastName: identification.surnames,
                    gender: identification.sex == 'M' ? 'Male' : 'Female',
                    dob: identification.dateOfBirth.split('/').reverse().join('-'),
                });
                setNidaResponse(true);
            } else
            {
                setSearchLoading(false);
                toast.error("National ID does not exist")
            }
        } catch (err)
        {
            toast.error("National ID does not exist")
            setSearchLoading(false);
        }
    };
    useEffect(() => {
        setSearchLoading(false);
        if (nidaResponse) {
            Object.keys(identification).forEach((key, i) => setFieldValue(key, Object.values(identification)[i]));
        }
    }, [nidaResponse]);
    return (
        <Layout>
            <div className="pt-4">
                <Container>
                    <Row>
                        <Col md={12}>
                            <Breadcrumb>
                                <Breadcrumb.Item className='text-primary'>
                                    <Link to='/cpanel/overview'>Overview</Link>
                                </Breadcrumb.Item>
                                
                                <Breadcrumb.Item className='text-primary'>
                                    <Link to={Routes.dashboard.listGroup.groups}>Trainees</Link>
                                </Breadcrumb.Item>
                                
                                <Breadcrumb.Item active>Add New Trainee</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col className="col-12 col-md-9">
                            <div>
                                <span className="text-title">Add a new trainee</span>
                            </div>
                        </Col>
                    </Row>

                    <div className="pt-5 body-content">
                        <Row>
                            <Col md={12}>
                                <div className="box">
                                    <Form>
                                        <p className="text-title mb-2">Basic information</p>

                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl
                                                            placeholder="National ID (Optional)"
                                                            value={values.nationalIdentity}
                                                            name="nationalIdentity"
                                                            onChange={event => {
                                                                let sanitized = matchDigit(String(event.target.value));

                                                                sanitized = sanitized.substring(0, 16);

                                                                setFieldValue('nationalIdentity', sanitized);
                                                            }}
                                                        />

                                                        {touched.nationalIdentity && errors.nationalIdentity && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.nationalIdentity}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Button onClick={handleSearch} className="px-5">
                                                        {searchLoading ? <Spinner size="sm" animation="border" variant="light" /> : 'Find'}
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl placeholder="First name" value={values.firstName} name="firstName" onChange={handleChange} />

                                                        {touched.firstName && errors.firstName && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.firstName}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            {/*  */}
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl placeholder="Last name" value={values.lastName} name="lastName" onChange={handleChange} />
                                                    </InputGroup>

                                                    {touched.lastName && errors.lastName && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.lastName}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <FormControl value={values.middleName} name="middleName" onChange={handleChange} placeholder="Middle name (optional)" />
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <Form.Control as="select" className="custom-select" name="gender" value={values.gender} onChange={handleChange}>
                                                            <option value="" disabled selected>
                                                                Gender
                                                            </option>
                                                            <option>Female</option>
                                                            <option>Male</option>
                                                        </Form.Control>

                                                        {touched.gender && errors.gender && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.gender}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl placeholder="Date of birth" name="dob" value={values.dob} onChange={handleChange} type="date" />

                                                        {touched.dob && errors.dob && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.dob}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl
                                                            placeholder="Phone (optional)"
                                                            name="phone"
                                                            value={values.phone}
                                                            onChange={event => {
                                                                let sanitized = matchPhone(String(event.target.value));

                                                                sanitized = sanitized.substring(0, 13);

                                                                setFieldValue('phone', sanitized);
                                                            }}
                                                        />

                                                        {touched.phone && errors.phone && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.phone}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <InputGroup>
                                                        <FormControl placeholder="Email (optional)" name="email" value={values.email} onChange={handleChange} type="email" />

                                                        {touched.email && errors.email && (
                                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                                {errors.email}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <p className="text-title mb-3 mt-4">Location details</p>

                                        <Row>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="province" onChange={onProvinceChange}>
                                                        <option value="" selected disabled>
                                                            Province
                                                        </option>
                                                        <option>Kigali City</option>
                                                        <option>Western Province</option>
                                                        <option>Southern Province</option>
                                                        <option>Northern Province</option>
                                                        <option>Eastern Province</option>
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="district" onChange={onDistrictChange}>
                                                        <option value="" selected disabled>
                                                            District
                                                        </option>
                                                        {districts && districts.map((district, index) => <option key={index}>{district}</option>)}
                                                    </Form.Control>
                                                    {districtLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                                </FormGroup>
                                            </Col>

                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="sector" onChange={onSectorChange}>
                                                        <option value="" selected disabled>
                                                            Sector
                                                        </option>
                                                        {sectors && sectors.map((sector, index) => <option key={index}>{sector}</option>)}
                                                    </Form.Control>
                                                    {sectorLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row className="mt-3">
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="cell" onChange={onCellChange}>
                                                        <option value="" selected disabled>
                                                            Cell
                                                        </option>
                                                        {cells && cells.map((cell, index) => <option key={index}>{cell}</option>)}
                                                    </Form.Control>
                                                    {cellLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                                </FormGroup>
                                            </Col>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="village" onChange={onVillageChange}>
                                                        <option value="" disabled selected>
                                                            Village
                                                        </option>
                                                        {villages && villages.map((village, index) => <option key={index}>{village}</option>)}
                                                    </Form.Control>
                                                    {villageLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                                </FormGroup>
                                            </Col>
                                            <Col className="col-12 col-md-6 col-lg-3">
                                                <FormGroup>
                                                    <Form.Control as="select" className="custom-select" name="center" onChange={onCenterChange}>
                                                        <option value="" disabled selected>
                                                            Training center
                                                        </option>
                                                        {centers && centers.map((center, index) => <option key={index}>{center.centerName}</option>)}
                                                    </Form.Control>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Button
                                            type="submit"
                                            onClick={handleSubmit}
                                            // to={Routes.register.verify}
                                            className="btn btn-primary px-5 mt-4"
                                        >
                                            {loading ? <Spinner size="sm" animation="border" variant="light" /> : 'Save'}
                                            {/* Save */}
                                        </Button>
                                        {/* <ToastContainer /> */}
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </Layout>
    );
}

export default AddTrainees;
