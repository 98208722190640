export const REQUEST_CREATE_COURSE = "REQUEST_CREATE_COURSE";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAILED = "CREATE_COURSE_FAILED";

export const REQUEST_GET_COURSE = "REQUEST_GET_COURSE";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_FAILED = "GET_COURSE_FAILED";

export const REQUEST_GET_COURSES = "REQUEST_GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAILED = "GET_COURSES_FAILED";

export const REQUEST_UPDATE_COURSE = "REQUEST_UPDATE_COURSE";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAILED = "UPDATE_COURSE_FAILED";

export const REQUEST_DELETE_COURSE = "REQUEST_DELETE_COURSE";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILED = "DELETE_COURSE_FAILED";