import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, Button, FormGroup, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import { withNamespaces } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';

import Header from './components/Header';
import Footer from './components/Footer';

import './index.css';
import BackLink from '../../components/commons/back-link/BackLink';
import routes from '../../routes/names';
import * as Yup from 'yup';
import { matchDigit, checkDates, matchPhone } from '../../helpers';
import { getLocationByProvince, getLocationByDistrict, getLocationBySector, getLocationByCell } from '../../redux/actions';
import { getCenterLocation } from '../../redux/actions';
import { createTrainee } from '../../redux/actions';

const { REACT_APP_BACKEND } = process.env;

const RegisterPage = ({ t, history }) => {
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        nationalIdentity: Yup.string().min(16, `${t(`National ID is invalid`)}`),
        firstName: Yup.string().required(`${t(`First name is required`)}`),
        lastName: Yup.string().required(`${t(`Last name is required`)}`),
        gender: Yup.string().required(`${t(`Please select gender`)}`),
        dob: Yup.string().required(`${t(`Date of birth is required`)}`),
        email: Yup.string().email(`${t(`Email is Invalid`)}`),
        phone: Yup.string().min(10, `${t(`Phone number is invalid`)}`),
        centerId: Yup.string().required(`${t(`Please select training center`)}`),
    });
    const initialValues = {
        nationalIdentity: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        gender: '',
        dob: '',
        email: '',
        centerId: '',
    };

    const [residentId, setResidentId] = useState('');
    const [residentIds, setResidentIds] = useState([]);
    const [centerId, setCenterId] = useState('');
    const [nidaResponse, setNidaResponse] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [userResidence, setUserResidence] = useState({
        province: '',
        district: '',
        sector: '',
        cell: '',
        village: '',
    });
    const [identification, setIdentification] = useState({
        nationalIdentity: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        gender: '',
        dob: '',
        email: '',
    });

    const { residence, districts, sectors, cells, villages, districtLoading, sectorLoading, cellLoading, villageLoading } = useSelector(state => state.residence);
    const { centers } = useSelector(state => state.centers);
    const { success, message, error, loading } = useSelector(state => state.trainees);

    const onProvinceChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, province: value });
        dispatch(getLocationByProvince(value));
    };
    const onDistrictChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, district: value });
        dispatch(getLocationByDistrict(value));
    };
    const onSectorChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, sector: value });
        dispatch(getLocationBySector(value));
        let ids = [];
        let sectors = residence.filter(res => res.sector === value);
        sectors.forEach(c => ids.push(c.id));
        setResidentIds([...new Set(ids)]);
    };
    const onCellChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, cell: value });
        dispatch(getLocationByCell(userResidence.sector, value));
    };
    const onVillageChange = e => {
        const { value } = e.target;
        setUserResidence({ ...userResidence, village: value });
        let village = residence.filter(res => res.village === value);
        setResidentId(village[0].id);
        dispatch(getCenterLocation(residentIds));
    };

    const onCenterChange = e => {
        const { value } = e.target;
        let center = centers.filter(center => center.centerName === value);
        setCenterId(center[0].id);
        setFieldValue('centerId', center[0].id);
    };

    const { values, handleSubmit, errors, handleChange, setFieldValue, touched,setErrors } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            if (checkDates(values.dob, 12)) {
                dispatch(createTrainee({ ...values, residenceLocationId: residentId + '', trainingcenterId: values.centerId, ...userResidence }, history));
            } else {
                toast.error(`${t(`You should be above 12 years to be eligible for DAP`)}`);
                setErrors({dob:`${t(`You should be above 12 years to be eligible for DAP`)}`})
            }
        },
    });

    const handleSearch = async e => {
        e.preventDefault();
        setSearchLoading(true);
        try
        {
            const identifications = await axios.post(`${REACT_APP_BACKEND}/api/identification`, { nationalIdentification: values.nationalIdentity });
            if (identifications?.data)
            {
                const { identification } = identifications.data.data
                setIdentification({
                    natiananalIdentity: identification.documentNumber,
                    firstName: identification.foreName,
                    lastName: identification.surnames,
                    gender: identification.sex === 'M' ? 'Male' : 'Female',
                    dob: identification.dateOfBirth.split('/').reverse().join('-'),
                });
                setNidaResponse(true);
            } else
            {
                setSearchLoading(false);
                toast.error(`${t(`National ID does not exist`)} !`)
            }
        } catch (err)
        {
            toast.error(`${t(`National ID does not exist`)} !`)
            setSearchLoading(false);
        }
    };

    useEffect(() => {
        setSearchLoading(false);
        if (nidaResponse) {
            Object.keys(identification).forEach((key, i) => setFieldValue(key, Object.values(identification)[i]));
        }
    }, [nidaResponse]);

    return (
        <div className="App">
            <Header />

            <div className="body-content bg-dark-primary pt-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <BackLink path={routes.home} />
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col md={12}>
                            <div className="box">
                                <Form>
                                    <p className="text-title mb-2">{`${t(`Gusaba guhugurwa`)}`}</p>
                                    <p className="text-normal">
                                        {`${t(`Uzuza iyi fishi kugira ngo ubashe gusaba guhugurwa muri gahunda y'intore mu ikoranabuhanga`)}`}.{`${t(`Niba ufite indangamuntu, uzuzamo nimero y'indangamuntu gusa, ubundi imyirondoro yawe igaragare`)}`}
                                    </p>

                                    <p className="text-title mb-3">{`${t(`Amakuru y'ingenzi`)}`}</p>
                                    <Row>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl
                                                        placeholder={`${t(`Indangamuntu (niba uyifite)`)}`}
                                                        value={values.nationalIdentity}
                                                        name="nationalIdentity"
                                                        onChange={event => {
                                                            let sanitized = matchDigit(String(event.target.value));

                                                            sanitized = sanitized.substring(0, 16);

                                                            setFieldValue('nationalIdentity', sanitized);
                                                        }}
                                                    />

                                                    {touched.nationalIdentity && errors.nationalIdentity && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.nationalIdentity}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Button onClick={handleSearch} className="px-5">
                                                    {searchLoading ? <Spinner size="sm" animation="border" variant="light" /> : `${t(`Shakisha`)}`}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`${t(`Izina ryambere`)}`} value={values.firstName} name="firstName" onChange={handleChange} />

                                                    {touched.firstName && errors.firstName && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.firstName}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        {/*  */}
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`${t(`Izina rya kabiri`)}`} value={values.lastName} name="lastName" onChange={handleChange} />
                                                </InputGroup>

                                                {touched.lastName && errors.lastName && (
                                                    <Form.Control.Feedback className="d-block" type="invalid">
                                                        {errors.lastName}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <FormControl value={values.middleName} name="middleName" onChange={handleChange} placeholder={`${t(`Irindi zina (niba urifite)`)}`} />
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <Form.Control as="select" className="custom-select" name="gender" value={values.gender} onChange={handleChange}>
                                                        <option value="" disabled selected>{`${t(`Igitsina`)}`}</option>
                                                        <option value="Male">{`${t(`Male`)}`}</option>
                                                        <option value="Female">{`${t(`Female`)}`}</option>
                                                    </Form.Control>

                                                    {touched.gender && errors.gender && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.gender}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`${t(`Itariki y'amavuko`)}`} name="dob" value={values.dob} onChange={handleChange} type="date" />

                                                    {touched.dob && errors.dob && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.dob}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>

                                                <Form.Text className="text-muted">{`${t(`Itariki y'amavuko`)}`}</Form.Text>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl
                                                        placeholder={`${t(`Telefoni (Niba uyifite)`)}`}
                                                        name="phone"
                                                        value={values.phone}
                                                        onChange={event => {
                                                            let sanitized = matchPhone(String(event.target.value));

                                                            sanitized = sanitized.substring(0, 13);

                                                            setFieldValue('phone', sanitized);
                                                        }}
                                                    />

                                                    {touched.phone && errors.phone && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.phone}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-4">
                                            <FormGroup>
                                                <InputGroup>
                                                    <FormControl placeholder={`${t(`Email (Niba uyifite)`)}`} name="email" value={values.email} onChange={handleChange} type="email" />

                                                    {touched.email && errors.email && (
                                                        <Form.Control.Feedback className="d-block" type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <p className="text-title mb-3 mt-4">{`${t(`Aho utuye`)}`}</p>

                                    <Row>
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" name="province" onChange={onProvinceChange}>
                                                    <option>{`${t(`Intara`)}`}</option>
                                                    <option>Kigali City</option>
                                                    <option>Western Province</option>
                                                    <option>Southern Province</option>
                                                    <option>Northern Province</option>
                                                    <option>Eastern Province</option>
                                                </Form.Control>
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" name="district" onChange={onDistrictChange}>
                                                    <option>{`${t(`Akarere`)}`}</option>
                                                    {districts && districts.map((district, index) => <option key={index}>{district}</option>)}
                                                </Form.Control>
                                                {districtLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" name="sector" onChange={onSectorChange}>
                                                    <option>{`${t(`Umurenge`)}`}</option>
                                                    {sectors && sectors.map((sector, index) => <option key={index}>{sector}</option>)}
                                                </Form.Control>
                                                {sectorLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" name="cell" onChange={onCellChange}>
                                                    <option>{`${t(`Akagari`)}`}</option>
                                                    {cells && cells.map((cell, index) => <option key={index}>{cell}</option>)}
                                                </Form.Control>
                                                {cellLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" name="village" onChange={onVillageChange}>
                                                    <option>{`${t(`Umudugudu`)}`}</option>
                                                    {villages && villages.map((village, index) => <option key={index}>{village}</option>)}
                                                </Form.Control>
                                                {villageLoading && <Spinner size="sm" animation="border" variant="primary" />}
                                            </FormGroup>
                                        </Col>

                                        <Col className="col-12 col-md-6 col-lg-3">
                                            <FormGroup>
                                                <Form.Control as="select" className="custom-select" name="centerId" onChange={onCenterChange}>
                                                    <option value="" disabled selected>{`${t(`Aho uzatorezwa`)}`}</option>
                                                    {centers && centers.map((center, index) => <option key={index}>{center.centerName}</option>)}
                                                </Form.Control>
                                                {touched.centerId && errors.centerId && (
                                                    <Form.Control.Feedback className="d-block" type="invalid">
                                                        {errors.centerId}
                                                    </Form.Control.Feedback>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Button type="submit" onClick={handleSubmit} className="btn btn-primary px-5 mt-4">
                                        {loading ? <Spinner size="sm" animation="border" variant="light" /> : `${t(`Kwemeza`)}`}
                                    </Button>
                                    {/* <ToastContainer/> */}
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer />
        </div>
    );
};

export default withNamespaces()(RegisterPage);
