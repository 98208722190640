import {
    REQUEST_CREATE_COURSE,
    CREATE_COURSE_FAILED,
    CREATE_COURSE_SUCCESS,
    REQUEST_GET_COURSE,
    GET_COURSE_FAILED,
    GET_COURSE_SUCCESS,
    REQUEST_UPDATE_COURSE,
    UPDATE_COURSE_FAILED,
    UPDATE_COURSE_SUCCESS,
    REQUEST_DELETE_COURSE,
    DELETE_COURSE_FAILED,
    DELETE_COURSE_SUCCESS,
} from '../types';
import axios from 'axios';
import { toast } from 'react-toastify';

const { REACT_APP_BACKEND } = process.env;

export const createCourse =
    ({ payload, onClose, resetForm }) =>
    dispatch => {
        dispatch({ type: REQUEST_CREATE_COURSE });
        axios
            .post(`${REACT_APP_BACKEND}/api/courses`, payload)
            .then(res => {
                toast.success(res.data.message);
                dispatch({ type: CREATE_COURSE_SUCCESS, payload: res.data.message });

                onClose && onClose();
                resetForm && resetForm();
                dispatch(getAllCourses());
            })
            .catch(err => {
                dispatch({
                    type: CREATE_COURSE_FAILED,
                    payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
                });
            });
    };

export const getAllCourses = () => dispatch => {
    dispatch({ type: REQUEST_GET_COURSE });
    axios
        .get(`${REACT_APP_BACKEND}/api/courses`)
        .then(res => {
            dispatch({ type: GET_COURSE_SUCCESS, payload: res.data.data });
        })
        .catch(err => {
            dispatch({
                type: GET_COURSE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const updateCourse =
    ({ id, payload, onClose, resetForm }) =>
    dispatch => {
        dispatch({ type: REQUEST_UPDATE_COURSE, error: '' });
        axios
            .put(`${REACT_APP_BACKEND}/api/courses/${id}`, payload)
            .then(res => {
                toast.success(res.data.message);
                dispatch({ type: UPDATE_COURSE_SUCCESS, payload: res.data.message });
                onClose && onClose();
                resetForm && resetForm();
                dispatch(getAllCourses());
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
                dispatch({
                    type: UPDATE_COURSE_FAILED,
                    payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
                });
            });
    };

export const deleteCourse =
    ({ id }) =>
    dispatch => {
        dispatch({ type: REQUEST_DELETE_COURSE, error: '' });

        console.log('Course ID:', id);
        axios
            .delete(`${REACT_APP_BACKEND}/api/courses/${id}`)
            .then(res => {
                dispatch({ type: DELETE_COURSE_SUCCESS, payload: res.data.message });
                dispatch(getAllCourses());
                toast.success(res.data.message);
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
                dispatch({
                    type: DELETE_COURSE_FAILED,
                    payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
                });
            });
    };
