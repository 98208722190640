import React, { useState } from "react";
import CpanelLayout from "../../../../layouts/AdminLayout";
import ReportsContent from './reportsContent';

import { Container, Row, Col } from 'react-bootstrap';
import { useEffect } from "react";

const SpecialReports = ({ location }) => {
  const [print, setPrint] = useState(false);
  const [active, setActive] = useState(1);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const print = JSON.parse(query.get('print'));
    const active = JSON.parse(query.get('active'));

    if (print) {
      setPrint(true);
      setActive(active);
    };
  },[location.search]);
  

  if (print) {
    return (
      <div className="layout-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="p-3"><ReportsContent active={active} print={print} /></div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else {
    return (
      <CpanelLayout>
        <ReportsContent active={active} print={print} />
      </CpanelLayout>
    )
  }
};

export default SpecialReports;
