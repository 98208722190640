/* eslint-disable import/no-anonymous-default-export */
import {
  LATEST_REPORTS_ERROR,
  GET_LATEST_REPORTS,
  REQUEST_LATEST_REPORTS
} from '../types';

const initialState = {
  loading: null,
  reports: [],
  report: {},
  error: "",
  message: "",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LATEST_REPORTS:
      return {
        ...state,
        loading: true,
      }
    case GET_LATEST_REPORTS:
      return {
        ...state,
        loading: null,
        report: action.payload
      }
    case LATEST_REPORTS_ERROR:
      return {
        ...state,
        loading: null,
        error: action.payload
      }
  
    default:
      return state;
  }
}