import React, { useState, useEffect } from 'react';
import Layout from '../../../../layouts/AdminLayout';
import { Link } from 'react-router-dom';
import routes from '../../../../routes/names';

import { Row, Col, Button } from 'react-bootstrap';
import { ConfirmAttendees, Sessions, ScheduleSession, EditSession, Session, SessionDetails } from '../../components/training';

import { CoursesList } from '../../../../components/courses/';
import { createSession, getAllSessions, getAllCourses, getAllDaGroups, deleteSession, makeAttendence, updateSession, getSessionAttendees, getDaSessions } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import ZZZ from '../../../../assets/images/zzz.png';
import { toast } from 'react-toastify';

const Title = ({ children }) => {
    return (
        <div className="py-3">
            <span className="text-medium">{children}</span>
        </div>
    );
};

function Training() {
    const dispatch = useDispatch();
    const { groups } = useSelector(state => state.groups);
    const { courses } = useSelector(state => state.courses);
    const { sessions, loading, createLoading, updateLoading, success, attendees } = useSelector(state => state.sessions);
    const { trainees } = useSelector(state => state.trainees);

    const [modals, setModals] = useState({
        comfirmAttendees: false,
        scheduleSession: false,
        editSession: false,
        sessionDetails: false,
    });

    const [selectedSession, setSelectedSession] = useState({});
    const [upcomingSessions, setUpcomingSessions] = useState([]);
    const [currentSessions, setCurrentSessions] = useState([]);
    const [previousSessions, setPreviousSessions] = useState([]);

    // Confirm Session Attendees Modal
    const openComfirmAttendees = () => setModals({ ...modals, comfirmAttendees: true });
    const closeComfirmAttendees = () => setModals({ ...modals, comfirmAttendees: false });

    // Schedule Session Modal
    const openScheduleSession = () => {
        dispatch(getAllDaGroups());
        dispatch(getAllCourses());
        setModals({ ...modals, scheduleSession: true });
    };
    const closeScheduleSession = () => setModals({ ...modals, scheduleSession: false });

    //Edit Session Modal
    const openEditSession = () => {
        dispatch(getAllDaGroups());
        dispatch(getAllCourses());
        setModals({ ...modals, editSession: true });
    };
    const closeEditSession = () => setModals({ ...modals, editSession: false });

    const confirmAttendees = session => {
        openComfirmAttendees();
        // dispatch(getAllTrainees());
        dispatch(getSessionAttendees(session.id));
        setSelectedSession(session);
    };

    const editSession = session => {
        setSelectedSession(session);
        openEditSession();
    };

    // View Session Details

    const openSessionDetails = () => setModals({ ...modals, sessionDetails: true });
    const closeSessionDetails = () => setModals({ ...modals, sessionDetails: false });
    const handleScheduleSession = session => {
        dispatch(createSession(session));
    };

    const updateSessionHandle = (id, session) => {
        dispatch(updateSession(id, session));
        // console.log("====my session==>",session);
    };

    const deleteSessionHandle = id => {
        dispatch(deleteSession(id));
    };

    const getingAllSession = () => {
        dispatch(getDaSessions());
        setTimeout(() => {
            getingAllSession();
        }, 10000);
    };
    useEffect(() => {
        getingAllSession();
        dispatch(getAllCourses());
    }, []);

    useEffect(() => {
        if (success) {
            dispatch(getDaSessions());
            dispatch(getAllCourses());
            setModals({ ...modals, comfirmAttendees: false, scheduleSession: false, editSession: false });
        }
    }, [success]);

    const createAttendence = attendence => {
        closeComfirmAttendees();
        dispatch(makeAttendence({ attendence }));
    };

    const viewDetails = session => {
        setSelectedSession(session);
        openSessionDetails();
    };

    useEffect(() => {
        const pendingSessions = sessions.filter(session => session.status.toLowerCase() == 'pending');
        setUpcomingSessions(pendingSessions);

        const runningSessions = sessions.filter(session => session.status.toLowerCase() == 'current');
        setCurrentSessions(runningSessions);

        const previosSessions = sessions.filter(session => session.status.toLowerCase() == 'completed');
        setPreviousSessions(previosSessions);
    }, [sessions]);

    return (
        <Layout>
            <div className="pt-4  px-1">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <span className="text-title">Training Activities</span>

                    <div>
                        {/* <Link className="btn btn-light" to={routes.dashboard.ambassadors.assesment}>
                            {' '}
                            <i className="fas fa-chart-bar"></i>&nbsp;
                            <span>Complete Survey</span>
                        </Link> */}

                        <Button className="btn btn-light" onClick={openScheduleSession}>
                            {' '}
                            <i className="fas fa-clock-o"></i>&nbsp;
                            <span>Schedule a session</span>
                        </Button>
                    </div>
                </div>

                <div className="pt-5">
                    <Row>
                        <Col className="col-12">
                            <div>
                                <Title>Current session</Title>

                                {currentSessions.length !== 0 && <Sessions sessions={currentSessions} confirmAttendees={confirmAttendees} editSession={editSession} deleteSession={deleteSessionHandle} viewDetails={viewDetails} />}

                                <div>
                                    {currentSessions.length === 0 && (
                                        <div>
                                            <Image src={ZZZ} alt="ZZZ" width="90px" className="img-fluid" />
                                            No Session being held currently
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <Title>Upcoming sessions</Title>
                                {upcomingSessions.length !== 0 ? (
                                    <Sessions sessions={upcomingSessions} confirmAttendees={confirmAttendees} editSession={editSession} deleteSession={deleteSessionHandle} viewDetails={viewDetails} />
                                ) : (
                                    <div>
                                        <Image src={ZZZ} alt="ZZZ" width="90px" className="img-fluid" />
                                        No Pending sessions so far
                                    </div>
                                )}
                            </div>

                            <div>
                                <Title>Previous sessions</Title>
                                {previousSessions.length !== 0 ? (
                                    <Sessions sessions={previousSessions} confirmAttendees={confirmAttendees} editSession={editSession} deleteSession={deleteSessionHandle} viewDetails={viewDetails} />
                                ) : (
                                    <div>
                                        <Image src={ZZZ} alt="ZZZ" width="90px" className="img-fluid" />
                                        No Previous sessions found
                                    </div>
                                )}
                            </div>
                        </Col>

                        <Col className="col-lg-3 col-md-6 col-sm-12"></Col>
                    </Row>
                </div>
            </div>

            {/*** Course List */}

            <div className="py-3">
                <div>
                    <Title> Modules</Title>
                </div>
                {courses && <CoursesList courses={courses} />}
            </div>

            <ScheduleSession open={modals.scheduleSession} onClose={closeScheduleSession} groups={groups} courses={courses} loading={createLoading} submit={handleScheduleSession} success={success} />

            <EditSession open={modals.editSession} onClose={closeEditSession} session={selectedSession} groups={groups} loading={updateLoading} courses={courses} submit={updateSessionHandle} />

            {attendees && <ConfirmAttendees open={modals.comfirmAttendees} onClose={closeComfirmAttendees} session={selectedSession} trainees={attendees} submit={createAttendence} />}
            {attendees && <SessionDetails open={modals.sessionDetails} onClose={closeSessionDetails} session={selectedSession} trainees={attendees} submit={closeSessionDetails} />}
        </Layout>
    );
}

export default Training;
