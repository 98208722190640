import React, { useState } from "react";
import {
  Modal,
  Button,
  Container,
  Table,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

import keyBy from "lodash/keyBy";
import "./index.css";

import clx from "classnames";

function AdmitRegistrants({ open, onClose, registrants, submit, loading }) {
  const [modifiedRegistrants, setRegistrants] = useState({
    ...keyBy(registrants, "id"),
  });

  const onAdmit = (registrant) => {
    const newRegistrant = { ...registrant, admitted: true, declined: false };

    setRegistrants({
      ...modifiedRegistrants,
      [newRegistrant.id]: newRegistrant,
    });
  };

  const onDecline = (registrant) => {
    const newRegistrant = { ...registrant, declined: true, admitted: false };

    setRegistrants({
      ...modifiedRegistrants,
      [newRegistrant.id]: newRegistrant,
    });
  };

  const isAdmitted = (registrant) => registrant?.admitted === true;
  const isDeclined = (registrant) => registrant?.declined === true;

  const handleSubmit = () => {
    const payload = Object.values(modifiedRegistrants);

    return submit({ list: [...payload] });
  };

  return (
    <div>
      <Container>
        <Modal
          show={open}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="registrants"
          className="registrants"
          animation={false}
          onHide={onClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Pending registrants</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="d-flex flex-row text-small">
              {" "}
              <div>
                <span> {""} </span>
              </div>
              <div className="px-3">
                <span> {""} </span>
              </div>
              <div className="px-3">
                <span> {""} </span>
              </div>
            </div>
            <div className="confirm-attendees-scrollbar table-wrapper-scroll-y pt-4">
              <Table responsive="sm" borderless>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Names</th>

                    <th className="text-center"></th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {registrants &&
                    Object.values(modifiedRegistrants).map((registrant, i) => {
                      const admitted = isAdmitted(registrant);
                      const declined = isDeclined(registrant);
                      let admitButtonClasses = clx({
                        "bg-success": !admitted,
                        "bg-light": admitted,
                        "text-success": admitted,
                      });
                      admitButtonClasses =
                        admitButtonClasses + "   rounded  border-0 px-5";

                      let declineButtonClasses = clx({
                        "bg-danger": !declined,
                        "bg-light": declined,
                        "text-danger": declined,
                      });
                      declineButtonClasses =
                        declineButtonClasses + "   rounded  border-0 px-5";

                      return (
                        <tr key={registrant.id}>
                          <td>{i + 1}</td>
                          <td>
                            {registrant.lastName + " " + registrant.firstName}
                          </td>
                          <td>
                            {" "}
                            <div className="d-flex align-items-center justify-content-center">
                              {" "}
                              <Button
                                disabled={isAdmitted(registrant)}
                                onClick={() => {
                                  onAdmit && onAdmit(registrant);
                                }}
                                className={admitButtonClasses}
                              >
                                <span>
                                  {" "}
                                  {isAdmitted(registrant)
                                    ? "admitted"
                                    : "Admit"}
                                  &nbsp;
                                  {admitted && (
                                    <i className="fas fa-check-circle"></i>
                                  )}
                                </span>
                              </Button>
                            </div>
                          </td>

                          <td>
                            {" "}
                            <div className="d-flex align-items-center justify-content-center">
                              {" "}
                              <Button
                                disabled={declined}
                                onClick={() => {
                                  onDecline && onDecline(registrant);
                                }}
                                className={declineButtonClasses}
                              >
                                {declined ? "Declined" : "Decline"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Container className="pt-2">
              <Row>
                <Col>
                  <div className="d-flex align-items-end justify-content-end">
                    <Button
                      variant="primary"
                      className="px-5 rounded"
                      onClick={handleSubmit}
                    >
                      {loading ? (
                        <Spinner size="sm" animation="border" variant="light" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default AdmitRegistrants;
