import React from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import SlideShow from "../../components/landing-page/slide-show/SlideShow";

import CpanelLayout from "../../../../layouts/AdminLayout";

function LandingPage() {
  const [activeTab, setActiveTab] = React.useState();

  const { roles:LoggedInUserRoles } = useSelector(state => state.auth);
  const isAllowed = LoggedInUserRoles && LoggedInUserRoles.some((role)=>role==="super_admin" ||  role==="progr-manager")

  return (
    <CpanelLayout>
      <Container>
        <Row>
          <Col className="col-md-6">
            <div>
              <span className="text-title">Manage Landing Page</span>
            </div>
          </Col>
        </Row>

        <div className="pt-5">
          <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
            <Tab eventKey="content" title="Content">
              <>
              <div className="d-flex align-items-center my-3">
                      {
                        isAllowed && <button
                        className="btn text-primary"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                    >
                        <i className="fa fa-plus" /> &nbsp; Add Content
                    </button>
                      }

                      <div className="border-bottom ml-3">
                          <input
                              type="text"
                              placeholder="Search Content"
                              className="border-bottom border-0"
                          />
                          <button className="btn">
                              <i className="fa fa-search" />
                          </button>
                      </div>
                  </div>
                <div className="card border-0 shadow-sm my-2">
                  <div className="card-body">
                    <h5>Gahunda y'intore mu ikoranabuhanga</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non hendrerit risus. Integer a erat risus. Nulla facilisi. Nam sagittis velit sed tempor maximus. Maecenas urna erat, gravida eu nibh quis, tincidunt pellentesque dui. Cras non rutrum mi. Vestibulum gravida, nisi eget condimentum scelerisque, nibh orci iaculis tellus, maximus ultricies lorem magna et dui. Phasellus quis dapibus felis, ut volutpat mi. Aliquam facilisis turpis et nunc sodales, vitae tincidunt velit rhoncus. Aenean lacinia lorem eu metus bibendum egestas</p>
                    {
                      isAllowed && <div className="mt-4">
                      <button className="btn btn-primary px-5 py-1 rounded float-right">Edit</button>
                    </div>
                    }
                  </div>
                </div>
              </>
            </Tab>
            <Tab eventKey="slide-show" title="Slide Show">
              <SlideShow isAllowed={isAllowed} />
            </Tab>
            <Tab eventKey="faq" title="FAQs">
            <>  
                  <div className="d-flex align-items-center my-3">
                        {
                          isAllowed && <button
                              className="btn text-primary"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                          >
                              <i className="fa fa-plus" /> &nbsp; Add FAQ
                          </button>
                        }

                      <div className="border-bottom ml-3">
                          <input
                              type="text"
                              placeholder="Search Questions"
                              className="border-bottom border-0"
                          />
                          <button className="btn">
                              <i className="fa fa-search" />
                          </button>
                      </div>
                  </div>
                  <div className="card my-2">
                      <div className="card-body">
                          <h6>Ni gute ninjira mu rubuga</h6>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non hendrerit risus. Integer a erat risus. Nulla facilisi. Nam sagittis velit sed tempor maximus. Maecenas urna erat, gravida eu nibh quis, tincidunt pellentesque dui. Cras non rutrum mi. Vestibulum gravida, nisi eget condimentum scelerisque, nibh orci iaculis tellus, maximus ultricies lorem magna et dui. Phasellus quis dapibus felis, ut volutpat mi. Aliquam facilisis turpis et nunc sodales, vitae tincidunt velit rhoncus. Aenean lacinia lorem eu metus bibendum egestas</p>
                          
                          {
                            isAllowed && <div className="mt-4">
                                <button data-toggle="modal" data-target="#exampleModal" className="btn btn-primary px-5 py-1 rounded float-right">Edit</button>
                            </div>
                          }
                      </div>
                  </div>
              </>
            </Tab>
          </Tabs>
        </div>
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row align-items-center">
                                <div className="col-3">
                                    <h6><b>Question:</b></h6>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="form-control rounded" placeholder="Ni gute ninjira mu rubuga?" />
                                </div>
                            </div>
                            <label><b>Answer:</b></label>
                            <textarea placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non hendrerit risus. Integer a erat risus. Nulla facilisi. Nam sagittis velit sed tempor maximus. Maecenas urna erat, gravida eu nibh quis, tincidunt pellentesque dui. Cras non rutrum mi. Vestibulum gravida, nisi eget condimentum scelerisque, nibh orci iaculis tellus, maximus ultricies lorem magna et dui. Phasellus quis dapibus felis, ut volutpat mi. Aliquam facilisis turpis et nunc sodales, vitae tincidunt velit rhoncus. Aenean lacinia lorem eu metus bibendum egestas" className="form-control rounded" rows="10"></textarea>
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-primary px-5 rounded">Update</button>
                        </div>
                    </div>
                </div>
            </div>
      </Container>
    </CpanelLayout>
  );
}

export default LandingPage;
