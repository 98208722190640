import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Button,
  Container,
  Table,
  FormCheck,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import has from "lodash/has";
import omit from "lodash/omit";
import { removeObjectKey } from "../../../../../helpers";

// const trainees = [
//   { firstName: "Mutuyimana", lastName: "ange", traineeIdentity: "10340997890" },
//   {
//     firstName: "Karangwa",
//     lastName: "Joseph",
//     traineeIdentity: "103409732890",
//   },
//   { firstName: "Rurangwa", lastName: "Kevin", traineeIdentity: "1034767890" },
//   { firstName: "Umutesi", lastName: "Jovia", traineeIdentity: "00349067890" },
//   { firstName: "Kamugisha", lastName: "Robert", traineeIdentity: "8034767890" },
//   {
//     firstName: "Hategekimana",
//     lastName: "Luis",
//     traineeIdentity: "80097008890",
//   },

//   { firstName: "Mutuyimana", lastName: "ange", traineeIdentity: "1034876890" },
//   { firstName: "Karangwa", lastName: "Joseph", traineeIdentity: "10398732890" },
//   { firstName: "Rurangwa", lastName: "Kevin", traineeIdentity: "103476547890" },
//   { firstName: "Umutesi", lastName: "Jovia", traineeIdentity: "00348533890" },
//   {
//     firstName: "Kamugisha",
//     lastName: "Robert",
//     traineeIdentity: "80890667890",
//   },
// ];

function AddGroupTrainees({ trainees, open, onClose, group, submit, loading }) {
  const [attendees, setAttendees] = useState({});

  const { name, cohort } = group;

  const toggleAttendee = (trainee) => {
    if (has(attendees, trainee.id)) {
      const newAttendees = omit(attendees, [trainee.id]);

      return setAttendees(newAttendees);
    }
    setAttendees({
      ...attendees,
      [trainee.id]: {
        ...trainee,
      },
    });
  };

  const handleSubmit = () => {
    const payload = Object.keys(attendees);
    return submit(payload);
  };

  const totalTrainees = trainees.length;
  const addedTrainees = Object.values(attendees).length;

  return (
    <div>
      <Container>
        <Modal
          show={open}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="custom-modal"
          className="custom-modal"
          animation={false}
          onHide={onClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Trainees</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="d-flex flex-row text-small">
              {" "}
              <div>
                <span> {cohort && cohort.name} </span>
              </div>
              <div className="px-3">
                <span> {name} </span>
              </div>
              <div className="px-3 color-grey">
                <span>
                  {" "}
                  Trainees added : {addedTrainees}/{totalTrainees}
                </span>
              </div>
            </div>
            <div className="confirm-attendees-scrollbar table-wrapper-scroll-y pt-4">
              <Table responsive="sm" borderless>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Names</th>
                    <th>Trainee ID</th>

                    <th className="text-center">Add</th>
                  </tr>
                </thead>
                <tbody>
                  {trainees &&
                    trainees.map((trainee, i) => {
                      return (
                        <tr key={trainee.id}>
                          <td>{i + 1}</td>
                          <td>{trainee.lastName + " " + trainee.firstName}</td>
                          <td>{trainee.traineeIdentity}</td>
                          <td>
                            {" "}
                            <div className="d-flex align-items-center justify-content-center">
                              {" "}
                              <FormCheck
                                size={30}
                                type="radio"
                                checked={has(attendees, trainee.id)}
                                onClick={() => {
                                  toggleAttendee(trainee);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Container className="pt-2">
              <Row>
                <Col></Col>
                <Col className="col-lg-3 col-sm-12">
                  <div className="d-flex align-items-center justify-content-center">
                    <Button variant="primary" block onClick={handleSubmit}>
                      {loading ? (
                        <Spinner size="sm" animation="border" variant="light" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default AddGroupTrainees;
