import React from 'react';
import ReactDOM from 'react-dom';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux/store/index';

import './utils/i18n';
import { logoutUser } from './redux/actions';

// axios.interceptors.response.use(
//     response => response,
//     error => {
//         if (error.response && [401].includes(error.response.status)) {
//             // store.dispatch(logoutUser());
//             // window.location.reload();
//         }
//         return;
//     },
// );

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
