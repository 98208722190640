import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CpanelLayout from '../../../../layouts/AdminLayout';
import TraineChart from './TraineChart';
import DoughnutChart from './DoughnutChart';
import VerticalChart from './VerticalChart';
import './index.css';
import { getDasAndTraineesPerProvince, getAllUsersChart, getAllTraineesChart, getAllTraineesByAgeChart, getAllTrainingCenter, getAllAtendence, getAllAmbsadorsChart, getAllActiveAmbsadorsChart } from '../../../../redux/actions';
import Spinner from '../../../../components/spinner/Spinner';

import OverviewProvincesMap from '../../../admin-dashboard/components/overview/provinces-map/OverviewProvincesMap';
import CircleLegend from '../../../admin-dashboard/components/overview/legends/circle-legend';
import moment from 'moment';

import UserSVG from '../collaborator/user.svg';
import UsersSVG from '../collaborator/users.svg';
import HomeSVG from '../collaborator/home.svg';

function computeSum() {
    return [...arguments].reduce((acc, curr) => acc + curr);
}

function Overview() {
    const dispatch = useDispatch();
    const [showChart, setShowChart] = useState('week');

    const { success, loading, data } = useSelector(state => state.trainees);

    const allUsers = useSelector(state => state.chart.users);
    const allAmbs = useSelector(state => state.chart.ambsadors);
    const allTrainees = useSelector(state => state.chart.trainees);
    const traineeByAge = useSelector(state => state.chart.traineeByAge);

    const countUsersThisWeek = allUsers.filter(value => moment(value.createdAt).isSame(moment(), 'week')).length;
    const countAmbsThisWeek = allAmbs.filter(value => moment(value.createdAt).isSame(moment(), 'week')).length;
    const countTraineesThisWeek = allTrainees.filter(value => moment(value.createdAt).isSame(moment(), 'week')).length;

    const trainingCenter = useSelector(state => state.chart.trainingCenter);
    const { attendences } = useSelector(state => state.attendence);

    useEffect(() => {
        dispatch(getDasAndTraineesPerProvince());
        dispatch(getAllUsersChart());
        dispatch(getAllTraineesChart());
        // dispatch(getAllAmbsadorsChart());
        dispatch(getAllActiveAmbsadorsChart());
        dispatch(getAllAtendence());
        dispatch(getAllTraineesByAgeChart());
        dispatch(getAllTrainingCenter());
    }, [dispatch]);

    const TraineeCountMale = allTrainees.filter(value => value.gender.toLowerCase() === 'male').length;
    const TraineeCountFemale = allTrainees.filter(value => value.gender.toLowerCase() === 'female').length;

    const divideTraineeCount = allTrainees.length === 0 ? 1 : allTrainees.length;

    const traineeGender = { male: (TraineeCountMale * 100) / divideTraineeCount, female: (TraineeCountFemale * 100) / divideTraineeCount };

    const TraineeByGenderChart = {
        labels: [`Female (${Number(traineeGender?.female).toFixed(0)}%)`, `Male (${Number(traineeGender?.male).toFixed(0)}%)`],
        datasets: [
            {
                label: '# of Votes',
                data: [Number(traineeGender?.female).toFixed(0), Number(traineeGender?.male).toFixed(0)],
                backgroundColor: ['#cf1b33', '#078ece'],
                borderColor: ['#cf1b33', '#078ece'],
                borderWidth: 1,
            },
        ],
    };

    const traineeByAgeChart = {
        labels: traineeByAge?.labels,
        datasets: [
            {
                label: 'By Age',
                data: traineeByAge?.data,
                backgroundColor: '#078ece',
                borderColor: '#078ece',
                borderWidth: 1,
            },
        ],
    };

    const traineedByDayChart = {
        labels: [
            moment().subtract(5, 'day').format('MMMM DD'),
            moment().subtract(4, 'day').format('MMMM DD'),
            moment().subtract(3, 'day').format('MMMM DD'),
            moment().subtract(2, 'day').format('MMMM DD'),
            moment().subtract(1, 'day').format('MMMM DD'),
            moment().subtract(0, 'day').format('MMMM DD'),
        ],

        datasets: [
            {
                label: 'Trained',
                data: [
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(5, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(4, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(3, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(2, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(1, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(0, 'day'), 'day')).length,
                ],
                fill: true,
                backgroundColor: 'rgba(103, 189, 255, 0.7)',
                borderColor: '#115e82',
            },
        ],
    };

    const chartDataMonth = {
        // labels: ["Apr 5", "Apr 16", "Apr 17", "Apr 18", "Apr 19", "Apr 20", "Apr 21"],
        labels: [moment().subtract(3, 'week').format('MMMM DD'), moment().subtract(2, 'week').format('MMMM DD'), moment().subtract(1, 'week').format('MMMM DD'), moment().subtract(0, 'week').format('MMMM DD')],

        datasets: [
            {
                label: '# Trainees',
                data: [
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(30, 'day'), moment().subtract(23, 'day')), 'days', '[]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(22, 'day'), moment().subtract(15, 'day')), 'days', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(14, 'day'), moment().subtract(7, 'day')), 'days', '(])').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(6, 'day'), moment().subtract(0, 'day')), 'days', '(]').length,
                ],
                fill: true,
                backgroundColor: 'rgba(103, 189, 255, 0.7)',
                borderColor: '#115e82',
                yAxisID: 'y-axis-1',
            },
        ],
    };

    const chartDataYear = {
        // labels: ["Apr 5", "Apr 16", "Apr 17", "Apr 18", "Apr 19", "Apr 20", "Apr 21"],
        labels: [
            moment().subtract(11, 'month').format('MMMM'),
            moment().subtract(10, 'month').format('MMMM'),
            moment().subtract(9, 'month').format('MMMM'),
            moment().subtract(8, 'month').format('MMMM'),
            moment().subtract(7, 'month').format('MMMM'),
            moment().subtract(6, 'month').format('MMMM'),
            moment().subtract(5, 'month').format('MMMM'),
            moment().subtract(4, 'month').format('MMMM'),
            moment().subtract(3, 'month').format('MMMM'),
            moment().subtract(2, 'month').format('MMMM'),
            moment().subtract(1, 'month').format('MMMM'),
            moment().subtract(0, 'month').format('MMMM'),
        ],

        datasets: [
            {
                label: '# Trainees',
                data: [
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(13, 'month'), moment().subtract(12, 'month')), 'months', '[]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(12, 'month'), moment().subtract(11, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(11, 'month'), moment().subtract(10, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(10, 'month'), moment().subtract(9, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(9, 'month'), moment().subtract(8, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(7, 'month'), moment().subtract(6, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(6, 'month'), moment().subtract(5, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(5, 'month'), moment().subtract(4, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(4, 'month'), moment().subtract(3, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(3, 'month'), moment().subtract(2, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(2, 'month'), moment().subtract(1, 'month')), 'months', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(1, 'month'), moment().subtract(0, 'month')), 'months', '(]').length,
                ],
                fill: true,
                backgroundColor: 'rgba(103, 189, 255, 0.7)',
                borderColor: '#115e82',
                yAxisID: 'y-axis-1',
            },
        ],
    };

    return (
        <CpanelLayout>
            <Container>
                <Row>
                    <p className="text-title col-md-12 mb-3">Overview</p>

                    <Col lg={3}>
                        <Row>
                            <Col md={4}>
                                <Image src={UserSVG} width="70px" height="70px" className="img-fluid" alt="Image" />
                            </Col>

                            <Col md={8} className="px-0">
                                <p className="text-title">
                                    {allAmbs.length}
                                    <span className="text-small d-block">Active DAs</span>
                                    <span className="text-small d-block">
                                        {countAmbsThisWeek >= 0 && (
                                            <>
                                                <i className="fas fa-caret-up text-success"></i> +{countAmbsThisWeek} this week
                                            </>
                                        )}
                                    </span>
                                </p>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={3}>
                        <Row>
                            <Col md={4}>
                                <Image src={UsersSVG} width="70px" height="70px" className="img-fluid" alt="Image" />
                            </Col>

                            <Col md={8} className="px-0">
                                <p className="text-title">
                                    {allTrainees.length}
                                    <span className="text-small d-block">Active Trainees</span>
                                    <span className="text-small d-block">
                                        {countTraineesThisWeek >= 0 && (
                                            <>
                                                <i className="fas fa-caret-up text-success"></i> +{countTraineesThisWeek} this week
                                            </>
                                        )}
                                    </span>
                                </p>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={3}>
                        <Row>
                            <Col md={4}>
                                <Image src={HomeSVG} width="70px" height="70px" className="img-fluid" alt="Image" />
                            </Col>

                            <Col md={8} className="px-0">
                                <p className="text-title">
                                    {trainingCenter.length}
                                    <span className="text-small d-block">SAPs</span>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col md={6} sm={12}>
                        <p className="text-title col-md-12 mb-3">Citizens Trained</p>
                    </Col>
                    <Col md={6} sm={12}>
                        viewing for
                        <select onChange={e => setShowChart(e.target.value)} className="ml-2 px-3 py-1">
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                        </select>
                    </Col>

                    <Col md={12}>
                        <div className="traine-box">
                            {showChart === 'week' && <TraineChart data={traineedByDayChart} />}
                            {showChart === 'month' && <TraineChart data={chartDataMonth} />}
                            {showChart === 'year' && <TraineChart data={chartDataYear} />}
                        </div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <p className="text-title col-md-12 mb-3">Trainees</p>

                    <Col md={3}>
                        <div className="dogn-box">
                            <h5>By Gender</h5>
                            <DoughnutChart data={TraineeByGenderChart} />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="vert-box">
                            <h5>By Age</h5>
                            <VerticalChart data={traineeByAgeChart} />
                        </div>
                    </Col>
                </Row>

                {!loading && success && data ? (
                    <Row className="mt-4">
                        <Col md={5}>
                            <div className="dogn-box">
                                <h5>By Location</h5>
                                <OverviewProvincesMap data={data} />
                            </div>
                        </Col>

                        <Col md={7} className="mt-5">
                            <>
                                <CircleLegend label={computeSum(data.usersKigali, data.usersNorth, data.usersSouth, data.usersWestern, data.usersEastern) + ' DAs'} type="das" />

                                <CircleLegend label={computeSum(data.traineesKigali, data.traineesNorth, data.traineesSouth, data.traineesWestern, data.traineesEastern) + ' Trainees'} type="trainees" />

                                {/* <div style={{ marginBottom: "20px" }}>
                  <strong>{data && data.usersKigali}</strong> (DAs) Kigali city{" "}
                  <br />
                  <strong>{data && data.traineesKigali}</strong> (Trainees)
                  Kigali city
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <strong>{data && data.usersNorth}</strong> (DAs) Northern
                  Province <br />
                  <strong>{data && data.traineesNorth}</strong> (Trainees)
                  Northern Province
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <strong>{data && data.usersSouth}</strong> (DAs) Southern
                  Province <br />
                  <strong>{data && data.traineesSouth}</strong> (Trainees)
                  Southern Province
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <strong>{data && data.usersWestern}</strong> (DAs) Western
                  Province <br />
                  <strong>{data && data.traineesWestern}</strong> (Trainees)
                  Western Province
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <strong>{data && data.usersEastern}</strong> (DAs) Eastern
                  Province <br />
                  <strong>{data && data.traineesEastern}</strong> (Trainees)
                  Eastern Province
                </div> */}
                            </>
                        </Col>
                    </Row>
                ) : (
                    <div className="py-5">
                        {' '}
                        <Spinner />
                    </div>
                )}
            </Container>
        </CpanelLayout>
    );
}

export default Overview;
