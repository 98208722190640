import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
// import { useFormik } from 'formik';

// import * as Yup from 'yup';
import moment from 'moment';

const todayDate = () => {
    const date = moment().toDate().toLocaleDateString();

    return date;
};

const nextMonthfromToday = () => {
    const date = moment().add(1, 'month').calendar();

    return date;
};

function EditSession({ open, onClose, session, loading, groups, courses, submit }) {
    // const initialValues = { ...session };
    // const { values, errors, setFieldValue, handleChange, handleSubmit } =
    //   useFormik({
    //     initialValues,
    //     onSubmit: (values) => {
    //       // return submit(session.id, values);
    //       console.log(values)
    //     },
    //   });

    const [values, setValues] = useState({
        groupId: '',
        courseId: '',
        startAt: '',
        endAt: '',
        date: '',
    });

    const [data, setData] = useState({
        group: '',
        course: '',
    });

    useEffect(() => {
        setValues({ ...values, ...session, date: session?.date?.split(' ')[0] });
        console.log('==date-format==>', session?.date?.split(' ')[0]);
        if (groups.length !== 0 && courses.length !== 0) {
            let theCourse = courses.filter(course => course.id === session.courseId);
            let theGroup = groups.filter(group => group.id === session.groupId);
            setData({ ...data, course: theCourse[0]?.title, group: theGroup[0]?.name });
        }
    }, [session, groups, courses]);

    const onGroupChange = e => {
        let theGroup = groups.filter(group => group.name === e.target.value);
        setData({ ...data, group: e.target.value });
        setValues({ ...values, groupId: theGroup[0].id });
    };
    const onCourseChange = e => {
        let theCourse = courses.filter(course => course.title === e.target.value);
        setData({ ...data, course: e.target.value });
        setValues({ ...values, courseId: theCourse[0].id });
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setValues(session => ({ ...session, [name]: value }));
    };
    return (
        <div>
            <Container>
                <Modal show={open} size="md" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit scheduled session</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Container>
                            <Row>
                                <Form.Group as={Col} className="col-12">
                                    <Form.Control as="select" className="custom-select" name="group" value={data.group} onChange={onGroupChange}>
                                        {/* <option value="" selected disabled>
                      {" "}
                      Group
                    </option> */}
                                        {groups &&
                                            groups.map(group => (
                                                <option selected={group.name === data.group ? true : false} value={group.name}>
                                                    {group.name}
                                                </option>
                                            ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} className="col-12">
                                    <Form.Control as="select" className="custom-select" name="module" value={data.course} onChange={onCourseChange}>
                                        {courses &&
                                            courses.map(course => (
                                                <option selected={course.title === data.course ? true : false} value={course.title}>
                                                    {course.title}
                                                </option>
                                            ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group as={Col} className="col-12">
                                    <Form.Control type="time" placeholder="Time" min="08:00" max="17:00" name="startAt" value={values.startAt} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group as={Col} className="col-12">
                                    <Form.Control type="date" placeholder="Date" min={todayDate()} max={nextMonthfromToday()} name="date" value={values.date} onChange={handleChange} />
                                </Form.Group>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="pt-2">
                            <Row>
                                <Col className="col-9">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Button
                                            variant="primary"
                                            className="btn-primary"
                                            block
                                            // onClick={()=>console.log("===values===>", values)}
                                            onClick={() => submit(session.id, values)}
                                        >
                                            {loading ? <Spinner size="sm" animation="border" variant="light" /> : 'Schedule Session'}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
}

export default EditSession;
