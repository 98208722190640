export const REQUEST_CREATE_ATTENDENCE = "REQUEST_CREATE_ATTENDENCE";
export const CREATE_ATTENDENCE_SUCCESS = "CREATE_ATTENDENCE_SUCCESS";
export const CREATE_ATTENDENCE_FAILED = "CREATE_ATTENDENCE_FAILED";

export const REQUEST_GET_ATTENDENCE = "REQUEST_GET_ATTENDENCE";
export const GET_ATTENDENCE_SUCCESS = "GET_ATTENDENCE_SUCCESS";
export const GET_ATTENDENCE_FAILED = "GET_ATTENDENCE_FAILED";

export const REQUEST_GET_ATTENDENCES = "REQUEST_GET_ATTENDENCES";
export const GET_ATTENDENCES_SUCCESS = "GET_ATTENDENCES_SUCCESS";
export const GET_ATTENDENCES_FAILED = "GET_ATTENDENCES_FAILED";

export const REQUEST_UPDATE_ATTENDENCE = "REQUEST_UPDATE_ATTENDENCE";
export const UPDATE_ATTENDENCE_SUCCESS = "UPDATE_ATTENDENCE_SUCCESS";
export const UPDATE_ATTENDENCE_FAILED = "UPDATE_ATTENDENCE_FAILED";

export const REQUEST_DELETE_ATTENDENCE = "REQUEST_DELETE_ATTENDENCE";
export const DELETE_ATTENDENCE_SUCCESS = "DELETE_ATTENDENCE_SUCCESS";
export const DELETE_ATTENDENCE_FAILED = "DELETE_ATTENDENCE_FAILED";