import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Table } from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import AdminLayout from '../../../../layouts/AdminLayout';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import axios from 'axios';
import { getAllTraineesByDa, getTraineesAgeRangeByDa, getAllSessions, getDaAtendence, getUserInfo, getDaCenters, getAllDaGroups, getAtendenceByGroup } from '../../../../redux/actions';
import AddTraning from '../../../admin-dashboard/components/sap/add-sap/AddTraning';
import { reverse } from 'named-urls';
import { Link } from 'react-router-dom';
import routes from '../../../../routes/names';

const DAOverView = () => {
    const dispatch = useDispatch();

    const [modals, setModals] = useState({
        addTrainingCenter: false,
        // updateTrainingCenter: false,
        addTransaction: false,
        addService: false,
    });

    const [trainingCenterInfo, setTrainingCenterInfo] = useState({});
    const [showChart, setShowChart] = useState('week');
    const [centerName, setCenterName] = useState('All');
    const openAddTrainingCenterModal = () => setModals({ ...modals, addTrainingCenter: true });
    const closeAddTrainingCenterModal = () => setModals({ ...modals, addTrainingCenter: false });

    // const openUpdateTrainingCenterModal = () => setModals({ ...modals, updateTrainingCenter: true });
    // const closeUpdateTrainingCenterModal = () => {
    //     setTrainingCenterInfo({});
    //     setModals({ ...modals, updateTrainingCenter: false });
    // };

    useEffect(() => {
        const token = jwtDecode(localStorage.getItem('IdToken'));
        const userID = token?.id;
        dispatch(getAllTraineesByDa());
        dispatch(getTraineesAgeRangeByDa());
        dispatch(getAllSessions());
        dispatch(getDaAtendence());
        dispatch(getUserInfo(userID));
        dispatch(getDaCenters(userID));
        dispatch(getAllDaGroups());
    }, [dispatch]);
    const data = [1, 2];

    const { trainees } = useSelector(state => state.trainees);
    const traineeByAge = useSelector(state => state.chart.traineeByAge);
    const { sessions } = useSelector(state => state.sessions);
    const { attendences } = useSelector(state => state.attendence);
    const { userInfos: userDetail } = useSelector(state => state.users);
    const { centers } = useSelector(state => state.centers);
    const { groups } = useSelector(state => state.groups);

    const [upcomingSessions, setUpcomingSessions] = useState([]);
    // const [userDetail, setUserDetail] = useState({});

    useEffect(() => {
        const pendingSessions = sessions.filter(session => session.status.toLowerCase() === 'pending');
        setUpcomingSessions(pendingSessions);
    }, [sessions]);

    const latestSession = upcomingSessions.length - 1;

    const handleAttendencesChange = e => {
        if (e.target.value === 'All') {
            dispatch(getDaAtendence());
            setCenterName('All');
        } else {
            dispatch(getAtendenceByGroup(e.target.value));
            const selectedGroup = groups.filter(group => group.id === e.target.value);
            setCenterName(selectedGroup[0].trainingCenterName);
        }
    };

    const chartDataWeek = {
        // labels: ["Apr 5", "Apr 16", "Apr 17", "Apr 18", "Apr 19", "Apr 20", "Apr 21"],
        labels: [
            moment().subtract(6, 'day').format('MMMM DD'),
            moment().subtract(5, 'day').format('MMMM DD'),
            moment().subtract(4, 'day').format('MMMM DD'),
            moment().subtract(3, 'day').format('MMMM DD'),
            moment().subtract(2, 'day').format('MMMM DD'),
            moment().subtract(1, 'day').format('MMMM DD'),
            moment().subtract(0, 'day').format('MMMM DD'),
        ],

        datasets: [
            {
                label: '# Trainees',
                data: [
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(6, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(5, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(4, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(3, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(2, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(1, 'day'), 'day')).length,
                    attendences.filter(value => moment(value.createdAt).isSame(moment().subtract(0, 'day'), 'day')).length,
                ],
                fill: true,
                backgroundColor: 'rgba(103, 189, 255, 0.7)',
                borderColor: '#115e82',
                yAxisID: 'y-axis-1',
            },
        ],
    };
    const chartDataMonth = {
        // labels: ["Apr 5", "Apr 16", "Apr 17", "Apr 18", "Apr 19", "Apr 20", "Apr 21"],
        labels: [moment().subtract(3, 'week').format('MMMM DD'), moment().subtract(2, 'week').format('MMMM DD'), moment().subtract(1, 'week').format('MMMM DD'), moment().subtract(0, 'week').format('MMMM DD')],

        datasets: [
            {
                label: '# Trainees',
                data: [
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(30, 'day'), moment().subtract(23, 'day')), 'days', '[]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(22, 'day'), moment().subtract(15, 'day')), 'days', '(]').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(14, 'day'), moment().subtract(7, 'day')), 'days', '(])').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(6, 'day'), moment().subtract(0, 'day')), 'days', '(]').length,
                ],
                fill: true,
                backgroundColor: 'rgba(103, 189, 255, 0.7)',
                borderColor: '#115e82',
                yAxisID: 'y-axis-1',
            },
        ],
    };
    const chartDataYear = {
        // labels: ["Apr 5", "Apr 16", "Apr 17", "Apr 18", "Apr 19", "Apr 20", "Apr 21"],
        labels: [
            moment().subtract(11, 'month').format('MMMM'),
            moment().subtract(10, 'month').format('MMMM'),
            moment().subtract(9, 'month').format('MMMM'),
            moment().subtract(8, 'month').format('MMMM'),
            moment().subtract(7, 'month').format('MMMM'),
            moment().subtract(6, 'month').format('MMMM'),
            moment().subtract(5, 'month').format('MMMM'),
            moment().subtract(4, 'month').format('MMMM'),
            moment().subtract(3, 'month').format('MMMM'),
            moment().subtract(2, 'month').format('MMMM'),
            moment().subtract(1, 'month').format('MMMM'),
            moment().subtract(0, 'month').format('MMMM'),
        ],

        datasets: [
            {
                label: '# Trainees',
                data: [
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(13, 'month'), moment().subtract(12, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(12, 'month'), moment().subtract(11, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(11, 'month'), moment().subtract(10, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(10, 'month'), moment().subtract(9, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(9, 'month'), moment().subtract(8, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(7, 'month'), moment().subtract(6, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(6, 'month'), moment().subtract(5, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(5, 'month'), moment().subtract(4, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(4, 'month'), moment().subtract(3, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(3, 'month'), moment().subtract(2, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(2, 'month'), moment().subtract(1, 'month')), 'months', '[)').length,
                    attendences.filter(value => moment(value.createdAt).isBetween(moment().subtract(1, 'month'), moment().subtract(0, 'month')), 'months', '[]').length,
                ],
                fill: true,
                backgroundColor: 'rgba(103, 189, 255, 0.7)',
                borderColor: '#115e82',
                yAxisID: 'y-axis-1',
            },
        ],
    };

    const femaleTrainees = trainees.filter(trainee => trainee.gender === 'Female').length;
    const maleTrainees = trainees.filter(trainee => trainee.gender === 'Male').length;

    const ByGenderChart = {
        labels: ['Male', 'Female'],
        datasets: [
            {
                label: '# of Votes',
                data: [maleTrainees ?? 0, femaleTrainees ?? 0],
                backgroundColor: ['#078ece', '#cf1b33'],
                borderColor: ['#078ece', '#cf1b33'],
                borderWidth: 1,
            },
        ],
    };

    const traineeByAgeChart = {
        labels: traineeByAge?.labels,
        datasets: [
            {
                label: 'Trainees',
                data: traineeByAge?.data,
                backgroundColor: '#078ece',
                borderColor: '#078ece',
                borderWidth: 1,
            },
        ],
    };

    return (
        <AdminLayout>
            <Container className="py-3">
                <Row>
                    <Col md={12}>
                        <h2>Overview</h2>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col md={12}>
                        <div className="d-flex justify-content-between">
                            <div>
                                <h4>Citizens Trained</h4>
                            </div>
                            <Row>
                                <Col md={6} sm={12}>
                                    Groups {'=>'} {centerName}
                                    <select onChange={e => handleAttendencesChange(e)} className="ml-2 mr-4 px-3 py-1">
                                        <option>All</option>
                                        {groups.map(single => (
                                            <option value={single.id} key={single.id}>
                                                {single.name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col md={6} sm={12}>
                                    viewing for
                                    <select onChange={e => setShowChart(e.target.value)} className="ml-2 px-3 py-1">
                                        <option value="week">Week</option>
                                        <option value="month">Month</option>
                                        <option value="year">Year</option>
                                    </select>
                                </Col>
                            </Row>
                        </div>
                        {showChart === 'week' && <Line data={chartDataWeek} height="70px" />}
                        {showChart === 'month' && <Line data={chartDataMonth} height="70px" />}
                        {showChart === 'year' && <Line data={chartDataYear} height="70px" />}
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col md={8}>
                        <Row>
                            <Col>
                                <h4>Your Training Centers</h4>
                            </Col>
                            <Col>
                                <i className="fas fa-plus text-primary  text-medium cursor" onClick={openAddTrainingCenterModal}></i>
                            </Col>
                        </Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th> Center Name</th>
                                    <th>Location</th>
                                </tr>
                            </thead>

                            <tbody>
                                {userDetail.DAInfo?.map(data => (
                                    <tr>
                                        <td>
                                            {' '}
                                            <Link to={reverse(routes.dashboard.sap.view, { id: data.id })}>{data.centerName} </Link>{' '}
                                        </td>
                                        <td>{`${userDetail?.district}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>

                    <Col md={4}>
                        {upcomingSessions.length ? (
                            <div className="shadow-sm px-4 py-3">
                                <h4>
                                    Next Session
                                    {/* <i className="fas fa-pen text-primary"></i> */}
                                </h4>
                                <p className="mt-4 mb-2">Date:&nbsp; {moment(upcomingSessions[latestSession].date).format('MMM Do YYYY')}</p>
                                <p className="mb-2">Time:&nbsp;{upcomingSessions[latestSession].startAt}</p>
                                <p className="mb-2">Group: &nbsp;{upcomingSessions[latestSession].groups ? upcomingSessions[latestSession].groups.name : null}</p>
                                <p className="mb-2">Center: &nbsp;{upcomingSessions[latestSession].groups ? upcomingSessions[latestSession].groups.trainingCenterName : null}</p>
                            </div>
                        ) : (
                            <>
                                <p>No next session available</p>
                            </>
                        )}
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col md={12}>
                        <h4>Your Trainees</h4>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col md={3}>
                        <p>By Gender</p>
                        <Doughnut data={ByGenderChart} />
                    </Col>
                    <Col md={6}>
                        <p>By Age</p>
                        <Bar data={traineeByAgeChart} />
                    </Col>
                </Row>
            </Container>

            <AddTraning user={userDetail} title="New Training Center" open={modals.addTrainingCenter} onClose={closeAddTrainingCenterModal} />
        </AdminLayout>
    );
};

export default DAOverView;
