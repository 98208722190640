import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import moment from 'moment';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { reverse } from 'named-urls';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useDispatch, useSelector } from 'react-redux';
import { getAllCenters, deleteCenterLocation } from '../../../../redux/actions';

import routes from '../../../../routes/names';
import Layout from '../../../../layouts/AdminLayout';
import AddAdminSap from '../../../admin-dashboard/components/sap/add-sap/AddAdminSap';
import UpdateTraining from '../../../admin-dashboard/components/sap/update-sap/UpdateTraning';
import Spinner from '../../../../components/spinner/Spinner';
import { SpinnerWrapper } from '../roles/viewRoles';
import { emptifyFalseValues } from '../../../../helpers';

const sanitizeData = centers =>
    centers.map((center, index) => {
        const centerInfo = {
            ...center,
            province: center.Location.province,
            district: center.Location.district,
            sector: center.Location.sector,
            cell: center.Location.cell,
            index: index + 1,
            daInCharge: (center && center.DAInfo && `${center.DAInfo.firstName} ${center.DAInfo.lastName}`) || '',
            dateOpened: isEmpty(center.createdAt) ? '' : moment(center.createdAt).format('YYYY-MM-DD'),
        };

        return { ...emptifyFalseValues(centerInfo) };
    });

export default function TraningManagement() {
    const dispatch = useDispatch();
    const [modals, setModals] = useState({ addTrainingCenter: false, updateTrainingCenter: false });
    const [isLoading, setIsLoading] = useState(true);

    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    const [userDetail, setUserDetail] = useState({});
    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const [trainingCenterInfo, setTrainingCenterInfo] = useState({});
    const openAddTrainingCenterModal = () => setModals({ ...modals, addTrainingCenter: true });
    const closeAddTrainingCenterModal = () => setModals({ ...modals, addTrainingCenter: false });

    const openUpdateTrainingCenterModal = () => setModals({ ...modals, updateTrainingCenter: true });
    const closeUpdateTrainingCenterModal = () => {
        setTrainingCenterInfo({});
        dispatch(getAllCenters());
        setModals({ ...modals, updateTrainingCenter: false });
    };

    useEffect(() => {
        if (isLoading) {
            dispatch(getAllCenters());
            setIsLoading(false);
        }

        (async () => {
            const token = jwtDecode(localStorage.getItem('IdToken'));
            const userID = token?.id;
            const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/users/${userID}`);
            setUserDetail(data?.data);
        })();
    }, [isLoading]);

    const { loading, allCenters } = useSelector(state => state.centers);
    const { roles } = useSelector(state => state.auth);
    const onDeleteTrainingCenterHandler = centerId => {
        dispatch(deleteCenterLocation(centerId));
    };
    const renderOptons = (cell, row) => {
        const id = row.id;
        const editSap = () => {
            setTrainingCenterInfo({});
            openUpdateTrainingCenterModal();
            setTrainingCenterInfo(row);
        };

        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle variant="light" className="text-dark">
                        Actions &nbsp; <i class="fas fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {!roles.includes('SU') && (
                            <Dropdown.Item onClick={editSap}>
                                <i class="fas fa-pencil-alt text-primary "></i> &nbsp;Edit
                            </Dropdown.Item>
                        )}
                        {!roles.includes('SU') && (
                            <Dropdown.Item onClick={() => onDeleteTrainingCenterHandler(id)}>
                                <span className="text-small">
                                    <i class="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                                </span>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item>
                            <Link to={reverse(routes.dashboard.sap.view, { id })}>
                                <span className="text-small">
                                    <i class="fas fa-info-circle text-primary "></i> &nbsp; More details
                                </span>
                            </Link>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const columns = [
        { dataField: 'index', text: '#', csvExport: false },
        { dataField: 'centerName', text: 'Center name' },
        { dataField: 'province', text: 'Province' },
        { dataField: 'district', text: 'District' },
        { dataField: 'sector', text: 'Sector' },
        { dataField: 'cell', text: 'Cell' },
        { dataField: 'daInCharge', text: 'DA in charge' },
        { dataField: 'dateOpened', text: 'Date Opened' },
        { dataField: 'options', text: 'Options', formatter: renderOptons, headerClasses: 'pull-right', csvExport: false },
    ];

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: 'All', value: Array.isArray(allCenters) ? allCenters.length : 0 },
        ],
    };

    return (
        <Layout>
            <Container>
                <Row>
                    <Col className="col-6">
                        <div>
                            <span className="text-title">Training Centers management</span>
                        </div>
                    </Col>

                    <Col className="col-6">
                        <div className="d-flex align-items-end justify-content-end">
                            {!roles.includes('SU') && (
                                <Button className="btn btn-light" onClick={openAddTrainingCenterModal}>
                                    <i className="fa fa-plus" aria-hidden="true"></i>&nbsp;
                                    <span className="text-dark">Add Training Center</span>
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>

                <ToolkitProvider
                    keyField="index"
                    data={sanitizeData([...allCenters])}
                    columns={columns}
                    search
                    exportCSV={{
                        fileName: `DAP-TRAINING-CENTERS-${new Date().toLocaleTimeString()}.csv`,
                        separator: ',',
                        ignoreHeader: false,
                        noAutoBOM: false,
                    }}
                >
                    {props => (
                        <div className="pt-4  px-1 material">
                            <span className="text-title">Working Training</span>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                        <i class="fas fa-file-excel text-small"></i> &nbsp;Export to CSV
                                    </ExportCSVButton>
                                </div>
                                <div className="d-flex align-items-center">
                                    <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0" placeholder="Search Traning ..." />
                                </div>
                            </div>
                            <div
                                className="pt-3"
                                {...{
                                    style: {
                                        ...{ position: loading ? 'relative' : '' },
                                    },
                                }}
                            >
                                <BootstrapTable classes="data-table" bordered={false} bootstrap4 {...props.baseProps} pagination={paginationFactory(options)} options={options} />
                                {loading && (
                                    <SpinnerWrapper>
                                        <Spinner isUpload={true} />
                                    </SpinnerWrapper>
                                )}
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
            </Container>

            <AddAdminSap user={userDetail} title="New Training Center" open={modals.addTrainingCenter} onClose={closeAddTrainingCenterModal} />
            {!isEmpty(trainingCenterInfo) && <UpdateTraining open={modals.updateTrainingCenter} onClose={closeUpdateTrainingCenterModal} trainingCenterInfo={trainingCenterInfo} />}
        </Layout>
    );
}
