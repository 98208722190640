import React from 'react';
import { Modal, Button, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import './index.css';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { createGroup } from '../../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

function AddGroup({ centers, open, onClose }) {
    const dispatch = useDispatch();
    const { createLoading, success } = useSelector(state => state.groups);
    const validationSchema = Yup.object().shape({
        center: Yup.string().required('Please select training center'),
    });

    const initialValues = {
        center: '',
        description: '',
        inNextCohort: false,
    };

    const { values, errors, handleChange, handleSubmit } = useFormik({
        validationSchema,
        initialValues,
        onSubmit: values => {
            dispatch(
                createGroup({
                    trainingCenterName: values.center,
                    description: values.description,
                    inNextCohort: values.inNextCohort,
                }),
            );
        },
    });

    return (
        <div>
            <Container>
                <Modal show={open} size="md" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Group</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form>
                            {/** Form section - Identity*/}

                            <Row className="py-1">
                                <Col>
                                    {' '}
                                    <Form.Group>
                                        <Form.Control as="select" className="custom-select" name="center" value={values.center} onChange={handleChange}>
                                            <option value="" selected disabled>
                                                Training centre
                                            </option>
                                            {centers && centers.map((center, index) => <option key={index}>{center.centerName}</option>)}
                                        </Form.Control>

                                        {errors.center && (
                                            <Form.Control.Feedback className="d-block" type="invalid">
                                                {errors.center}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="py-1">
                                <Col>
                                    <Form.Group>
                                        <Form.Control value={values.description} placeholder="Group Description" as="textarea" rows="5" name="description" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div>
                                <span className="text-small">The group you are creating belongs to the {moment(new Date()).format('MMM YYYY')} cohort. Check the box below if you wish to add it to the next cohort instead.</span>
                            </div>

                            <div className="py-3">
                                <div className="d-flex align-items-center text-normal ">
                                    <Form.Check type="checkbox" value={values.inNextCohort} name="inNextCohort" onChange={handleChange} />
                                    <span className="text-normal color-grey">This group will be part of the next cohort</span>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleSubmit}>
                            {createLoading ? <Spinner size="sm" animation="border" variant="light" /> : 'Create Group'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
}

export default AddGroup;
