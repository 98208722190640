import React, { useState, useEffect } from 'react';
import clx from 'classnames';
import Layout from '../../../../layouts/AdminLayout';
import * as XLSX from 'xlsx';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Dropdown, Button } from 'react-bootstrap';
import './index.css';
import AddMaterial from '../../components/sap/add-material/AddMaterial';
import { useDispatch, useSelector } from 'react-redux';
import { listMaterial, updateMaterial, deleteTrainingCenterMaterial } from '../../../../redux/actions/materials';

import { csvToJson, emptifyFalseValues } from '../../../../helpers';
import Spinner from '../../../../components/spinner/Spinner';
import { toast } from 'react-toastify';
import Axios from 'axios';
import moment from 'moment';
import { getSingleCenter } from '../../../../redux/actions';
import { Link, useParams } from 'react-router-dom';
import routes from '../../../../routes/names';
import { useFileDownload } from '../../../../hooks';

import { Row, Col, Breadcrumb } from "react-bootstrap";
import Routes from "../../../../routes/names";
import { reverse } from 'named-urls';

const { ExportCSVButton } = CSVExport;

const sanitizeData = items =>
    items.map(item => ({
        ...emptifyFalseValues(item),
        updatedAt: moment(item.updatedAt).format('YYYY-MM-DD'),
    }));

function Material({ search }) {
    const { id: centerId } = useParams();
    const [modals, setModals] = useState({
        addMaterial: false,
    });
    const [isLoading, setIsLoading] = useState(false);

    const { singleCenter, loading, error } = useSelector(state => state.centers);
    const { roles } = useSelector(state => state.auth);
    const materials = singleCenter?.Material || [];

    const removeInvalidData = data => {
        /* Remove invalid data and duplicates */
        return [...new Set(data.filter(el => Boolean(el.name) && Boolean(el.materialType)))];
    };

    const [fileJsonData, setfileJsonData] = useState([]);

    const { downloading, onDownload } = useFileDownload('/assets/templates/dap_materials.xlsx', 'dap_materials_template.xlsx');

    const fileInputRef = React.useRef();

    const { SearchBar } = Search;

    const renderID = (cell, row, rowIndex) => {
        return <span>{rowIndex + 1}</span>;
    };

    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const renderStatus = (cell, row) => {
        const isDamaged = String(row.status).toLocaleLowerCase() === 'damaged';

        const classes = clx({ 'text-danger': isDamaged });
        return <span className={`${classes}`}> {row.status} </span>;
    };

    const renderOptons = (cell, row) => {
        const id = row.id;
        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle disabled={roles.includes('SU')} variant="light" className="text-dark">
                        Actions &nbsp; <i class="fas fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className="pl-3">
                            <Dropdown drop="left">
                                <Dropdown.Toggle variant="light" className="text-dark text-small">
                                    <i class="fas fa-angle-left"></i> &nbsp; <i class="fas fa-pencil-alt text-primary "></i> &nbsp;Edit Status
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleUpdateMaterial(row.id, 'functional')}>
                                        <span className="text-small">Functional</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleUpdateMaterial(row.id, 'damaged')}>
                                        <span className="text-small"> Damaged</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleUpdateMaterial(row.id, 'removed')}>
                                        {' '}
                                        <span className="text-small">Removed</span>{' '}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Dropdown.Item onClick={() => handleDeleteMaterial(row.id)}>
                            <span className="text-small">
                                {' '}
                                <i class="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                            </span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const columns = [
        { dataField: 'id', text: '#', formatter: renderID, csvExport: false },
        { dataField: 'name', text: 'Item', csvExport: true, sort: true },
        { dataField: 'sapmaterialIdentity', text: 'Item Code' },
        {
            dataField: 'status',
            text: 'Status',
            formatter: renderStatus,
            csvExport: true,
            sort: true,
        },
        { dataField: 'origin', text: 'Origin', csvExport: true, sort: true },
        {
            dataField: 'updatedAt',
            text: 'Date Modified',
            formatter: (cell, row) => moment(row).format('YYYY-MM-DD'),
            csvExport: true,
            sort: true,
        },
        {
            dataField: 'options',
            text: 'Options',
            formatter: renderOptons,
            headerClasses: 'pull-right',
            csvExport: false,
        },
    ];

    const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            {
                text: '5',
                value: 5,
            },
            {
                text: '10',
                value: 10,
            },
            {
                text: 'All',
                value: materials?.length || 0,
            },
        ],
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (centerId) {
            dispatch(getSingleCenter(centerId));
        }
    }, [dispatch, centerId]);

    const openAddMaterial = () => setModals({ ...modals, addMaterial: true });
    const closeAddMaterial = () => setModals({ ...modals, addMaterial: false });

    const handleUpdateMaterial = (id, status) => {
        dispatch(updateMaterial({ id, status, centerId }));
    };

    const handleDeleteMaterial = materialId => {
        dispatch(deleteTrainingCenterMaterial(materialId, centerId));
    };

    const uploadSpreadsheet = async e => {
        e.stopPropagation();
        e.preventDefault();
        setIsLoading(true);
        let file = e.target.files[0];
        await readFile(file);
    };

    const readFile = async rawData => {
        let file = rawData;

        const reader = new FileReader();
        reader.onload = async evt => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            const jsonData = removeInvalidData(csvToJson(data));
            setfileJsonData(jsonData);

            /** Note: Backend team should use this data as payload  */
            const dapMaterials = { dapMaterialList: jsonData };
            try {
                const response = await Axios.post(`${process.env.REACT_APP_BACKEND}/api/sapmaterials/imported`, dapMaterials);
                if (response.status === 201) {
                    toast.success(response.data.message);
                }
            } catch (error) {
                if (error.response.status === 400) {
                    toast.error(error.response.data.error);
                }
            }
            dispatch(listMaterial());
            setIsLoading(false);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        };
        reader.readAsBinaryString(file);
    };

    return (
        <Layout>
            <ToolkitProvider
                keyField="id"
                data={sanitizeData([...fileJsonData, ...materials])}
                columns={columns}
                search
                exportCSV={{
                    fileName: `DAP-SAP-MATERIALS-${new Date().toLocaleTimeString()}.csv`,
                    separator: ',',
                    ignoreHeader: false,
                    noAutoBOM: false,
                }}
            >
                {props => (
                    <div className="pt-4  px-1 material">
                        <Row>
                            <Col md={12}>
                                <Breadcrumb>
                                    <Breadcrumb.Item className='text-primary'>
                                        <Link to='/cpanel/overview'>Overview</Link>
                                    </Breadcrumb.Item>
                                    
                                    <Breadcrumb.Item className='text-primary'>
                                        <Link to={reverse(Routes.dashboard.sap.view, { id: centerId })}>SAP Management</Link>
                                    </Breadcrumb.Item>
                                    
                                    <Breadcrumb.Item active>SAP Materials</Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <span className="text-title">SAP Materials</span>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                    <i class="fas fa-file-excel text-small"></i> &nbsp;Export to Excel
                                </ExportCSVButton>
                            </div>

                            <div className="d-flex align-items-center">
                                <Dropdown drop="down">
                                    {!roles.includes("SU") && <Dropdown.Toggle variant="light" className="text-dark text-small">
                                        <span>
                                            {' '}
                                            <i className="fas fa-plus text-primary" aria-hidden="true">
                                                {' '}
                                            </i>
                                            &nbsp; Add Material
                                        </span>
                                    </Dropdown.Toggle>}
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={openAddMaterial}>
                                            {' '}
                                            <i className="fas fa-plus text-primary"></i>&nbsp;
                                            <span>Add single material</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as="div"
                                            role="button"
                                            className="cursor-pointer"
                                            onClick={() => {
                                                fileInputRef.current?.click();
                                            }}
                                        >
                                            <input type="file" id="file" ref={fileInputRef} onChange={uploadSpreadsheet} accept=".xlsx, .xls, .csv" multiple={false} />

                                            <span>
                                                <i class="fas fa-file-upload text-primary"></i>
                                                &nbsp; Import from file
                                            </span>
                                        </Dropdown.Item>

                                        <Dropdown.Item as="button" role="button" disabled={downloading} onClick={onDownload}>
                                            <span>
                                                <i className="fas fa-file-export text-primary"></i>
                                                &nbsp; {downloading ? 'Downloading' : 'Download Template'}
                                            </span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0 text-small" placeholder="Search Participants ..." />
                            </div>
                        </div>

                        <div className="pt-2">{isLoading ? <Spinner /> : <BootstrapTable bordered={false} {...props.baseProps} pagination={paginationFactory(options)} options={options} bootstrap4 />}</div>
                    </div>
                )}
            </ToolkitProvider>

            <AddMaterial id={centerId} open={modals.addMaterial} onClose={closeAddMaterial} />
        </Layout>
    );
}

export default Material;
