import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Container, Table, FormCheck, Row, Col, Form, Spinner } from 'react-bootstrap';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';

function ConfirmAttendees({ trainees, open, onClose, session, submit }) {
    const [attendees, setAttendees] = useState({});
    // const [list, setList] = useState([]);
    const [observation, setObservation] = useState('');

    const { name, cohort, date, time, completed, attendeesConfirmed, group } = session;

    const { createLoading } = useSelector(state => state.attendence);
    const toggleAttendee = trainee => {
        if (has(attendees, trainee.id)) {
            const newAttendees = omit(attendees, [trainee.id]);

            return setAttendees(newAttendees);
        }
        setAttendees({
            ...attendees,
            [trainee.id]: {
                ...trainee,
            },
        });
    };

    const handleChange = event => {
        setObservation(event.target.value);
    };

    const handleSubmit = () => {
        const payload = {
            list: Object.keys(attendees),
            observation,
            sessionId: session.id,
        };
        console.log("===the payload===>",payload);
        if (payload.list.length !== 0)
        {
            return submit(payload);
        }else
        {
            toast.error("There is no Student selected for this attendance")
        }
    };

    return (
        <div>
            <Container>
                <Modal show={open} size="lg" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm attendance</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="d-flex flex-row text-small">
                            {' '}
                            <div>
                                <span> {cohort} </span>
                            </div>
                            <div className="px-3">
                                <span> {group} </span>
                            </div>
                            <div className="px-3">
                                <span> {name} </span>
                            </div>
                        </div>
                        <div className="confirm-attendees-scrollbar table-wrapper-scroll-y pt-4">
                            <Table responsive="sm" borderless>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Names</th>
                                        <th>Trainee ID</th>

                                        <th className="text-center">Confirmation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trainees &&
                                        trainees.map((trainee, i) => {
                                            return (
                                                <tr key={trainee.id}>
                                                    <td>{i + 1}</td>
                                                    <td>{trainee.lastName + ' ' + trainee.firstName}</td>
                                                    <td>{trainee.traineeIdentity}</td>
                                                    <td>
                                                        {' '}
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            {' '}
                                                            <FormCheck
                                                                size={30}
                                                                type="radio"
                                                                checked={has(attendees, trainee.id)}
                                                                onClick={() => {
                                                                    toggleAttendee(trainee);
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="pt-2">
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Control placeholder="Add training observation if any..." as="textarea" rows="1" name="observation" onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Button variant="primary" block onClick={handleSubmit}>
                                            {createLoading ? <Spinner size="sm" animation="border" variant="light" /> : 'Save'}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>
    );
}

export default ConfirmAttendees;
