import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../components/spinner/Spinner";

import { Container, Row, Col } from "react-bootstrap";
import { SearchBox } from "../../../../components/commons/search-box";
import { FeedBackMessagesList } from "../../components/feedback";
import { getAllFeedbacks } from "../../../../redux/actions";

import CpanelLayout from "../../../../layouts/AdminLayout";

function Feedback() {
  // const feedbacks = useSelector(state => state.feedback.feedbacks);
  const { error, success, feedbackLoading, feedbacks } = useSelector(
    (state) => state.feedback
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllFeedbacks());
  }, [dispatch]);

  return (
    <CpanelLayout>
      <Container fluid>
        <Row>
          <Col className="col-md-5">
            <div>
              <span className="text-title">Citizen Feedback</span>
            </div>
          </Col>

          {/* <Col className="col-md-7">
            <div className="d-flex align-items-end justify-content-end">
              <div className="btn btn-light">Showing latest</div>
              <SearchBox
                toggled={true}
                onChange={(value) => console.log(value)}
              />
            </div>
          </Col> */}
        </Row>

        <div className="pt-5">
          <Row>
            <Col className="col-10">
              {feedbackLoading ? (
                <Spinner />
              ) : (
                feedbacks?.data?.length !== 0 ? <FeedBackMessagesList feedbacks={feedbacks} /> : <p className="text-small">There is no feedback yet !</p>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </CpanelLayout>
  );
}

export default Feedback;
