import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup, Marker } from 'react-simple-maps';
import locations from './rwanda-province.json';
import ReactTooltip from 'react-tooltip';

import './index.css';
import isEmpty from 'lodash/isEmpty';

const mapColors = {
    'City of Kigali': '#286D8E',
    'Southern Province': '#578EA7',
    'Northern Province': '#709EB4',
    'Eastern Province': '#87AEC0',
    'Western Province': '#407E9B',
};

function CircleLegend({ color, label }) {
    return (
        <div>
            <span className="text-small">
                <i className="fa fa-circle" style={{ color: color || '#cf1b33' }}></i>{' '}
                <span className="text-extra-small" style={{ color: '#808080' }}>
                    {label || ' '}
                </span>
            </span>
        </div>
    );
}

const sanitizeSapData = saps => {
    let result = [];
    saps.forEach(sap => {
        if (sap.Coordinates.latitude && sap.Coordinates.longitude) {
            result.push({
                id: sap.id,
                sapName: sap.centerName,
                coordinates: [parseFloat(sap.Coordinates.longitude), parseFloat(sap.Coordinates.latitude)],
                damagedMaterials: isEmpty(sap.Material) ? 0 : sap.Material.filter(el => el.status === 'damaged')?.length || 0,
                offeredServices: isEmpty(sap.Services) ? 0 : sap.Services?.length,
            });
        }
    });

    return result;
};

const SapLocations = ({ saps }) => {
    const [position, setPosition] = useState({
        coordinates: [29.9, -1.9],
        zoom: 1,
    });
    const [content, setContent] = useState({});

    function handleZoomIn() {
        if (position.zoom >= 64) return;
        setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));
    }

    function handleZoomOut() {
        if (position.zoom <= 1) return;
        setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }));
    }

    function handleMoveEnd(position) {
        setPosition(position);
    }

    const sapsMetrics = sanitizeSapData(saps);

    return (
        <div>
            <div>
                {!isEmpty(sapsMetrics) && (
                    <ComposableMap projection="geoTransverseMercator" height={1100} width={1200} projectionConfig={{ center: [29.9, -2.3], scale: 26000 }}>
                        <ZoomableGroup
                            zoom={position.zoom}
                            onMoveEnd={handleMoveEnd}
                            center={position.coordinates}
                            onZoomStart={(event, position) => {
                                console.log({ position, event });
                            }}
                            maxZoom={200}
                        >
                            <Geographies geography={locations}>
                                {({ geographies }) =>
                                    geographies.map(geo => {
                                        const province = geo.properties.Province;
                                        return <Geography key={geo.rsmKey} geography={geo} fill={mapColors[province]} />;
                                    })
                                }
                            </Geographies>

                            {sapsMetrics.map(metric => (
                                <Marker
                                    data-tip=""
                                    key={metric.id}
                                    coordinates={metric.coordinates}
                                    onMouseEnter={() => {
                                        setContent(metric);
                                    }}
                                    onMouseLeave={() => {
                                        setContent({});
                                    }}
                                >
                                    <g fill="#cf1b33" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="translate(-12, -24)">
                                        <circle cx="12" cy="10" r="3" />
                                        <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                    </g>
                                </Marker>
                            ))}
                        </ZoomableGroup>
                    </ComposableMap>
                )}

                <div className="controls">
                    <div className="btn-zoom my-1" onClick={handleZoomIn}>
                        <span className="text-extra-small">
                            {' '}
                            <i className="fas fa-plus"></i>
                        </span>
                    </div>
                    <div className="btn-zoom my-1" onClick={handleZoomOut}>
                        <span className="text-extra-small">
                            {' '}
                            <i className="fas fa-minus"></i>
                        </span>
                    </div>
                </div>
            </div>

            <ReactTooltip backgroundColor="#eef1f8" textColor="#000f16" className="tooltip">
                <div>
                    <strong className="text-small py-3">{content?.sapName} </strong>
                    <CircleLegend label={(content?.damagedMaterials === undefined ? '' : content?.damagedMaterials) + ' ' + 'unfunctional devices'} />
                    <CircleLegend label={(content?.offeredServices === undefined ? '' : content?.offeredServices) + ' ' + 'offered services'} color="#078ece" />
                </div>
            </ReactTooltip>
        </div>
    );
};

export default SapLocations;
