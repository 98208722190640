import React from "react";
import CpanelLayout from "../../../../layouts/AdminLayout";
import Edit from "../../../../assets/images/edit-24px.svg";
import Grade from "../../../../assets/images/grade-24px.svg";
import Change from "../../../../assets/images/change_circle_black_24dp.svg";
import Inbox from "../../../../assets/images/move_to_inbox_black_24dp.svg";
import Info from "../../../../assets/images/info_black_24dp.svg";
import Rwanda from "../../../../assets/images/rwanda.svg";

const SAPManagement = () => {
  return (
    <CpanelLayout>
      <main>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="text-dark">SAP Management</h3>
          <div className="d-flex align-items-center text-primary">
            <button
              className="btn text-primary"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <i className="fa fa-plus" /> &nbsp; Add a SAP
            </button>
            <div className="border-bottom ml-3">
              <input
                type="text"
                placeholder="Search SAPs"
                className="border-bottom border-0"
              />
              <button className="btn">
                <i className="fa fa-search" />
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-5 my-5">
          <h5>SAP Locations</h5>
          <img src={Rwanda} className="img-fluid w-100" alt="" />
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <h3 className="text-dark">Working SAPs</h3>
          <div className="d-flex align-items-center text-primary">
            <div className="border-bottom ml-3">
              <input
                type="text"
                placeholder="Search SAP"
                className="border-bottom border-0"
              />
              <button className="btn">
                <i className="fa fa-search" />
              </button>
            </div>
          </div>
        </div>
        <table className="table table-borderless mt-3">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Names</th>
              <th scope="col">Trainee ID</th>
              <th scope="col">Status</th>
              <th scope="col">Sessions</th>
              <th scope="col">Options</th>
            </tr>
          </thead>
          <tbody className="text-primary">
            <tr>
              <th scope="row">1</th>
              <td>
                Munyaneza Iradukunda placide{" "}
                <i className="fa fa-star text-primary" />
              </td>
              <td>1234567890</td>
              <td>Active</td>
              <td>3/9</td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    actions
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="#">
                      <img src={Edit} /> <span> Edit</span>
                    </a>
                    <a className="dropdown-item" href="#">
                      <img src={Grade} /> <span> Mark as captain</span>
                    </a>
                    <a
                      className="dropdown-item d-flex justify-content-between align-items-center"
                      href="#"
                    >
                      <span>
                        <img src={Change} /> <span> Change status</span>
                      </span>
                      <i className="fa fa-chevron-right mt-1 ml-4" />
                    </a>
                    <a
                      className="dropdown-item d-flex justify-content-between align-items-center"
                      href="#"
                    >
                      <span>
                        <img src={Inbox} /> <span> Move to</span>
                      </span>
                      <i className="fa fa-chevron-right mt-1 ml-1" />
                    </a>
                    <a className="dropdown-item" href="#">
                      <img src={Info} /> <span> More details</span>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">1</th>
              <td>Munyaneza Iradukunda placide</td>
              <td>1234567890</td>
              <td>Active</td>
              <td>3/9</td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    actions
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a className="dropdown-item" href="#">
                      <img src={Edit} /> <span> Edit</span>
                    </a>
                    <a className="dropdown-item" href="#">
                      <img src={Grade} /> <span> Mark as captain</span>
                    </a>
                    <a
                      className="dropdown-item d-flex justify-content-between align-items-center"
                      href="#"
                    >
                      <span>
                        <img src={Change} /> <span> Change status</span>
                      </span>
                      <i className="fa fa-chevron-right mt-1 ml-4" />
                    </a>
                    <a
                      className="dropdown-item d-flex justify-content-between align-items-center"
                      href="#"
                    >
                      <span>
                        <img src={Inbox} /> <span> Move to</span>
                      </span>
                      <i className="fa fa-chevron-right mt-1 ml-1" />
                    </a>
                    <a className="dropdown-item" href="#">
                      <img src={Info} /> <span> More details</span>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog px-1">
            <div className="modal-content">
              <div className="modal-header">
                <h5>New SAP</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body px-1">
                <h6 className="mb-3">Basic Information</h6>
                <form>
                  <input
                    className="form-control mx-0"
                    type="text"
                    placeholder="SAP Name"
                  />
                  <div className="row my-3 align-items-center">
                    <div className="col-md-6 my-2 mx-0">
                      <select className="custom-select mr-sm-2">
                        <option selected>Province</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-md-6 my-2 mx-0">
                      <select className="custom-select mr-sm-2">
                        <option selected>District</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-md-6 my-2 mx-0">
                      <select className="custom-select mr-sm-2">
                        <option selected>Sector</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-md-6 my-2 mx-0">
                      <select className="custom-select mr-sm-2">
                        <option selected>Cell</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-8 mt-4">
                      <input
                        type="text"
                        className="form-control rounded pr-0"
                        placeholder="User ID of DA in charge"
                      />
                    </div>
                    <div className="col-4 mt-4">
                      <button className="btn btn-primary rounded btn-block">
                        Add
                      </button>
                    </div>
                  </div>
                  <h6 className="mb-3">SAP Services</h6>
                  <div className="row mt-3 align-items-center">
                    <div className="col-8 mt-2">
                      <select className="custom-select mr-sm-2">
                        <option selected>Search or add service</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-4 mt-2">
                      <button className="btn btn-primary rounded btn-block">
                        Add
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <button type="button" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </main>
    </CpanelLayout>
  );
};

export default SAPManagement;
