import React from "react";
import "./index.css";
import clx from "classnames";
import { Container, Row, Col } from "react-bootstrap";

const Title = ({ children }) => {
  return (
    <div className="py-3">
      <span className="text-medium">{children}</span>
    </div>
  );
};

// const transfarees = [
//   {
//     id: 1,
//     firstName: "Kalisa",
//     lastName: "John",
//     admitted: false,
//   },
//   {
//     id: 2,
//     firstName: "Umulisa",
//     lastName: "Ange",
//     admitted: true,
//   },

//   {
//     id: 3,
//     firstName: "KAREMERA",
//     lastName: "Patrick",
//     admitted: true,
//   },

//   {
//     id: 4,
//     firstName: "Rurangirwa",
//     lastName: "Moses",
//     admitted: true,
//   },
// ];

// const newTransfarees = transfarees.filter(
//   (transfaree) => !transfaree.admitted
// ).length;

export default function Transferees({ onAdmit, transferees }) {
  return (
    <Container>
      <Row>
        <Col className="">
          <div className="transferees-card  px-4 py-3 ">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <Title> Transferees</Title>

              {transferees > 0 && (
                <div>
                  <span className="text-medium text-danger">
                    +{transferees}
                  </span>
                </div>
              )}
            </div>

            {transferees && transferees.map((transferee) => {
              return (
                <div key={transferee.id}>
                  <div className="pb-1">
                    <span
                      // className={`text-extra-small ${clx({
                      //   "text-bold": !transferee.admitted,
                      // })}`}
                    >
                      {transferee.trainee.firstName} {transferee.trainee.lastName}
                    </span>
                  </div>
                </div>
              );
            })}

            <div className="pt-2">
              <div
                className="btn btn-light"
                onClick={() => onAdmit && onAdmit(transferees)}
              >
                Admit transferees
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
