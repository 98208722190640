/* eslint-disable import/no-anonymous-default-export */
import {
    REQUEST_CREATE_SESSION,
    CREATE_SESSION_FAILED,
    CREATE_SESSION_SUCCESS,
    REQUEST_GET_SESSION,
    GET_SESSION_FAILED,
    GET_SESSION_SUCCESS,
    REQUEST_UPDATE_SESSION,
    UPDATE_SESSION_FAILED,
    UPDATE_SESSION_SUCCESS,
    REQUEST_DELETE_SESSION,
    DELETE_SESSION_FAILED,
    DELETE_SESSION_SUCCESS,
    REQUEST_GET_ATTENDEES,
    GET_ATTENDEES_FAILED,
    GET_ATTENDEES_SUCCESS,
} from '../types';

const initialState = {
    loading: null,
    createLoading: null,
    updateLoading: null,
    deleteLoading: null,
    sessions: [],
    message: null,
    error: null,
    success: false,
    attendees: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REQUEST_CREATE_SESSION:
            return {
                ...state,
                createLoading: true,
                success: false,
            };

        case CREATE_SESSION_SUCCESS:
            return {
                ...state,
                createLoading: null,
                message: action.payload,
                success: true,
            };
        case CREATE_SESSION_FAILED:
            return {
                ...state,
                createLoading: null,
                error: action.payload,
            };
        case REQUEST_GET_SESSION:
            return {
                ...state,
                getLoading: true,
            };
        case GET_SESSION_SUCCESS:
            return {
                ...state,
                getLoading: null,
                sessions: [...action.payload.data],
            };
        case GET_SESSION_FAILED:
            return {
                ...state,
                getLoading: null,
                error: action.payload,
                sessions: [],
            };
        case REQUEST_UPDATE_SESSION:
            return {
                ...state,
                updateLoading: true,
                success: false,
            };

        case UPDATE_SESSION_SUCCESS:
            return {
                ...state,
                updateLoading: null,
                message: action.payload,
                success: true,
            };
        case UPDATE_SESSION_FAILED:
            return {
                ...state,
                updateLoading: null,
                error: action.payload,
            };
        case REQUEST_GET_ATTENDEES:
            return {
                ...state,
                attendees: [],
            };
        case GET_ATTENDEES_SUCCESS:
            return {
                ...state,
                getLoading: null,
                attendees: action.payload,
            };
        case GET_ATTENDEES_FAILED:
            return {
                ...state,
                getLoading: null,
                error: action.payload,
            };
        case REQUEST_DELETE_SESSION:
            return {
                ...state,
                updateLoading: true,
                success: false,
            };

        case DELETE_SESSION_SUCCESS:
            return {
                ...state,
                updateLoading: null,
                message: action.payload,
                success: true,
            };
        case DELETE_SESSION_FAILED:
            return {
                ...state,
                updateLoading: null,
                error: action.payload,
            };
        default:
            return state;
    }
}
