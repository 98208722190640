import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';
import { loginUser } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../utils/LoadingSmall';
import Routes from '../../routes/names';
import { withNamespaces } from 'react-i18next';

const Login = props => {
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState({ email: '', password: '' });
    const loading = useSelector(state => state.auth.loginData);
    const loginFailure = useSelector(state => state.auth.loginFailure);
    const loginSuccess = useSelector(state => state.auth.loginSuccess);
    const roles = useSelector(state => state.auth.roles);
    const [credentialsValid, setCredentialsValid] = useState(true)

    const token = localStorage.IdToken;

    const dispatch = useDispatch();

    const handleChange = e => {
        const { name, value } = e.target;
        setCredentialsValid(true)
        setUser(user => ({ ...user, [name]: value }));
    };

    const msg = {
        error:`${props.t("Imeli cyangwa ijambo ry'ibanga ntago aribyo")}`,
        success:`${props.t("Successful logged in")}`
    }
    const handleSubmit = e => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        e.preventDefault();
        setValidated(true);

        if (user.email && user.password) {
            dispatch(loginUser(user, setValidated, setCredentialsValid, msg));
        }
    };

    if (loginSuccess) {
        return <Redirect to={Routes.dashboard.profile} />;
    }

    if (roles.includes('SU')) {
        return <Redirect to={Routes.dashboard.overview} />;
    }

    if (token) {
        return <Redirect to={Routes.dashboard.overview} />;
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mt-3">
                <Form.Control className={!credentialsValid&&'border border-danger'} required type="email" name="email" value={user.email} placeholder={props.t(`Email`)} onChange={handleChange} />
                <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mt-3">
                <Form.Control className={!credentialsValid&&'border border-danger'} required type="password" name="password" value={user.password} placeholder={props.t(`Ijambo banga`)} onChange={handleChange} />
                <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group className='mt-3'>
				<Form.Check type='checkbox' label={`Kunyibuka`} />
			</Form.Group> */}

            <Form.Group className="mt-3">
                <Button type="submit" variant="primary" className="btn-block" style={{ width: '100%' }}>
                    {props.t(`Kwinjira`)}
                </Button>
                {loading && <Loading />}
            </Form.Group>

            {/* {loginFailure && <Alert className="alert-danger">{loginFailure}</Alert>} */}

            <p className="cursor text-primary text-small mt-3" onClick={props.onNextPage2}>
                {props.t(`Wibagiwe ijambo ry'ibanga`)}?
            </p>
        </Form>
    );
};

export default withNamespaces()(Login)