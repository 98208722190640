import { useState } from 'react';
import fileDownload from 'js-file-download';

function useFileDownload(filePath, fileName) {
    const [downloading, setDownloading] = useState(false);
    const onDownload = () => {
        setDownloading(true);
        fetch(filePath, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
        })
            .then(resp => resp.blob())
            .then(blob => {
                fileDownload(blob, fileName);
                setDownloading(false);
            })
            .catch(error => {
                setDownloading(false);
            });
    };

    return { onDownload, downloading };
}

export default useFileDownload;
