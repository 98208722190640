import React, { useState, useEffect } from 'react';
import clx from 'classnames';
import Layout from '../../../../layouts/AdminLayout';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Button } from 'react-bootstrap';
import './index.css';
import { AddTransaction } from '../../components/sap';
import { useSuperAdmin } from '../../../../hooks';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { emptifyFalseValues } from '../../../../helpers';
import { useParams } from 'react-router';
import { getSingleCenter } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Spinner from '../../../../components/spinner/Spinner';
import moment from 'moment';

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const sanitizeData = items =>
    Object.keys(items).length && items.Transactions && items.Transactions.length
        ? items.Transactions.map((item, index) => ({ index: index + 1, time: moment(item.createdAt).format('HH:mm A'), date: moment(item.createdAt).format('MMMM Do, YYYY'), ...emptifyFalseValues(item) }))
        : [];
const customTotal = (from, to, size) => (
    <span className="pull-left color-grey text-small">
        Showing {from}- {to} in {size}
    </span>
);

const renderType = (cell, row) => {
    const caret = row.expensesType === 'income' ? 'up' : 'down';

    const classes = clx({
        'income-caret': row.expensesType === 'income',
        'expense-caret': row.expensesType === 'expense',
    });
    return <i className={`fas fa-caret-${caret} ${classes} text-medium`}></i>;
};

const RenderItemName = (cell, row) => {
    if (row.name) return <span>{row.name}</span>;
    return '';
};

const columns = [
    { dataField: 'index', text: '#', csvExport: false, sort: true },
    { dataField: 'name', text: 'Name', formatter: RenderItemName },
    { dataField: 'expensesType', text: 'Type', formatter: renderType },
    { dataField: 'amount', text: 'Amount', formatter: (_, row) => Number(row.amount).toLocaleString() },
    { dataField: 'time', text: 'Time' },
    { dataField: 'date', text: 'Date' },
];

function Transactions() {
    const dispatch = useDispatch();
    const params = useParams();
    const [modals, setModals] = useState({
        addTransaction: false,
    });

    const openAddTransaction = () => setModals({ ...modals, addTransaction: true });
    const closeAddTransaction = () => setModals({ ...modals, addTransaction: false });

    const isAdmin = useSuperAdmin();

    const [isLoading, setIsLoading] = useState(true);
    const { loading, singleCenter } = useSelector(state => state.centers);
    useEffect(() => {
        if (isLoading) {
            const trainingCenterId = params.id;
            dispatch(getSingleCenter(trainingCenterId));
            setIsLoading(false);
        }
    }, [isLoading]);

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        sizePerPageList: [
            {
                text: '5',
                value: 5,
            },
            {
                text: '10',
                value: 10,
            },
            {
                text: 'All',
                value: singleCenter.Transactions.length || 0,
            },
        ],
    };

    return (
        <Layout>
            <div className="pt-4  px-1 transaction">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="text-title">SAP Transactions</span>

                    {!isAdmin && (
                        <div className="d-flex align-items-center">
                            <Button className="btn btn-light" onClick={openAddTransaction}>
                                {' '}
                                <i className="fas fa-plus"></i>&nbsp;
                                <span className="text-dark">Add transaction</span>
                            </Button>
                        </div>
                    )}
                </div>

                <div className="pt-5">
                    <ToolkitProvider
                        keyField="index"
                        data={sanitizeData(singleCenter)}
                        columns={columns}
                        search
                        exportCSV={{
                            fileName: `DAP-SAP-TRANSACTIONS-${new Date().toLocaleTimeString()}.csv`,
                            separator: ',',
                            ignoreHeader: false,
                            noAutoBOM: false,
                        }}
                    >
                        {props => (
                            <div>
                                <div>
                                    <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                        <i class="fas fa-file-excel text-small"></i> &nbsp;Export to CSV
                                    </ExportCSVButton>
                                </div>
                                <div className="d-flex align-items-end justify-content-end">
                                    <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0 text-small" placeholder="Search transactions ..." />
                                </div>

                                <div
                                    className="pt-3"
                                    {...{
                                        style: {
                                            ...{ position: loading ? 'relative' : '' },
                                        },
                                    }}
                                >
                                    <BootstrapTable bordered={false} {...props.baseProps} pagination={paginationFactory(options)} options={options} bootstrap4 />
                                    {loading && (
                                        <SpinnerWrapper>
                                            <Spinner isUpload={true} />
                                        </SpinnerWrapper>
                                    )}
                                </div>
                            </div>
                        )}
                    </ToolkitProvider>
                </div>
            </div>
            <AddTransaction open={modals.addTransaction} onClose={closeAddTransaction} />
        </Layout>
    );
}

const SpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccccccba;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 20;
`;

export default Transactions;
