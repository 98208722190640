import {
    REQUEST_CREATE_ATTENDENCE,
    CREATE_ATTENDENCE_FAILED,
    CREATE_ATTENDENCE_SUCCESS,
    REQUEST_GET_ATTENDENCE,
    GET_ATTENDENCE_FAILED,
    GET_ATTENDENCE_SUCCESS,
    REQUEST_UPDATE_ATTENDENCE,
    UPDATE_ATTENDENCE_FAILED,
    UPDATE_ATTENDENCE_SUCCESS,
    REQUEST_DELETE_ATTENDENCE,
    DELETE_ATTENDENCE_FAILED,
    DELETE_ATTENDENCE_SUCCESS,
} from '../types';
import axios from 'axios';
import { toast } from 'react-toastify';

const { REACT_APP_BACKEND } = process.env;

export const makeAttendence =
    ({ attendence, closeComfirmAttendee }) =>
    dispatch => {
        dispatch({ type: REQUEST_CREATE_ATTENDENCE });
        axios
            .post(`${REACT_APP_BACKEND}/api/attendences/make`, attendence)
            .then(res => {
                toast.success(res.data.message);
                dispatch({ type: CREATE_ATTENDENCE_SUCCESS, payload: res.data.message });

                closeComfirmAttendee && closeComfirmAttendee();
            })
            .catch(err => {
                toast.error(err.response ? err.response.data.error || err.response.data.Errors[0] : null);
                dispatch({
                    type: CREATE_ATTENDENCE_FAILED,
                    payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
                });
            });
    };

export const getAllAtendence = () => dispatch => {
    dispatch({ type: REQUEST_GET_ATTENDENCE });
    axios
        .get(`${REACT_APP_BACKEND}/api/attendences`)
        .then(res => {
            dispatch({ type: GET_ATTENDENCE_SUCCESS, payload: res.data.data });
        })
        .catch(err => {
            dispatch({
                type: GET_ATTENDENCE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};

export const getDaAtendence = () => dispatch => {
    dispatch({ type: REQUEST_GET_ATTENDENCE });
    axios
        .get(`${REACT_APP_BACKEND}/api/attendences/my-attendences`)
        .then(res => {
            dispatch({ type: GET_ATTENDENCE_SUCCESS, payload: res.data.data });
        })
        .catch(err => {
            dispatch({
                type: GET_ATTENDENCE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};
export const getAtendenceByGroup = id => dispatch => {
    dispatch({ type: REQUEST_GET_ATTENDENCE });
    axios
        .get(`${REACT_APP_BACKEND}/api/attendences/group-attendences/${id}`)
        .then(res => {
            dispatch({ type: GET_ATTENDENCE_SUCCESS, payload: res.data.data });
        })
        .catch(err => {
            dispatch({
                type: GET_ATTENDENCE_FAILED,
                payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
            });
        });
};
