const matchCoordinates = (value) => {
  var numberPattern = /[0-9-.]/g;

  const matchedArray = String(value).match(numberPattern);

  let result = Array.isArray(matchedArray) ? matchedArray.join("") : "";

  return result;
};

export default matchCoordinates;
