import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import "./index.css";
import {
  transferTrainee,
  getAmbassadors,
  getAllLocations,
  getCenterLocation,
  getDaCenters,
} from "../../../../../redux/actions";



function TransferTraineeToCenter({ open, onClose, id }) {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.trainees);
  const { residence } = useSelector(state=>state.residence);
  const { centers } = useSelector(state => state.centers);
  const { ambassadors } = useSelector(state => state.users);

  const [centerId, setCenterId] = useState('');
  const [residentIds, setResidentIds] = useState([]);
  const [userId, setUserId] = useState('');

  const onCenterChange = e => {
    const { value } = e.target;
    let center = centers.filter(center => center.centerName === value);
    setCenterId(center[0].id);
  }

  useEffect(() => {
    const user = ambassadors.filter(ambs => ambs.id === userId);
    let ids = [];
    let sectors = residence.filter(res => res.sector === user.sector);
    sectors.forEach(c => ids.push(c.id));
    setResidentIds([...new Set(ids)]);
  },[userId])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(transferTrainee(id, { ambassadorId: userId, trainingcenterId: centerId }))
  }

  useEffect(() => {
    dispatch(getAmbassadors());
    dispatch(getAllLocations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCenterLocation(residentIds));
  },[residentIds])
  return (
    <div>
      <Container>
        <Modal
          show={open}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="custom-modal"
          className="custom-modal"
          animation={false}
          onHide={onClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transfer Trainee</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Row className="py-1">
                <Col>
                  {" "}
                  <Form.Group>
                    <Form.Control
                      as="select"
                      className="custom-select"
                      name="cell"
                      // value={values.cell}
                      onChange={e => {
                        setUserId(e.target.value)
                        dispatch(getDaCenters(e.target.value));
                      }}
                    >
                      <option value="" selected disabled>
                        DA Name
                      </option>

                      {ambassadors && ambassadors.map((ambs) => (
                        <option key={ambs.id} value={ambs.id}>
                          {ambs.firstName} {ambs.lastName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {/* <Col>
                  {" "}
                  <Form.Group>
                    <Form.Control
                      as="select"
                      className="custom-select"
                      name="cell"
                      value={values.cell}
                      onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Cell
                      </option>

                      {trainingCenters.map((center) => (
                        <option key={center.id} value={center.value}>
                          {center.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col> */}
              </Row>

              <Row className="py-1">
                <Col>
                  {" "}
                  <Form.Group>
                    <Form.Control
                      // value={values.da}
                      disabled={!userId}
                      as="select"
                      className="custom-select"
                      // name="da"
                      // onChange={handleChange}
                      onChange={onCenterChange}
                    >
                      <option value="" selected disabled>
                        {/* DA Name */}
                        Center name
                      </option>
                      {centers.map((da) => (
                        <option key={da.id} value={da.value}>
                          {da.centerName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit}>
            {loading ? (
                <Spinner size="sm" animation="border" variant="light" />
              ) : (
                "Update"
              )}
              {/* Update */}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default TransferTraineeToCenter;
