import React, { useState } from "react";
import { withNamespaces } from 'react-i18next';
import Course from "../course/Course";

import { CourseDetailsModal } from "../course-details";

const modalRef = React.createRef();
// const courses = [
//   {
//     title: "Introduction to Computer Hardware",
//     duration: 35,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget rutrum nibh. Vestibulum malesuada purus in maximus lacinia. Morbi fermentum arcu at odio fermentum, in maximus nulla dictum.",
//     link: "https://elearning.reb.rw/",
//   },

//   {
//     title: "Introduction to Computer Software",
//     duration: 45,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget rutrum nibh. Vestibulum malesuada purus in maximus lacinia. Morbi fermentum arcu at odio fermentum, in maximus nulla dictum.",
//     link: "https://elearning.reb.rw/",
//   },

//   {
//     title: "Getting Started with Windows Operating System",
//     duration: 60,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget rutrum nibh. Vestibulum malesuada purus in maximus lacinia. Morbi fermentum arcu at odio fermentum, in maximus nulla dictum.",
//     link: "https://elearning.reb.rw/",
//   },

//   {
//     title: "Getting Started with Microsoft Office",
//     duration: 50,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget rutrum nibh. Vestibulum malesuada purus in maximus lacinia. Morbi fermentum arcu at odio fermentum, in maximus nulla dictum.",
//     link: "https://elearning.reb.rw/",
//   },

//   {
//     title: "Getting Started with Internet",
//     duration: 30,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget rutrum nibh. Vestibulum malesuada purus in maximus lacinia. Morbi fermentum arcu at odio fermentum, in maximus nulla dictum.",
//     link: "https://elearning.reb.rw/",
//   },

//   {
//     title: " Searching on Internet",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget rutrum nibh. Vestibulum malesuada purus in maximus lacinia. Morbi fermentum arcu at odio fermentum, in maximus nulla dictum.",
//     link: "https://elearning.reb.rw/",
//     duration: 60,
//   },

//   {
//     title: " Creating email with Gmail",
//     duration: 35,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget rutrum nibh. Vestibulum malesuada purus in maximus lacinia. Morbi fermentum arcu at odio fermentum, in maximus nulla dictum.",
//     link: "https://elearning.reb.rw/",
//   },

//   {
//     title: " Creating Social Network Profile - Facebook, Twitter, Linkedin",
//     duration: 90,
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     link: "https://elearning.reb.rw/",
//   },
// ];

const CoursesList = ({ t, courses }) => {
  const [selectedCourse, setSelectedCourse] = useState({});

  return (
    <>
      <CourseDetailsModal ref={modalRef} {...selectedCourse} t={ t } />
      <div className="row no-gutter">
        {courses&&courses.map((course, index) => {
          return (
            <div className="col-lg-4 col-md-6 col-xs-6 ">
              {" "}
              <Course
                {...course}
                id={index + 1}
                onViewCourseDetails={() => {
                  modalRef.current?.open();
                  setSelectedCourse({ ...course, id: index + 1 });
                }}
                key={index + 1}
                t = {t}
                link = {course.link}
                duration= {course.duration}
              />{" "}
            </div>
          );
        })}
      </div>
    </>
  );
}
export default withNamespaces() (CoursesList);
