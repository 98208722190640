import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Layout from '../../layouts';
import { CoursesList } from '../../components/courses';

import './index.css';
import { Container } from 'react-bootstrap';
import BackLink from '../../components/commons/back-link/BackLink';
import routes from '../../routes/names';
import { getAllCourses } from '../../redux/actions';

const Courses = ({ t }) => {
    const dispatch = useDispatch();
    const { courses } = useSelector(state => state.courses);

    useEffect(() => {
        dispatch(getAllCourses());
    }, []);

    return (
        <>
            <Layout>
                <div className="dap-bg-primary py-4">
                    <Container>
                        <BackLink path={routes.home} />
                        <div>
                            <div className="row margin-bottom-medium d-flex justify-content-center align-items-center ">
                                <div className="col-12">
                                    <div className="d-flex justify-content-center align-items-center py-4">
                                        <span className="text-title text-white "> {`${t(`Amasomo`)}`}</span>
                                    </div>

                                    <div>
                                        <Container className="w-75 px-3">
                                            <span className="text-white  description text-center ">
                                                {`${t(`Iri somo riteguye mu buryo bw’amasomo kugirango bifashe abigisha/abatoza kugendera ku byateguwe kandi babashe kubikuriiranya uko byateguwe.`)}`}{' '}
                                                {`${t(`Mu mahugurwa y’abigisha/abatoza abahugura babinyuriramo mu buryo bwihuse ariko kwigisha umuturage bisaba kugenza gahoro.`)}`} <br />
                                                <div className="py-2 text-left">
                                                    {/* <i className="fa fa-circle text-extra-small"></i> {`${t(`Umubare w’iminota`)}`}: 420 {`${t(`minutes`)}`} (~ 7 {`${t(`amasaha`)}`}), ({`${t(`Iminsi 5 + Umunsi 1 wo Gusoza`)}`}) <br /> */}
                                                    <i className="fa fa-circle text-extra-small"></i> {`${t(`Buri tsinda rigomba kurangiza ibi byigwa mu gihe kitarenze ukwezi kumwe.`)}`}
                                                </div>
                                            </span>
                                        </Container>
                                    </div>

                                    {/** Course Lists */}

                                    <div className=" py-5">
                                        <CoursesList courses={courses} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </Layout>
        </>
    );
};
export default withNamespaces()(Courses);
