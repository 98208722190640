export const onlySuperAdminRole = ['super_admin'];
export const adminRoles = ['SU', 'super_admin', 'progr-manager'];
export const contentManagerRoles = ['SU', 'super_admin', 'progr-manager'];
export const daRoles = ['DA', 'SU', 'progr-manager', 'Monitoring_team_manager', 'super_admin'];
export const captain = ['DA', 'SU', 'Monitoring_team_manager', 'super_admin', 'progr-manager', 'club_capt'];
export const managerRole = ['SU', 'super_admin', 'progr-manager', 'SAP_manager'];
export const exCaptain = ['Ex_captain'];

export const onlyAdmin = ['SU', 'super_admin', 'progr-manager'];
export const forAdmin = ['super_admin', 'progr-manager'];
export const onlyDA = ['DA', 'Monitoring_team_manager'];
export const onlyPartners = ['country_partner', 'province_partner', 'district_partner', 'sector_partner'];
