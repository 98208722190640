import React from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import CheckOTP from '../../components/auth/CheckOTP';

const OTPPage = () => {
	return (
		<div className='App'>
			<Header />
			<CheckOTP />
			<Footer />
		</div>
	);
};

export default OTPPage;
