import React, { useEffect, useRef, useState } from 'react';

import Layout from '../../../../layouts/AdminLayout';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Button, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { listService, deleteService } from '../../../../redux/actions/services';

import AddService from '../../components/services/add-service/AddService';
import UpdateService from '../../components/services/add-service/UpdateService';
import isEmpty from 'lodash/isEmpty';
const customTotal = (from, to, size) => (
    <span className="pull-left color-grey text-small">
        Showing {from}- {to} in {size}
    </span>
);

const options = {
    paginationSize: 10,
    pageStartIndex: 1,
    hideSizePerPage: true,
    firstPageText: '',
    prePageText: '<',
    nextPageText: '>',
    paginationTotalRenderer: customTotal,
    disablePageTitle: false,
    hidePageListOnlyOnePage: true,
    showTotal: true,
};

const renderID = (cell, row, rowIndex) => {
    return <span>{rowIndex + 1}</span>;
};

const renderRate = (cell, row) => <span> {row.rate}%</span>;

function Services() {
    const [modals, setModals] = useState({ addService: false, updateService: false });
    const services = useSelector(state => state.services.services);
    const { roles } = useSelector(state => state.auth);

    const openAddService = () => setModals({ addService: true });
    const closeAddService = () => {
        setModals({ addService: false });
    };

    const openUpdateService = () => setModals({ updateService: true });
    const closeUpdateService = () => {
        setService({});
        setModals({ updateService: false });
    };

    const dispatch = useDispatch();

    const [service, setService] = useState({});

    useEffect(() => {
        dispatch(listService());
    }, [dispatch]);

    const renderOptons = (cell, row) => {
        const id = row.id;

        const updateService = () => {
            setService({});
            setService(row);

            openUpdateService();
        };

        const removeService = () => {
            dispatch(deleteService(row));
        };

        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle disabled={roles.includes('SU')} variant="light" className="text-dark">
                        Actions &nbsp; <i class="fas fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={updateService}>
                            <i class="fas fa-pencil-alt text-primary "></i> &nbsp;Edit
                        </Dropdown.Item>

                        <Dropdown.Item onClick={removeService}>
                            <span className="text-small">
                                {' '}
                                <i class="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                            </span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const columns = [
        { dataField: 'id', text: '#', formatter: renderID },
        { dataField: 'name', text: 'Service' },
        { dataField: 'rate', text: 'Rate', formatter: renderRate },
        {
            dataField: 'createdAt',
            text: 'Added on ',
            filterValue: (cell, row) => row.createdAt,
            csvExport: true,
            formatter: (cell, row) => (row.createdAt ? moment(row.createdAt).format('MMMM Do, YYYY') : 'N/A'),
        },

        { dataField: 'options', text: 'Options', formatter: renderOptons },
    ];

    return (
        <Layout>
            <div className="pt-4  px-1 transaction">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="text-title"> Services Management</span>

                    <div className="d-flex align-items-center">
                        {!roles.includes('SU') && (
                            <Button className="btn btn-light" onClick={openAddService}>
                                {' '}
                                <i className="fas fa-plus"></i>&nbsp;
                                <span className="text-dark">Add a Service</span>
                            </Button>
                        )}
                    </div>
                </div>

                <div className="pt-3">
                    <BootstrapTable classes="data-table" bordered={false} bootstrap4 keyField="index" data={services} columns={columns} pagination={paginationFactory(options)} />
                </div>
            </div>

            <AddService open={modals.addService} onClose={closeAddService} />

            {!isEmpty(service) && <UpdateService open={modals.updateService} onClose={closeUpdateService} service={service} />}
        </Layout>
    );
}

export default Services;
