import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Loading from '../../../../utils/LoadingSmall';

function DeleteRoles({ onClose, handleDeleteRole, loading, open, children, headerText }) {
    return (
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" show={open} onHide={() => onClose()} backdrop="static" animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mt-2 mb-2">
                    <Col md={12}>
                        <p>
                            {children}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Button className="px-5 py-2 mt-3 mr-2 rounded bg-secondary" onClick={onClose} disabled={loading}>
                        No
                    </Button>
                    <Button className={`px-5 py-2 mt-3 rounded bg-primary ${loading && 'd-flex'}`} onClick={handleDeleteRole} disabled={loading}>
                        {!loading ? 'Yes' : <Loading color="#FFF" marginTop=" " center={true} />}
                    </Button>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default DeleteRoles;
