import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import checkAuthorized from '../utils/checkAuthorized';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import routes from '../routes/names';
import jwtDecode from 'jwt-decode';
import { onlySuperAdminRole, adminRoles, daRoles, captain, onlyPartners } from '../utils/roles';
import { reverse } from 'named-urls';
import { getUserInfo } from '../redux/actions';

function SideBarMenu() {
    const { roles } = useSelector(state => state.auth);
    const { userInfos: userDetail } = useSelector(state => state.users);
    const [showCenters, setShowCenters] = useState(false);

    const dispatch = useDispatch();
    const params = useParams();

    const { id } = params;

    const activeMenu = path => {
        const url = window.location.pathname;

        if (Array.isArray(path)) return path.includes(url) ? 'active' : '';

        return path === url ? 'active' : '';
    };

    const findDaLeader = () => {
        const leadersRole = roles.filter(role => role.includes('DA_leader'))[0];
        if (leadersRole) return true;
        return false;
    };

    const checkDALeader = findDaLeader();

    useEffect(() => {
        (async () => {
            try {
                const token = jwtDecode(localStorage.getItem('IdToken'));
                const userID = token?.id;
                dispatch(getUserInfo(userID));
            } catch (error) {
                console.error(error);
            }
        })();
    }, [dispatch]);

    if (checkAuthorized(adminRoles, roles)) {
        return (
            <Container fluid className="p-0">
                <div className="menu">
                    <ul className="p-0">
                        <li>
                            <Link>{checkAuthorized(onlySuperAdminRole, roles) ? 'SUPER ADMIN DASHBOARD' : 'SUPER USER DASHBOARD '}</Link>
                        </li>

                        <li className="pt-2">
                            <Link to={routes.dashboard.overview} className={activeMenu(routes.dashboard.overview)}>
                                Overview
                            </Link>
                        </li>

                        <li>
                            <Link to={routes.dashboard.ambassadors.list} className={activeMenu([routes.dashboard.ambassadors.list, routes.dashboard.ambassadors.create])}>
                                DA Management
                            </Link>
                        </li>
                        <li>
                            <Link to={routes.dashboard.partners.list} className={activeMenu([routes.dashboard.partners.list, routes.dashboard.partners.create])}>
                                Partner Management
                            </Link>
                        </li>

                        <li>
                            <Link to={routes.dashboard.curriculum} className={activeMenu(routes.dashboard.curriculum)}>
                                Curriculum Management
                            </Link>
                        </li>

                        <li>
                            <Link
                                to={routes.dashboard.sap.overview}
                                className={`${activeMenu([
                                    routes.dashboard.sap.overview,
                                    routes.dashboard.sap.services,
                                    reverse(routes.dashboard.sap.materials, { id }),
                                    routes.dashboard.sap.transactions,
                                    reverse(routes.dashboard.sap.view, { id }),
                                    reverse(routes.dashboard.sap.services, { id }),
                                ])}`}
                            >
                                SAP Management
                            </Link>
                        </li>

                        <li>
                            <Link to={routes.dashboard.training.manage} className={`${activeMenu(routes.dashboard.training.manage)}`}>
                                Training Location
                            </Link>
                        </li>

                        <li>
                            <Link to={routes.dashboard.services.list} className={activeMenu(routes.dashboard.services.list)}>
                                Services Management
                            </Link>
                        </li>

                        <li>
                            <Link to={routes.dashboard.trainees.all} className={activeMenu([routes.dashboard.trainees.all, reverse(routes.dashboard.trainees.view, { id })])}>
                                Trainees
                            </Link>
                        </li>

                        <li>
                            <Link to={routes.dashboard.reports} className={activeMenu(routes.dashboard.reports)}>
                                Special Report
                            </Link>
                        </li>

                        <li>
                            <Link to={routes.dashboard.communication} className={activeMenu(routes.dashboard.communication)}>
                                Communications
                            </Link>
                        </li>

                        <li>
                            <Link to={routes.dashboard.feedback} className={activeMenu(routes.dashboard.feedback)}>
                                Feedback
                            </Link>
                        </li>
                        <li>
                            <Link to={routes.dashboard.landingPage} className={activeMenu(routes.dashboard.landingPage)}>
                                Manage Landing Page
                            </Link>
                        </li>
                        <li>
                            {checkAuthorized(onlySuperAdminRole, roles) && (
                                <Link to={routes.dashboard.userRoles} className={activeMenu([routes.dashboard.userRoles, routes.dashboard.editRoles, routes.dashboard.users.create])}>
                                    Manage User roles
                                </Link>
                            )}
                        </li>
                        <li>
                            <Link to={routes.importRecords} className={activeMenu(routes.importRecords)}>
                                Import past records
                            </Link>
                        </li>
                    </ul>
                </div>
            </Container>
        );
    } else if (checkAuthorized(daRoles, roles)) {
        return (
            <Container fluid className="p-0">
                <div className="menu">
                    <ul className="p-0">
                        <li>
                            <Link>DA DASHBOARD</Link>
                        </li>
                        <li className="pt-2">
                            <Link to={routes.dashboard.overview} className={activeMenu(routes.dashboard.overview)}>
                                Overview
                            </Link>
                        </li>
                        <li>
                            <Link to={routes.dashboard.listGroup.groups} className={activeMenu([routes.dashboard.listGroup.groups, routes.dashboard.listGroup.details, routes.dashboard.trainees.create, routes.dashboard.ambassadors.myTrainees])}>
                                Trainees
                            </Link>
                        </li>
                        <li>
                            <Link to={routes.dashboard.training.overview} className={activeMenu([routes.dashboard.training.overview, routes.dashboard.ambassadors.assesment])}>
                                Training Activities
                            </Link>
                        </li>
                        <li onClick={() => setShowCenters(prev => !prev)}>
                            <Link to="#" className="d-flex justify-content-between align-items-center">
                                <span>Training Centers Management</span> <i className={`fas fa-angle-${showCenters ? 'up' : 'down'}`}></i>
                            </Link>
                        </li>
                        {showCenters && (
                            <li>
                                <ul className="pl-2">
                                    {userDetail.DAInfo &&
                                        userDetail.DAInfo.map(info => (
                                            <li>
                                                <Link to={reverse(routes.dashboard.sap.view, { id: info.id })} className={`list-sub-menu ${params && params.id && params.id === info.id ? 'active' : ''}`}>
                                                    <i className="fas fa-building" style={{ fontSize: 15 }}></i> &nbsp; {info.centerName}
                                                </Link>
                                            </li>
                                        ))}
                                </ul>
                            </li>
                        )}

                        {Boolean(userDetail.DAInfo?.length) && (
                            <li>
                                <Link to={routes.dashboard.searchCode} className={activeMenu(routes.dashboard.searchCode)}>
                                    Search Trainees
                                </Link>
                            </li>
                        )}

                        <li>
                            <Link to={routes.dashboard.communication} className={activeMenu(routes.dashboard.communication)}>
                                Communications
                            </Link>
                        </li>
                        {checkDALeader && (
                            <>
                                <li className="pt-2">
                                    <Link to={routes.dashboard.co.overview} className={activeMenu(routes.dashboard.co.overview)}>
                                        Whole Overview
                                    </Link>
                                </li>
                                <li>
                                    <Link to={routes.dashboard.co.reports} className={activeMenu(routes.dashboard.co.reports)}>
                                        System Reports
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </Container>
        );
    } else if (checkAuthorized(captain, roles)) {
        return (
            <Container fluid className="p-0">
                <div className="menu">
                    <ul className="p-0">
                        <li>
                            <Link>CLUB CAPTAIN</Link>
                        </li>
                        <li className="pt-2">
                            <Link to={routes.dashboard.captain.group} className={activeMenu(routes.dashboard.captain.group)}>
                                Your Group
                            </Link>
                        </li>
                        <li>
                            <Link to={routes.dashboard.captain.communication} className={activeMenu(routes.dashboard.captain.communication)}>
                                Communications
                            </Link>
                        </li>
                    </ul>
                </div>
            </Container>
        );
    } else if (checkAuthorized(onlyPartners, roles)) {
        return (
            <Container fluid className="p-0">
                <div className="menu">
                    <ul className="p-0">
                        <li>
                            <Link>PARTNERS DASHBOARD</Link>
                        </li>
                        <li className="pt-2">
                            <Link to={routes.dashboard.co.overview} className={activeMenu(routes.dashboard.co.overview)}>
                                Overview
                            </Link>
                        </li>
                        <li>
                            <Link to={routes.dashboard.co.reports} className={activeMenu(routes.dashboard.co.reports)}>
                                Special Reports
                            </Link>
                        </li>
                    </ul>
                </div>
            </Container>
        );
    } else {
        return (
            <Container fluid className="p-0">
                <div className="menu"></div>
            </Container>
        );
    }
}

export default SideBarMenu;
