/* eslint-disable import/no-anonymous-default-export */
import {
  REQUEST_CREATE_GROUP,
  CREATE_GROUP_FAILED,
  CREATE_GROUP_SUCCESS,
  REQUEST_GET_GROUP,
  GET_GROUP_FAILED,
  GET_GROUP_SUCCESS,
  REQUEST_UPDATE_GROUP,
  UPDATE_GROUP_FAILED,
  UPDATE_GROUP_SUCCESS,
  REQUEST_DELETE_GROUP,
  DELETE_GROUP_FAILED,
  DELETE_GROUP_SUCCESS,
  REQUEST_GET_ONE_GROUP,
  GET_ONE_GROUP_FAILED,
  GET_ONE_GROUP_SUCCESS,
  REQUEST_ADD_GROUP_TRAINEE,
  ADD_GROUP_TRAINEE_FAILED,
  ADD_GROUP_TRAINEE_SUCCESS,
  
} from "../types"

const initialState = {
  loading: null,
  createLoading: null,
  success: false,
  updateLoading: null,
  deleteLoading: null,
  deleteSucess: false,
  groups: [],
  group: null,
  message: null,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_CREATE_GROUP:
      return {
        ...state,
        createLoading: true,
        success: false,
      }
    
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        createLoading: null,
        success: true,
        message: action.payload
      }
    case CREATE_GROUP_FAILED:
      return {
        ...state,
        createLoading: null,
        error: action.payload
      }
    case REQUEST_GET_GROUP:
      return {
        ...state,
        getLoading: true
      }
    case GET_GROUP_SUCCESS:
      return {
        ...state,
        getLogin: null,
        groups: action.payload
    }
    case GET_GROUP_FAILED:
      return {
        ...state,
        createLoading: null,
        error: action.payload
      }
    case REQUEST_UPDATE_GROUP:
      return {
        ...state,
        updateLoading: true,
        success: false,
    }
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        updateLoading: null,
        success: true,
        message: action.payload
      }
    case UPDATE_GROUP_FAILED:
      return {
        ...state,
        updateLoading: null,
        error: action.payload
      }
    case REQUEST_GET_ONE_GROUP:
      return {
        ...state,
        loading: true
      }
    case GET_ONE_GROUP_SUCCESS:
      return {
        ...state,
        loading: null,
        group: action.payload
    }
    case GET_ONE_GROUP_FAILED:
      return {
        ...state,
        loading: null,
        error: action.payload
      }
    case REQUEST_ADD_GROUP_TRAINEE:
      return {
        ...state,
        updateLoading: true,
        success: false,
    }
    case ADD_GROUP_TRAINEE_SUCCESS:
      return {
        ...state,
        updateLoading: null,
        success: true,
        message: action.payload
      }
    case ADD_GROUP_TRAINEE_FAILED:
      return {
        ...state,
        updateLoading: null,
        error: action.payload
      }
    case REQUEST_DELETE_GROUP:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
    }
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        deleteLoading: null,
        deleteSuccess: true,
        message: action.payload
      }
    case DELETE_GROUP_FAILED:
      return {
        ...state,
        deleteLoading: null,
        error: action.payload
      }
    default:
      return state;
  }
}