import React from 'react';

import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';

const HomePage = () => {
	return (
		<div className='App'>
			<Header />
			<Body />
			<Footer />
		</div>
	);
};

export default HomePage;
