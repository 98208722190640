/* eslint-disable import/no-anonymous-default-export */
import {
  REQUEST_SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED,
  // REQUEST_GET_SENT_MESSAGE,
  GET_SENT_MESSAGE_SUCCESS,
  GET_SENT_MESSAGE_FAILED,
  // REQUEST_GET_RECEIVED_MESSAGE,
  GET_RECEIVED_MESSAGE_SUCCESS,
  GET_RECEIVED_MESSAGE_FAILED,
} from "../types";

const initialState = {
  sentCommunications: [],
  receivedCommunications: [],
  communication: {},
  message: "",
  error: "",
  loading: null,
  success: false,
}

export default (state=initialState, action) => {
  switch (action.type) {
    case REQUEST_SEND_MESSAGE:
      return {
        ...state,
        loading: true,
      }
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        success: true
      }
    case SEND_MESSAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case GET_SENT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        sentCommunications: action.payload
      }
    case GET_SENT_MESSAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        sentCommunications: []
      }
    case GET_RECEIVED_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        receivedCommunications: action.payload
      }
    case GET_RECEIVED_MESSAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        receivedCommunications: []
      }
    default:
      return state
  }
}