import React, { useState, useEffect } from "react";
import { Modal, Button, Container, Table, Row, Col } from "react-bootstrap";

import keyBy from "lodash/keyBy";

import clx from "classnames";

function AdmitTransferees({ open, onClose, transferees, submit }) {
  const [modifiedTransferees, setTransferees] = useState({
    ...keyBy(transferees, "id"),
  });

  useEffect(()=>{
    setTransferees({
      ...keyBy(transferees, "id"),
    })
  }, [transferees])

  const onAdmit = (transferee) => {
    const newTransferee = { ...transferee, admitted: true, declined: false };

    setTransferees({
      ...modifiedTransferees,
      [newTransferee.id]: newTransferee,
    });
  };

  const onDecline = (transferee) => {
    const newTransferee = { ...transferee, declined: true, admitted: false };

    setTransferees({
      ...modifiedTransferees,
      [newTransferee.id]: newTransferee,
    });
  };

  const isAdmitted = (transferee) => transferee?.admitted === true;
  const isDeclined = (transferee) => transferee?.declined === true;

  const handleSubmit = () => {
    const payload = Object.keys(modifiedTransferees);

    console.log(payload);
    submit({list: [...payload]})
  };

  return (
    <div>
      <Container>
        <Modal
          show={open}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="admit-modal"
          className="admit-modal"
          animation={false}
          onHide={onClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transferred trainees</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="d-flex flex-row text-small">
              {" "}
              <div>
                <span> {""} </span>
              </div>
              <div className="px-3">
                <span> {""} </span>
              </div>
              <div className="px-3">
                <span> {""} </span>
              </div>
            </div>
            <div className="confirm-attendees-scrollbar table-wrapper-scroll-y pt-4">
              <Table responsive="sm" borderless>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Names</th>
                    <th>Trainee ID</th>
                    <th>Previous DA</th>

                    <th className="text-center"></th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {transferees &&
                    Object.values(modifiedTransferees).map((transferee, i) => {
                      const admitted = isAdmitted(transferee);
                      const declined = isDeclined(transferee);
                      let admitButtonClasses = clx({
                        "bg-success": !admitted,
                        "bg-light": admitted,
                        "text-success": admitted,
                      });
                      admitButtonClasses =
                        admitButtonClasses + "   rounded  border-0 px-5";

                      let declineButtonClasses = clx({
                        "bg-danger": !declined,
                        "bg-light": declined,
                        "text-danger": declined,
                      });
                      declineButtonClasses =
                        declineButtonClasses + "   rounded  border-0 px-5";

                      return (
                        <tr key={transferee.id}>
                          <td>{i + 1}</td>
                          <td>
                            {transferee.trainee.lastName + " " + transferee.trainee.firstName}
                          </td>
                          <td>{transferee.trainee.traineeIdentity}</td>
                          <td>{transferee.users.firstName + " " + transferee.users.lastName}</td>
                          <td>
                            {" "}
                            <div className="d-flex align-items-center justify-content-center">
                              {" "}
                              <Button
                                disabled={isAdmitted(transferee)}
                                onClick={() => {
                                  onAdmit && onAdmit(transferee);
                                }}
                                className={admitButtonClasses}
                              >
                                <span>
                                  {" "}
                                  {isAdmitted(transferee)
                                    ? "admitted"
                                    : "Admit"}
                                  &nbsp;
                                  {admitted && (
                                    <i className="fas fa-check-circle"></i>
                                  )}
                                </span>
                              </Button>
                            </div>
                          </td>

                          <td>
                            {" "}
                            <div className="d-flex align-items-center justify-content-center">
                              {" "}
                              <Button
                                disabled={declined}
                                onClick={() => {
                                  onDecline && onDecline(transferee);
                                }}
                                className={declineButtonClasses}
                              >
                                {declined ? "Declined" : "Decline"}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Container className="pt-2">
              <Row>
                <Col>
                  <div className="d-flex align-items-end justify-content-end">
                    <Button
                      variant="primary"
                      className="px-5 rounded"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default AdmitTransferees;
