/* eslint-disable import/no-anonymous-default-export */
import { Switch, Route } from 'react-router-dom';
import RoutesName from './names';
import AuthRoute from '../utils/AuthRoute';
import PrivateRoute from '../utils/PrivateRoute';
import { useSuperAdmin } from '../hooks';
import { Link } from 'react-router-dom';
import SearchCode from '../pages/search-code/SearchCode';
import {
    DAOverview,
    DASapOverview,
    DAFeedback,
    DAAmbassadors,
    DATrainees,
    AdminAddAmbassor,
    DALandingPage,
    DATraining,
    DAMaterials,
    CommunicationDash,
    NotificationDash,
    DATransactions,
    DAAddTrainee,
    DAViewTrainee,
    AdminTrainees,
    DAServices,
    AdminUpdateAmbassador,
    DAUpdateTrainee,
    AddPartner,
    UpdatePartner,
    Partners,
    AddUser,
    AllDATrainees,
    PastRecords,
} from '../dashboards/da-dashboard/pages';

import { AdminSapOverview, AdminServices } from '../dashboards/admin-dashboard/pages';
import ListGroup from '../dashboards/da-dashboard/pages/list-group/listGroup';
import Profile from '../dashboards/da-dashboard/pages/profile/profile';
import SpecialReports from '../dashboards/da-dashboard/pages/special-reports/specialReports';
import CaptainGroup from '../dashboards/da-dashboard/pages/captain/group/captainGroup';
import Details from '../dashboards/da-dashboard/pages/captain/detail/details';
import Communication from '../dashboards/da-dashboard/pages/captain/communication/communication';
import { onlySuperAdminRole, adminRoles, daRoles, captain, managerRole, contentManagerRoles, onlyAdmin, onlyDA } from '../utils/roles';
import SAPManagement from '../dashboards/da-dashboard/pages/sap/Management';
import Assesment from '../dashboards/da-dashboard/pages/sap/Assesment';
import Curriculum from '../dashboards/da-dashboard/pages/sap/Curriculum';
import EditRoles from '../dashboards/da-dashboard/pages/roles/editRoles';
import ViewRoles from '../dashboards/da-dashboard/pages/roles/viewRoles';

import UserDaOverView from '../dashboards/da-dashboard/pages/da-overview/DAOverview';

import CoOverview from '../dashboards/da-dashboard/pages/collaborator/CoOverview';
import CoReports from '../dashboards/da-dashboard/pages/collaborator/CoReports';

import DATrainingManage from '../dashboards/da-dashboard/pages/training/TraningManagement';

export default function () {
    const isAdmin = useSuperAdmin();

    return (
        <Switch>
            <PrivateRoute path={RoutesName.dashboard.overview} component={isAdmin ? DAOverview : UserDaOverView} allowed={daRoles} />

            <AuthRoute path={RoutesName.dashboard.feedback} component={DAFeedback} />

            <PrivateRoute path={RoutesName.dashboard.ambassadors.list} component={DAAmbassadors} allowed={daRoles} />
            <PrivateRoute path={RoutesName.dashboard.ambassadors.create} component={AdminAddAmbassor} allowed={adminRoles} />

            <PrivateRoute path={RoutesName.dashboard.ambassadors.edit} component={AdminUpdateAmbassador} allowed={adminRoles} />

            <PrivateRoute path={RoutesName.dashboard.trainees.overview} component={DATrainees} allowed={captain} />

            <PrivateRoute path={RoutesName.dashboard.listGroup.details} component={ListGroup} allowed={captain} />
            <PrivateRoute path={RoutesName.dashboard.listGroup.groups} component={DATrainees} allowed={daRoles} />
            <PrivateRoute path={RoutesName.dashboard.trainees.create} component={DAAddTrainee} allowed={daRoles} />

            <PrivateRoute path={RoutesName.dashboard.trainees.edit} component={DAUpdateTrainee} allowed={captain} />
            <PrivateRoute path={RoutesName.dashboard.trainees.view} component={DAViewTrainee} allowed={captain} />
            <PrivateRoute path={RoutesName.dashboard.trainees.all} component={AdminTrainees} allowed={adminRoles} />
            <PrivateRoute path={RoutesName.dashboard.landingPage} component={DALandingPage} allowed={contentManagerRoles} />

            <PrivateRoute path={RoutesName.dashboard.communication} component={CommunicationDash} allowed={daRoles} />
            <PrivateRoute path={RoutesName.dashboard.notification} component={NotificationDash} allowed={captain} />

            <PrivateRoute path={RoutesName.dashboard.training.overview} component={DATraining} allowed={daRoles} />
            <PrivateRoute path={RoutesName.dashboard.training.manage} component={DATrainingManage} allowed={daRoles} />

            <PrivateRoute path={RoutesName.dashboard.sap.materials} component={DAMaterials} allowed={daRoles} />

            <AuthRoute path={RoutesName.dashboard.sap.overview} component={AdminSapOverview} allowed={onlyAdmin} />

            <AuthRoute path={RoutesName.dashboard.sap.view} component={DASapOverview} allowed={onlyDA} />

            <PrivateRoute path={RoutesName.dashboard.sap.transactions} component={DATransactions} allowed={daRoles} />
            <PrivateRoute path={RoutesName.dashboard.reports} component={SpecialReports} allowed={managerRole} />
            <PrivateRoute path={RoutesName.dashboard.sap.services} component={DAServices} allowed={daRoles} />

            {/* this route is causing heavy problem */}
            {/* <PrivateRoute path={RoutesName.dashboard.listGroup} component={ListGroup} allowed={daRoles}/> */}

            <PrivateRoute path={RoutesName.dashboard.reports} component={SpecialReports} allowed={managerRole} />
            <AuthRoute path={RoutesName.dashboard.profile} component={Profile} />

            <PrivateRoute path={RoutesName.dashboard.captain.group} component={CaptainGroup} allowed={captain} />
            <PrivateRoute path={RoutesName.dashboard.captain.details} component={Details} allowed={captain} />
            <PrivateRoute path={RoutesName.dashboard.captain.communication} component={Communication} allowed={captain} />

            <AuthRoute path={RoutesName.dashboard.sap.management} component={SAPManagement} />

            <AuthRoute path={RoutesName.dashboard.curriculum} component={Curriculum} />

            <AuthRoute path={RoutesName.dashboard.ambassadors.assesment} component={Assesment} />
            <PrivateRoute path={RoutesName.dashboard.ambassadors.myTrainees} component={AllDATrainees} allowed={daRoles} />

            <PrivateRoute path={RoutesName.dashboard.editRoles} component={EditRoles} allowed={onlySuperAdminRole} />
            <PrivateRoute path={RoutesName.dashboard.userRoles} component={ViewRoles} allowed={onlySuperAdminRole} />
            <PrivateRoute path={RoutesName.dashboard.partners.list} component={Partners} allowed={adminRoles} />
            <PrivateRoute path={RoutesName.dashboard.partners.create} component={AddPartner} allowed={adminRoles} />
            <PrivateRoute path={RoutesName.dashboard.users.create} component={AddUser} allowed={adminRoles} />

            <PrivateRoute path={RoutesName.dashboard.partners.edit} component={UpdatePartner} allowed={adminRoles} />

            <AuthRoute path={RoutesName.dashboard.co.overview} component={CoOverview} />
            <AuthRoute path={RoutesName.dashboard.co.reports} component={CoReports} />

            <PrivateRoute path={RoutesName.dashboard.services.list} component={AdminServices} allowed={onlyAdmin} />

            <PrivateRoute path={RoutesName.dashboard.searchCode} component={SearchCode} allowed={onlyDA} />
            <PrivateRoute path={RoutesName.importRecords} component={PastRecords} allowed={adminRoles} />

            <Route path="*" exact>
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <h2>404 Not Found</h2>
                            <p>Something went wrong we couldn't find what your looking for</p>
                            <Link to="/" className="btn btn-primary">
                                Go Back Home
                            </Link>
                        </div>
                    </div>
                </div>
            </Route>
        </Switch>
    );
}
