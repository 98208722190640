import React from "react";
import { Link, useHistory } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import "./index.css";

const BackLink = ({ t, path }) =>  {
  let history = useHistory();
  const goToPreviousPath = () => {
    history.goBack();
  };

  return (
    <div>
      {path ? (
        <Link to={path} className=" text-normal back-link">
          <i className="fas fa-caret-left"></i> {`${t(`Gusubira inyuma`)}`}
        </Link>
      ) : (
        <div onClick={goToPreviousPath} className=" text-normal back-link">
          <i className="fas fa-caret-left"></i> {`${t(`Gusubira inyuma`)}`}
        </div>
      )}
    </div>
  );
}

export default withNamespaces()(BackLink);
