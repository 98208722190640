import React, { useState } from 'react';
import CpanelLayout from '../../../../layouts/AdminLayout';
import { Col, Row, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Routes from "../../../../routes/names";

const Assesment = () => {
    const [payload, setPayload] = useState({});

    const handleChange = ({ target }) => {
        setPayload({ ...payload, [target.name]: target.value });
    }

    const impression = ['Very Good', 'Good', 'Fair', 'Bad', 'Very Bad'];

    return ( 
        <CpanelLayout>
            <Row>
                <Col md={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item className='text-primary'>
                            <Link to='/cpanel/overview'>Overview</Link>
                        </Breadcrumb.Item>
                        
                        <Breadcrumb.Item className='text-primary'>
                            <Link to={Routes.dashboard.training.overview}>Training Activities</Link>
                        </Breadcrumb.Item>
                        
                        <Breadcrumb.Item active>Monthly DA Assesment</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <h5>Monthly DA Assesment</h5>
            <p>Complete this survey to allow administration to track your progress</p>
            
            <h5 className="mb-4">Trainings</h5>
            
            <p className="py-0 my-0 mt-3">Average number of trained citizens</p>
            <div className="col-md-4 px-0">
                <input type="number" name="trained" className="form-control" value={payload.trained} required placeholder="50" onChange={handleChange} />
            </div>

            <p className="py-0 my-0 mt-3">Completed courses</p>
            <div className="col-md-4 px-0">
                <input type="number" name="courses" className="form-control" value={payload.courses} required placeholder="9" onChange={handleChange} />
            </div>

            <p className="py-0 my-0 mt-3">Conducted Sessions</p>
            <div className="col-md-4 px-0">
                <input type="number" name="session" className="form-control" value={payload.session} required placeholder="9" onChange={handleChange} />
            </div>

            <fieldset className="col-md-12 px-0 mt-4">
                <span>What is the citizens' impressions about the trainings?</span>
                <div className="col-sm-10">
                    {
                        impression.map((value, key) => (
                            <div className="form-check my-2">
                                <input className="form-check-input" type="radio" name="impression" id={key} value={value} checked={payload.impression === value ? true : false} onChange={handleChange} />
                                <label className="form-check-label" for={key}>
                                    {value}
                                </label>
                            </div>
                        ))
                    }
                </div>
            </fieldset>

            <fieldset className="col-md-12 px-0 mt-4">
                <span>Lorem ipsum dolor sit amet?</span>
                <div className="col-sm-10">
                    {
                        impression.map((value, key) => (
                            <div className="form-check my-2">
                                <input className="form-check-input" type="radio" name="impression" id={key} value={value} checked={payload.impression === value ? true : false} onChange={handleChange} />
                                <label className="form-check-label" for={key}>
                                    {value}
                                </label>
                            </div>
                        ))
                    }
                </div>
            </fieldset>

            <h5 className="mt-4">Trainings</h5>

            <p className="py-0 my-0 mt-3">Average number of trained citizens</p>
            <div className="col-md-4 px-0">
                <input type="number" name="trained" className="form-control" value={payload.trained} required placeholder="50" onChange={handleChange} />
            </div>

            <p className="py-0 my-0 mt-3">Completed courses</p>
            <div className="col-md-4 px-0">
                <input type="number" name="courses" className="form-control" value={payload.courses} required placeholder="9" onChange={handleChange} />
            </div>

            <p className="py-0 my-0 mt-3">Conducted Sessions</p>
            <div className="col-md-4 px-0">
                <input type="number" name="session" className="form-control" value={payload.session} required placeholder="9" onChange={handleChange} />
            </div>

            <fieldset className="col-md-12 px-0 mt-4">
                <span>What is the citizens' impressions about the trainings?</span>
                <div className="col-sm-10">
                    {
                        impression.map((value, key) => (
                            <div className="form-check my-2">
                                <input className="form-check-input" type="radio" name="impression" id={key} value={value} checked={payload.impression === value ? true : false} onChange={handleChange} />
                                <label className="form-check-label" for={key}>
                                    {value}
                                </label>
                            </div>
                        ))
                    }
                </div>
            </fieldset>

            <fieldset className="col-md-12 px-0 mt-4">
                <span>Lorem ipsum dolor sit amet?</span>
                <div className="col-sm-10">
                    {
                        impression.map((value, key) => (
                            <div className="form-check my-2">
                                <input className="form-check-input" type="radio" name="impression" id={key} value={value} checked={payload.impression === value ? true : false} onChange={handleChange} />
                                <label className="form-check-label" for={key}>
                                    {value}
                                </label>
                            </div>
                        ))
                    }
                </div>
            </fieldset>
            
            <button className="btn btn-primary px-5 py-2 mt-4 rounded">Schedule sessions</button>
        </CpanelLayout>
    );
}
 
export default Assesment;
