import {
  REQUEST_CREATE_GROUP,
  CREATE_GROUP_FAILED,
  CREATE_GROUP_SUCCESS,
  REQUEST_GET_GROUP,
  GET_GROUP_FAILED,
  GET_GROUP_SUCCESS,
  REQUEST_UPDATE_GROUP,
  UPDATE_GROUP_FAILED,
  UPDATE_GROUP_SUCCESS,
  REQUEST_DELETE_GROUP,
  DELETE_GROUP_FAILED,
	DELETE_GROUP_SUCCESS,
	REQUEST_GET_ONE_GROUP,
  GET_ONE_GROUP_FAILED,
  GET_ONE_GROUP_SUCCESS,
  REQUEST_ADD_GROUP_TRAINEE,
  ADD_GROUP_TRAINEE_FAILED,
  ADD_GROUP_TRAINEE_SUCCESS,
} from "../types"
import axios from "axios";
import { toast } from 'react-toastify';

const { REACT_APP_BACKEND } = process.env;

export const createGroup = group => dispatch => {
	dispatch({ type: REQUEST_CREATE_GROUP });
	axios
		.post(`${REACT_APP_BACKEND}/api/groups`, group)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: CREATE_GROUP_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: CREATE_GROUP_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const getAllDaGroups = () => (dispatch) => {
  dispatch({ type: REQUEST_GET_GROUP });
  axios
    .get(`${REACT_APP_BACKEND}/api/groups/my-groups`)
    .then((res) => {
      dispatch({ type: GET_GROUP_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_GROUP_FAILED,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};

export const getAllGroups = () => (dispatch) => {
  dispatch({ type: REQUEST_GET_GROUP });
  axios
    .get(`${REACT_APP_BACKEND}/api/groups`)
    .then((res) => {
      dispatch({ type: GET_GROUP_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_GROUP_FAILED,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};

export const getOneGroup = (id) => (dispatch) => {
  dispatch({ type: REQUEST_GET_ONE_GROUP });
  axios
    .get(`${REACT_APP_BACKEND}/api/groups/${id}`)
    .then((res) => {
      dispatch({ type: GET_ONE_GROUP_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ONE_GROUP_FAILED,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};
export const getCaptainGroup = () => (dispatch) => {
  dispatch({ type: REQUEST_GET_ONE_GROUP });
  axios
    .get(`${REACT_APP_BACKEND}/api/trainees/captain/collegues`)
    .then((res) => {
      dispatch({ type: GET_ONE_GROUP_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ONE_GROUP_FAILED,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};

export const updateGroup = (id, group )=> dispatch => {
	dispatch({ type: REQUEST_UPDATE_GROUP });
	axios
		.put(`${REACT_APP_BACKEND}/api/groups/${id}`, group)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: UPDATE_GROUP_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: UPDATE_GROUP_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const addGroupTrainee = (id, group )=> dispatch => {
	dispatch({ type: REQUEST_UPDATE_GROUP });
	axios
		.put(`${REACT_APP_BACKEND}/api/groups/${id}/add-trainee`, group)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: UPDATE_GROUP_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: UPDATE_GROUP_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const deleteGroup = (id )=> dispatch => {
	dispatch({ type: REQUEST_DELETE_GROUP });
	axios
		.delete(`${REACT_APP_BACKEND}/api/groups/${id}`)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: DELETE_GROUP_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: DELETE_GROUP_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};