import React, { useState, useEffect } from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import { Col, Row, Modal, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourses } from '../../../../../redux/actions';

export default function Groups({ centers, groups }) {
    const [editData, setEditData] = useState({});
    const [editModal, setEditModal] = useState(false);
    const { courses } = useSelector(state => state.courses);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCourses());
    }, [dispatch]);

    return (
        <div>
            <Row>
                {groups.map(group => {
                    return (
                        <Col className="col-sm-12 col-md-12 col-lg-4">
                            <div key={group.id} className="group-card px-4 py-4 mb-3 ml-3">
                                <div className="py-2 d-flex align-items-center justify-content-between">
                                    <Link to={`/cpanel/groups/group/${group.id}`}>
                                        <span className="text-medium">{group.name}</span>
                                    </Link>
                                    <div
                                        className="btn btn-light"
                                        onClick={() => {
                                            setEditData(group);
                                            setEditModal(true);
                                        }}
                                    >
                                        <i className="fas fa-pencil-alt text-medium"></i>
                                    </div>
                                </div>
                                <div className="text-small">
                                    <span>{group.trainees.length} members</span>
                                </div>

                                <div className="py-2">
                                    <span className="text-extra-small">
                                        Completed sessions:{' '}
                                        <span className="text-small">
                                            {group.trainees.length ? (group.trainees[0].attendenceCount ? group.trainees[0].attendenceCount : 0) : '0'}/{group.trainees.length ? group.trainees[0].courseNumber : courses.length}
                                        </span>
                                    </span>
                                </div>

                                <div className="py-1">
                                    <span className="text-extra-small">{group.cohort.name}</span>
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>

            <Modal show={editModal} size="md" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={() => setEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Group</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Row className="py-1">
                            <Col>
                                {' '}
                                <Form.Group>
                                    <Form.Control as="select" className="custom-select" name="center">
                                        <option value="" selected disabled>
                                            Training centre
                                        </option>
                                        {centers &&
                                            centers.map((center, index) => (
                                                <option key={index} selected={editData?.trainingCenterName === center.centerName ? true : false}>
                                                    {center.centerName}
                                                </option>
                                            ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="py-1">
                            <Col>
                                <Form.Group>
                                    <Form.Control placeholder="Group Description" as="textarea" rows="5" name="description" value={editData?.description} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary">Edit Group</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
