import DAOverview from './overview/Overview';
import DASapOverview from './sap/Overview';
import DAFeedback from './feedback/Feedback';
import DAAmbassadors from './ambassadors/Ambassadors';
import DATrainees from './trainees/DATrainees';
import AdminAddAmbassor from './ambassadors/add-ambassador/AddAmbassador';
import DALandingPage from './landing-page/LandingPage';
import DATraining from './training/Training';
import DAMaterials from './sap/Materials';
import DATransactions from './sap/Transactions';
import DAServices from './sap/Services';
import DAAddTrainee from './trainees/AddTrainee';
import DAViewTrainee from './trainees/ViewTrainee';
import CommunicationDash from './communication/CommunicationDash';
import NotificationDash from './Notifications/NotificationDas';
import AdminTrainees from './trainees/AdminTrainees';
import AdminUpdateAmbassador from './ambassadors/update-ambassador/UpdateAmbassador';
import DAUpdateTrainee from './trainees/UpdateTrainee';
import AddPartner from './partners/AddPartner/AddPartners';
import UpdatePartner from './partners/UpdatePartner/UpdatePartner.js';
import Partners from './partners/Partners';
import AddUser from './users/add-ambassador/AddUser';
import PastRecords from './pastRecords';
import { AllDATrainees } from './trainees';

export {
    DAOverview,
    DASapOverview,
    DAFeedback,
    DAAmbassadors,
    DATrainees,
    AdminAddAmbassor,
    DALandingPage,
    DATraining,
    DAMaterials,
    CommunicationDash,
    DATransactions,
    DAAddTrainee,
    DAViewTrainee,
    AdminTrainees,
    DAServices,
    AdminUpdateAmbassador,
    DAUpdateTrainee,
    AddPartner,
    UpdatePartner,
    Partners,
    AddUser,
    AllDATrainees,
    PastRecords,
    NotificationDash,
};
