/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unreachable */
/* eslint-disable no-undef */
import { GET_NOTIFICATION_FAILURE, GET_ONE_NOTIFICATION, GET_UNSEEN_NOTIFICATIONS, REQUEST_ONE_NOTIFICATION, REQUEST_UNSEEN_NOTIFICATIONS, REQUEST_ALL_NOTIFICATIONS, GET_ALL_NOTIFICATIONS, ALL_NOTIFICATIONS_FAILED } from '../types';

const initialState = {
    message: '',
    error: '',
    notification: {},
    notifications: [],
    unSeenNotifications: [],
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_UNSEEN_NOTIFICATIONS:
            return {
                ...state,
                loading: true,
            };
        case GET_UNSEEN_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                unSeeenNotifications: action.payload.data,
            };

        // case REQUEST_ONE_NOTIFICATION:
        //     return {
        //         ...state,
        //         loading: false
        //     }
        case GET_ONE_NOTIFICATION:
            return {
                ...state,
                loading: false,
                notification: action.payload.data,
            };
        case GET_NOTIFICATION_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case REQUEST_ALL_NOTIFICATIONS:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_NOTIFICATIONS:
            return {
                ...state,
                loading: false,
                notifications: action.payload.data,
            };
        case ALL_NOTIFICATIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
