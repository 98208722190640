/* eslint-disable import/no-anonymous-default-export */
import {
    REQUEST_GET_USERS,
    REQUEST_USER_CREATION,
    USER_CREATION_SUCCESS,
    USER_CREATION_FAILED,
    GET_USERS_SUCCESS,
    GET_USERS_FAILED,
    REQUEST_MAKE_COORD,
    MAKE_COORD_SUCCESS,
    MAKE_COORD_FAILED,
    REQUEST_USER_UPDATE,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILED,
    REQUEST_UPDATE_USER,
    REQUEST_GET_DA,
    GET_DA_SUCCESS,
    GET_DA_FAILED,
    GET_USER_INFO,
} from '../types';

const initialState = {
    success: false,
    message: '',
    error: '',
    loading: null,
    userLoading: null,
    cordLoading: null,
    updateLoading: null,
    users: [],
    ambassadors: [],
    update: {},
    userInfos: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_UPDATE_USER:
            return {
                ...state,
                update: action.payload,
            };

        case REQUEST_USER_CREATION:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_GET_USERS:
            return {
                ...state,
                userLoading: true,
            };

        case USER_CREATION_SUCCESS:
            return {
                ...state,
                message: action.payload,
                success: true,
                loading: null,
                error: null,
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                success: true,
                userLoading: null,
                error: null,
                users: action.payload.data,
            };
        case USER_CREATION_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: null,
            };
        case GET_USERS_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                userLoading: null,
            };
        case REQUEST_MAKE_COORD:
            return {
                ...state,
                cordLoading: true,
            };
        case MAKE_COORD_SUCCESS:
            return {
                ...state,
                message: action.payload,
                success: true,
                cordLoading: null,
                error: null,
            };
        case MAKE_COORD_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                cordLoading: null,
            };
        case REQUEST_USER_UPDATE:
            return {
                ...state,
                updateLoading: true,
            };
        case USER_UPDATE_SUCCESS:
            return {
                ...state,
                message: action.payload,
                success: true,
                updateLoading: null,
                error: null,
            };
        case USER_UPDATE_FAILED:
            return {
                ...state,
                error: action.payload,
                success: false,
                updateLoading: null,
            };
        case REQUEST_GET_DA:
            return {
                ...state,
                loading: true,
                ambassadors: [],
            };
        case GET_DA_SUCCESS:
            return {
                ...state,
                loading: null,
                ambassadors: action.payload,
            };
        case GET_DA_FAILED:
            return {
                ...state,
                loading: null,
                error: action.payload,
            };
        case GET_USER_INFO:
            return {
                ...state,
                userInfos: action.payload.data,
            };
            
        default:
            return state;
    }
};
