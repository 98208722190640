import axios from 'axios';
import { toast } from 'react-toastify';
import { ERROR_TRANSACTION, GET_ALL_TRANSACTION, LOAD_TRANSACTION } from '../types';
import { getSingleCenter } from './centers';
const { REACT_APP_BACKEND } = process.env;

export const createTransaction = (payload, onClose) => async dispatch => {
    dispatch({ type: LOAD_TRANSACTION, payload: { loading: true } });
    try {
        const URL = `${REACT_APP_BACKEND}/api/saptransactions/create`;
        const { data } = await axios.post(URL, payload);
        toast.success(data.message);
        dispatch(getAllTransactions());
        dispatch(getSingleCenter(payload.trainingCenterId))
        onClose()
    } catch (err) {
        dispatch({ type: LOAD_TRANSACTION, payload: { loading: false } });
        dispatch({
            type: ERROR_TRANSACTION,
            payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
        });
    }
};

export const getAllTransactions = () => async dispatch => {
    dispatch({ type: LOAD_TRANSACTION, payload: { loading: true } });
    try {
        const URL = `${REACT_APP_BACKEND}/api/saptransactions`;
        const { data } = await axios.get(URL);
        dispatch({ type: GET_ALL_TRANSACTION, payload: data?.data });
        dispatch({ type: LOAD_TRANSACTION, payload: { loading: false } });
    } catch (err) {
        dispatch({ type: LOAD_TRANSACTION, payload: { loading: false } });
        dispatch({
            type: ERROR_TRANSACTION,
            payload: err.response ? err.response.data.error || err.response.data.Errors[0] : null,
        });
    }
};
