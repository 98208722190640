import React from 'react';
import { Line } from 'react-chartjs-2';

const LinearChart = ({ data }) => (
	<>
		<Line data={data} />
	</>
);

export default LinearChart;
