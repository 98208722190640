import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import routes from '../../../../../routes/names';
import { reverse } from 'named-urls';

function ManageMaterials({ id, materials }) {
    const totalMaterials = isEmpty(materials) || !Array.isArray(materials) ? 0 : materials.length;
    const functionalMaterials = isEmpty(materials) || !Array.isArray(materials) ? 0 : materials.filter(el => el.status === 'functional').length;
    const damagedMaterials = isEmpty(materials) || !Array.isArray(materials) ? 0 : materials.filter(el => el.status === 'damaged').length;

    const data = {
        labels: ['Functional', 'With issues'],
        datasets: [
            {
                label: '# of Votes',
                data: [functionalMaterials, damagedMaterials],
                backgroundColor: ['#1da992', '#cf1b33'],
                borderColor: ['#1da992', '#cf1b33'],
                borderWidth: 1,
            },
        ],
    };
    return (
        <div className="shadow p-4">
            <div className="row">
                <div className="col-md-9">
                    <p className="text-medium">Materials overview</p>
                </div>

                <div className="col-md-3">
                    <Link to={reverse(routes.dashboard.sap.materials, { id })} className="btn pull-right transparent">
                        <i className="fas fa-pen"></i> Manage
                    </Link>
                </div>
            </div>

            <div className="row">
                <div className="col-md-5">
                    <Doughnut data={data} />
                </div>

                <div className="col-md-5">
                    <p className="mt-4 text-normal mb-1">Total Materials</p>
                    <p className="text-title text-bold text-primary">{totalMaterials}</p>

                    <p className="mt-4 text-normal mb-1">Functional Materials</p>
                    <p className="text-title text-bold text-primary">{functionalMaterials}</p>
                </div>
            </div>
        </div>
    );
}

export default ManageMaterials;
