/* eslint-disable eqeqeq */
import React from 'react';
import './index.css';
import moment from 'moment';

import { Button } from 'react-bootstrap';
import clx from 'classnames';

export default function Session({ session, confirmAttendees, sessionStatus, editSession, deleteSession, viewDetails }) {
    const textStyles = clx({
        'text-white': sessionStatus == 'completed' && !session.isConfirmed,
        'text-medium': true,
    });

    const buttonStyles = clx({
        'btn-light': sessionStatus == 'completed' && !session.isConfirmed,
        'btn-primary': sessionStatus == 'completed' && session.isConfirmed,
        rounded: true,
    });

    const shouldConfirmAttendees = (sessionStatus !== 'pending' && !session.isConfirmed) || sessionStatus == 'current';
    return (
        <div
            key={session.id}
            className={
                'session-card px-4 py-4 mb-3 ' +
                clx({
                    'session-attendees-unconfirmed': session.status == 'completed' && !session.isConfirmed,
                    'text-white': sessionStatus == 'completed' && !session.isConfirmed,
                })
            }
        >
            <div>
                <div className="py-2 d-flex align-items-center justify-content-between">
                    <span className={textStyles}>{session.name}</span>

                    {sessionStatus == 'completed' && !session.isConfirmed && (
                        <div className="btn btn-link " onClick={() => deleteSession(session.id)}>
                            <i className="fas fa-trash-alt text-medium text-white"></i>
                        </div>
                    )}

                    {sessionStatus !== 'completed' && (
                        <Button className="btn btn-light " onClick={() => editSession && editSession(session)}>
                            <i className="fas fa-pencil-alt text-medium"></i>
                        </Button>
                    )}
                </div>

                <div className="py-1">
                    <span className={'text-normal color-grey' + textStyles}>{session.cohorts ? session.cohorts.name : null}</span>
                </div>

                {session.date && (
                    <div className="py-1">
                        <span className={'text-small color-grey' + textStyles}>
                            Date:&nbsp; <span className="session-label-bold">{moment(session.date.split(' ')[0]).format('MMM Do YYYY')}</span>{' '}
                        </span>
                    </div>
                )}

                {session.startAt && (
                    <div className="py-1">
                        <span className={'text-small color-grey' + textStyles}>
                            Time:&nbsp; <span className="session-label-bold">{session.startAt}</span>
                        </span>
                    </div>
                )}

                <div className="py-1">
                    <span className={'text-small color-grey' + textStyles}>
                        Group: &nbsp;<span className="session-label-bold">{session.groups ? session.groups.name : null}</span>{' '}
                    </span>
                </div>
                <div className="py-1">
                    <span className={'text-small color-grey' + textStyles}>
                        Module: &nbsp;<span className="session-label-bold">{session.course ? session.course.title : null}</span>{' '}
                    </span>
                </div>
            </div>

            <div className="pt-2">
                {shouldConfirmAttendees && (
                    <Button className={buttonStyles} onClick={() => confirmAttendees && confirmAttendees(session)} block>
                        Confirm attendance
                    </Button>
                )}

                {sessionStatus == 'completed' && !session.isConfirmed && (
                    <Button className="rounded" onClick={() => editSession && editSession(session)} block>
                        Reschedule
                    </Button>
                )}

                {sessionStatus == 'completed' && (
                    <Button className="rounded" onClick={() => viewDetails && viewDetails(session)} block>
                        View Details
                    </Button>
                )}
            </div>
        </div>
    );
}
