import axios from 'axios';
import {
  LATEST_REPORTS_ERROR,
  GET_LATEST_REPORTS,
  REQUEST_LATEST_REPORTS
} from '../types';

const { REACT_APP_BACKEND } = process.env;

export const getLatestReport = () => (dispatch) => {
  dispatch({ type: REQUEST_LATEST_REPORTS });
  axios
    .get(`${REACT_APP_BACKEND}/api/reports/latest`)
    .then((res) => {
      dispatch({ type: GET_LATEST_REPORTS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({
        type: LATEST_REPORTS_ERROR,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};