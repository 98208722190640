import axios from 'axios';
import { RESIDENCE_ERROR, GET_RESIDENCE_LOCATIONS, GET_USER_CELL, GET_USER_DISTRICT, GET_USER_VILLAGE, GET_USER_SECTOR, REQUEST_DISTRICT, REQUEST_SECTOR, REQUEST_CELSS, REQUEST_VILLAGES, CLEAR_TRAINING_CENTERS } from '../types';

const { REACT_APP_BACKEND } = process.env;

export const getAllLocations = () => dispatch => {
    axios
        .get(`${REACT_APP_BACKEND}/api/locations`)
        .then(res => {
            dispatch({
                type: GET_RESIDENCE_LOCATIONS,
                payload: res.data,
            });
        })
        .catch(err => {
            dispatch({
                type: RESIDENCE_ERROR,
                payload: err.response ? err.response.data.error : null,
            });
        });
};

export const getLocationByProvince = province => dispatch => {
    dispatch({ type: REQUEST_DISTRICT });
    axios
        .get(`${REACT_APP_BACKEND}/api/locations/province/${province}`)
        .then(res => {
            let district = [];
            res.data.locations.forEach(location => district.push(location.district));
            let districts = [...new Set(district)];
            dispatch({
                type: GET_USER_DISTRICT,
                payload: districts,
            });
        })
        .catch(err => {
            dispatch({
                type: RESIDENCE_ERROR,
                payload: err.response ? err.response.data.error : null,
            });
        });
};
export const getLocationByDistrict = district => dispatch => {
    dispatch({ type: REQUEST_SECTOR });
    axios
        .get(`${REACT_APP_BACKEND}/api/locations/district/${district}`)
        .then(res => {
            let sector = [];
            res.data.locations.forEach(location => sector.push(location.sector));
            let sectors = [...new Set(sector)];
            dispatch({
                type: GET_USER_SECTOR,
                payload: sectors,
                residence: res.data.locations,
            });
        })
        .catch(err => {
            dispatch({
                type: RESIDENCE_ERROR,
                payload: err.response ? err.response.data.error : null,
            });
        });
};
export const getLocationBySector = sector => dispatch => {
    dispatch({ type: REQUEST_CELSS });
    dispatch({ type: CLEAR_TRAINING_CENTERS });
    axios
        .get(`${REACT_APP_BACKEND}/api/locations/sector/${sector}`)
        .then(res => {
            let cell = [];
            res.data.locations.forEach(location => cell.push(location.cell));
            let cells = [...new Set(cell)];
            dispatch({
                type: GET_USER_CELL,
                payload: cells,
                residence: res.data.locations,
            });
        })
        .catch(err => {
            dispatch({
                type: RESIDENCE_ERROR,
                payload: err.response ? err.response.data.error : null,
            });
        });
};
export const getLocationByCell = (sector, cell) => dispatch => {
    dispatch({ type: REQUEST_VILLAGES });
    axios
        .get(`${REACT_APP_BACKEND}/api/locations/cell/${sector}/${cell}`)
        .then(res => {
            let village = [];
            res.data.locations.forEach(location => village.push(location.village));
            let villages = [...new Set(village)];
            dispatch({
                type: GET_USER_VILLAGE,
                payload: villages,
                residence: res.data.locations,
            });
        })
        .catch(err => {
            dispatch({
                type: RESIDENCE_ERROR,
                payload: err.response ? err.response.data.error : null,
            });
        });
};
