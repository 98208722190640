import 'dotenv/config';
import {
	SEND_FEEDBACK,
	SEND_FEEDBACK_SUCCESS,
	SEND_FEEDBACK_FAILURE,
	REQUEST_GET_FEEDBACKS,
	GET_FEEDBACKS_SUCCESS,
	GET_FEEDBACKS_FAILED,
} from '../types';
import axios from 'axios';
import { toast } from 'react-toastify';

const { REACT_APP_BACKEND } = process.env;

export const sendFeedback = (loginData, message) => dispatch => {
	dispatch({ type: SEND_FEEDBACK, payload: loginData });
	axios
		.post(`${REACT_APP_BACKEND}/api/feedbacks/create`, loginData)
		.then(res => {
			console.log(res.data);
			toast.success(message.success);
			dispatch({ type: SEND_FEEDBACK_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(message.error);
			dispatch({
				type: SEND_FEEDBACK_FAILURE,
				payload: err.response ? err.response.data.error : null,
			});
		});
};

export const getAllFeedbacks = () => dispatch => {
	dispatch({ type: REQUEST_GET_FEEDBACKS });
	axios
		.get(`${REACT_APP_BACKEND}/api/feedbacks`)
		.then(res => {
			dispatch({ type: GET_FEEDBACKS_SUCCESS, payload: res.data });
		})
		.catch(err => {
			dispatch({
				type: GET_FEEDBACKS_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};
