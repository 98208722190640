import DATrainees from "./DATrainees";
import AddTrainee from "./AddTrainee";
import AdminTrainees from "./AdminTrainees";
import DAViewTrainee from "./ViewTrainee";
import DAUpdateTrainee from "./UpdateTrainee";
import AllDATrainees from "./AllDaTrainee";
export {
  DATrainees,
  AddTrainee,
  AdminTrainees,
  DAViewTrainee,
  DAUpdateTrainee,
  AllDATrainees
};
