const matchPhone = value => {
    var numberPattern = /[0-9+]+/g;

    const matchedArray = String(value).match(numberPattern);

    const result = Array.isArray(matchedArray) ? matchedArray.join('') : '';

    return result;
};

export default matchPhone;
