import {
	REQUEST_CREATE_ROLE,
	CREATE_ROLE_FAILED,
	CREATE_ROLE_SUCCESS,
	REQUEST_GET_ROLE,
	GET_ROLE_FAILED,
	GET_ROLES_SUCCESS,
	REQUEST_ASSIGN_ROLE
} from "../types"
import axios from "axios";
import { toast } from 'react-toastify';
import { getUsers } from "./users";

const { REACT_APP_BACKEND } = process.env;

export const createRole = data => dispatch => {
	dispatch({ type: REQUEST_CREATE_ROLE });
	axios
		.post(`${REACT_APP_BACKEND}/api/roles/create`, data)
		.then(res => {
			toast.success(res.data.message);
			dispatch({ type: CREATE_ROLE_SUCCESS, payload: res.data.message });
		})
		.catch(err => {
			toast.error(err.response ? (err.response.data.error || err.response.data.Errors[0]): null,)
			dispatch({
				type: CREATE_ROLE_FAILED,
				payload: err.response ? (err.response.data.error || err.response.data.Errors[0]): null,
			});
		});
};

export const getAllRoles = () => (dispatch) => {
  dispatch({ type: REQUEST_GET_ROLE });
  axios
    .get(`${REACT_APP_BACKEND}/api/roles`)
    .then((res) => {
      dispatch({ type: GET_ROLES_SUCCESS, payload: res.data.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ROLE_FAILED,
        payload: err.response
          ? err.response.data.error || err.response.data.Errors[0]
          : null,
      });
    });
};

export const assignRoleToUser = (userId,roleId) => (dispatch) => {
	dispatch({ type: REQUEST_ASSIGN_ROLE, payload:{loading:true} });
	axios
	    .put(`${REACT_APP_BACKEND}/api/roles/update/${userId}/${roleId}`)
	  .then((res) => {
		toast.success(res.data.message)
		dispatch(getUsers())
		dispatch({ type: REQUEST_ASSIGN_ROLE, payload:{loading:false} });
	  })
	  .catch((err) => {
		if(err.response.status ===400) toast.error(err.response.data.error)
		dispatch({ type: REQUEST_ASSIGN_ROLE, payload:{loading:false} });
	  });
};


export const deleteDARole = (userId,roleId, onCloseDeleteModal=undefined) => (dispatch) => {
	dispatch({ type: REQUEST_ASSIGN_ROLE, payload:{loading:true} });
	axios
	  .delete(`${REACT_APP_BACKEND}/api/roles/delete/${userId}/${roleId}`)
	  .then((res) => {
		toast.success(res.data.message)
		dispatch(getUsers())
		dispatch({ type: REQUEST_ASSIGN_ROLE, payload:{loading:false} });
        if(typeof onCloseDeleteModal==="function") onCloseDeleteModal();
	  })
	  .catch((err) => {
		if(err.response.status ===404) toast.error(err.response.data.error)
		dispatch({ type: REQUEST_ASSIGN_ROLE, payload:{loading:false} });
	  });
  };

