import React from "react";
import { Doughnut, Bar } from "react-chartjs-2";


export default function RegistrationMeans({ mobilePerc, webPerc, ussdPerc }) {
  const dataset = {
    datasets: [
      {
        label: "# of Votes",
        data: [mobilePerc, webPerc, ussdPerc],
        backgroundColor: ["#CF1B33", "#078ECE", "#40A55B", "#CFC71B"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div>
      <h3>Registration Means</h3>
      <div className="row align-items-center">
        <div className="col-4">
          <Doughnut data={dataset} />
        </div>
        <div>
          <p>{`${mobilePerc ?? 0} % Mobile`}</p>
          <p>{`${webPerc ?? 0} % Web`}</p>
          <p>{`${ussdPerc ?? 0} % USSD`}</p>
          {/* <p>35% DA</p> */}
        </div>
      </div>
    </div>
  );
}
