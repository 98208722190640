import 'dotenv/config';
import { REQUEST_GET_MATERIALS, CREATE_MATERIAL_START, CREATE_MATERIAL_SUCCESS, CREATE_MATERIAL_FAIL } from '../types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getSingleCenter } from './centers';

const { REACT_APP_BACKEND } = process.env;

export const listMaterial = () => dispatch => {
    axios
        .get(`${REACT_APP_BACKEND}/api/sapmaterials`)
        .then(res => {
            dispatch({ type: REQUEST_GET_MATERIALS, payload: res.data.data });
        })
        .catch(err => {
            console.log(err);
        });
};

export const createMaterial =
    ({ payload, onClose, resetForm }) =>
    dispatch => {
        dispatch({ type: CREATE_MATERIAL_START, loading: true, error: '' });
        axios
            .post(`${REACT_APP_BACKEND}/api/sapmaterials/create`, payload)
            .then(res => {
                onClose && onClose();
                resetForm && resetForm();
                dispatch({ type: CREATE_MATERIAL_SUCCESS, loading: false });
                dispatch(getSingleCenter(payload.trainingcenterId));
            })
            .catch(err => {
                dispatch({ type: CREATE_MATERIAL_FAIL, loading: false, error: 'Sorry, We could not add material to this SAP. Try again later.' });
            });
    };

export const updateMaterial = payload => dispatch => {
    const { id, status, centerId } = payload;

    axios
        .put(`${REACT_APP_BACKEND}/api/sapmaterials/status/${id}`, { status })
        .then(res => {
            toast.success(res.data.message);
            dispatch(getSingleCenter(centerId));
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteMaterial = id => dispatch => {
    axios
        .delete(`${REACT_APP_BACKEND}/api/sapmaterials/${id}`)
        .then(res => {
            toast.success(res.data.message);
            dispatch(listMaterial());
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteTrainingCenterMaterial = (sapMaterialId, centerId) => dispatch => {
    axios
        .delete(`${REACT_APP_BACKEND}/api/centers/${centerId}/material/${sapMaterialId}`)
        .then(res => {
            toast.success(res.data.message);
            dispatch(getSingleCenter(centerId));
        })
        .catch(err => {
            console.log(err);
        });
};
