import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';

import { getLocationByProvince, getLocationByDistrict, getLocationBySector, getLocationByCell, createCenterLocation, updateCenterLocation } from '../../../../../redux/actions';
import { getCenterLocation } from '../../../../../redux/actions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { matchCoordinates } from '../../../../../helpers';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Loading from '../../../../../utils/LoadingSmall';
import isObject from 'lodash/isObject';
import uniqBy from 'lodash/uniqBy';
import { isEmpty } from 'lodash';

export default function UpdateSap({ open, onClose, sap }) {
    const validationSchema = Yup.object().shape({
        centerName: Yup.string().required(' First name is required'),
        province: Yup.string().required(' Province is required'),
        district: Yup.string().required(' District is required'),
        sector: Yup.string().required(' Sector is required'),
        cell: Yup.string().required(' Cell is required'),
        coordinates: Yup.object().shape({
            latitude: Yup.number('Latitude must be a number').min(-90, 'latitude should be above -90').max(90, 'latitude should be less than 90').required(' SAP Latitude coordinate is required'),
            longitude: Yup.number('Longitude must be a number').min(-180, 'longitude should be above -180').max(180, 'longitude should be less than 180').required(' SAP Longitude coordinate is required'),
        }),
        daInCharge: Yup.string().required('DA ID In Charge for SAP is required'),
        sapServices: Yup.array(Yup.object().shape({ label: Yup.string(), key: Yup.string() })),
    });

    const getPreviousValues = values => {
        if (isObject(values)) {
            return {
                centerName: values.centerName,
                province: values.Location?.province || '',
                district: values.Location?.district || '',
                sector: values.Location?.sector || '',
                cell: values.Location?.cell || '',
                coordinates: values.Coordinates,
                daInCharge: '',
            };
        }

        return {};
    };

    const initialValues = {
        centerName: '',
        province: '',
        district: '',
        sector: '',
        cell: '',
        coordinates: { latitude: '', longitude: '' },
        daInCharge: '',
        sapServices: [],
        ...getPreviousValues(sap),
    };
    const dispatch = useDispatch();

    // const [residentId, setResidentId] = useState('');
    // const [residentIds, setResidentIds] = useState([]);
    // const [centerId, setCenterId] = useState('');
    // const [userResidence, setUserResidence] = useState({
    //     province: '',
    //     district: '',
    //     sector: '',
    //     cell: '',
    // });

    const [allSapServices, setAllSapServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedService, setSelectedService] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);

    const { residence, districts, sectors, cells, villages } = useSelector(state => state.residence);
    const { loading, error } = useSelector(state => state.centers);
    const { services, loading: ServicesLoading } = useSelector(state => state.services);

    const { values, handleSubmit, errors, handleChange, setFieldValue, touched, setErrors, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const payload = {
                ...values,
                coordinates: {
                    latitude: parseFloat(values.coordinates.latitude),
                    longitude: parseFloat(values.coordinates.longitude),
                },

                sapServices: selectedServices,
            };

            // console.log({ payload });
            const searchUrl = `${process.env.REACT_APP_BACKEND}/api/users/search/${payload.daInCharge}`;
            axios
                .get(searchUrl)
                .then(res => {
                    dispatch(updateCenterLocation(sap.id, payload, onClose, resetForm));
                })
                .catch(err => {
                    if (err.response.status === 404) {
                        const fieldErrors = {};
                        fieldErrors['daInCharge'] = err.response.data.error;
                        setErrors(fieldErrors);
                    }
                });
        },
    });

    useEffect(() => {
        if (isLoading && !isEmpty(sap) && !isEmpty(sap.DAInfo)) {
            const {
                DAInfo: { province, sector, cell, district, userIdentity },
                Services,
            } = sap;
            // const updateProvince = province === 'Kigali' ? `${province} City` : `${province} Province`;
            setFieldValue('province', province);
            setFieldValue('district', district);
            setFieldValue('sector', sector);
            setFieldValue('cell', cell);
            setFieldValue('daInCharge', userIdentity);
            const updateServices = Services.length ? Services.map(el => ({ key: el.id, label: el.name })) : [];
            setFieldValue('sapServices', updateServices);
            setSelectedServices(updateServices);
            dispatch(getLocationByProvince(province));
            dispatch(getLocationByDistrict(district));
            dispatch(getLocationBySector(sector));
        }

        if (services && services.length) {
            const realServices = services.map(service => ({ label: service.name, key: service.id }));
            setAllSapServices(realServices);
            setIsLoading(false);
        }

        // isLoading && fetchAllServices();
    }, [isLoading, sap, services]);

    const fetchAllServices = () => {
        const url = `${process.env.REACT_APP_BACKEND}/api/sapservices`;
        axios
            .get(url)
            .then(res => {
                if (res.status === 200) setAllSapServices(res.data.data);
            })
            .catch(err => {
                if (err.response?.status === 404) setAllSapServices([]);
            });
        setIsLoading(false);
    };

    const onProvinceChange = e => {
        const { value } = e.target;

        setFieldValue('province', value);
        dispatch(getLocationByProvince(value));
    };
    const onDistrictChange = e => {
        const { value } = e.target;
        setFieldValue('district', value);
        dispatch(getLocationByDistrict(value));
    };
    const onSectorChange = e => {
        const { value } = e.target;
        setFieldValue('sector', value);
        dispatch(getLocationBySector(value));
    };
    const onCellChange = e => {
        const { value } = e.target;
        setFieldValue('cell', value);
    };

    const onFindDAHandler = e => {
        e.preventDefault();
        if (values.daInCharge) {
            const searchUrl = `${process.env.REACT_APP_BACKEND}/api/users/search/${values.daInCharge}`;
            axios
                .get(searchUrl)
                .then(response => {
                    if (response.status === 200) toast.success('DA Identity exists');
                })
                .catch(err => {
                    if (err.response.status === 404) toast.error(err.response.data.error);
                });
        }
    };

    const addService = () => {
        if (selectedService) {
            const service = allSapServices.find(el => el.key === selectedService);
            const unique = uniqBy([...new Set([...selectedServices, service])], 'key');
            setSelectedServices(unique);
            setFieldValue('sapServices', unique);
        }
    };

    const removeService = key => {
        setSelectedServices(selectedServices.filter(el => el.key !== key));
        setFieldValue(
            'sapServices',
            selectedServices.filter(el => el.key !== key),
        );
    };

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal"
            className="custom-modal"
            show={open}
            onHide={() => {
                onClose();
                resetForm();
            }}
            backdrop="static"
            animation={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Update SAP</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <div className="py-3">
                        {' '}
                        <span className="text-medium">Basic Information</span>
                    </div>

                    <Form.Group>
                        <Form.Control type="text" placeholder="SAP Name" name="centerName" onChange={handleChange} value={values.centerName} />

                        {touched.centerName && errors.centerName && (
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {errors.centerName}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Row>
                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control as="select" className="custom-select" name="province" onChange={onProvinceChange} value={values.province}>
                                    <option value="" selected disabled>
                                        {' '}
                                        Province
                                    </option>
                                    <option>Kigali City</option>
                                    <option>Western Province</option>
                                    <option>Southern Province</option>
                                    <option>Northern Province</option>
                                    <option>Eastern Province</option>
                                </Form.Control>

                                {touched.province && errors.province && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.province}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control as="select" className="custom-select" name="district" onChange={onDistrictChange} value={values.district}>
                                    <option value="" selected disabled>
                                        District
                                    </option>
                                    {districts &&
                                        districts.map((district, index) => (
                                            <option selected={district === values.district ? true : false} key={index}>
                                                {district}
                                            </option>
                                        ))}
                                </Form.Control>

                                {touched.district && errors.district && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.district}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control as="select" className="custom-select" name="sector" onChange={onSectorChange} value={values.sector}>
                                    <option value="" selected disabled>
                                        {' '}
                                        Sector
                                    </option>
                                    {sectors &&
                                        sectors.map((sector, index) => (
                                            <option selected={sector === values.sector ? true : false} key={index}>
                                                {sector}
                                            </option>
                                        ))}
                                </Form.Control>

                                {touched.sector && errors.sector && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.sector}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control as="select" className="custom-select" name="cell" onChange={onCellChange}>
                                    <option value="" selected disabled>
                                        Cell
                                    </option>
                                    {cells &&
                                        cells.map((cell, index) => (
                                            <option selected={cell === values.cell ? true : false} key={index}>
                                                {cell}
                                            </option>
                                        ))}
                                </Form.Control>

                                {touched.cell && errors.cell && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.cell}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="pt-3">
                        {' '}
                        <span className="text-medium">SAP Geolocation</span>
                    </div>

                    <Row className="mt-3">
                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control
                                    name="coordinates.latitude"
                                    placeholder="Latitude"
                                    onChange={e => {
                                        const { value } = e.target;

                                        setFieldValue('coordinates.latitude', matchCoordinates(value));
                                    }}
                                    value={values.coordinates.latitude}
                                />

                                {touched.coordinates?.latitude && errors.coordinates?.latitude && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.coordinates?.latitude}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control
                                    name="coordinates.longitude"
                                    placeholder="Longitude"
                                    onChange={e => {
                                        const { value } = e.target;

                                        setFieldValue('coordinates.longitude', matchCoordinates(value));
                                    }}
                                    value={values.coordinates.longitude}
                                />

                                {touched.coordinates?.longitude && errors.coordinates?.longitude && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.coordinates?.longitude}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col md={7}>
                            <Form.Group>
                                <Form.Control type="text" placeholder="User ID of DA in charge" name="daInCharge" onChange={handleChange} value={values.daInCharge} readOnly />
                                {touched.daInCharge && errors.daInCharge && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.daInCharge}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Button type="button" className="btn-block py-2 rounded " onClick={onFindDAHandler} disabled={loading}>
                                Find
                            </Button>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            <Form.Label>SAP Services</Form.Label>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={7}>
                            <Form.Group>
                                <Form.Control
                                    as="select"
                                    onChange={e => {
                                        const { value } = e.target;
                                        setSelectedService(value);
                                    }}
                                    custom
                                >
                                    <option value="" disabled selected>
                                        Search or add service
                                    </option>
                                    {allSapServices.length &&
                                        allSapServices.map(service => {
                                            return <option value={service.key}>{service.label}</option>;
                                        })}
                                </Form.Control>
                                {selectedServices.length > 0 &&
                                    selectedServices.map(el => {
                                        return (
                                            <div
                                                key={el.key}
                                                className="bg-white rounded p-2 my-2 border-1 d-flex align-items-center justify-content-between"
                                                style={{
                                                    borderWidth: 1,
                                                    border: '1px solid #ced4da',
                                                }}
                                            >
                                                <div>
                                                    <span className="text-small">{el.label} </span>
                                                </div>

                                                <div onClick={() => removeService(el.key)}>
                                                    <i className="fas fa-times-circle"></i>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </Form.Group>
                        </Col>

                        <Col md={4}>
                            <Button className="btn-block py-2 rounded" disabled={loading} onClick={addService}>
                                Add
                            </Button>
                        </Col>
                    </Row>

                    {error && (
                        <div>
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {error}
                            </Form.Control.Feedback>
                        </div>
                    )}

                    <Button className={`px-5 py-2 mt-3 rounded ${loading && 'd-flex'}`} onClick={handleSubmit} disabled={loading}>
                        Update {loading && <Loading color="#FFF" marginTop=" " center={true} />}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
