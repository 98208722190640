import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Form, Button } from 'react-bootstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { matchDigit } from '../../../../helpers';
import { updateCourse } from '../../../../redux/actions';
import routes from '../../../../routes/names';
import { isObject } from 'lodash';
import Loading from '../../../../utils/LoadingSmall';

export default function EditModule({ open, onClose, module }) {
    const dispatch = useDispatch();
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Course name is required'),
        link: Yup.string(),
        duration: Yup.number('Course duration must be a number').required('Course duration is required'),
    });
    const message = useSelector(state => state.courses.message);
    const { updateLoading, error } = useSelector(state => state.courses);

    const getPreviousValues = values => {
        if (isObject(values)) {
            return {
                ...values,
                duration: parseInt(values.duration),
            };
        }

        return {};
    };
    const initialValues = {
        title: '',
        description: '',
        duration: '',
        link: '',
        ...getPreviousValues(module),
    };

    const { values, errors, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            const payload = { ...values };
            if (/^[0-9]+$/.test(values.duration)) {
                payload.duration = values.duration;
            }

            if (/([0-9]+)(.*)/.test(values.duration)) {
                payload.duration = Number(
                    String(values.duration)
                        .split(/([0-9]+)(.*)/)
                        .filter(d => d)
                        .shift(),
                );
            }
            dispatch(updateCourse({ id: values.id, payload, onClose, resetForm }));
        },
    });

    return (
        <div>
            <Modal show={open} size="md" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="custom-modal" className="custom-modal" animation={false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Module</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Control type="text" placeholder="Module Title" className="form-control rounded my-2" value={values.title} name="title" onChange={handleChange} disabled={updateLoading} />
                            {errors.title && (
                                <Form.Control.Feedback className="d-block" type="invalid">
                                    {errors.title}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Duration"
                                className="form-control rounded my-2"
                                value={values.duration}
                                name="duration"
                                disabled={updateLoading}
                                onChange={event => {
                                    let value = matchDigit(event.target.value);

                                    value = value.substr(0, 3);

                                    value = Number(value) > 120 ? 120 : value;

                                    setFieldValue('duration', value);
                                }}
                            />

                            {errors.duration && (
                                <Form.Control.Feedback className="d-block" type="invalid">
                                    {errors.duration}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Control as="textarea" placeholder="Description" className="form-control rounded my-2" rows="5" value={values.description} name="description" onChange={handleChange} disabled={updateLoading} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control type="text" placeholder="Link to course" className="form-control rounded my-2" name="link" value={values.link} onChange={handleChange} disabled={updateLoading} />
                        </Form.Group>
                    </Form>

                    <Form.Control.Feedback className="d-block" type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Modal.Body>

                <Modal.Footer>
                    <Button className={`px-5 py-2 mt-3 rounded ${updateLoading && 'd-flex'}`} onClick={handleSubmit} disabled={updateLoading}>
                        Save {updateLoading && <Loading color="#FFF" marginTop=" " center={true} />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
