import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch} from "react-redux";
import {Modal, Button} from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import {SET_EXPIRED} from '../redux/types';
import {logoutUser, refreshToken} from "../redux/actions"

let interval;

export const checkTokenExpiration = (token, store) => {
    const exp = jwtDecode(token).exp;
    const timeDiff = (exp * 1000) - Date.now()
    interval = setTimeout(() => {
      checkTokenExpiration(token, store)
  }, 6000);
    if (timeDiff < 500000) {
      store.dispatch({type: SET_EXPIRED, payload: true})
      interval = clearInterval(interval)
    }
}

export const ExpirationModal = () =>{
  const dispatch = useDispatch();

  const [show, setShow] = useState();
  const [counter, setCounter] = useState(60)

  const {expired} = useSelector(state=>state.auth);
    

  const handleClose = () => {
    dispatch({type: SET_EXPIRED, payload: false})
    dispatch(logoutUser());
    window.location.reload();
  }
  const handleContinue = () => {
    dispatch(refreshToken())
  };
  useEffect(()=>{
    setShow(expired)
    if(expired){
      setTimeout(() => {
        setCounter(counter-1)
      }, 1000);
    }else{
      setCounter(60)
    }
    if(counter === 0){
      dispatch(logoutUser());
      window.location.reload();
    }
  },[expired, counter, dispatch])


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Token expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your token is about to be expired, do you wish to continue ?<br/>
          If no action taken, you will be logged out in {counter} seconds
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleContinue}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}