import React from "react";
import {
  View,
  Image,
} from "@react-pdf/renderer";

import BackSide from "../../assets/images/back-side.png";

const BackPage = () => {
  return (
    <View>
      <Image src={BackSide} style={{ width: "98%", height: "98%" }} />
    </View>
  );
};

export default BackPage;
