export const convertMonthInKinya = (month) => {
    if(month === '01' || month === '1'){
        return 'Kwa Mutarama';
    } else if(month === '02' || month === '2'){
        return 'Kwa Gashyantare';
    } else if(month === '03' || month === '3'){
        return 'Kwa Werurwe';
    } else if(month === '04' || month === '4'){
        return 'Kwa Mata';
    } else if(month === '05' || month === '5'){
        return 'Kwa Gicurasi';
    } else if(month === '06' || month === '6'){
        return 'Kwa Kamena';
    } else if(month === '07' || month === '7'){
        return 'Kwa Nyakanya';
    } else if(month === '08' || month === '8'){
        return 'Kwa Kanama';
    } else if(month === '09' || month === '9'){
        return 'Kwa Nzeri';
    } else if(month === '10' || month === '010'){
        return `K'\Ukwakira`;
    } else if(month === '11' || month === '011'){
        return `K'\Ugushyingo`;
    } else if(month === '12' || month === '012'){
        return `K'\Ukuboza`;
    } else{
        return '---';
    }
};

export const convertMonthInKinyaDOB = (month) => {
    if(month === '01' || month === '1'){
        return 'Mutarama';
    } else if(month === '02' || month === '2'){
        return 'Gashyantare';
    } else if(month === '03' || month === '3'){
        return 'Werurwe';
    } else if(month === '04' || month === '4'){
        return 'Mata';
    } else if(month === '05' || month === '5'){
        return 'Gicurasi';
    } else if(month === '06' || month === '6'){
        return 'Kamena';
    } else if(month === '07' || month === '7'){
        return 'Nyakanya';
    } else if(month === '08' || month === '8'){
        return 'Kanama';
    } else if(month === '09' || month === '9'){
        return 'Nzeri';
    } else if(month === '10' || month === '010'){
        return `Ukwakira`;
    } else if(month === '11' || month === '011'){
        return `Ugushyingo`;
    } else if(month === '12' || month === '012'){
        return `Ukuboza`;
    } else{
        return '---';
    }
};
