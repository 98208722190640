import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CpanelLayout from '../../../../layouts/AdminLayout';
import { Button, Modal } from 'react-bootstrap';
import { getAllRoles } from '../../../../redux/actions';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

import { Row, Col, Breadcrumb } from "react-bootstrap";

import { AddRole } from '../../../admin-dashboard/components/roles';
import EditRole from '../../../admin-dashboard/components/roles/edit-role/EditRole';
import isEmpty from 'lodash/isEmpty';
import { emptifyFalseValues } from '../../../../helpers';

import { Link } from 'react-router-dom';
import Routes from '../../../../routes/names';

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const sanitizeData = roles =>
    roles.map((role, index) => ({
        ...emptifyFalseValues(role),
        index: index + 1,
    }));

const EditRoles = () => {
    const [modals, setModals] = useState({ addRole: false, editRole: false });

    const [role, setRole] = useState({});

    const dispatch = useDispatch();
    const { succeess, loading, roles } = useSelector(state => state.roles);

    useEffect(() => {
        dispatch(getAllRoles());
    }, [dispatch]);

    const openAddRole = () => setModals({ ...modals, addRole: true });
    const closeAddRole = () => setModals({ ...modals, addRole: false });

    const openEditRole = () => setModals({ ...modals, editRole: true });
    const closeEditRole = () => {
        setRole({});
        setModals({ ...modals, editRole: false });
    };

    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const renderOptons = (cell, row) => {
        const userID = row.id;
        return (
            <div className="pull-right">
                <Button
                    className="btn btn-light"
                    onClick={() => {
                        setRole(row);

                        openEditRole();
                    }}
                >
                    {' '}
                    <i className="fas fa-pencil-alt text-primary "></i> &nbsp; Edit role
                </Button>
            </div>
        );
    };

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: 'All',
                value: roles.length,
            },
        ],
    };
    const columns = [
        { dataField: 'index', text: '#', csvExport: false, sort: true },
        {
            dataField: 'name',
            text: 'Role',
            sort: true,
        },

        {
            dataField: 'description',
            text: 'Description',
        },

        {
            dataField: 'option',
            text: 'Actions',
            formatter: renderOptons,
            headerClasses: 'pull-left ml-5 pl-5',
            csvExport: false,
        },
    ];

    return (
        <CpanelLayout>
            <main className="mt-5">
                <Row>
                    <Col md={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item className='text-primary'>
                                <Link to='/cpanel/overview'>Overview</Link>
                            </Breadcrumb.Item>
                            
                            <Breadcrumb.Item className='text-primary'>
                                <Link to={Routes.dashboard.userRoles}>User Roles</Link>
                            </Breadcrumb.Item>
                            
                            <Breadcrumb.Item active>Edit Roles</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <div className="d-flex justify-content-between align-items-center">
                    <div className="pb-4">
                        {' '}
                        <span className="text-title">Edit Roles</span>
                    </div>

                    <div>
                      <button onClick={openAddRole} className="btn" data-toggle="modal" data-target="#addRole">
                          <i className="fa fa-plus" /> &nbsp; Add role
                      </button>

                      
                    </div>
                </div>

                <ToolkitProvider
                    keyField="name"
                    data={sanitizeData(roles)}
                    columns={columns}
                    search
                    exportCSV={{
                        fileName: `DAP-ROLES-${new Date().toLocaleTimeString()}.csv`,
                        separator: ',',
                        ignoreHeader: false,
                        noAutoBOM: false,
                    }}
                >
                    {props => (
                        <div>
                            <div>
                                <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                    <i class="fas fa-file-excel text-small"></i> &nbsp;Export to Excel
                                </ExportCSVButton>
                            </div>
                            <div className="d-flex align-items-end justify-content-end">
                                <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0 text-small" placeholder="Search users roles ..." />
                            </div>

                            <div className="pt-3">
                                <BootstrapTable classes="data-table" bordered={false} {...props.baseProps} pagination={paginationFactory(options)} options={options} bootstrap4 />
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
            </main>

            <AddRole open={modals.addRole} onClose={closeAddRole} />

            {!isEmpty(role) && <EditRole open={modals.editRole} onClose={closeEditRole} role={role} />}
        </CpanelLayout>
    );
};

export default EditRoles;
