import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { createCenterLocation, getUserInfo } from '../../../../../redux/actions';
import { listService } from '../../../../../redux/actions/services';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { matchCoordinates } from '../../../../../helpers';
import { useEffect } from 'react';
import Loading from '../../../../../utils/LoadingSmall';
import jwtDecode from 'jwt-decode';

export default function AddTraning({ user, open, onClose }) {
    const validationSchema = Yup.object().shape({
        centerName: Yup.string().required(' First name is required'),
        province: Yup.string().required(' Province is required'),
        district: Yup.string().required(' District is required'),
        sector: Yup.string().required(' Sector is required'),
        cell: Yup.string().required(' Cell is required'),
        coordinates: Yup.object().shape({
            latitude: Yup.number('Latitude must be a number').min(-90, 'latitude should be above -90').max(90, 'latitude should be less than 90').required(' SAP Latitude coordinate is required'),
            longitude: Yup.number('Longitude must be a number').min(-180, 'longitude should be above -180').max(180, 'longitude should be less than 180').required(' SAP Longitude coordinate is required'),
        }),
        daInCharge: Yup.string().required('DA ID In Charge for SAP is required'),
        sapServices: Yup.array(Yup.object().shape({ label: Yup.string(), key: Yup.string() })),
    });

    const initialValues = {
        centerName: '',
        province: '',
        district: '',
        sector: '',
        cell: '',
        coordinates: { latitude: '-1.9507305', longitude: '30.1212607' },
    };
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const { loading } = useSelector(state => state.centers);

    useEffect(() => {
        isLoading && dispatch(listService());
        if (user) {
            const { province, district, sector, cell, userIdentity } = user;
            // const DaProvince = province === 'Kigali' ? `${province} City` : `${province} Province`;
            setFieldValue('daInCharge', userIdentity);
            setFieldValue('province', province);
            setFieldValue('district', district);
            setFieldValue('sector', sector);
            setFieldValue('cell', cell);
            setIsLoading(false);
        }
    }, [isLoading, user]);

    const { values, handleSubmit, errors, handleChange, setFieldValue, touched, setErrors, resetForm } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async values => {
            const payload = {
                ...values,
                coordinates: {
                    latitude: parseFloat(values.coordinates.latitude),
                    longitude: parseFloat(values.coordinates.longitude),
                },
            };
            dispatch(createCenterLocation(payload, onClose, resetForm));
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    const token = jwtDecode(localStorage.getItem('IdToken'));
                    const userID = token?.id;
                    dispatch(getUserInfo(userID));
                }, 1000);
            });
        },
    });

    // Getting current location
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setFieldValue('coordinates.latitude', position.coords.latitude);
                setFieldValue('coordinates.longitude', position.coords.longitude);
            });
        } else {
            setFieldValue('coordinates.latitude', '-1.9507305');
            setFieldValue('coordinates.longitude', '30.1212607');
        }
    };

    useEffect(() => {
        getLocation();
    }, []);

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal"
            className="custom-modal"
            show={open}
            onHide={() => {
                onClose();
                resetForm();
            }}
            backdrop="static"
            animation={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>New Training Center</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <div className="py-3">
                        <span className="text-medium">Basic Information</span>
                    </div>

                    <Form.Group>
                        <Form.Control type="text" placeholder="Training Center Name" name="centerName" onChange={handleChange} value={values.centerName} />
                        {touched.centerName && errors.centerName && (
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {errors.centerName}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    <Row className="mt-2">
                        <Col md={12}>
                            <Form.Group>
                                <Form.Control type="text" placeholder="User ID of DA in charge" value={user?.userIdentity} name="daInCharge" readOnly />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control placeholder="province" value={user?.province} readOnly />
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control placeholder="district" value={user?.district} readOnly />
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control placeholder="sector" value={user?.sector} readOnly />
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <Form.Control placeholder="cell" value={user?.cell} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="pt-3">
                        <span className="text-medium">Training Center Geolocation</span>
                    </div>

                    <Row>
                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <p>Latitude</p>
                                <Form.Control name="coordinates.latitude" placeholder="Latitude" onChange={({ target }) => setFieldValue('coordinates.latitude', matchCoordinates(target.value))} value={values.coordinates.latitude} />
                                {touched.coordinates?.latitude && errors.coordinates?.latitude && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.coordinates?.latitude}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>

                        <Col className="col-12 col-md-6 ">
                            <Form.Group>
                                <p>Longitude</p>
                                <Form.Control name="coordinates.longitude" placeholder="Longitude" onChange={({ target }) => setFieldValue('coordinates.longitude', matchCoordinates(target.value))} value={values.coordinates.longitude} />
                                {touched.coordinates?.longitude && errors.coordinates?.longitude && (
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.coordinates?.longitude}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <p>
                            {' '}
                            <i style={{ color: 'red' }} className="fa fa-info-circle"></i> Geolocation (Latitude and Longitude) values should be a decimal number with more than 7 decimals
                        </p>
                    </Row>

                    <Button className={`px-5 py-2 mt-3 rounded ${loading && 'd-flex'}`} onClick={handleSubmit} disabled={loading}>
                        Save {loading && <Loading color="#FFF" marginTop=" " center={true} />}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
