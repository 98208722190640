import React, { useState } from "react";
import {
  Container,
  Dropdown,
  ButtonGroup,
  DropdownButton,
} from "react-bootstrap";
import Axios from 'axios';
import "./index.css";
import UploadImage from "./UploadImage";
import { toast } from "react-toastify";
import styled from "styled-components";
import Spinner from "../../../../../components/spinner/Spinner";

const SpinnerWrapper=styled.div`
  display: flex;
  width: 200px;
  height: 133px;
  margin-left: 0;
  background-color: #ccccccd4;
  align-items: center;
  justify-content: center;
`;
const UploadableImage = ({ image,setImage, imageToUpload, setImageToUpload, fetchAdminImages, isLoading, setIsLoading }) => {
  const handleUpload = async e  => {
    e.preventDefault();
    setIsLoading(true)
    try {
      const formData = new FormData();
      const url =  `${process.env.REACT_APP_BACKEND}/api/sliders/image/create`
      formData.append("dir",'landingpage')
      formData.append('single_image', imageToUpload);
      const config = { headers: { 'Access-Control-Allow-Origin': '*' }};
      const response = await Axios.post(url, formData, config);
      if(response.status===201){
        toast.success(response.data.message);
      }
      setImage(null)
      setImageToUpload(null)
      fetchAdminImages()
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false)
  };

  return (
    <div
      className="img uploadable-img d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }}
    >
      {
        isLoading?
        <SpinnerWrapper>
          <Spinner isUpload={true}/>
        </SpinnerWrapper>:
        <div className="btn btn-primary rounded" onClick={handleUpload}>
          <div className="d-flex flex-row text-medium ">
            {" "}
            Upload{" "}
            <div className="text-white px-3 ">
              <i className="fas fa-plus-circle "></i>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default function SlideShow({ isAllowed }) {
  const [images, setImages]=React.useState([])
  const [image, setImage] = React.useState(null);
  const [imageToUpload,setImageToUpload]=React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)

  const uploadLocal = (base64,file) => {
    if(Math.round((file.size)/1024)>=1024){
      toast.error("Your image is too big, Please select an image size which is less or equal than 1MB")
      return;
    }
    setImage(base64);
    setImageToUpload(file);
  }

  React.useEffect(()=>{
    isLoading && fetchAdminImages()
  },[image,isLoading])
  const fetchAdminImages = async()=>{
    const url = `${process.env.REACT_APP_BACKEND}/api/sliders/image/fetch`
    const response = await Axios.get(url);
    if(response.status===200){
      setImages(response.data.images)
      setIsLoading(false)
    }
  }
  const deleteImageHandler=async(imageIndex)=>{
    const imageName = images[imageIndex].name
    const url = `${process.env.REACT_APP_BACKEND}/api/sliders/image/delete/${imageName}`
    const response = await Axios.delete(url);
    if(response.status===200) {
      toast.success(response.data.message)
      setImages(images.filter((_,index)=>imageIndex!==index))
      fetchAdminImages()
    }
  }
  return (
    <div className="py-3 px-3">
      <h4>Images of landing page</h4>
      <p className="d-flex align-items-center text-small text-bold">
        <span><i className="fa fa-info-circle text-medium mr-1" style={{color:"brown"}}></i></span>
        Before you upload an image, make sure that the image size is less or equal than 1MB!
        </p>
      <Container className="d-flex flex-row align-items-center flex-no-wrap">
        <div className="dap-bg-secondary py-3 d-flex flex-row align-items-center flex-no-wrap slide-show ">
          {images.map((img, index) => {
            const imageUrl = `${process.env.REACT_APP_BACKEND}/${img.url}`
            return (
              <div key={index} className="mx-2">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: "cover",
                  }}
                >
                  {" "}
                  {
                    isAllowed && <div>
                    <div className="pull-right">
                      <DropdownButton
                        as={ButtonGroup}
                        variant="light"
                        style={{ zIndex: 9000 }}
                        drop="right"
                        title=""
                      >
                        <Dropdown.Item eventKey="1" onClick={()=>deleteImageHandler(index)}>Delete</Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div> }
                </div>
              </div>
            );
          })}{" "}
        </div>

        {image && (
          <div className="ml-3">
            {" "}
            <UploadableImage isLoading={isLoading} setIsLoading={setIsLoading} fetchAdminImages={fetchAdminImages} image={image} setImage={setImage} imageToUpload={imageToUpload} setImageToUpload={setImageToUpload} />
          </div>
        )}
        
        {
          isAllowed && <div className="ml-3">
          {" "}
          <UploadImage onUpload={uploadLocal} />
        </div> }
      </Container>
    </div>
  );
}
