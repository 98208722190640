import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './index.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import routes from '../../../../../../routes/names';
import { emptifyFalseValues } from '../../../../../../helpers';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const sanitizeData = users =>
    users.map((user, index) => ({
        ...emptifyFalseValues(user),
        names: user.firstName + ' ' + user.lastName + ' ' + user.middleName,
        status: user.isActive ? 'Active' : 'Inactive',
        index: index + 1,
        createdAt: user.createdAt ? moment(user.createdAt).format('DD-MM-YYYY') : 'N/A',
    }));

function PartnerTables({ partners, search, makeCoordinator, updateStatus, isAllowed }) {
    const customTotal = (from, to, size) => (
        <span className="pull-left color-grey text-small">
            Showing {from}- {to} in {size}
        </span>
    );

    const renderNames = (cell, row, rowIndex) => {
        return (
            <span>
                {row.firstName} {row.middleName} {row.lastName}{' '}
            </span>
        );
    };

    const renderOptons = (cell, row) => {
        const id = row.id;

        return (
            <div className="pull-right">
                <Dropdown>
                    <Dropdown.Toggle variant="light" className="text-dark">
                        Actions &nbsp; <i className="fas fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {isAllowed && (
                            <Dropdown.Item as="div">
                                <Link to={reverse(routes.dashboard.partners.edit, { id })}>
                                    <div className="w-100">
                                        {' '}
                                        <i className="fas fa-pencil-alt text-primary "></i> &nbsp;Edit{' '}
                                    </div>
                                </Link>{' '}
                            </Dropdown.Item>
                        )}
                        {isAllowed && (
                            <Dropdown.Item>
                                <span className="text-small">
                                    {' '}
                                    <i className="fas fa-trash-alt text-primary "></i>&nbsp; Delete
                                </span>
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item>
                            <Link to={`/cpanel/profile?user=${row.id}`}>
                                {' '}
                                <span className="text-small">
                                    {' '}
                                    <i className="fas fa-info-circle text-primary "></i> &nbsp; More details
                                </span>
                            </Link>
                        </Dropdown.Item>

                        {/* <Dropdown.Item>
              {" "}
              <span
                className="text-small"
                onClick={() => makeCoordinator(row.id)}
              >
                {" "}
                <i className="fas fa-star text-primary "></i> &nbsp; Make
                Cordinator
              </span>
            </Dropdown.Item> */}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    };

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true,
        firstPageText: '',
        prePageText: '<',
        nextPageText: '>',
        paginationTotalRenderer: customTotal,
        disablePageTitle: false,
        showTotal: true,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '20', value: 20 },
            { text: 'All', value: Array.isArray(partners) ? partners.length : 0 },
        ],
    };
    const columns = [
        { dataField: 'index', text: '#', sort: true, csvExport: false },
        {
            dataField: 'names',
            text: 'Names',
            formatter: renderNames,
            filterValue: (cell, row) => row.firstName + row.lastName + row.middleName,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'userIdentity',
            text: 'Partner ID',
            sort: true,
            filterValue: (cell, row) => (row.userIdentity ? row.userIdentity : 'N/A'),
            csvExport: true,
        },

        {
            dataField: 'email',
            text: 'Email',
            filterValue: (cell, row) => row.email,
            csvExport: true,
        },

        {
            dataField: 'phone',
            text: 'Phone',
            filterValue: (cell, row) => row.phone,

            csvExport: true,
        },
        {
            dataField: 'province',
            text: 'Province',
            filterValue: (cell, row) => row.province,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'district',
            text: 'District',
            filterValue: (cell, row) => row.district,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'sector',
            text: 'Sector',
            filterValue: (cell, row) => row.sector,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'cell',
            text: 'Cell',
            filterValue: (cell, row) => row.sector,
            sort: true,
            csvExport: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            filterValue: (cell, row) => row.status,
            sort: true,
            csvExport: true,
        },

        {
            dataField: 'createdAt',
            text: '   Joined_Date  ',

            csvExport: true,
        },
        { dataField: 'options', text: 'Options', formatter: renderOptons, csvExport: false },
    ];
    return (
        <div>
            {partners.length ? (
                <ToolkitProvider
                    keyField="userIdentity"
                    data={sanitizeData(partners)}
                    columns={columns}
                    search
                    exportCSV={{
                        fileName: `DAP-AMBASSADORS-${new Date().toLocaleTimeString()}.csv`,
                        separator: ',',
                        ignoreHeader: false,
                        noAutoBOM: false,
                    }}
                >
                    {props => (
                        <div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <ExportCSVButton {...props.csvProps} className="btn btn-primary rounded">
                                        <i class="fas fa-file-excel text-small"></i> &nbsp;Export to CSV
                                    </ExportCSVButton>
                                </div>
                                {search && <SearchBar {...props.searchProps} className="mr-sm-2 border-top-0 border-right-0 border-left-0" placeholder="Search Partners ..." />}
                            </div>

                            <div className="pt-3">
                                <BootstrapTable classes="data-table" bordered={false} {...props.baseProps} pagination={paginationFactory(options)} options={options} bootstrap4 />
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
            ) : (
                <div className="d-flex align-item-center justify-content-center h-100">
                    <span> There's no currently registered Digital ambassadors</span>
                </div>
            )}
        </div>
    );
}

export default PartnerTables;
