export const REQUEST_TRAINEE_CREATION = 'REQUEST_TRAINEE_CREATION';
export const TRAINEE_CREATION_SUCCESS = 'TRAINEE_CREATION_SUCCESS';
export const TRAINEE_CREATION_FAILED = 'TRAINEE_CREATION_FAILIED';

export const REQUEST_GET_TRAINEES = 'REQUEST_GET_TRAINEES';
export const GET_TRAINEES_SUCCESS = 'GET_TRAINEES_SUCCESS'; 
export const GET_TRAINEES_FAILED =  'GET_TRAINEES_FAILED';

export const REQUEST_SEARCH_CERTIFICATE = 'REQUEST_SEARCH_CERTIFICATE';
export const SEARCH_CERTIFICATE_SUCCESS = 'SEARCH_CERTIFICATE_SUCCESS';
export const SEARCH_CERTIFICATE_FAILED = 'SEARCH_CERTIFICATE_FAILED';

export const REQUEST_VIEW_CERTIFICATE = "REQUEST_VIEW_CERTIFICATE";
export const VIEW_CERTIFICATE_SUCCESS = "VIEW_CERTIFICATE_SUCCESS";
export const VIEW_CERTIFICATE_FAILED = "VIEW_CERTIFICATE_FAILED";

export const REQUEST_UPDATE_STATUS = "REQUEST_UPDATE_STATUS";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_ERROR = "UPDATE_STATUS_ERROR";

export const REQUEST_CAPTAIN_MAKING = "REQUEST_CAPTAIN_MAKING";
export const MAKE_CAPTAIN_SUCCESS = "MAKE_CAPTAIN_SUCCESS";
export const MAKE_CAPTAIN_FAILED = "MAKE_CAPTAIN_FAILED";

export const REQUEST_TRAINEE_DELETE = "REQUEST_TRAINEE_DELETE";
export const DELETE_TRAINEE_SUCCESS = "DELETE_TRAINEE_SUCCESS";
export const DELETE_TRAINEE_FAILED = "DELETE_TRAINEE_FAILED";

export const REQUEST_GET_DA_TRAINEES = 'REQUEST_GET_DA_TRAINEES';
export const GET_DA_TRAINEES_SUCCESS = 'GET_DA_TRAINEES_SUCCESS'; 
export const GET_DA_TRAINEES_FAILED = 'GET_DA_TRAINEES_FAILED';

export const REQUEST_GET_PENDING_TRAINEES = 'REQUEST_GET_PENDING_TRAINEES';
export const GET_PENDING_TRAINEES_SUCCESS = 'GET_PENDING_TRAINEES_SUCCESS'; 
export const GET_PENDING_TRAINEES_FAILED = 'GET_PENDING_TRAINEES_FAILED';

export const REQUEST_APPROVE_TRAINEES = 'REQUEST_APPROVE_TRAINEES';
export const APPROVE_TRAINEES_SUCCESS = 'APPROVE_TRAINEES_SUCCESS'; 
export const APPROVE_TRAINEES_FAILED =  'APPROVE_TRAINEES_FAILED';

export const REQUEST_GET_ALL_TRAINEE_DA_BY_PROVINCE = 'REQUEST_GET_ALL_TRAINEE_DA_BY_PROVINCE';
export const GET_ALL_TRAINEE_DA_BY_PROVINCE_SUCCESS = 'GET_ALL_TRAINEE_DA_BY_PROVINCE_SUCCESS';
export const GET_ALL_TRAINEE_DA_BY_PROVINCE_FAILED = 'GET_ALL_TRAINEE_DA_BY_PROVINCE_FAILED';

export const REQUEST_GET_ATTENDEES = 'REQUEST_GET_ATTENDEES';
export const GET_ATTENDEES_SUCCESS = 'GET_ATTENDEES_SUCCESS'; 
export const GET_ATTENDEES_FAILED =  'GET_ATTENDEES_FAILED';

export const REQUEST_TRANSFER_TRAINEE = 'REQUEST_TRANSFER_TRAINEE';
export const TRANSFER_TRAINEE_SUCCESS = 'TRANSFER_TRAINEE_SUCCESS'; 
export const TRANSFER_TRAINEE_FAILED = 'TRANSFER_TRAINEE_FAILED';

export const REQUEST_GET_PENDING_TRANSFER = 'REQUEST_GET_PENDING_TRANSFER';
export const GET_PENDING_TRANSFER_SUCCESS = 'GET_PENDING_TRANSFER_SUCCESS'; 
export const GET_PENDING_TRANSFER_FAILED =  'GET_PENDING_TRANSFER_FAILED';

export const REQUEST_APPROVE_TRANSFERS = 'REQUEST_APPROVE_TRANSFERS';
export const APPROVE_TRANSFERS_SUCCESS = 'APPROVE_TRANSFERS_SUCCESS'; 
export const APPROVE_TRANSFERS_FAILED = 'APPROVE_TRANSFERS_FAILED';

export const REQUEST_UPDATE_GROUP = "REQUEST_UPDATE_GROUP";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_STATUS_ERROR";

export const REQUEST_GET_TRAINEE = 'REQUEST_GET_TRAINEE';
export const GET_TRAINEE_SUCCESS = 'GET_TRAINEE_SUCCESS'; 
export const GET_TRAINEE_FAILED =  'GET_TRAINEE_FAILED';
export const CLEAR_SUCCESS_STATUS = "CLEAR_SUCCESS_STATUS";

export const VIEW_TRAINEE = 'VIEW_TRAINEE';
export const REQUEST_DOWNLOAD_CREATION = "REQUEST_DOWNLOAD_CREATION";
export const DOWNLOAD_CREATION_SUCCESS = "DOWNLOAD_CREATION_SUCCESS";
export const DOWNLOAD_CREATION_FAILED = "DOWNLOAD_CREATION_FAILED";
