import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';
import { sendLinkResetPassword } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../utils/LoadingSmall';

export const ForgotPassword = props => {
	const [email, setEmail] = useState('');
	const [validated, setValidated] = useState(false);
	const loading = useSelector(state => state.auth.sendLinkData);
	const sendLinkFailed = useSelector(state => state.auth.sendLInkFailure);
	const sendLinkSucceeded = useSelector(state => state.auth.sendLinkSuccess);

	const dispatch = useDispatch();

	const handleChange = e => {
		const { value } = e.target;
		setEmail(value);
	};

	const handleSubmit = e => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.stopPropagation();
		}
		setValidated(true);
		e.preventDefault();
		if (email) {
			dispatch(sendLinkResetPassword({ email }));
			localStorage.setItem('email', email);
		}
		// setEmail('');
	};

	if (sendLinkSucceeded) {
		return <Redirect to='/check-otp' />;
	}
	return (
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
			<Form.Group className='mt-3'>
				<Form.Control
					required
					type='email'
					name='email'
					value={email}
					placeholder={props.t(`Email`)}
					onChange={handleChange}
				/>
				<Form.Control.Feedback type='invalid'>
					{`Email irakenewe!`}
				</Form.Control.Feedback>
			</Form.Group>
			<Form.Group className='mt-3'>
				<Button
					type='submit'
					variant='primary'
					className='btn-block'
					style={{ width: '100%' }}
				>
					{props.t(`Ohereza`)}
				</Button>
				{loading && <Loading />}
			</Form.Group>
			<p>
				{sendLinkFailed && (
					<Alert className='alert-warning'>{sendLinkFailed}</Alert>
				)}
				{sendLinkSucceeded && (
					<>
						<Alert className='alert-success'>{sendLinkSucceeded}</Alert>
					</>
				)}
			</p>
			<p
				className='cursor text-primary text-small mt-3'
				onClick={props.onNextPage1}
			>
				{props.t(`Subira ahabanza`)}
			</p>
		</Form>
	);
};
