// User reducer types
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';
export const GET_SEND_FEEDBACK_SUCCESS = 'GET_SEND_FEEDBACK_SUCCESS';
export const GET_SEND_FEEDBACK_FAILURE = 'GET_SEND_FEEDBACK_FAILURE';

export const REQUEST_GET_FEEDBACKS = 'REQUEST_GET_FEEDBACKS';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const GET_FEEDBACKS_FAILED = "GET_FEEDBACKS_FAILED";
