import { Container, Row, Col } from "react-bootstrap";
import { withNamespaces } from 'react-i18next';

const Footer = ({ t, fluid }) => {
  return (
    <footer className="bg-dark-primary py-3">
      <Container fluid={fluid ?? false}>
        <Row>
          <Col md={12}>
            <p className="text-normal text-white text-small mb-1 mt-3">
              <span>{`${t(`Connect with us`)}`}:</span>
              <span> +250 786 791 388 </span>{" "}
              <span>info@minict.gov.rw</span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
export default withNamespaces()(Footer);
